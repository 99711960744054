/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import QREnrollment from './components/QREnrollemnt';
import ManualEnrollment from './components/ManualEnrollment';
import HtmlComments from '../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

class Enroll extends React.Component {
  render() {
    const { enrollmentType, selectedDeviceType } = this.props;
    return (
      <div style={{ textAlign: 'center', fontSize: 12 }}>
        {enrollmentType === 'manual' && (
          <ManualEnrollment
            selectedDeviceType={selectedDeviceType}
            appName={this.props.appName}
            goBack={this.props.goBack}
          />
        )}
        {enrollmentType === 'qr' && (
          <div>
            <HtmlComments
              permission={'/permission/admin/device-mgt/metadata/view'}
            />
            <QREnrollment
              deviceType={selectedDeviceType.toLowerCase()}
              appName={this.props.appName}
              goBack={this.props.goBack}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(Enroll));
