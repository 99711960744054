/*
 * Copyright (C) 2018 - 2024 Entgra (Pvt) Ltd, Inc - All Rights Reserved.
 *
 * Unauthorised copying/redistribution of this file, via any medium is strictly prohibited.
 *
 * Licensed under the Entgra Commercial License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *       https://entgra.io/licenses/entgra-commercial/1.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import React from 'react';
import { Table } from 'antd';
import 'moment-timezone';

const AlertsTable = ({ alerts, count, columns, pagination, loading, handleTableChange }) => {
    return (
        <Table
            rowKey={(record) => `${record.deviceId}`}
            dataSource={alerts}
            columns={columns}
            pagination={{
                ...pagination,
                size: 'small',
                total: count,
                disabled: loading,
                defaultPageSize: 15,
                showSizeChanger: true,
                pageSizeOptions: ['15', '20', '25', '30'],
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
            onChange={handleTableChange}
            loading={loading}
            bordered
        />
    );
};

export default AlertsTable;
