import {RoutesContext} from '../../index';
import React, {useState, useEffect, useContext} from 'react';
import {Col, Result, Row} from 'antd';
import {withConfigContext} from '../../../../components/ConfigContext';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import {withTranslation} from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import { subDays } from 'date-fns';
import IframeWrapper from '../../components/IframeWrapper';
import { getUnixTime } from "../../../../services/utils/functions";
import configs from "../../../../../public/conf/config.json";

const OtherDevicesUPS = (props) => {
    const config = props.context;
    const {t} = props;
    const routesContext = useContext(RoutesContext);
    const rightNow = new Date();
    const [range, setRange] = useState({
        from: getUnixTime(subDays(rightNow, 2)),
        to: getUnixTime(rightNow),
    });
    const [deviceId, setdeviceIdValue] = useState('');
    const [outsidedeviceId, setOutsidedeviceId] = useState(false);
    const [outsideDates, setOutsideDates] = useState(false);
    const [value, setValue] = useState('');
    const [dashboardUrl1, setDashboardUrl1] = useState('');
    const [dashboardUrl2, setDashboardUrl2] = useState('');

    useEffect(() => {
        // Use the context to set the current route
        routesContext.setCurrentRoute('allOtherDevicesUPS');
        setDashboardUrl1(`${configs.grafanaConfig.temperatureSensors}`);
        setDashboardUrl2(`${configs.grafanaConfig.UPSSensors}`);
    }, [deviceId, range.from, range.to]);

    useEffect(() => {

        const str = window.location.search;
        if (str) {
            const urlParams = new URLSearchParams(window.location.search);
            const paramdeviceId = urlParams.get('deviceId');
            const paramFrom = urlParams.get('from');
            const paramTo = urlParams.get('to');
            if (paramdeviceId !== null) {
                setdeviceIdValue(paramdeviceId);
                setOutsidedeviceId(true);
            }
            if (paramFrom !== null && paramTo !== null) {
                setRange({ from: paramFrom, to: paramTo });
                setOutsideDates(true);
            }
        }
        // eslint-disable-next-line
    }, [t]);

    const routes = (appName) => [
        {
            path: `/${appName}`,
            breadcrumbName: t('home_menu_home'),
        },
        {
            breadcrumbName: t('home_menu_other_devices'),
        },
        {
            breadcrumbName: t('home_menu_other_devices_ups'),
        },
    ];

    return (
        <div>
            <Authorized
                scope={['perm:rmr:admin:meter-inventory']}
                yes={
                    <div>
                        <PageHeader
                            className={styles.pageHeader}
                            ghost={false}
                            breadcrumb={
                                <BreadcrumbComponent
                                    breadcrumbList={routes(config.appName, t)}
                                />
                            }
                        >
                        </PageHeader>
                        <Row gutter={12}>
                            {/*<Col span={24}>*/}
                            {/*    <div className={styles.iframeContainer}>*/}
                            {/*        <IframeWrapper*/}
                            {/*            iframeSrc={dashboardUrl1}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</Col>*/}
                            {/*<Col xl={12} xs={24}>*/}
                            {/*    <div className={styles.iframeContainer}>*/}
                            {/*        <IframeWrapper*/}
                            {/*            iframeSrc={dashboardUrl1}*/}
                            {/*            height={"700px"}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</Col>*/}
                            <Col span={24}>
                                <div className={styles.iframeContainer}>
                                    <IframeWrapper
                                        iframeSrc={dashboardUrl2}
                                        height={"720px"}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                }
                no={
                    <Result
                        status="403"
                        title={t('noPerm_accessPageTitle')}
                        subTitle={t('noPerm_contactSysAdmin')}
                    />
                }
            />
        </div>
    );
};

export default withConfigContext(withTranslation()(OtherDevicesUPS));
