/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { AppstoreOutlined } from '@ant-design/icons';
import { Result, Typography } from 'antd';
import { withConfigContext } from '../../../../components/ConfigContext';
import DEPDevicesTable from './components/DEPDevicesTable';
import { RoutesContext } from '../../index';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import HtmlComments from '../../../../components/ReactHtmlComments/HtmlComments';
import { Trans, withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';

const { Paragraph, Title } = Typography;

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      breadcrumbName: t('home_menu_depDevices'),
    },
  ];
};

class DEPDevices extends React.Component {
  componentDidMount() {
    this.context.setCurrentRoute('dep-devices');
  }

  render() {
    const config = this.props.context;
    const { t } = this.props;
    const apiUrl =
      window.location.origin +
      config.serverConfig.invoker.uri +
      '/device-mgt/ios/v1.0/dep/devices?';
    return (
      <div>
        <HtmlComments
          permission={'/permission/admin/device-mgt/devices/dep/view'}
        />
        <Authorized
          scope={['perm:ios:dep-view']}
          yes={
            <div>
              <PageHeader
                className={styles.pageHeader}
                title={
                  <Title level={1}>
                    <span className={styles.icon}>
                      <AppstoreOutlined />
                    </span>
                    {t('home_menu_depDevices')}
                  </Title>
                }
                ghost={false}
                breadcrumb={
                  <BreadcrumbComponent
                    breadcrumbList={routes(config.appName, t)}
                  />
                }
              >
                <Paragraph className={styles.description}>
                  <Trans i18nKey="depDeviceDescription">
                    Your devices enrolled with Apple’s Device Enrollment
                    Programme (DEP) are displayed here. You can assign a user,
                    and whether logging-in and agent installation is required
                    for a particular device, by clicking on Edit Enrollment. A
                    device can be assigned to a profile by clicking Assign DEP
                    Profile and specifying the profile UUID.
                  </Trans>
                </Paragraph>
              </PageHeader>
              <div className={styles.table}>
                <DEPDevicesTable apiUrl={apiUrl} />
              </div>
            </div>
          }
          no={
            <Result
              status="403"
              title={t('noPerm_accessPageTitle')}
              subTitle={t('noPerm_contactSysAdmin')}
            />
          }
        />
      </div>
    );
  }
}

DEPDevices.contextType = RoutesContext;
export default withConfigContext(withTranslation()(DEPDevices));
