/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import { Alert, Divider, List, Row, Tooltip, Typography } from 'antd';
import axios from 'axios';
import CustomAlerts from '../CustomAlerts';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import { QuestionCircleOutlined } from '@ant-design/icons';
import './style.css';
import APICard from './components/APICard';
import { withTranslation } from 'react-i18next';

const { Title, Text } = Typography;

class CustomAPITable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      modalVisible: false,
      modalData: {},
      contentPayload: {},
      loading: false,
      checkedItemsObject: {},
      initialValue: [],
    };
  }

  componentDidMount() {
    const config = this.props.context;
    // XML structure from backend
    const { itemData } = this.props;
    // If there are any values saved, store that value array on contentPayload
    // otherwise edit wont save existing values
    if (itemData.value) {
      let contentPayload = {};
      itemData.value.forEach(item => {
        contentPayload[item.index] = item;
      });

      this.setState({
        contentPayload,
        initialValue: itemData.value,
      });
    }
    const apiUrl =
      window.location.origin +
      config.serverConfig.invoker.uri +
      itemData.apiTable.url;
    this.fetchData(apiUrl);
  }

  // fetch data from api
  fetchData = apiUrl => {
    // send request to the invokerss
    const { itemData, t, deviceType } = this.props;
    this.setState({ loading: true });
    let payload = JSON.parse(itemData.apiTable.payload);
    // This is only for Enrollment App restriction policy
    // todo dynamically add payload values
    payload.deviceType = deviceType;
    axios
      .post(apiUrl, payload)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            data: res.data.data[itemData.apiTable.mainAttribute],
            loading: false,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_data') }),
          t,
        );
        this.setState({ loading: false });
      });
  };

  // Callback of APICard
  getAPICardData = (checkboxStatus, cardData, index) => {
    // Get current payload array from state
    const { contentPayload } = this.state;

    // This value (enrollmentAppInstall) is a specific value for enrollment application install policy
    // TODO: Modify the agent side accordingly and remove this line
    cardData.enrollmentAppInstall = true;

    contentPayload[index] = cardData;

    const item = { ...this.props.itemData };

    if (checkboxStatus === false) {
      delete contentPayload[index];
    }

    // Set payload array as the value of the item (CustomAPITable) and sent through the callback
    item.value = Object.values(contentPayload);
    this.props.callback(item);
    this.setState({ contentPayload });
  };

  renderCard = (item, index) => {
    const { data } = this.state;
    const itemData = { ...this.props.itemData };
    return (
      <List.Item>
        <APICard
          item={item}
          apiItem={data[index]}
          itemCount={data.length}
          index={data.indexOf(item)}
          itemData={itemData}
          callback={(checkboxStatus, cardData, index) =>
            this.getAPICardData(checkboxStatus, cardData, index)
          }
          disabled={this.props.disabled}
        />
      </List.Item>
    );
  };

  render() {
    const { data, loading } = this.state;
    const { itemData, t } = this.props;
    const { value } = itemData;
    return (
      <div>
        {itemData.divider && (
          <Divider plain>
            <Text type="secondary">{t(itemData.divider)}</Text>
          </Divider>
        )}
        {itemData.subTitle && <Title level={4}> {t(itemData.divider)} </Title>}
        <CustomAlerts notifications={itemData.notifications} />
        <div className="apiTableContainer">
          <Row>
            <span>
              {t(`${itemData.label}`)} &nbsp;
              {itemData.tooltip && (
                <Tooltip
                  title={
                    <div>
                      <div>{t(`${itemData.tooltip}`)}</div>
                      <div style={{ textAlign: 'right' }}>
                        {itemData.docLink && (
                          <a
                            onClick={() => {
                              window.open(itemData.docLink, '_blank');
                            }}
                          >
                            {' '}
                            {t('label_SeeMoreDetails')}
                          </a>
                        )}
                      </div>
                    </div>
                  }
                  placement="right"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              )}
            </span>
          </Row>
          <Row
            style={{ maxHeight: 250, overflowY: 'auto', overflowX: 'hidden' }}
          >
            <div style={{ margin: 10 }}>
              <List
                loading={loading}
                dataSource={data}
                grid={{
                  gutter: 16,
                  xs: 1,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                  xxl: 4,
                }}
                renderItem={(item, index) => this.renderCard(item, index)}
              />
            </div>
          </Row>
          <Alert
            style={{
              marginTop: 5,
              display:
                (value === '' ||
                  (Array.isArray(value) && value.length === 0)) &&
                itemData.error &&
                itemData.error.required
                  ? 'flex'
                  : 'none',
            }}
            message={
              (value === '' || (Array.isArray(value) && value.length === 0)) &&
              itemData.error &&
              itemData.error.required &&
              itemData.error.required
            }
            type="error"
          />
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(CustomAPITable));
