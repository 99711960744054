import React from 'react';
import { DatePicker } from "antd";
import { CalendarOutlined } from "@ant-design/icons";

const CustomInput = React.forwardRef((props, ref) => {
    return (
        <div className="calender-input" onClick={props.onClick}>
            <input
                className="form-control"
                type="text"
                onClick={props.onClick}
                ref={ref}
                value={props.value}
                placeholder={props.placeholder}
            />
            <div className="calender-icon">
                <CalendarOutlined/>
            </div>
        </div>
    );
});

const DateInput = ({
                       id = 'startDatePicker',
                       selected,
                       onChange,
                       dateTimeFormat = 'dd/MM/yyyy h:mm a',
                       disabled = false,
                       dateInputName = 'startDatePicker',
                       placeholderText,
                   }) => {
    return (
        <>
            <DatePicker
                selected={selected}
                onChange={onChange}
                className="form-control"
                showTime
                dateFormat={dateTimeFormat}
                id={id}
                dateInputName={dateInputName}
                autoComplete="off"
                customInput={<CustomInput/>}
                disabled={disabled}
                placeholderText={placeholderText}
            />
        </>
    );
};

export default DateInput;
