/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Form, Button, Input, Modal, notification } from 'antd';
import axios from 'axios';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import { withTranslation } from 'react-i18next';
import { splitUserDomain } from '../../../../../../../../services/utils/commonHandler';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

class ExternalDevicesModal extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      isDeviceEditModalVisible: false,
      metaData: null,
      loading: false,
    };
  }

  openDeviceEditModal = () => {
    this.setState({
      isDeviceEditModalVisible: true,
    });
    this.getExternalDevicesForUser(this.props.user);
  };

  onCancelHandler = () => {
    this.setState({
      isDeviceEditModalVisible: false,
    });
  };

  getExternalDevicesForUser = userName => {
    const { t } = this.props;
    let userDomain = splitUserDomain(userName);
    let apiURL =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      this.config.serverConfig.invoker.deviceMgt +
      `/users/claims?username=${userDomain[0]}&domain=${userDomain[1]}`;

    axios
      .get(apiURL)
      .then(res => {
        if (res.status === 200) {
          if (res.data.data.hasOwnProperty('http://wso2.org/claims/devices')) {
            this.setState({
              metaData: JSON.parse(
                res.data.data['http://wso2.org/claims/devices'],
              ),
            });
          } else {
            this.setState({
              metaData: [],
            });
          }
        }
      })
      .catch(error => {
        handleApiError(error, t('api_retrieveClaimsError'), t);
      });
  };

  setExternalDevicesForUser = (userName, payload) => {
    this.setState({ loading: true });
    const { t } = this.props;
    let userDomain = splitUserDomain(userName);
    let apiURL =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      this.config.serverConfig.invoker.deviceMgt +
      `/users/claims?username=${userDomain[0]}&domain=${userDomain[1]}`;

    axios
      .put(apiURL, payload)
      .then(res => {
        if (res.status === 200) {
          notification.success({
            message: t('api_successMsg'),
            duration: 0,
            description: t('api_updatedMsg'),
          });
        }
        this.setState({
          isDeviceEditModalVisible: false,
          loading: false,
        });
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_updateError', { label: t('label_claims') }),
          t,
        );
        this.setState({ loading: false });
      });
  };

  onFinish = values => {
    this.setExternalDevicesForUser(this.props.user, values.claims);
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div>
          <Button type="link" size={'small'} onClick={this.openDeviceEditModal}>
            {t('label_userAttributes')}
          </Button>
        </div>
        <div>
          <Modal
            title={t('title_externalDeviceClaims')}
            width="40%"
            open={this.state.isDeviceEditModalVisible}
            onCancel={this.onCancelHandler}
            maskClosable={false}
            footer={null}
          >
            <div style={{ alignItems: 'center' }}>
              <p>{t('external_devices_infoTxt')}</p>
              {this.state.metaData && (
                <Form
                  name="dynamic_form_item"
                  {...formItemLayoutWithOutLabel}
                  onFinish={this.onFinish}
                  initialValues={{ claims: this.state.metaData }}
                >
                  <Form.List name="claims">
                    {(fields, { add, remove }) => {
                      return (
                        <div>
                          {fields.map((field, index) => (
                            <Form.Item
                              {...(index === 0
                                ? formItemLayout
                                : formItemLayoutWithOutLabel)}
                              label={index === 0 ? t('label_deviceMAC') : ''}
                              required={false}
                              key={field.key}
                            >
                              <Form.Item
                                {...field}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: t('form_MACRequiredMsg'),
                                  },
                                ]}
                                noStyle
                              >
                                <Input
                                  placeholder={t('label_MACAddress')}
                                  style={{ width: '60%' }}
                                />
                              </Form.Item>
                              {fields.length > 1 ? (
                                <MinusCircleOutlined
                                  className="dynamic-delete-button"
                                  style={{ margin: '0 8px' }}
                                  onClick={() => {
                                    remove(field.name);
                                  }}
                                />
                              ) : null}
                            </Form.Item>
                          ))}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => {
                                add();
                              }}
                              style={{ width: '60%' }}
                            >
                              <PlusOutlined /> {t('label_addField')}
                            </Button>
                          </Form.Item>
                        </div>
                      );
                    }}
                  </Form.List>
                  <Form.Item>
                    <Button
                      loading={this.state.loading}
                      type="primary"
                      htmlType="submit"
                    >
                      {t('label_submit')}
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(ExternalDevicesModal));
