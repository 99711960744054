/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { BookOutlined, SearchOutlined } from '@ant-design/icons';
import {Button, Collapse, Result, Typography} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import RolesTable from './components/RolesTable';
import { RoutesContext } from '../../index';
import styles from './styles.module.css';
import Filter from '../../components/Filter';
import { withConfigContext } from '../../../../components/ConfigContext';
import Authorized from '../../../../components/Authorized';
import HtmlComments from '../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      breadcrumbName: t('home_menu_roles'),
    },
  ];
};

const searchFields = t => {
  return [
    {
      name: 'filter',
      placeholder: t('label_roleName'),
      label: t('label_name'),
      type: 'input',
    },
  ];
};

class Roles extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      filters: {},
    };
  }

  componentDidMount() {
    this.context.setCurrentRoute('roles');
  }

  fetchFilterData = (params, values) => {
    this.setState({ filters: values });
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <HtmlComments permission={'/permission/admin/device-mgt/roles/view'} />
        <Authorized
          scope={['perm:roles:view']}
          yes={
            <div>
              <PageHeader
                className={styles.pageHeader}
                title={
                  <Title level={1}>
                    <span className={styles.icon}>
                      <BookOutlined />
                    </span>
                    {t('home_menu_roles')}
                  </Title>
                }
                ghost={false}
                breadcrumb={
                  <BreadcrumbComponent
                    breadcrumbList={routes(this.config.appName, t)}
                  />
                }
                extra={[
                  <Button onClick={() => {this.props.history.push(`/${this.config.appName}/roles?add-new-role=true`)}} style={{marginRight:'50px'}} type={"primary"}>{t('add_role')}</Button>,
                ]}
              >
                <Paragraph className={styles.description}>
                  {t('roles_description')}
                </Paragraph>
              </PageHeader>
              <div className={styles.table}>
                <Collapse
                  bordered={false}
                  expandIcon={() => <SearchOutlined />}
                  className={styles.searchCollapse}
                >
                  <Panel
                    header={t('form_searchRoles')}
                    key="1"
                    style={{ borderColor: '#fff' }}
                  >
                    <Filter
                      filters={searchFields(t)}
                      callback={(params, values) =>
                        this.fetchFilterData({}, values)
                      }
                    />
                  </Panel>
                </Collapse>
                <RolesTable filters={this.state.filters} />
              </div>
            </div>
          }
          no={
            <Result
              status="403"
              title={t('noPerm_accessPageTitle')}
              subTitle={t('noPerm_contactSysAdmin')}
            />
          }
        />
      </div>
    );
  }
}
Roles.contextType = RoutesContext;
export default withConfigContext(withTranslation()(Roles));
