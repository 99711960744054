/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Button,
  Divider,
  Form,
  Input,
  Select,
  Tooltip,
  Upload,
} from 'antd';
import { InboxOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 10 },
    xl: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 12 },
    xl: { span: 12 },
  },
};

const buttonFormItem = {
  wrapperCol: {
    md: { span: 20 },
    lg: { span: 22 },
    xl: { span: 22 },
  },
};

class APNSConfig extends React.Component {
  formRef = React.createRef();

  apnsCert = '';
  apnsCertName = '';

  constructor(props) {
    super(props);
    this.state = {
      APNSFileList: [],
    };
  }

  componentDidMount() {
    let policyUIPayload = [...this.props.platformConfigData];
    let initialValues = {};

    for (let policyConfig of policyUIPayload) {
      switch (policyConfig.name) {
        case 'APNSCert':
          this.apnsCert = policyConfig.value;
          break;
        case 'APNSCertName':
          this.apnsCertName = policyConfig.value;
          initialValues.APNSFileList = {
            fileList: [
              {
                name: policyConfig.value,
              },
            ],
          };
          this.setState({
            APNSFileList: [
              {
                uid: '1',
                name: policyConfig.value,
              },
            ],
          });
          break;
        case 'APNSCertPassword':
          initialValues.APNSCertPassword = policyConfig.value;
          break;
        case 'iOSConfigAPNSMode':
          initialValues.iOSConfigAPNSMode = policyConfig.value;
          break;
      }
    }
    this.formRef.current.setFieldsValue(initialValues);
  }

  onFinish = values => {
    values.APNSCertName = this.apnsCertName;
    values.APNSCert = this.apnsCert;
    delete values.APNSFileList;
    this.props.callback(values);
  };

  onFinishFailed = err => {
    console.log('Finish Failed ....... ', err);
  };

  beforeUpload = file => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      this.apnsCert = e.target.result;
    };
    // Prevent upload
    return false;
  };

  handleAPNSFileChange = info => {
    let fileList = [...info.fileList];

    // Limit the number of uploaded files
    fileList = fileList.slice(-1);
    this.setState({ APNSFileList: fileList });
    if (fileList.length === 0) {
      this.apnsCertName = '';
      this.apnsCert = '';
    } else {
      this.apnsCertName = fileList[0].name;
    }
  };

  render() {
    const { APNSFileList } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div>
          <Form
            {...formItemLayout}
            name="APNSProfileForm"
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            scrollToFirstError
            ref={this.formRef}
          >
            <div>
              <Divider>
                <PageHeader
                  className="site-page-header-responsive"
                  subTitle={t('label_APNSCertificate')}
                />
              </Divider>
            </div>

            <Form.Item
              label={
                <span>
                  {t('label_APNSCertificate')}&nbsp;
                  <Tooltip title={t('form_uploadAPNSCertificate')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              rules={[
                {
                  required: true,
                  message: t('form_generalRequiredMsg', {
                    label: t('label_APNSCertificate'),
                  }),
                },
                {
                  validator(rule, value) {
                    if (
                      !value ||
                      (value && value.fileList && value.fileList.length === 0)
                    ) {
                      return Promise.reject(
                        t('form_generalRequiredMsg', {
                          label: t('label_APNSCertificate'),
                        }),
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              name="APNSFileList"
            >
              <Upload.Dragger
                beforeUpload={this.beforeUpload}
                onChange={this.handleAPNSFileChange}
                fileList={APNSFileList}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  {t('uploadAPNSCertificate_infoTxt')}
                </p>
              </Upload.Dragger>
            </Form.Item>

            <Form.Item
              label={
                <span>
                  {t('label_APNSCertificatePassword')}&nbsp;
                  <Tooltip title={t('label_APNSCertificatePassword')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="APNSCertPassword"
              rules={[
                {
                  required: true,
                  message: t('form_generalRequiredMsg', {
                    label: t('label_APNSCertificatePassword'),
                  }),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="iOSConfigAPNSMode"
              label={
                <span>
                  {t('label_mode')}&nbsp;
                  <Tooltip title={t('label_releaseMode')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              initialValue="1"
            >
              <Select style={{ width: 120 }}>
                <Option value="1">{t('label_production')}</Option>
                <Option value="2">{t('label_developer')}</Option>
              </Select>
            </Form.Item>
            <Form.Item {...buttonFormItem}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ float: 'right' }}
              >
                {t('label_save')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(APNSConfig);
