/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Row, Col, Card, Steps, message, Result, Button, Alert } from 'antd';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import ConfigureProfile from '../../../../components/ConfigureProfile';
import SelectPolicyType from '../../../../components/SelectPolicyType';
import AssignGroups from '../../../../components/AssignGroups';
import PublishDevices from '../../../../components/PublishDevices';
import { combinePolicyDevicePayloadWithUIPayload } from '../../../../services/payloadHandler';
import axios from 'axios';
import styles from './styles.module.css';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import { isAuthorized } from '../../../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

const { Step } = Steps;

class DuplicatePolicy extends React.Component {
  policyUIConfigurations = null;
  policyFeatureList = [];
  policyData = {};
  payloadData = {};
  deviceType = null;
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      currentStepIndex: -1,
      isSuccess: false,
    };
  }

  componentDidMount() {
    this.getSelectedPolicy(this.props.policyId);
  }

  modifyUIPayload = policyUIPayload => {
    this.policyUIConfigurations = combinePolicyDevicePayloadWithUIPayload(
      policyUIPayload,
      this.policyFeatureList,
    );
    this.setState({
      currentStepIndex: 0,
    });
  };

  setPolicyPayloadData = data => {
    this.payloadData = { ...this.payloadData, ...data };
  };

  setPublishData = data => {
    this.payloadData = { ...this.payloadData, ...data };
    this.createPayload();
  };

  createPayload = () => {
    const {
      publishDevicesData,
      configureProfileData,
      policyTypeData,
      groupData,
    } = this.payloadData;
    const profile = {
      profileName: publishDevicesData.policyName,
      deviceType: this.deviceType,
      profileFeaturesList: configureProfileData,
    };

    const payload = {
      ...publishDevicesData,
      compliance: 'enforce',
      ownershipType: null,
      ...policyTypeData,
      profile: profile,
      ...groupData,
    };
    this.onDuplicatePolicy(JSON.stringify(payload));
  };

  getSelectedPolicy = policyId => {
    const { t } = this.props;
    let apiUrl =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      this.config.serverConfig.invoker.deviceMgt +
      '/policies/' +
      policyId;

    // send request to the invokers
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          this.policyFeatureList = res.data.data.profile.profileFeaturesList;
          this.policyData = res.data.data;
          this.deviceType = res.data.data.profile.deviceType;
          if (
            isAuthorized(this.config.scopes, [
              [
                'perm:android:view-configuration',
                'perm:ios:view-configuration',
                'perm:windows:view-configuration',
              ],
            ])
          ) {
            this.getPolicyConfigJson(this.deviceType);
          }
          this.props.setPolicyData(this.deviceType, res.data.data.policyName);
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_selectedPolicy') }),
          t,
        );
      });
  };

  getPolicyConfigJson = type => {
    const { t } = this.props;
    let apiUrl =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      '/device-mgt/' +
      type +
      '/v1.0/configuration/policy-ui';
    // send request to the invokers
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          const policyUIPayload = res.data.data.policies.map(policy => {
            policy.isActivePolicy = false;
            return policy;
          });
          this.modifyUIPayload(policyUIPayload);
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_policyDetails') }),
          t,
        );
      });
  };

  onDuplicatePolicy = value => {
    const { t } = this.props;
    axios
      .post(
        window.location.origin +
          this.config.serverConfig.invoker.uri +
          this.config.serverConfig.invoker.deviceMgt +
          '/policies/',
        value,
        { headers: { 'Content-Type': 'application-json' } },
      )
      .then(res => {
        if (res.status === 201) {
          this.setState({
            isSuccess: true,
            currentStepIndex: 4,
          });
        }
      })
      .catch(error => {
        if (error.hasOwnProperty('response') && error.response.status === 401) {
          message.error(t('api_notLoggedInError'));
          window.location.href =
            window.location.origin + `/${this.config.appName}/login`;
        } else {
          this.setState({ currentStepIndex: 4 });
        }
      });
  };

  setNextStep = () => {
    const currentStepIndex = this.state.currentStepIndex + 1;
    this.setState({ currentStepIndex });
  };

  setPrevStep = () => {
    const currentStepIndex = this.state.currentStepIndex - 1;
    this.setState({ currentStepIndex });
  };

  render() {
    const { currentStepIndex, isSuccess } = this.state;
    const { configureProfileData } = this.payloadData;
    const { t } = this.props;
    return (
      <div>
        {currentStepIndex !== -1 && (
          <Row>
            <Col span={20} offset={2}>
              <Steps style={{ minHeight: 32 }} current={currentStepIndex}>
                <Step
                  key="ProfileConfigure"
                  title={t('label_configureProfile')}
                />
                <Step key="PolicyType" title={t('label_selectPolicyType')} />
                <Step key="AssignGroups" title={t('label_assignToGroups')} />
                <Step key="Publish" title={t('label_publishToDevices')} />
                <Step key="Finish" title={t('label_finish')} />
              </Steps>
            </Col>
            <Col span={22} offset={1}>
              <Card className={styles.cardContainer}>
                <div
                  style={{ display: currentStepIndex === 0 ? 'unset' : 'none' }}
                >
                  {!isAuthorized(this.config.scopes, [
                    [
                      'perm:android:view-configuration',
                      'perm:ios:view-configuration',
                      'perm:windows:view-configuration',
                    ],
                  ]) && (
                    <div>
                      <HtmlComments
                        permission={
                          '/permission/admin/device-mgt/platform-configurations/view OR\n' +
                          '/permission/admin/device-mgt/devices/enroll/android'
                        }
                      />
                      <Alert
                        message={t(
                          'form_senoPerm_policyConfigurationsarchRoles',
                        )}
                        banner
                        style={{ marginBottom: 15 }}
                      />
                    </div>
                  )}
                  {isAuthorized(this.config.scopes, [
                    [
                      'perm:android:view-configuration',
                      'perm:ios:view-configuration',
                      'perm:windows:view-configuration',
                    ],
                  ]) && (
                    <div>
                      <HtmlComments
                        permission={
                          '/permission/admin/device-mgt/platform-configurations/view OR\n' +
                          '/permission/admin/device-mgt/devices/enroll/android'
                        }
                      />
                      <ConfigureProfile
                        policyUIConfigurationsList={this.policyUIConfigurations}
                        setPolicyPayloadData={this.setPolicyPayloadData}
                        setNextStep={this.setNextStep}
                        deviceType={this.deviceType}
                        disabled={false}
                      />
                    </div>
                  )}
                </div>
                <div
                  style={{ display: currentStepIndex === 1 ? 'unset' : 'none' }}
                >
                  {configureProfileData && (
                    <SelectPolicyType
                      activeFeatures={configureProfileData}
                      setPolicyPayloadData={this.setPolicyPayloadData}
                      policyData={this.policyData}
                      setPrevStep={this.setPrevStep}
                      setNextStep={this.setNextStep}
                    />
                  )}
                </div>
                <div
                  style={{ display: currentStepIndex === 2 ? 'unset' : 'none' }}
                >
                  <HtmlComments
                    permission={
                      '/permission/admin/device-mgt/roles/view,\n' +
                      '/permission/admin/device-mgt/admin/groups/view OR\n' +
                      '/permission/admin/device-mgt/groups/view'
                    }
                  />
                  <AssignGroups
                    setPolicyPayloadData={this.setPolicyPayloadData}
                    policyData={this.policyData}
                    setPrevStep={this.setPrevStep}
                    setNextStep={this.setNextStep}
                  />
                </div>
                <div
                  style={{ display: currentStepIndex === 3 ? 'unset' : 'none' }}
                >
                  <PublishDevices
                    policyData={this.policyData}
                    setPublishData={this.setPublishData}
                    setPrevStep={this.setPrevStep}
                    disabled={isSuccess}
                  />
                </div>
                <div
                  style={{ display: currentStepIndex === 4 ? 'unset' : 'none' }}
                >
                  {isSuccess && (
                    <Result
                      status="success"
                      title={t('api_policyDuplicateMsg')}
                      extra={[
                        <Button
                          type="primary"
                          key="console"
                          onClick={() => {
                            window.location.href =
                              window.location.origin +
                              `/${this.config.appName}/policies/`;
                          }}
                        >
                          {t('label_gotoPolicies')}
                        </Button>,
                      ]}
                    />
                  )}
                  {!isSuccess && (
                    <Result
                      status="error"
                      title={t('api_errorMsg')}
                      subTitle={t('api_updateError', {
                        label: t('home_menu_policy'),
                      })}
                      extra={[
                        <Button
                          type="primary"
                          key="console"
                          onClick={this.setPrevStep}
                        >
                          {t('label_back')}
                        </Button>,
                      ]}
                    />
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(DuplicatePolicy));
