/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */
import React from 'react';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import { Form, Button, Col, Radio, Select, Spin } from 'antd';
import {
  onChangeRoles,
  onChangeDeviceGroups,
} from '../../services/policyFunctionHandler';
import axios from 'axios';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import styles from './styles.module.css';
import { isAuthorized } from '../../../../../../services/utils/authorizationHandler';
const { Option } = Select;
import debounce from 'lodash.debounce';
import HtmlComments from '../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

class AssignGroups extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.userSelector = React.createRef();
    this.roleSelector = React.createRef();
    this.state = {
      roles: [],
      users: [],
      groups: [],
      isRoleSelect: false,
      fetchingUsers: false,
    };
    this.getUsersList = debounce(this.getUsersList, 800);
  }
  componentDidMount() {
    if (isAuthorized(this.config.scopes, ['perm:roles:view'])) {
      this.getRolesList();
    }
    if (
      isAuthorized(this.config.scopes, [
        ['perm:admin-groups:view', 'perm:groups:groups'],
      ])
    ) {
      this.getGroupsList();
    }
    const { policyData } = this.props;

    this.setState({
      isRoleSelect: !policyData || (policyData && policyData.roles.length > 0),
    });
  }

  // generate payload by adding Assign Groups
  onFinish = values => {
    if (typeof values.roles === 'string') {
      values.roles = [values.roles];
    }
    if (!values.users) {
      delete values.users;
    }

    if (this.state.isRoleSelect) {
      values.users = [];
    } else {
      values.roles = [];
    }

    if (values.deviceGroups === 'NONE') {
      delete values.deviceGroups;
    } else {
      values.deviceGroups = values.deviceGroups.map(deviceGroup => {
        return JSON.parse(deviceGroup);
      });
    }
    this.props.setPolicyPayloadData({ groupData: values });
    this.props.setNextStep();
  };

  checkIsUserSelect = e => {
    if (e.target.value === 'roleSelector') {
      this.setState({ isRoleSelect: true });
    } else {
      this.setState({ isRoleSelect: false });
    }
  };

  getRolesList = () => {
    const { t } = this.props;
    let apiURL =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      this.config.serverConfig.invoker.deviceMgt +
      '/roles?user-store=all&limit=100';

    axios
      .get(apiURL)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            roles: res.data.data.roles,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_roles') }),
          t,
        );
      });
  };

  getUsersList = value => {
    this.setState({ fetchingUsers: true });
    const { t } = this.props;
    let apiURL =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      this.config.serverConfig.invoker.deviceMgt +
      '/users/search/usernames?filter=' +
      value +
      '&domain=all';
    axios
      .get(apiURL)
      .then(res => {
        if (res.status === 200) {
          let users = res.data.data;
          this.setState({
            users,
            fetchingUsers: false,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_users') }),
          t,
        );
        this.setState({ fetchingUsers: false });
      });
  };

  // fetch data from api
  getGroupsList = () => {
    let apiUrl;
    const { t } = this.props;
    if (isAuthorized(this.config.scopes, ['perm:admin-groups:view'])) {
      apiUrl =
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        this.config.serverConfig.invoker.deviceMgt +
        '/admin/groups';
    } else if (isAuthorized(this.config.scopes, ['perm:groups:groups'])) {
      apiUrl =
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        this.config.serverConfig.invoker.deviceMgt +
        '/groups';
    }
    apiUrl += '?offset=0&limit=99';

    // send request to the invokerss
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          const groups = res.data.data.deviceGroups.map(group => {
            group.deviceGroupValue = {
              id: group.id,
              name: group.name,
            };
            return group;
          });
          this.setState({
            groups,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_deviceGroups') }),
          t,
        );
      });
  };

  render() {
    const { policyData, t } = this.props;
    const { fetchingUsers } = this.state;
    let deviceGroups = null;
    if (policyData && policyData.deviceGroups.length > 0) {
      deviceGroups = policyData.deviceGroups.map(deviceGroup => {
        const initialGroupValues = JSON.stringify({
          id: deviceGroup.id,
          name: deviceGroup.name,
        });
        return initialGroupValues;
      });
    } else {
      deviceGroups = 'NONE';
    }
    return (
      <div>
        <Form
          name="assignGroups"
          onFinish={this.onFinish}
          initialValues={{
            roles: policyData ? policyData.roles : 'ANY',
            deviceGroups: policyData ? deviceGroups : 'NONE',
            users: policyData && policyData.users,
          }}
        >
          <div>
            <Radio.Group
              defaultValue={
                !policyData || (policyData && policyData.roles.length > 0)
                  ? 'roleSelector'
                  : 'userSelector'
              }
              onChange={this.checkIsUserSelect}
              disabled={this.props.disabled}
            >
              <Radio value="roleSelector">{t('form_setUserRole')}</Radio>
              <Radio value="userSelector">{t('form_setUser')}</Radio>
            </Radio.Group>
            <div>
              {this.state.isRoleSelect && (
                <Form.Item
                  name="roles"
                  rules={[
                    {
                      required: true,
                      message: t('form_roleRequiredMsg'),
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    style={{ width: '100%', marginTop: 10 }}
                    defaultActiveFirstOption={true}
                    onChange={onChangeRoles}
                    disabled={this.props.disabled}
                  >
                    <Option value={'ANY'}>{t('label_any')}</Option>
                    {this.state.roles.map(role => (
                      <Option key={role} value={role}>
                        {role}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </div>
            <div>
              <HtmlComments
                permission={'/permission/admin/device-mgt/users/view'}
              />
              {!this.state.isRoleSelect && (
                <Form.Item
                  name="users"
                  rules={[
                    {
                      required: true,
                      message: t('form_userRequiredMsg'),
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder={t('form_searchUser')}
                    style={{ width: '100%', marginTop: 10 }}
                    onSearch={
                      isAuthorized(this.config.scopes, ['perm:users:search'])
                        ? this.getUsersList
                        : null
                    }
                    disabled={this.props.disabled}
                    notFoundContent={
                      fetchingUsers ? <Spin size="small" /> : null
                    }
                    filterOption={false}
                  >
                    {this.state.users.map(user => (
                      <Option key={user.username} value={user.username}>
                        {user.username}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </div>
          </div>
          <Form.Item
            style={{ display: 'block' }}
            name="deviceGroups"
            label={t('form_selectGroups')}
          >
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              onChange={onChangeDeviceGroups}
              disabled={this.props.disabled}
            >
              <Option value={'NONE'}>NONE</Option>
              {this.state.groups.map(group => (
                <Option
                  key={group.name}
                  value={JSON.stringify(group.deviceGroupValue)}
                  // value={group.name}
                >
                  {group.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {!this.props.disabled && (
            <Col span={16} offset={18}>
              <div className={styles.buttons}>
                <Form.Item>
                  <Button
                    style={{ marginRight: 8 }}
                    onClick={this.props.setPrevStep}
                  >
                    {t('label_back')}
                  </Button>
                  <Button type="primary" htmlType="submit">
                    {t('label_continue')}
                  </Button>
                </Form.Item>
              </div>
            </Col>
          )}
        </Form>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(AssignGroups));
