/*
 * Copyright (C) 2018 - 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Table } from 'antd';
// Load locale-specific relative date/time formatting rules.
import { withConfigContext } from '../../../../components/ConfigContext';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import { withTranslation } from 'react-i18next';

class InvoiceHistoryTable extends React.Component {
    constructor(props) {
        super(props);
        this.config = this.props.context;
        this.state = {
            data: {
                count: 12,
                operators: this.props.invoiceHistory
            },
            pagination: {},
            loading: false,
            selectedRows: [],
            selectedRowKeys: [],
        };
    }

    handleTableChange = (pagination) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
    };

    render() {
        const { t } = this.props;
        const {
            data,
            pagination,
            loading,
        } = this.state;


        const columns = [
            {
                title: "Month",
                dataIndex: 'MONTH_NAME',
                width: 75,
                // eslint-disable-next-line react/display-name
                render: (data, row) => {
                    const { MONTH_NAME } = row;
                    return (
                        <p>{MONTH_NAME}</p>
                    );
                },
            },
            {
                title: "Invoice Value",
                dataIndex: 'INVOICE_VALUE',
                key: 'INVOICE_VALUE',
                width: 75,
                // eslint-disable-next-line react/display-name
                render: (data, row) => {
                    const { INVOICE_VALUE } = row;
                    return (typeof INVOICE_VALUE !== 'undefined' ? INVOICE_VALUE : '');
                },
            },
            {
                title: "Invoice Date",
                dataIndex: 'INVOICE_DATE',
                key: 'INVOICE_DATE',
                width: 75,
                // eslint-disable-next-line react/display-name
                render: (data, row) => {
                    const { INVOICE_DATE } = row;
                    return (typeof INVOICE_DATE !== 'undefined' ? INVOICE_DATE : '');
                },
            },
            {
                title: "Payments",
                dataIndex: 'PAYMENTS',
                key: 'PAYMENTS',
                width: 75,
                // eslint-disable-next-line react/display-name
                render: (data, row) => {
                    const { PAYMENTS } = row;
                    return (typeof PAYMENTS !== 'undefined' ? PAYMENTS : '');
                },
            },
            {
                title: "Balance C/F",
                dataIndex: 'BALANCE_CF',
                key: 'BALANCE_CF',
                width: 75,
                // eslint-disable-next-line react/display-name
                render: (data, row) => {
                    const { BALANCE_CF } = row;
                    return (typeof BALANCE_CF !== 'undefined' ? BALANCE_CF : '');
                },
            },
            {
                title: "Invoice",
                dataIndex: 'INVOICE',
                key: 'INVOICE',
                width: 75,
                // eslint-disable-next-line react/display-name
                render: (data, row) => {
                    const { MONTH_ID } = row;
                    return (
                        <Link to={{
                            pathname: `/${this.config.appName}/invoice/view`,
                            state: {
                                monthID: MONTH_ID
                            },
                        }}
                        >
                            <p>View Invoice</p>
                        </Link>
                    );
                },
            },
        ];

        return (
            <div className={styles.tableContainer}>
                {data.count !== 0 && (
                    <div>
                        <Table
                            className={styles.customTable}
                            columns={columns}
                            rowKey={e => e.MONTH_ID}
                            dataSource={data.operators}
                            pagination={{
                                ...pagination,
                                size: 'small',
                                total: data.count,
                                showTotal: (total, range) =>
                                    t('table_pagination', {
                                        range1: range[0],
                                        range2: range[1],
                                        total: total,
                                        data: t('home_menu_invoiceHistory'),
                                    }),
                            }}
                            onChange={this.handleTableChange}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default withConfigContext(withTranslation()(InvoiceHistoryTable));
