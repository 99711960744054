/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Button,
  Select,
  Row,
  Form,
  Input,
  Card,
  Space,
  Col,
  Tooltip,
  DatePicker,
} from 'antd';
import {
  SearchOutlined,
  ClearOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import dayjs from "dayjs";

const { Option } = Select;
const { RangePicker } = DatePicker;

const defaultStartTime = moment().subtract(12, 'hours'); // Set default start time to 12 hours ago
const defaultEndTime = moment(); // Set default end time to current time

class FilterNew extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      searchEnabled: false,
      enableClear: false,
      labelNameMap: {},
    };
  }

  onFinish = values => {
    let payload = {};
    this.props.filters.forEach((filter, index) => {
      const filterValue = values[`filter-${index}`];
      if (filterValue !== undefined) {
        payload[this.state.labelNameMap[filter.label]] = filterValue;
      }
    });
    this.props.callback({}, payload);
  };

  componentDidMount() {
    let labelNameMap = { ...this.state.labelNameMap };
    this.props.filters.forEach(filter => {
      labelNameMap[filter.label] = filter.name;
    });
    this.setState({ labelNameMap });
  }

  clearForm = () => {
    this.formRef.current.resetFields();
    this.props.callback({}, {});
    this.setState({
      searchEnabled: false,
      enableClear: false,
    });
  };

  handleValuesChange = (changedValues, allValues) => {
    const searchEnabled = Object.values(allValues).some(value => value !== undefined && value !== '');
    this.setState({ searchEnabled, enableClear: searchEnabled });
  };

  renderFilter = (filter, index) => {
    const { t } = this.props;
    const formItemMargin = -2;

    switch (filter.type) {
      case 'select':
        return (
            <Form.Item
                name={`filter-${index}`}
                fieldKey={`filter-${index}`}
                style={{ marginBottom: formItemMargin }}
            >
              <Select
                  size="small"
                  bordered={false}
                  placeholder={`${t('label_select')} ${filter.placeholder}`}
                  dropdownMatchSelectWidth={false}
              >
                {filter.values.map((value, i) => (
                    <Option key={i} value={value}>
                      {value}
                    </Option>
                ))}
              </Select>
            </Form.Item>
        );
      case 'creatableSelect':
        return (
            <Form.Item
                name={`filter-${index}`}
                fieldKey={`filter-${index}`}
                style={{ marginBottom: formItemMargin }}
            >
              <Select
                  mode="tags"
                  size="small"
                  bordered={false}
                  placeholder={`${t('label_select')} ${filter.placeholder}`}
                  dropdownMatchSelectWidth={false}
              >
                {filter.values.map((value, i) => (
                    <Option key={i} value={value}>
                      {value}
                    </Option>
                ))}
              </Select>
            </Form.Item>
        );
      case 'calender':
        return (
            <Form.Item
                name={`filter-${index}`}
                fieldKey={`filter-${index}`}
                rules={[
                  {
                    required: true,
                    message: t('label_value_required'),
                  },
                ]}
                style={{ marginBottom: formItemMargin }}
            >
              <RangePicker
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  defaultPickerValue={[dayjs(defaultStartTime), dayjs(defaultEndTime)]}
                  disabledDate={current => current && current > moment(new Date(), 'YYYY-MM-DD')}
                  autoFocus
                  placeholder={[t('label_start_date'), t('label_end_date')]}
              />
            </Form.Item>
        );
      case 'multipleSelect':
        return (
            <Form.Item
                name={`filter-${index}`}
                fieldKey={`filter-${index}`}
                style={{ marginBottom: formItemMargin, minWidth: 170 }}
            >
              <Select
                  mode="multiple"
                  size="small"
                  bordered={false}
                  placeholder={`${t('label_select')} ${filter.placeholder}`}
                  dropdownMatchSelectWidth={false}
              >
                {filter.values.map((value, i) => (
                    <Option key={i} value={value}>
                      {value}
                    </Option>
                ))}
              </Select>
            </Form.Item>
        );
      case 'input':
        return (
            <Form.Item
                name={`filter-${index}`}
                fieldKey={`filter-${index}`}
                style={{ marginBottom: formItemMargin }}
            >
              <Input
                  size="small"
                  style={{ border: 'none' }}
                  placeholder={`${t('label_input')} ${filter.placeholder}`}
              />
            </Form.Item>
        );
      default:
        return null;
    }
  };

  render() {
    const { t } = this.props;
    const { searchEnabled, enableClear } = this.state;

    return (
        <Form
            name="filter_form"
            onFinish={this.onFinish}
            autoComplete="off"
            ref={this.formRef}
            onValuesChange={this.handleValuesChange}
            initialValues={{
              [`filter-2`]: [defaultStartTime, defaultEndTime], // filter-2 is for calendar filter
            }}
        >
          <Row>
            <Col span={22}>
              <Row>
                {this.props.filters.map((filter, index) => (
                    <Card
                        key={index}
                        bodyStyle={{ padding: 3 }}
                        style={{
                          borderRadius: 10,
                          marginLeft: '3px',
                          marginBottom: '3px',
                        }}
                        hoverable
                    >
                      <Space size="small">
                        <span style={{ marginLeft: 10, marginRight: 10 }}>{filter.label}</span>
                        {this.renderFilter(filter, index)}
                      </Space>
                    </Card>
                ))}
                <Form.Item style={{ marginBottom: -8 }}>
                  <Tooltip title={t('label_search')}>
                    <Button
                        disabled={!searchEnabled}
                        htmlType="submit"
                        type="primary"
                        icon={<SearchOutlined />}
                        size="large"
                        style={{ marginLeft: '5px', marginTop: '0px' }}
                    />
                  </Tooltip>
                </Form.Item>
              </Row>
            </Col>
            <Col span={2}>
              <div style={{ float: 'right' }}>
                <Space>
                  <Form.Item style={{ marginBottom: -8 }}>
                    <Tooltip title={t('label_clear_tag')}>
                      <Button
                          disabled={!enableClear}
                          type="dashed"
                          danger
                          icon={<ClearOutlined />}
                          size="large"
                          style={{ borderRadius: 10, marginLeft: '3px' }}
                          onClick={this.clearForm}
                      />
                    </Tooltip>
                  </Form.Item>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
    );
  }
}

export default withTranslation()(FilterNew);
