/*
 * Copyright (C) 2018 - 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Col,
  DatePicker,
  Result,
  Row,
  Select,
  Spin,
  Card,
  Button,
  Space,
  Divider,
  Descriptions,
  Typography,
  Statistic,
  Table
} from 'antd';
import { withConfigContext } from '../../../../components/ConfigContext';
import { RoutesContext } from '../../index';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import { timeRanges } from '../../../../services/utils/commonUtils';
import axios from "axios";
import MeterDetails from './MeterDetails';

const { Title, Text } = Typography;
const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      breadcrumbName: t('home_menu_readings'),
    },
    {
      breadcrumbName: t('home_menu_meterReadings'),
    },
  ];
};

class MeterReadingsHistorical extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    console.log(this.props.location.state? this.props.location.state.monthID : '');
    this.config = this.props.context;
    const { t } = this.props;
    const currentDate = new Date();
    currentDate.setHours(8, 0, 0, 0);
    this.timeRangeOptions = timeRanges(t);
    this.state = {
      meters: [], // Initialize meters as an empty array to store data
    };
  }

  componentDidMount() {
    this.context.setCurrentRoute('allMeterReadings');
    this.getMeterReadings();
  }

  getMeterReadings = () => {
    const { t } = this.props;
    let apiUrl =
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        '/device-mgt/power-meter-dlms/v1.0/admin/devices/readings?operationCode=BILLING_REGISTERS_RETRIEVE&startTimestamp=1697653800000&endTimestamp=1697712761077';
    axios.get(apiUrl)
        .then((response) => {
              let newMeterReadings = response.data.data.meterReadings;
              newMeterReadings = newMeterReadings.map((meter) => ({
                ...meter,
                date: meter.response['0.9.2_0'],
                time: meter.response['0.9.1_0'],
                totalImport: meter.response['1.8.0_0'],
                totalExport: meter.response['2.8.0_0'],
                totalImportPV1: meter.response['1.8.0*01_0'],
                totalExportPV1: meter.response['2.8.0*01_0'],
                totalMaxDemandImportPV1: meter.response['9.6.0*01_0'],
                remoteRelayStatus: meter.response['CSRQ_RL'],
                phaseACurrentA: meter.response['31.7.0_0'],
                phaseAVoltageV: meter.response['32.7.0_0'],
                phaseBCurrentA: meter.response['51.7.0_0'],
                phaseBVoltageV: meter.response['52.7.0_0'],
                phaseCCurrentA: meter.response['71.7.0_0'],
                phaseCVoltageV: meter.response['72.7.0_0'],
                frequency: meter.response['14.7.0_0'],
                TR1TotalImportkWh: meter.response['1.8.1_0'],
                TR2TotalImportkWh: meter.response['1.8.2_0'],
                TR3TotalImportkWh: meter.response['1.8.3_0'],
                TR1TotalExportkWh: meter.response['2.8.1_0'],
                TR2TotalExportkWh: meter.response['2.8.2_0'],
                TR3TotalExportkWh: meter.response['2.8.3_0'],
                TR1TotalImportPV1kWh: meter.response['1.8.1*01_0'],
                TR2TotalImportPV1kWh: meter.response['1.8.2*01_0'],
                TR3TotalImportPV1kWh: meter.response['1.8.3*01_0'],
                TR1TotalExportPV1kWh: meter.response['2.8.1*01_0'],
                TR2TotalExportPV1kWh: meter.response['2.8.2*01_0'],
                TR3TotalExportPV1kWh: meter.response['2.8.3*01_0'],
                customerRef: meter.customerRef,
                branch: meter.depot,
                depot: meter.depot,
                substationId: meter.substationId,
                subTypeId: meter.subTypeId,
                nodeType: meter.nodeType,
                category: meter.category,
                hasEbilling: meter.hasEbilling,
                hasExport: meter.hasExport,
              }));
          this.setState({ meters: newMeterReadings });
        })
        .catch((error) => {
          handleApiError(
              error,
              t('api_loadError', { label: t('label_machinw') }),
              t,
          );
        });
  }
  render() {
    const config = this.props.context;
    const { Option } = Select;
    const { t } = this.props;
    const { meters } = this.state; // retrieve data from the component state

    const columns = [
      {
        title: 'Serial',
        dataIndex: 'serial',
        key: 'serial',
      },
      {
        title: 'Account No.',
        dataIndex: 'customerRef',
        key: 'customerRef',
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: 'Time',
        dataIndex: 'time',
        key: 'nodeType',
      },
      {
        title: 'Total Import (kWh)',
        dataIndex: 'totalImport',
        key: 'totalImport',
      },
      {
        title: 'Total Export (kWh)',
        dataIndex: 'totalExport',
        key: 'totalExport',
      },
      {
        title: 'Total Import-PV1 (kWh)',
        dataIndex: 'totalImportPV1',
        key: 'totalImportPV1',
      },
      {
        title: 'Total Export-PV1 (kWh)',
        dataIndex: 'totalExportPV1',
        key: 'totalExportPV1',
      },
      {
        title: 'Max Demand Import-Pv1 (kVA)',
        dataIndex: 'totalMaxDemandImportPV1',
        key: 'totalMaxDemandImportPV1',
      },
      {
        title: 'Remote Meter Relay Status',
        dataIndex: 'remoteRelayStatus',
        key: 'remoteRelayStatus',
      },
    ];
    const { Meta } = Card;

    const handleChange = (value) => {
      console.log(`selected ${value}`);
    };

    return (
        <div>
          <Authorized
              scope={['perm:rmr:admin:view-meter-reading']}
              yes={
                <div>
                  <PageHeader className={styles.pageHeader} ghost={false}>
                    <div className={styles.positioning}>
                      <Row justify="space-between" align="middle" gutter={8}>
                        <Col>
                          <BreadcrumbComponent breadcrumbList={routes(config.appName, t)} />
                        </Col>
                      </Row>
                    </div>
                  </PageHeader>
                  <Row>
                    <br />
                  </Row>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div>
                        <Table
                            // dataSource={meters}
                            dataSource={meters.map((meter, index) => ({ ...meter, key: index }))}
                            columns={columns}
                            expandable={{
                              expandedRowRender: (record) => <MeterDetails record={record} />,
                              expandRowByClick: false,
                            }}
                            bordered
                        />
                      </div>
                    </Col>
                  </Row>
                  <Divider />
                </div>
              }
              no={
                <Result
                    status="403"
                    title={t('noPerm_accessPageTitle')}
                    subTitle={t('noPerm_contactSysAdmin')}
                />
              }
          />
        </div>
    );
  }
}

MeterReadingsHistorical.contextType = RoutesContext;
export default withConfigContext(withTranslation()(MeterReadingsHistorical));
