/*
 * Copyright (C) 2018 - 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Table } from 'antd';
import { withConfigContext } from '../../../../components/ConfigContext';
import styles from './styles.module.css';
import { withTranslation } from 'react-i18next';

class AdjustmentsTable extends React.Component {
    constructor(props) {
        super(props);
        this.config = this.props.context;

        this.state = {
            data: {
                count: 2,
                operators: [
                    {
                        ADJUSTMENT_ID: 1,
                        ADJUSTMENT_NAME: 'Levy Addition',
                        ADJUSTMENT_VALUE: "Rs. 598.55"
                    },
                    {
                        ADJUSTMENT_ID: 2,
                        ADJUSTMENT_NAME: 'Deduction',
                        ADJUSTMENT_VALUE: "Rs. 0.00"
                    },
                ]
            },
            pagination: {},
            loading: false,
            selectedRows: [],
            selectedRowKeys: [],
        };
    }

    handleTableChange = (pagination) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
          pagination: pager,
        });
      };

    render() {
        const { t } = this.props;
        const {
            data,
            pagination,
            loading,
        } = this.state;


        const columns = [
              {
                title: "Adjustment Type",
                dataIndex: 'ADJUSTMENT_NAME',
                width: 150,
                // eslint-disable-next-line react/display-name
                render: (data, row) => {
                  const { ADJUSTMENT_NAME } = row;
                  return (
                      <p>{ADJUSTMENT_NAME}</p>
                  );
                },
              },
            {
                title: "Value",
                dataIndex: 'ADJUSTMENT_VALUE',
                key: 'ADJUSTMENT_VALUE',
                width: 150,
                // eslint-disable-next-line react/display-name
                render: (data, row) => {
                    const { ADJUSTMENT_VALUE } = row;
                    return (typeof ADJUSTMENT_VALUE !== 'undefined' ? ADJUSTMENT_VALUE : '');
                },
            },
        ];

        return (
            <div className={styles.tableContainer}>
                {data.count !== 0 && (
                    <div>
                        <Table
                            className={styles.customTable}
                            columns={columns}
                            rowKey={e => e.ADJUSTMENT_ID}
                            dataSource={data.operators}
                          onChange={this.handleTableChange}
                          title={() => <b>Adjustments</b>}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default withConfigContext(withTranslation()(AdjustmentsTable));
