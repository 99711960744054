import React, {useState, useEffect, useContext} from 'react';
import {Col, Result, Row, Divider, Select, Space} from 'antd';
import {withConfigContext} from '../../../../components/ConfigContext';
import {RoutesContext} from '../../index';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import {withTranslation} from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import IframeWrapper from '../../components/IframeWrapper';
import {getBranchNameByCode} from "../../../../services/utils/functions";
import configs from '../../../../../public/conf/config.json';

const MeterAssignment = (props) => {
    const config = props.context;
    const {t} = props;
    console.log(props.branches);
    const routesContext = useContext(RoutesContext);
    const iframeHeight = 300;
    const branchOptions = Object.keys(props.branches).map(branchCode => {
        const branchName = getBranchNameByCode(branchCode);
        return {
            value: branchCode,
            label: branchName,
        };
    });

    const [selectedFacility, setSelectedFacility] = useState(
        ''
    );
    const [dashboardUrl, setDashboardUrl] = useState('');

    useEffect(() => {
        // Use the context to set the current route
        routesContext.setCurrentRoute('allFacilityMeterAssignment');
        setDashboardUrl(`${configs.grafanaConfig.meterDataVisualization}?orgId=1&panelId=2&var-operation_code=${selectedFacility}`);
    }, [selectedFacility]);


    const routes = (appName) => [
        {
            path: `/${appName}`,
            breadcrumbName: t('home_menu_home'),
        },
        {
            breadcrumbName: t('home_menu_facility_view'),
        },
        {
            breadcrumbName: t('home_menu_meter_assignment'),
        },
    ];

    return (
        <div>
            <Authorized
                scope={['perm:rmr:admin:meter-inventory']}
                yes={
                    <div>
                        <PageHeader className={styles.pageHeader} ghost={false}>
                            <div className={styles.positioning}>
                                <Row justify="space-between" align="middle" gutter={8}>
                                    <Col>
                                        <BreadcrumbComponent breadcrumbList={routes(config.appName)}/>
                                    </Col>
                                    <Col>
                                        <Row align="middle" gutter={[8, 8]}>
                                            <Col>
                                                <Space>
                                                    <Select
                                                        value={selectedFacility}
                                                        placeholder="Select"
                                                        style={{ width: 250 }}
                                                        onChange={(value) => setSelectedFacility(value)}
                                                    >
                                                        {branchOptions.map((option) => (
                                                            <Select.Option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>

                                                </Space>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </PageHeader>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <IframeWrapper
                                        iframeSrc={dashboardUrl}
                                    />
                                </div>
                            </div>
                        </div>
                        <Divider/>
                    </div>
                }
                no={
                    <Result
                        status="403"
                        title={t('noPerm_accessPageTitle')}
                        subTitle={t('noPerm_contactSysAdmin')}
                    />
                }
            />
        </div>
    );
};

export default withConfigContext(withTranslation()(MeterAssignment));
