/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Select, Typography } from 'antd';
import styles from '../../styles.module.css';
import searchRoutes from '../../../../../public/conf/search-routes.json';
import { Link } from 'react-router-dom';
import { withConfigContext } from '../../../../components/ConfigContext';
import { isAuthorized } from '../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

const { Text } = Typography;

class Navigator extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;
    const config = this.props.context;
    const options = searchRoutes.map((route, index) => {
      if (isAuthorized(config.scopes, route.scope)) {
        let breadcrumbList = route.breadcrumbList.map(obj => t(obj));
        return (
          <Select.Option key={index} value={route.title}>
            <Link to={`/${config.appName}${route.route}`}>
              <div>
                <HtmlComments permission={route.permission} />
                <Text>{t(route.titleKey)}</Text>
                <br />
                <div className={styles.breadCrumbList}>
                  {breadcrumbList.join(' > ')}
                </div>
              </div>
            </Link>
          </Select.Option>
        );
      }
    });
    return (
      <Select
        showSearch
        bordered={false}
        style={this.props.style}
        defaultActiveFirstOption={false}
        showArrow={false}
        className={styles.searchBar}
        filterOption={(input, option) =>
          option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        value={t('label_navigate_me_to')}
      >
        {options}
      </Select>
    );
  }
}

export default withConfigContext(withTranslation()(Navigator));
