/*
 * Copyright (C) 2028 - 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import moment from 'moment';

export const iframeHandler = (event, timeRangeOptions, defaultTimeOptionsLen, setTimeRangeOptions) => {
  const iframe = document.querySelector('iframe');
  // Check if the message is from the Grafana iframe
  if (event.source === iframe.contentWindow) {
    const message = event.data;

    // Check if the message is a console log message
    if (message.type === 'consoleLog') {
      if (message.from !== '' && message.to !== '') {
        const fromTimestamp = parseInt(message.from);
        const toTimestamp = parseInt(message.to);

        if (!isNaN(fromTimestamp) && !isNaN(toTimestamp)) {
          const newTimeRange = {
            id: defaultTimeOptionsLen + 1,
            label: `${moment(fromTimestamp).format('DD/MM/YY HH:mm')}-${moment(toTimestamp).format('DD/MM/YY HH:mm')}`,
            value: `from=${fromTimestamp}&to=${toTimestamp}`,
          };

          // Check if the new time range already exists in the options
          const existingByRange = timeRangeOptions.find((option) => option.value === newTimeRange.value);
          const existingById = timeRangeOptions.find((option) => option.id === newTimeRange.id);

          if (typeof existingById !== 'undefined') {
            if(existingById.label != newTimeRange.label){
              existingById.label = newTimeRange.label;
              existingById.value = newTimeRange.value;
              setTimeRangeOptions([...timeRangeOptions]);
            }
          } else if (!existingByRange) {
            setTimeRangeOptions([...timeRangeOptions, newTimeRange]);
          }
        }
      }
    }
  }
};