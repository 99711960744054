/*
 * Copyright (C) 2022. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';

import { Card, Col, Result, Row } from 'antd';
import { withTranslation } from 'react-i18next';
import { RoutesContext } from '../../../../index';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import styles from './styles.module.css';
import HtmlComments from '../../../../../../components/ReactHtmlComments/HtmlComments';
import Authorized from '../../../../../../components/Authorized';
import { Link } from 'react-router-dom';

class BillingCard extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      showBillingTable: false,
      billingYear: [],
    };
  }

  componentDidMount() {
    this.context.setCurrentRoute('billing');
  }

  showBillingTable = billingYear => {
    this.setState({ showBillingTable: true, billingYear: billingYear });
  };

  render() {
    const { t } = this.props;
    const card = (billingYear, hover) => {
      let color = 'white';
      if (billingYear.year === 'all') {
        color = '#65a2c754';
      }
      return (
        <Card
          bordered={true}
          hoverable={hover}
          style={{
            background: color.toString(),
          }}
          className={styles.appCard}
          onClick={() => {
            this.showBillingTable(billingYear.device);
          }}
        >
          {billingYear.year !== 'all' ? (
            <div style={{ marginTop: 32 }}></div>
          ) : null}
          <div align="center">
            {billingYear.year === 'all' ? <h2>Full Bill Period</h2> : null}
            <h2>
              <b>
                {billingYear.startDate.substr(
                  0,
                  billingYear.startDate.indexOf(' '),
                )}{' '}
                -{' '}
                {billingYear.endDate.substr(
                  0,
                  billingYear.endDate.indexOf(' '),
                )}
              </b>
            </h2>
            <p>
              Device Count {' - '} {billingYear.deviceCount}
            </p>
            <p>
              Total Cost {' - '} {billingYear.totalCostPerYear}$
            </p>
          </div>
        </Card>
      );
    };
    return (
      <div>
        <HtmlComments permission={'/device-mgt/admin/devices/usage/view'} />
        <Authorized
          scope={['perm:admin:usage:view']}
          yes={
            <div>
              <Row>
                {this.props.billingData &&
                  this.props.billingData.devices &&
                  this.props.billingData.devices.map(billingYear => {
                    return (
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={8}
                        key={billingYear.id}
                      >
                        {billingYear.totalCostPerYear === 0 ? (
                          card(billingYear, false)
                        ) : (
                          <Link
                            to={{
                              pathname: `/${this.config.appName}/billing/${billingYear.year}`,
                              state: {
                                start: billingYear.startDate,
                                end: billingYear.endDate,
                                tenantDomain: this.props.tenantDomain,
                                totalCostPerYear: billingYear.totalCostPerYear,
                                deviceCount: billingYear.deviceCount,
                                billPeriod: billingYear.billPeriod,
                                year: billingYear.year,
                                billingData: billingYear.device,
                              },
                              query: billingYear.device,
                            }}
                          >
                            {card(billingYear, true)}
                          </Link>
                        )}
                      </Col>
                    );
                  })}
              </Row>
            </div>
          }
          no={
            <Result
              status="403"
              title={t('noPerm_accessPageTitle')}
              subTitle={t('noPerm_contactSysAdmin')}
            />
          }
        />
      </div>
    );
  }
}

BillingCard.contextType = RoutesContext;
export default withConfigContext(withTranslation()(BillingCard));
