/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Input, notification, Modal, Descriptions } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import { withTranslation } from 'react-i18next';

class EditDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  deviceName = this.props.device ? this.props.device.name : '';
  updatedDescription = this.props.device.description;
  updatedProperties = this.props.device.properties;

  onBlurDeviceNameInput = e => {
    this.deviceName = e.target.value;
  };

  onPropertyChange = (e, propertyName) => {
    const { device} = this.props;

    if (propertyName === 'description') {
      this.updatedDescription = e.target.value;
    } else {
      const updatedProperties = (device.properties).map((property) => {
        if (property.name === propertyName) {
          return { ...property, value: e.target.value };
        }
        return property;
      });
      this.updatedProperties = updatedProperties;
    }

  };

  onClickUpdateButton = () => {
    this.onConfirmUpdate();
    this.setState({ visible: false });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  onConfirmUpdate = () => {
    const config = this.props.context;
    const { t } = this.props;

    const payload = { name: this.deviceName, description: this.updatedDescription, properties:this.updatedProperties };
    // send request to the invoker
    axios
      .put(
        window.location.origin +
          config.serverConfig.invoker.uri +
          config.serverConfig.invoker.deviceMgt +
          '/device/agent/enroll/' +
          this.props.device.type +
          '/' +
          this.props.device.deviceIdentifier,
        payload,
      )
      .then(res => {
        if (res.status === 202) {
          this.props.callback();
          notification.success({
            message: t('label_done'),
            duration: 4,
            description: t('form_update_notification'),
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('form_update_notification_errors'), t);
      });
  };

  render() {
    const { device } = this.props;
    const { t } = this.props;
    const translationKey = (key) => {
      const label = `label${key}`;
      const translatedValue = t(label);
      const isMissingKey = `label${key}` === t(`label${key}`);
      return isMissingKey ? key : translatedValue;
    } 
    return (
      <>
        <Button
          type="link"
          shape="circle"
          icon={<EditOutlined />}
          size={'default'}
          style={{ margin: '2px' }}
          onClick={this.handleVisibleChange}
        >
        </Button>

        <Modal
          open={this.state.visible}
          title={t("form_updateMachineProperties")}
          onCancel={this.handleCancel}
          footer={[
            <Button key="cancel" onClick={this.handleCancel}>
              {t("label_cancel")}
            </Button>,
            <Button
              key="update"
              type="primary"
              onClick={this.onClickUpdateButton}
            >
              {t("label_update")}
            </Button>,
          ]}
        >
          <div style={{ alignItems: 'right' }}>
            <Descriptions column={1} size="small" bordered>
              <Descriptions.Item label={t("label_machineName")}>
                <Input
                  defaultValue={device ? device.name : ''}
                  onChange={this.onBlurDeviceNameInput}
                  autoFocus
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("label_description")}>
                <Input
                  name={'description'}
                  defaultValue={device ? device.description : ''}
                  onChange={(e) => this.onPropertyChange(e, 'description')}
                />
              </Descriptions.Item>
              {(device.properties).map((property) => (
                !property.name.startsWith('_status_')
                  ?  
                  <Descriptions.Item label={
                    property.name.startsWith('_maintenance_')
                    ? translationKey(property.name)
                    : property.name} key={property.name}
                  >
                    <Input
                      name={property.name}
                      defaultValue={property.value}
                      onChange={(e) => this.onPropertyChange(e, property.name)}
                    />
                  </Descriptions.Item>
                  :
                  ""
              ))}
            </Descriptions>
          </div>
        </Modal>
      </>
    );
  }
}

export default withConfigContext(withTranslation()(EditDevice));
