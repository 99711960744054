/*
 * Copyright (C) 2018 - 2024 Entgra (Pvt) Ltd, Inc - All Rights Reserved.
 *
 * Unauthorised copying/redistribution of this file, via any medium is strictly prohibited.
 *
 * Licensed under the Entgra Commercial License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *       https://entgra.io/licenses/entgra-commercial/1.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import React from 'react';
import { Button, Input, notification, Modal, Table } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import { withTranslation } from 'react-i18next';
import styles from './styles.module.css';

class AcknowledgeAlert extends React.Component {
    constructor(props) {
        super(props);
        this.config = this.props.context;
        this.state = {
            comments: this.props.alert.reduce((acc, alert) => {
                acc[alert.alertId] = alert.comment || '';
                return acc;
            }, {}),
        };
    }

    onPropertyChange = (e, alertId) => {
        const { value } = e.target;
        this.setState((prevState) => ({
            comments: {
                ...prevState.comments,
                [alertId]: value,
            },
        }));
    };

    onClickUpdateButton = (alertId) => {
        this.onConfirmUpdate(alertId);
    };

    onConfirmUpdate = (alertId) => {
        const { t } = this.props;
        const comment = this.state.comments[alertId];
        axios
            .post(
                `${window.location.origin}${this.config.serverConfig.invoker.uri}/factory-mgt/v1.0/alerts/${alertId}`,
                { comment },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            )
            .then((res) => {
                if (res.status === 201) {
                    this.props.callback();
                    notification.success({
                        message: t('api_successMsg'),
                        description: t('description_ack_done'),
                    });
                    this.setState({ loading: true });
                }
            })
            .catch((error) => {
                handleApiError(error, t('api_sendAckError'), t);
                this.setState({ loading: false });
            });
    };

    render() {
        const { alert, visible, onCancel, deviceName, t } = this.props;

        const columns = [
            {
                title: t('label_alertMessage'),
                dataIndex: 'message',
                key: 'message',
            },
            {
                title: t('label_alertLevel'),
                dataIndex: 'level',
                key: 'level',
                render: level => {
                    let color;
                    let backGroundColor;
                    switch (level) {
                        case 'INFO':
                            backGroundColor = '#c4e0c6';
                            color = '#14403e';
                            break;
                        case 'WARN':
                            backGroundColor = '#f3d669';
                            color = '#fc8208';
                            break;
                        case 'CRITICAL':
                            backGroundColor = '#ff9999';
                            color = '#821212';
                            break;
                    }
                    return (
                        <span
                            style={{
                                display: 'inline-block',
                                width: 100,
                                textAlign: 'center',
                                color: color,
                                background: backGroundColor,
                                padding: '1px 5px',
                                borderRadius: 7,
                            }}
                        >
                            {level}
                        </span>
                    );
                },
            },
            {
                title: t('label_alertedTimestamp'),
                dataIndex: 'metaTimestamp',
                key: 'metaTimestamp',
                render: (metaTimestamp) => moment(metaTimestamp).format('YYYY-MM-DD HH:mm:ss'),
            },
            {
                title: t('label_acknowledged'),
                dataIndex: 'ackInfo',
                key: 'ackInfo',
                render: (ackInfo) => {
                    if (ackInfo && ackInfo.timestamp && ackInfo.userId) {
                        return `${ackInfo.userId} @ ${moment(ackInfo.timestamp).format('YYYY-MM-DD HH:mm:ss')}`;
                    } else if (ackInfo && ackInfo.timestamp) {
                        return moment(ackInfo.timestamp).format('YYYY-MM-DD HH:mm:ss');
                    } else {
                        return '-';
                    }
                },
            },
            {
                title: t('label_ackComment'),
                dataIndex: 'ackInfo',
                key: 'ackInfo.comment',
                render: (ackInfo, record) => (
                    <Input
                        name={'comment'}
                        value={ackInfo && ackInfo.ackStatus ? ackInfo.comment : this.state.comments[record.alertId]}
                        onChange={(e) => this.onPropertyChange(e, record.alertId)}
                        placeholder={"EX: Attend to the issue"}
                        className={styles.customInputField}
                        disabled={ackInfo && ackInfo.ackStatus}
                    />
                ),
            },
            {
                title: t('label_action'),
                key: 'action',
                render: (text, record) => (
                    <Button
                        type="primary"
                        onClick={() => this.onClickUpdateButton(record.alertId)}
                        disabled={record.ackInfo && record.ackInfo.ackStatus}
                        size="large"
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                    >
                        {t('label_acknowledge')}
                    </Button>
                ),
            },
        ];

        return (
            <Modal
                open={visible}
                className={styles.customModal}
                title={
                    <span className={styles.customModalTitle}>
                        {`${t('form_alertView')} - ${deviceName}`}
                    </span>}
                onCancel={onCancel}
                footer={[
                    <Button key="cancel" onClick={onCancel}>
                        {t('label_cancel')}
                    </Button>,
                ]}
            >
                <Table
                    columns={columns}
                    dataSource={alert}
                    rowKey="alertId"
                    pagination={false}
                />
            </Modal>
        );
    }
}

export default withConfigContext(withTranslation()(AcknowledgeAlert));
