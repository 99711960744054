/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DateInput from '../Elements/DateInput';
import { Button } from "antd";
import { CalendarOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { getDefaultStartDate, timeRanges, fromMapping, makeTimeRange } from '../../../../../services/utils/functions';
import styles from './styles.module.css';

function DateRangeDropdown({ getRange, closeOnBackground, reset, type, disabled }) {
  const location = useLocation();
  const [startDate, setStartDate] = useState(getDefaultStartDate(type));
  const [endDate, setEndDate] = useState(new Date()); // Initialize with current date/time
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [currentDateRange, setCurrentDateRange] = useState(type === "Alerts" ? 'Last 12 hours' : 'Last 5 minutes');

  const handleToggleDropdown = () => {
    if (!disabled) {
      setToggleDropdown(!toggleDropdown);
    }
  };

  const renderDropdownLabel = () => {
    return currentDateRange;
  };

  const defaultRange = type === "Alerts" ? timeRanges[4] : timeRanges[0];

  // Filter time ranges based on type
  const filteredTimeRanges = type === "TrackLocation"
      ? timeRanges.filter(time => ['5 minutes', '30 minutes', '1 hour', '6 hours'].includes(time.label))
      : timeRanges;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const from = queryParams.get('from');
    const to = queryParams.get('to');

    if (from && fromMapping[from]) {
      const label = fromMapping[from];
      setCurrentDateRange(label);
      makeTimeRange(label, setCurrentDateRange, setStartDate, setEndDate, getRange, startDate, endDate);
    } else if (from && to) {
      makeTimeRange('Custom', setCurrentDateRange, setStartDate, setEndDate, getRange, startDate, endDate);
    } else {
      // No query params, use default range
      makeTimeRange(defaultRange.label, setCurrentDateRange, setStartDate, setEndDate, getRange, startDate, endDate);
    }
  }, [location.search, reset]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reset) {
      makeTimeRange(defaultRange.label, setCurrentDateRange, setStartDate, setEndDate, getRange, startDate, endDate);
    }
  }, [reset]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
      <div className={styles.dateRanger}>
        <div
            className={`${styles.dateRangerPicker} ${disabled ? styles.disabled : ''}`}
            onClick={handleToggleDropdown}
        >
          <CalendarOutlined />
          <span className={styles.datePicker.span}>{renderDropdownLabel()}</span>
          {!toggleDropdown ? <UpOutlined /> : <DownOutlined />}
        </div>
        {toggleDropdown && (
            <>
              {closeOnBackground && (
                  <div className={styles.dateRangerOverlay} onClick={() => setToggleDropdown(false)}></div>
              )}
              <div className={styles.dateRangerPickerDropdown}>
                <div className={styles.dateRangerPickerColumns}>
                  <div className={styles.absoluteTimeRange}>
                    <label>
                      <b>Absolute time range</b>
                    </label>
                    <div className="mb-2 ml-2">
                      <label htmlFor="fromDate">From</label>
                      <DateInput
                          id="fromDate"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          className="form-control"
                      />
                    </div>
                    <div className="ml-2">
                      <label htmlFor="toDate">To</label>
                      <DateInput
                          id="toDate"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          className="form-control"
                      />
                      <Button
                          key="makeTimeRange"
                          type="primary"
                          icon={<CalendarOutlined />}
                          onClick={() => makeTimeRange('Custom', setCurrentDateRange, setStartDate, setEndDate, getRange, startDate, endDate)}
                          style={{ marginTop: 10 }}
                      >
                        Apply time range
                      </Button>
                    </div>
                  </div>
                  <div className={styles.relativeTimeRanges}>
                    <label>
                      <b>Relative time ranges</b>
                    </label>
                    <ul>
                      {filteredTimeRanges.map((time) => (
                          <li key={time.start}>
                            <Button
                                key={time.label}
                                type="default"
                                onClick={() =>
                                    makeTimeRange(time.label, setCurrentDateRange, setStartDate, setEndDate, getRange, startDate, endDate)
                                }
                                style={{ color: '#666f77' }}
                            >
                              Last {time.label}
                            </Button>
                          </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </>
        )}
      </div>
  );
}
export default DateRangeDropdown;
