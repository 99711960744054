/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Select, Tooltip } from 'antd';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { handleApiError } from '../../../../../../../../../../../../services/utils/errorHandler';

function EvaluateTenantForm(props) {
  const [tenantDomain, setTenantDomain] = useState('');
  const [tenantList, setTenantList] = useState([]);
  const { action, t } = props;
  const formRef = useRef(null);
  const [checkTenant, setCheckTenant] = useState(false);

  const buttonItemLayout = {
    wrapperCol: { span: 24, offset: 19 },
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

  useEffect(() => {
    getTenants();
    if (props.action === 'Add') {
      formRef.current.resetFields();
    }
    if (props.values) {
      formRef.current.setFieldsValue(props.values);
    }
  }, []);

  const handleDomainChange = value => {
    setTenantDomain(value);
  };

  const checkIfEvaluateTenantExist = event => {
    let evaluateTenants = [];
    props.metaData.forEach(tenants => {
      evaluateTenants.push(tenants.tenantDomain);
    });
    if (evaluateTenants.includes(event)) {
      setCheckTenant(true);
    } else {
      setCheckTenant(false);
    }
  };

  const onFinish = values => {
    props.callback(values);
  };

  const getTenants = () => {
    axios
      .get(
        window.location.origin +
          props.config.serverConfig.invoker.uri +
          '/device-mgt-config/v1.0/tenants',
      )
      .then(res => {
        setTenantList(res.data.data);
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_tenants') }),
          t,
        );
      });
  };

  return (
    <Form
      {...formItemLayout}
      name="evaluateForm"
      onFinish={onFinish}
      ref={formRef}
    >
      <Form.Item
        name="tenantDomain"
        label={t('label_tenantDomain')}
        initialValue={tenantDomain}
        rules={[
          {
            required: true,
            message: t('tenantDomain_infoTxt'),
          },
        ]}
      >
        <Select
          onChange={event => handleDomainChange(event)}
          onSelect={event => checkIfEvaluateTenantExist(event)}
        >
          {tenantList.map(item => (
            <Select.Option key={item.domain} value={item.domain}>
              {item.domain}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item {...buttonItemLayout}>
        <Tooltip
          placement="left"
          title={t('already_exist_tenant')}
          open={checkTenant}
        >
          <Button
            type="primary"
            htmlType="submit"
            disabled={checkTenant ? true : null}
          >
            {t(`label_${action.toLowerCase()}`)}
          </Button>
        </Tooltip>
      </Form.Item>
    </Form>
  );
}

export default withTranslation()(EvaluateTenantForm);
