import { jsPDF } from "jspdf";
import { getCompanyName } from "../../CommonDetails/CompanyInfo/getCompanyName";
import { getCompanyDetails } from "../../CommonDetails/CompanyInfo/getCompanyDetails";
import { getCustomerInfo } from "../../CommonDetails/CustomerInfo/getCustomerInfo";
import { getTotalConsumptionTable } from "./UsageReportBody/getTotalConsumptionTable";
import { getAverageConsumptionTable } from "./UsageReportBody/getAverageConsumptionTable";
import { getCurrentMonthConsumption } from "./UsageReportBody/getCurrentMonthConsumption";
import { getDailyConsumptionTable } from "./UsageReportBody/getDailyConsumptionTable";

export { jsPDF };

/**
 *
 * @param { {
*   fileName: string,
*   orientationLandscape?: boolean,
*   logo?: {
*      src?: string,
*      type?: string,
*      width?: number,
*      height?: number,
*      margin?: {
*        top?: number,
*        left?: number
*      }
*   },
*   stamp?: {
*      inAllPages?: boolean,
*      src?: string,
*      type?: string,
*      width?: number,
*      height?: number,
*      margin?: {
*        top?: number,
*        left?: number
*      }
*   },
*   company?: {
*       name?: string,
*       address_no?: string,
*       address_road?: string,
*       address_city?: string,
*       postalCode?: string,
*       phone?: string,
*       email?: string,
*       email_1?: string,
*       website?: string,
*   },
*   customerInfo?: {
*       label?: string,
*       name?: string,
*       accountNumber?: string,
*       address?: string,
*   },
*   reportBody?: {
*       label?: string,
*       num?: number,
*       reportGenDate?: string,
*       headerBorder?: boolean,
*       tableBodyBorder?: boolean,
*       header?: 
*        {
*          title: string, 
*          style?: { width?: number }
*        }[],
*       table?: any,
*       totalPayment?: string,
*   },
*   footer?: {
*       text?: string,
*   },
*   pageEnable?: boolean,
*   pageLabel?: string, } } props
*/

export function getUsageViewTemplate(props) {
    //param preparation   
    const param = {
        fileName: props.fileName || "",
        orientationLandscape: props.orientationLandscape || false,
        logo: {
            src: props.logo?.src || "",
            type: props.logo?.type || "",
            width: props.logo?.width || "",
            height: props.logo?.height || "",
            margin: {
                top: props.logo?.margin?.top || 0,
                left: props.logo?.margin?.left || 0,
            },
        },
        stamp: {
            inAllPages: props.stamp?.inAllPages || false,
            src: props.stamp?.src || "",
            width: props.stamp?.width || "",
            height: props.stamp?.height || "",
            margin: {
                top: props.stamp?.margin?.top || 0,
                left: props.stamp?.margin?.left || 0,
            },
        },
        company: {
            name: props.company?.name || "",
            address_no: props.company?.address_no || "",
            address_road: props.company?.address_road || "",
            address_city: props.company?.address_city || "",
            postalCode: props.company?.postalCode || "",
            phone: props.company?.phone || "",
            email: props.company?.email || "",
            email_1: props.company?.email_1 || "",
            website: props.company?.website || "",
        },
        customerInfo: {
            label: props.customerInfo?.label || "",
            name: props.customerInfo?.name || "",
            address: props.customerInfo?.address || "",
            accountStatus: props.customerInfo?.accountStatus || "",
        },
        reportBody: {
            label: props.reportBody?.label || "",
            num: props.reportBody?.num || "",
            reportGenDate: props.reportBody?.reportGenDate || "",
            TotalConsumptionLabel: props.reportBody?.TotalConsumptionLabel || "",
            TotalConsumptionHeaderBorder: props.reportBody?.TotalConsumptionHeaderBorder || false,
            TotalConsumptionTableBodyBorder: props.reportBody?.TotalConsumptionTableBodyBorder || false,
            TotalConsumptionHeader: props.reportBody?.TotalConsumptionHeader || [],
            TotalConsumptionTable: props.reportBody?.TotalConsumptionTable || [],
            AverageConsumptionLabel: props.reportBody?.AverageConsumptionLabel || "",
            AverageConsumptionHeaderBorder: props.reportBody?.AverageConsumptionHeaderBorder || false,
            AverageConsumptionTableBodyBorder: props.reportBody?.AverageConsumptionTableBodyBorder || false,
            AverageConsumptionHeader: props.reportBody?.AverageConsumptionHeader || [],
            AverageConsumptionTable: props.reportBody?.AverageConsumptionTable || [],
            DailyConsumptionLabel: props.reportBody?.DailyConsumptionLabel || "",
            DailyConsumptionHeaderBorder: props.reportBody?.DailyConsumptionHeaderBorder || false,
            DailyConsumptionTableBodyBorder: props.reportBody?.DailyConsumptionTableBodyBorder || false,
            DailyConsumptionHeader: props.reportBody?.DailyConsumptionHeader || [],
            DailyConsumptionTable: props.reportBody?.DailyConsumptionTable || [],
            AverageConsumption: props.reportBody?.AverageConsumption || "",
            CurrentMonthConsumption: props.reportBody?.CurrentMonthConsumption || "",
        },
        footer: {
            text: props.footer?.text || "",
        },
        pageEnable: props.pageEnable || false,
        pageLabel: props.pageLabel || "Page",
    };


    //jsPdf Options
    const options = {
        orientation: param.orientationLandscape ? "landscape" : ""
    };
    //end jsPdf Options

    //doc initiation
    var doc = new jsPDF(options);


    //pdf variable initiation
    var pdfConfig = {
        headerTextSize: 20,
        labelTextSize: 12,
        fieldTextSize: 10,
        lineHeight: 6,
        subLineHeight: 4,
        docWidth: doc.internal.pageSize.width,
        docHeight: doc.internal.pageSize.height,
        colorBlack: "#000000",
        colorGray: "#4d4e53",
        colorRed: "#ff0000",
        totalHeight: 15

    };

    getCompanyName(doc, pdfConfig, param);
    getCompanyDetails(doc, pdfConfig, param);
    //line breaker
    if (param.reportBody.TotalConsumptionHeader.length) {
        pdfConfig.totalHeight += pdfConfig.subLineHeight;
        doc.line(10, pdfConfig.totalHeight, pdfConfig.docWidth - 10, pdfConfig.totalHeight);
    }
    //end line breaker
    getCustomerInfo(doc, pdfConfig, param);
    getTotalConsumptionTable(doc, pdfConfig, param);
    getAverageConsumptionTable(doc, pdfConfig, param);
    getCurrentMonthConsumption(doc, pdfConfig, param);
    getDailyConsumptionTable(doc, pdfConfig, param);
    doc.save(param.fileName);
}

export default getUsageViewTemplate;


