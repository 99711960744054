/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 *  Licensed under the Entgra Commercial License,
 *  Version 1.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *
 *  Unless required by applicable law or agreed to in writing,
 *  software distributed under the License is distributed on an
 *  "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 *  KIND, either express or implied.  See the License for the
 *  specific language governing permissions and limitations
 *  under the License.
 *
 *  You may obtain a copy of the License at
 *  https://entgra.io/licenses/entgra-commercial/1.0
 */

import axios from 'axios';

export class Base64File {
  constructor(name, base64String) {
    this.name = name;
    this.base64String = base64String;
  }
}

export const getBase64String = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const getBase64FileFromFileUpload = async fileUpload => {
  const name = fileUpload.originFileObj.name;
  const base64String = await getBase64String(fileUpload.originFileObj);
  return new Base64File(name, base64String);
};

// function for access the full name of the binary file using the installation path
function extractFileNameFromImageUrl(imageUrl) {
  let urlFragments = imageUrl.split('/');
  return urlFragments[urlFragments.length - 1];
}

// function for access the file extension of artifacts (images, binary file)
function getFileExtension(url) {
  let splitUrl = url.split('/');
  let fileName = splitUrl[splitUrl.length - 1];
  return fileName.substr(fileName.lastIndexOf('.') + 1);
}

/* Method to create the icon fileObject by using the
   response received from the download icon url
*/
export const imageUrlToAntDUploadObject = async (url, uid, imageName = '') => {
  return axios
    .get(url, {
      responseType: 'blob',
    })
    .then(res => {
      const imageBlob = res.data;
      let fileName = imageName;
      if (!fileName) {
        fileName = extractFileNameFromImageUrl(url);
      }
      let iconFile = new File([imageBlob], fileName, {
        type: 'image/' + getFileExtension(url),
      });
      return {
        uid: uid,
        originFileObj: iconFile,
        url: url,
      };
    });
};
