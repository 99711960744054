/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useContext } from 'react';
import { PolicyDetailsContext } from '../../index';
import ConfigContext from '../../../../../../../../components/ConfigContext';
import {
  AndroidFilled,
  AppleFilled,
  HddFilled,
  WindowsFilled,
} from '@ant-design/icons';
import { Col, Menu, Row, Space, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import styles from './styles.module.css';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../../../../../components/BreadCrumb';

const { Title, Text } = Typography;

const routes = (policyName, appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      path: `/${appName}/devices`,
      breadcrumbName: t('home_menu_devices'),
    },
    {
      breadcrumbName: policyName.charAt(0).toUpperCase() + policyName.slice(1),
    },
  ];
};

const getItem = (label, key, icon, children, type) => {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
};

const Header = props => {
  const policyDetails = useContext(PolicyDetailsContext);
  const { t } = useTranslation();
  const { config } = useContext(ConfigContext);
  let history = useHistory();
  let icon;
  switch (policyDetails.policy.profile.deviceType) {
    case 'android':
      icon = <AndroidFilled />;
      break;
    case 'ios':
      icon = <AppleFilled />;
      break;
    case 'windows':
      icon = <WindowsFilled />;
      break;
    default:
      icon = <HddFilled />;
  }

  const items = [
    getItem(t('label_policyInformation'), 'policy-information'),
    policyDetails.policy.policyType === 'GENERAL' &&
      getItem(t('label_correctiveActions'), 'corrective-information'),
    getItem(t('label_groupInformation'), 'group-information'),
  ];

  const menuClick = item => {
    let path =
      `/${config.appName}/policy/view/${policyDetails.policyId}` +
      '/' +
      item.key;
    history.push(path);
  };

  let status = 'Inactive';
  let color = '#656565';
  if (policyDetails.policy.active) {
    color = '#C2D600';
    status = 'Active';
  }
  return (
    <PageHeader
      className={styles.pageHeader}
      ghost={false}
      breadcrumb={
        <BreadcrumbComponent
          breadcrumbList={routes(
            policyDetails.policy.policyName,
            config.appName,
            t,
          )}
        />
      }
      title={
        <div>
          <Space>
            <Title level={1} className={styles.deviceName}>
              <span className={styles.deviceIcon}>{icon}</span>
              {` ${policyDetails.policy.policyName.charAt(0).toUpperCase() +
                policyDetails.policy.policyName.slice(1)}`}
            </Title>
          </Space>
          <div>
            {policyDetails.policy.description !== '' && (
              <Text className={styles.deviceUpdatedTime}>
                {policyDetails.policy.description}
              </Text>
            )}
          </div>
        </div>
      }
    >
      <Row className={styles.descriptions}>
        <Col span={4}>
          {' '}
          <Text strong>{t('home_menu_platform')} : </Text>
          <Text>
            {policyDetails.policy.profile.deviceType.charAt(0).toUpperCase() +
              policyDetails.policy.profile.deviceType.slice(1)}
          </Text>
        </Col>
        <Col span={4}>
          {' '}
          <Text strong>{t('label_status')} : </Text>
          <Text>
            {' '}
            <span style={{ color: color }}>{status.toString()}</span>
            {policyDetails.policy.updated ? (
              <span style={{ marginLeft: '29%', color: 'cyan' }}>Updated</span>
            ) : null}
          </Text>
        </Col>
        <Col span={4}>
          {' '}
          <Text strong>{t('label_compliance')} : </Text>
          <Text>{policyDetails.policy.compliance}</Text>
        </Col>
        <Col span={4}>
          {' '}
          <Text strong>{t('label_policyType')} : </Text>
          <Text>{policyDetails.policy.policyType}</Text>
        </Col>
        <Col span={8}>
          {' '}
          <Text strong>{t('label_dateOfCreated')} : </Text>
          <Text>
            {moment(new Date(policyDetails.policy.profile.createdDate)).format(
              'ddd, DD MMM YYYY',
            )}
          </Text>
        </Col>
      </Row>

      <Menu
        mode="horizontal"
        selectedKeys={[policyDetails.currentRoute]}
        className={styles.menu}
        items={items}
        onClick={menuClick}
      />
    </PageHeader>
  );
};
export default Header;
