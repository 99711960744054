export function addStamp(param, doc, pdfConfig) {
    let _addStampBase = () => {
        var stampImage = '';
        if (typeof window === "undefined") {
            stampImage = param.stamp.src;
        } else {
            stampImage = new Image();
            stampImage.src = param.stamp.src;
        }

        if (param.stamp.type)
            doc.addImage(
                stampImage,
                param.stamp.type,
                10 + param.stamp.margin.left,
                pdfConfig.docHeight - 22 + param.stamp.margin.top,
                param.stamp.width,
                param.stamp.height
            );

        else
            doc.addImage(
                stampImage,
                10 + param.stamp.margin.left,
                pdfConfig.docHeight - 22 + param.stamp.margin.top,
                param.stamp.width,
                param.stamp.height
            );
    };

    if (param.stamp.src) {
        if (param.stamp.inAllPages)
            _addStampBase();
        else if (!param.stamp.inAllPages && doc.getCurrentPageInfo().pageNumber == doc.getNumberOfPages())
            _addStampBase();
    }

    return {
        doc: doc,
        pdfConfig: pdfConfig
    }
}
