/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Button,
  Divider,
  Form,
  Input,
  notification,
  Popconfirm,
  Typography,
} from 'antd';
import axios from 'axios';
import { handleApiError } from '../../../../../../../../../../services/utils/errorHandler';
import { withConfigContext } from '../../../../../../../../../../components/ConfigContext';
import TokenModal from './components/TokenModal';
import { withTranslation } from 'react-i18next';

const { Paragraph } = Typography;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};

const buttonFormItem = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 16 },
    lg: { span: 16 },
    xl: { span: 16 },
  },
};

class AndroidForWork extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      data: [],
      loading: false,
      token: '',
      enterpriseId: '',
      serverDetails: '',
      configButtonLoading: false,
    };
  }

  componentDidMount() {
    let params = new URL(window.location).searchParams;
    let tokenParam = params.get('enterpriseToken');
    const completionToken = sessionStorage.getItem('completionToken');
    if (tokenParam && completionToken) {
      this.completeSignUp(completionToken, tokenParam);
    }

    let platformConfigData = [...this.props.platformConfigData];
    let initialValues = {};
    for (let config of platformConfigData) {
      if (config.name === 'esa') {
        initialValues.esa = config.value;
      } else if (config.name === 'enterpriseId') {
        initialValues.enterpriseId = config.value;
      }
    }
    if (initialValues.esa && initialValues.enterpriseId) {
      this.setState({ enterpriseId: initialValues.enterpriseId });
    }
    this.formRef.current.setFieldsValue(initialValues);
  }

  onBeginConfiguration = values => {
    this.setState({ configButtonLoading: true });
    sessionStorage.setItem('serverDetails', values.serverDetails);
    sessionStorage.setItem('externalToken', values.externalToken);
    const endpoint =
      values.serverDetails +
      '/api/android-for-work/v1.0/google/enterprise/signup-url';

    values.callbackURL =
      window.location.origin +
      `/${this.config.appName}/configurations/platform-configuration/android/android-for-work`;

    // Call to google android for work protal
    this.callBackend(endpoint, values.externalToken, 'POST', values, 'token');
  };

  onFinishESASaveForm = values => {
    let payload = {};
    payload.esa = values.esa;
    payload.enterpriseId = values.enterpriseId;
    this.props.callback(payload);
  };

  onFinishFailedESASaveForm = () => {
    const { t } = this.props;
    notification.error({
      message: t('api_errorMsg'),
      duration: 10,
      description: t('api_saveError', { label: t('label_ESAEnterpriseID') }),
    });
  };

  callBackend = (url, token, method, payload, status) => {
    const { t } = this.props;
    axios
      .post(url, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then(res => {
        if (res.status === 200) {
          if (status === 'token') {
            // Store completion token in session
            sessionStorage.setItem('completionToken', res.data.completionToken);
            // Open google AFW portal
            window.location.replace(res.data.signupURL);
            // After filling the form of AFW portal,
            // it redirects the window to the given callback url (#98) with enterpriseToken as a query param
          } else if (status === 'complete-signup') {
            // Complete signup and proceed to generate ESA
            this.createESA(res.data);
          } else if (status === 'create-esa') {
            // Send generated ESA and enterpriseID to Entgra server backend
            this.callServer(res.data);
          }
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_androidConfigs') }),
          t,
        );
        this.setState({ loading: false });
      });
  };

  completeSignUp = (completionToken, enterpriseToken) => {
    let requestPayload = {};
    requestPayload.completionToken = completionToken;
    requestPayload.enterpriseToken = enterpriseToken;

    const enterpriseEndpoint =
      sessionStorage.getItem('serverDetails') +
      '/api/android-for-work/v1.0/google/enterprise/complete-signup';

    this.callBackend(
      enterpriseEndpoint,
      sessionStorage.getItem('externalToken'),
      'POST',
      requestPayload,
      'complete-signup',
    );
  };

  enterpriseId = '';

  createESA = data => {
    if (data.id) {
      this.enterpriseId = data.id;
      let serviceAccountRequest = {};
      serviceAccountRequest.enterpriseId = data.id;
      serviceAccountRequest.keyType = 'googleCredentials';

      const enterpriseEndpoint =
        sessionStorage.getItem('serverDetails') +
        '/api/android-for-work/v1.0/google/enterprise/create-esa';

      this.callBackend(
        enterpriseEndpoint,
        sessionStorage.getItem('externalToken'),
        'POST',
        serviceAccountRequest,
        'create-esa',
      );
    }
  };

  callServer = data => {
    let payload = {};
    payload.esa = data.data;
    payload.enterpriseId = this.enterpriseId;

    this.formRef.current.setFieldsValue(payload);
    this.props.callback(payload);
    sessionStorage.removeItem('completionToken');
    sessionStorage.removeItem('serverDetails');
    sessionStorage.removeItem('externalToken');
    window.history.pushState(null, '', location.href.split('?')[0]);
    this.setState({ configButtonLoading: false });
  };

  unregisterFromEMM = token => {
    const serverDetails = this.config.androidEnterpriseServer;
    const { enterpriseId } = this.state;
    const { t } = this.props;
    if (enterpriseId !== '') {
      const endpoint =
        serverDetails +
        '/api/android-for-work/v1.0/google/enterprise/unenroll/' +
        enterpriseId;
      const payload = {
        endpoint: endpoint,
        externalToken: token,
      };

      axios
        .put(
          window.location.origin +
            this.config.serverConfig.invoker.uri +
            '/devicemgt/api/enterprise/unenroll',
          payload,
          {
            headers: { 'Content-Type': 'application/json' },
          },
        )
        .then(res => {
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_unenrolledMsg'),
          });
        })
        .catch(error => {
          handleApiError(error, t('api_unenrollAndroidForWorkError'), t);
          this.setState({ loading: false });
        });
    } else {
      notification.error({
        message: t('enterpriseIDNotFound_errorTxt'),
        duration: 4,
        description: t('configureAndroidForWork_infoTxt'),
      });
    }
  };

  onBlurServerDetails = e => {
    this.setState({ serverDetails: e.target.value });
  };

  onTokenChange = e => {
    this.setState({ token: e.target.value });
  };

  onEnterpriceIdChange = e => {
    this.setState({ enterpriseId: e.target.value });
  };

  tokenCallback = token => {
    this.setState({ token: token });
    let values = {
      serverDetails: this.config.androidEnterpriseServer,
      externalToken: token,
    };
    this.onBeginConfiguration(values);
  };

  render() {
    const { token, configButtonLoading, enterpriseId } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div>
          <Divider>
            <PageHeader
              className="site-page-header-responsive"
              subTitle={t('label_emmInitiating')}
            />
          </Divider>
        </div>
        <div style={{ textAlign: 'center' }}>
          <TokenModal
            labelText={t('managedGooglePlayAccount_infoTxt')}
            buttonText={t('label_beginConfiguration')}
            tokenCallback={token => this.tokenCallback(token)}
            loading={configButtonLoading}
          />
        </div>
        <div>
          <Divider>
            <PageHeader
              className="site-page-header-responsive"
              subTitle={t('label_googleInitiating')}
            />
          </Divider>
        </div>
        <div>
          <Form
            {...formItemLayout}
            name="AndroidForWorkForm"
            initialValues={{}}
            onFinish={this.onFinishESASaveForm}
            onFinishFailed={this.onFinishFailedESASaveForm}
            ref={this.formRef}
          >
            <Form.Item label="ESA" name="esa">
              <Input />
            </Form.Item>
            <Form.Item label={t('label_enterpriseID')} name="enterpriseId">
              <Input onChange={this.onEnterpriceIdChange} />
            </Form.Item>
            <Form.Item {...buttonFormItem}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ float: 'right' }}
              >
                {t('label_save')}
              </Button>
            </Form.Item>
          </Form>
        </div>
        {enterpriseId && (
          <div>
            <div>
              <Divider>
                <PageHeader
                  className="site-page-header-responsive"
                  subTitle={t('label_unenrollFromEMM')}
                />
              </Divider>
            </div>
            <div style={{ textAlign: 'center' }}>
              <Typography>
                <Paragraph>
                  {token !== '' ? (
                    <div>
                      <p>{t('unregisterEnterpriseFromEMM_infoTxt')}</p>
                      <Popconfirm
                        title={t('unregisterFromEMM_confirmTxt')}
                        okText={t('label_yes')}
                        onConfirm={() => this.unregisterFromEMM(token)}
                        cancelText={t('label_no')}
                      >
                        <Button type="link">{t('label_clickHere')}</Button>
                      </Popconfirm>
                    </div>
                  ) : (
                    <TokenModal
                      labelText={t('unregisterEnterpriseEMM_descriptionTxt')}
                      buttonText={t('label_unenroll')}
                      tokenCallback={token => this.unregisterFromEMM(token)}
                      loading={false}
                    />
                  )}
                </Paragraph>
              </Typography>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(AndroidForWork));
