/*
 * Copyright (C) 2022. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Divider, Form, Row, Select, Tooltip, Typography } from 'antd';
import CustomAlerts from '../CustomAlerts';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import axios from 'axios';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import { withTranslation } from 'react-i18next';
const { Title, Text } = Typography;

const { Option } = Select;

class CustomSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      isDisableRow: true,
    };
  }

  componentDidMount() {
    const config = this.props.context;
    const { itemData } = this.props;
    let apiUrl =
      window.location.origin +
      config.serverConfig.invoker.uri +
      itemData.searchInput.apiUrl;
    itemData.searchInput.paramValue
      ? (apiUrl += itemData.searchInput.paramValue)
      : (apiUrl += '');
    this.fetchData(apiUrl);
  }

  onChange = value => {
    const item = { ...this.props.itemData };
    item.value = value;
    this.props.callback(item);
  };

  // fetch data from api
  fetchData = apiUrl => {
    const { itemData, t } = this.props;
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          const data = res.data.data;
          if (typeof data != 'string') {
            const iterateValue = itemData.searchInput.iteratorKeyValue;
            const list = data[iterateValue];
            const options = list.map((item, index) => (
              <Option
                key={index}
                value={item[itemData.searchInput.defineValueKey]}
              >
                {item[itemData.searchInput.displayValueKey]
                  .split('%20')
                  .join(' ')}
              </Option>
            ));
            this.setState({
              options,
              isDisableRow: false,
            });
          }
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_data') }),
          t,
        );
        this.setState({ loading: false });
      });
  };

  render() {
    const { options } = this.state;
    const { itemData, defaultValue, t } = this.props;
    const { value } = itemData;
    let selectDefaultValue = null;
    if (defaultValue) {
      if (itemData.searchInput.valueType === 'Integer') {
        selectDefaultValue = parseInt(defaultValue, 10);
      } else {
        selectDefaultValue = defaultValue;
      }
    } else {
      selectDefaultValue = value;
    }

    return (
      <div>
        {itemData.divider && (
          <Divider plain>
            <Text type="secondary">{t(`${itemData.divider}`)}</Text>
          </Divider>
        )}
        {itemData.subTitle && <Title level={4}> {t(itemData.subTitle)} </Title>}
        <CustomAlerts notifications={itemData.notifications} />
        <div style={{ margin: 5 }}>
          <Row>
            <span
              style={{
                display: itemData.label && itemData.tooltip ? 'inline' : 'none',
              }}
            >
              {t(`${itemData.label}`)}&nbsp;
              {itemData.tooltip && (
                <Tooltip
                  title={
                    <div>
                      <div>{t(`${itemData.tooltip}`)}</div>
                      <div style={{ textAlign: 'right' }}>
                        {itemData.docLink && (
                          <a
                            onClick={() => {
                              window.open(itemData.docLink, '_blank');
                            }}
                          >
                            {' '}
                            {t('label_SeeMoreDetails')}
                          </a>
                        )}
                      </div>
                    </div>
                  }
                  placement="right"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              )}
            </span>
          </Row>
          <Form>
            <Form.Item name={itemData.key}>
              <Select
                key={itemData.key}
                placeholder={t(itemData.searchInput.placeholderValue)}
                onChange={this.onChange}
                disabled={this.props.disabled || this.state.isDisableRow}
                dropdownMatchSelectWidth={false}
                defaultValue={selectDefaultValue}
                showSearch
                style={{
                  width: 300,
                }}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {options}
              </Select>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(CustomSearchInput));
