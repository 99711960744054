/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, {useContext, useState} from 'react';
import { Button, Input, Modal, Form, Divider, Select, Radio, notification } from 'antd';
import { CompassOutlined } from '@ant-design/icons';
import ConfigContext, { withConfigContext } from '../../../../../../components/ConfigContext';
import { withTranslation } from 'react-i18next';
import DateRangeDropdown from "../../../../components/common/DateRangeDropdown";
import { getUnixTime, getUnixTimestampFromRelativeTime } from "../../../../../../services/utils/functions";
import { subDays, differenceInHours } from 'date-fns';
import axios from 'axios';
import { handleApiError } from "../../../../../../services/utils/errorHandler";
import styles from './styles.module.css';
import { PageHeader } from "@ant-design/pro-layout";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const buttonItemLayout = {
  wrapperCol: { span: 24, offset: 19 },
};

const TrackLocation = (props) => {
  const { t } = props;
  const { config } = useContext(ConfigContext);
  const [range, setRange] = useState({
    from: getUnixTime(subDays(new Date(), 2)),
    to: getUnixTime(new Date()),
  });
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [provider, setProvider] = useState(null);
  const handleCancel = () => setVisible(false);
  const handleVisibleChange = () => setVisible(true);

  const onFinish = async (values) => {
    setLoading(true);
    // Check if the range values are in relative time format or Unix timestamp
    const fromTimestamp = typeof range.from === 'string' ? getUnixTimestampFromRelativeTime(range.from) : range.from;
    const toTimestamp = typeof range.to === 'string' ? getUnixTimestampFromRelativeTime(range.to) : range.to;
    const durationInHours = differenceInHours(new Date(toTimestamp), new Date(fromTimestamp));

    if (durationInHours > 6) {
      notification.error({
        message: t('api_errorMsg'),
        description: t('description_location_process_error'),
      });
      setLoading(false);
      return;
    }

    const deviceData = [
      {
        id: props.device,
        type: "atmm",
      },
    ];
    const payload = {
      devices: deviceData,
      fromTimestamp,
      toTimestamp,
    };
    const url = `${window.location.origin}${config.serverConfig.invoker.uri}/factory-mgt/v1.0/location/${values.provider}`;
    const finalUrl = values.provider === 'mobitel' ? url : `${url}?source=${values.mode}`;

    try {
      await axios.post(finalUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      notification.success({
        message: t('api_successMsg'),
        description: t('description_location_process_done'),
      });
      setVisible(false);
      props.callback(values.provider, range);
    } catch (error) {
        handleApiError(error, t('label_error_process_location'), t);
    } finally {
        setLoading(false);
    }
  };

  const handleProviderChange = (e) => {
    setProvider(e.target.value);
  };

  return (
      <>
        <Button
            type="primary"
            icon={<CompassOutlined />}
            style={{ marginRight: 10 }}
            onClick={handleVisibleChange}
        >
          {t('label_processLocation')}
        </Button>
        <Modal
            open={visible}
            onCancel={handleCancel}
            footer={null}
        >
          <Divider>
            <PageHeader
                className="site-page-header-responsive"
                subTitle={t('label_processDeviceLocation')}
            />
          </Divider>
          <Form
              {...formItemLayout}
              name="trackLocation"
              onFinish={onFinish}
          >
            <Form.Item
                name="deviceId"
                label={t('label_deviceIdentifier')}
                initialValue={props.device}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
                name="provider"
                label={t('label_locationProvider')}
                rules={[
                  {
                    required: true,
                    message: t('form_providerRequiredMsg'),
                  },
                ]}
            >
              <Radio.Group onChange={handleProviderChange}>
                <Radio value="google">Google</Radio>
                <Radio value="mobitel">Mobitel</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
                name="mode"
                label={t('label_locationTrackingMode')}
                rules={[
                  {
                    required: provider !== 'mobitel',
                    message: t('form_modeRequiredMsg'),
                  },
                ]}
            >
              <Select
                  key={provider}
                  placeholder={t('form_selectMode')}
                  className={styles.customFormItem}
                  disabled={provider === 'mobitel'}
              >
                <Select.Option value="wifi">WiFi</Select.Option>
                <Select.Option value="cell">Cell</Select.Option>
                {provider === 'mobitel' && <Select.Option value="none">None</Select.Option>}
              </Select>
            </Form.Item>
            <Form.Item
                name="timeRange"
                label={t('label_timeRange')}
            >
              <DateRangeDropdown
                  getRange={(data) => setRange(data)}
                  closeOnBackground
                  type="TrackLocation"
              />
            </Form.Item>
            <Form.Item {...buttonItemLayout}>
              <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
              >
                {t('label_sendRequest')}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
  );
};

export default withConfigContext(withTranslation()(TrackLocation));
