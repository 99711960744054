/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 *  Licensed under the Entgra Commercial License,
 *  Version 1.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *
 *  Unless required by applicable law or agreed to in writing,
 *  software distributed under the License is distributed on an
 *  "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 *  KIND, either express or implied.  See the License for the
 *  specific language governing permissions and limitations
 *  under the License.
 *
 *  You may obtain a copy of the License at
 *  https://entgra.io/licenses/entgra-commercial/1.0
 */
import axios from 'axios';
import { getUiConfig } from './uiConfigHandler';
import { notification } from 'antd';

let channelDetails = [];
let userExists = false;
let senderActorId = '';
let conversationIds = [];

export function checkAndLoadLiveChat(config, t, username, domain) {
  getUiConfig(config, t).then(uiConfig => {
    if (uiConfig !== undefined) {
      if (uiConfig.hubspotChat.isEnableHubspot) {
        senderActorId = uiConfig.hubspotChat.senderActorId;
        loadChatWidget(
          config,
          username,
          domain,
          uiConfig.hubspotChat.trackingUrl,
        );
      } else {
        notification.warning({
          message: 'Configure the chat feature',
          duration: 6,
          description:
            'You have access to the chat feature. To get the experience, please enable it',
        });
      }
    } else {
      notification.error({
        message: 'There was a problem',
        duration: 6,
        description: 'Error occurred while trying to load UI configurations',
      });
    }
  });
}

function loadChatWidget(config, username, domain, trackingUrl) {
  const script = document.createElement('script');
  script.src = '//' + trackingUrl;
  script.async = true;
  document.body.appendChild(script);
  chatLoad(config, username, domain);
  return () => {
    document.body.removeChild(script);
  };
}

function chatLoad(config, username, domain) {
  if (window.HubSpotConversations) {
    window.HubSpotConversations.on('conversationStarted', payload => {
      const conversationId = payload.conversation.conversationId;
      handleConversation(config, conversationId, username, domain);
    });
  } else {
    window.hsConversationsOnReady = [
      () => {
        if (window.HubSpotConversations) {
          window.HubSpotConversations.on('conversationStarted', payload => {
            const conversationId = payload.conversation.conversationId;
            conversationIds.push(conversationId);
            handleConversation(config, conversationId, username, domain);
          });
        }
      },
    ];
  }
}

function handleConversation(config, conversationId, username, domain) {
  chatProperties(config, username, domain)
    .then(userExists => {
      getChannelDetails(config)
        .then(channelDetails => {
          if (!userExists) {
            generateNewContact(config, username, domain);
          }
          sendUserIdentificationCommentToTeam(
            config,
            conversationId,
            username,
            domain,
            channelDetails,
          );
          sendGreetingMessageToThread(
            config,
            conversationId,
            username,
            domain,
            channelDetails,
          );
          sendInstructionMessageToTheThread(
            config,
            conversationId,
            channelDetails,
          );
        })
        .catch(error => {
          console.error('Error occurred when getting channel Details: ', error);
        });
    })
    .catch(error => {
      console.error('Error occurred when interacting with hubspot: ', error);
    });
}

async function chatProperties(config, username, domain) {
  const requestBody = {
    filterGroups: [
      {
        filters: [
          {
            propertyName: 'username',
            operator: 'EQ',
            value: username,
          },
          {
            propertyName: 'tenant',
            operator: 'EQ',
            value: domain,
          },
        ],
      },
    ],
  };
  let apiURL =
    window.location.origin +
    config.serverConfig.hubspotUri +
    '/crm/v3/objects/contacts/search';
  try {
    const response = await axios.post(apiURL, requestBody, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const contacts = response.data.data.results;
    userExists = contacts.length > 0;
    return userExists;
  } catch (error) {
    console.error('Error occurred when searching contact: ', error);
    throw error;
  }
}

async function getChannelDetails(config) {
  // This API has not released yet as this is at BETA version
  // Refer : https://developers.hubspot.com/docs/api/conversations/conversations
  let apiURL =
    window.location.origin +
    config.serverConfig.hubspotUri +
    '/conversations/v3/conversations/channel-accounts';
  try {
    const response = await axios.get(apiURL);
    const channels = response.data.data.results;
    channels.forEach(channelData => {
      if (channelData.name === 'Live chatflow') {
        channelDetails.channelId = channelData.channelId;
        channelDetails.channelAccountId = channelData.id;
      }
    });
    return channelDetails;
  } catch (error) {
    console.error('Error occurred when getting channel ID:', error);
    throw error;
  }
}

async function generateNewContact(config, username, domain) {
  const contact = {
    properties: {
      firstname: username,
      lastname: ' - ' + domain,
      username: username,
      tenant: domain,
    },
  };
  const apiURL =
    window.location.origin +
    config.serverConfig.hubspotUri +
    '/crm/v3/objects/contacts';
  try {
    await axios.post(apiURL, contact);
  } catch (error) {
    console.error('Error occurred when creating contact:', error);
    throw error;
  }
}

async function sendUserIdentificationCommentToTeam(
  config,
  conversationID,
  username,
  domain,
  channelDetails,
) {
  const requestBody = {
    channelAccountId: channelDetails.channelAccountId,
    channelId: channelDetails.channelId,
    senderActorId: senderActorId,
    text:
      'User Details: NAME: ' +
      username +
      ' - ' +
      domain +
      ', USERNAME: ' +
      username +
      ', TENANT: ' +
      domain,
    type: 'COMMENT',
    richText:
      '<p>User Details: </br> NAME: ' +
      username +
      ' - ' +
      domain +
      '</br> USERNAME: ' +
      username +
      ',</br> TENANT: ' +
      domain +
      '</p>',
    subject: 'User details comment',
  };
  // This API has not released yet as this is at BETA version
  // Refer : https://developers.hubspot.com/docs/api/conversations/conversations
  const apiURL =
    window.location.origin +
    config.serverConfig.hubspotUri +
    `/conversations/v3/conversations/threads/${conversationID}/messages`;
  try {
    await axios.post(apiURL, requestBody);
  } catch (error) {
    console.error('Error occurred when sending comment:', error);
    throw error;
  }
}

async function sendGreetingMessageToThread(
  config,
  conversationID,
  username,
  domain,
  channelDetails,
) {
  const requestBody = {
    channelAccountId: channelDetails.channelAccountId,
    channelId: channelDetails.channelId,
    senderActorId: senderActorId,
    text:
      'Hi ' + username + ' (from: ' + domain + '). How can I help you today?',
    type: 'MESSAGE',
    richText:
      'Hi ' + username + ' (from: ' + domain + '). How can I help you today?',
    subject: 'Greeting',
  };
  // This API has not released yet as this is at BETA version
  // Refer : https://developers.hubspot.com/docs/api/conversations/conversations
  const apiURL =
    window.location.origin +
    config.serverConfig.hubspotUri +
    `/conversations/v3/conversations/threads/${conversationID}/messages`;
  try {
    await axios.post(apiURL, requestBody);
  } catch (error) {
    console.error('Error occurred when sending greeting msg:', error);
    throw error;
  }
}

async function sendInstructionMessageToTheThread(
  config,
  conversationID,
  channelDetails,
) {
  const requestBody = {
    channelAccountId: channelDetails.channelAccountId,
    channelId: channelDetails.channelId,
    senderActorId: senderActorId,
    text:
      'SHOULD BE CONSIDERED : - Chat history will be cleared after loging out from the site. - Send attachments with the "Attachment" keyword.',
    type: 'MESSAGE',
    richText:
      '<b>SHOULD BE CONSIDERED : </b> </br> - Chat history will be cleared after loging out from the site. </br>  - Send attachments with the <b>"Attachment"</b> keyword.<p>',
    subject: 'Greeting',
  };
  // This API has not released yet as this is at BETA version
  // Refer : https://developers.hubspot.com/docs/api/conversations/conversations
  const apiURL =
    window.location.origin +
    config.serverConfig.hubspotUri +
    `/conversations/v3/conversations/threads/${conversationID}/messages`;
  try {
    await axios.post(apiURL, requestBody);
  } catch (error) {
    console.error('Error occurred when sending greeting msg:', error);
    throw error;
  }
}

export function archiveHubspotConversations(config) {
  conversationIds.forEach(async conversationID => {
    let apiURL =
      window.location.origin +
      config.serverConfig.hubspotUri +
      `/conversations/v3/conversations/threads/${conversationID}`;
    try {
      await axios.delete(apiURL);
    } catch (error) {
      console.error('Error occurred when archiving conversations:', error);
    }
  });
  clearHubspotCookies();
}

function clearHubspotCookies() {
  const pastDate = new Date(0).toUTCString();
  document.cookie = `__hstc=; expires=${pastDate}; path=/;`;
  document.cookie = `hubspotutk=; expires=${pastDate}; path=/;`;
  document.cookie = `__hssc=; expires=${pastDate}; path=/;`;
  document.cookie = `__hssrc=; expires=${pastDate}; path=/;`;
  document.cookie = `messagesUtk=; expires=${pastDate}; path=/;`;
}
