/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  AndroidFilled,
  AppleFilled,
  HddFilled,
  HistoryOutlined,
  WindowsFilled,
} from '@ant-design/icons';
import { Result, Spin, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import GeoDashboard from '../../components/GeoDashboard';
import styles from './styles.module.css';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import axios from 'axios';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import { isAuthorized } from '../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../../../components/BreadCrumb';

const { Title } = Typography;

const routes = (deviceName, appName, deviceType, deviceIdentifier, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      path: `/${appName}/devices`,
      breadcrumbName: t('home_menu_devices'),
    },
    {
      path: `/${appName}/devices/${deviceType}/${deviceIdentifier}/info`,
      breadcrumbName: deviceName.charAt(0).toUpperCase() + deviceName.slice(1),
    },
    {
      breadcrumbName: t('title_locationHistory'),
    },
  ];
};

class GeoHistory extends React.Component {
  routes;

  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.routes = props.routes;
    this.state = {
      loading: true,
      isUserAuthorized: false,
      data: [],
    };
  }

  componentDidMount() {
    if (isAuthorized(this.config.scopes, ['perm:devices:details'])) {
      this.fetchDevice();
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  fetchDevice = () => {
    let { t } = this.props;
    const { deviceIdentifier, deviceType } = this.props.match.params;
    axios
      .get(
        window.location.origin +
          this.config.serverConfig.invoker.uri +
          this.config.serverConfig.invoker.deviceMgt +
          `/devices/${deviceType}/${deviceIdentifier}`,
      )
      .then(result => {
        if (result.status === 200) {
          this.setState({
            data: result.data.data,
            isUserAuthorized: true,
            loading: false,
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_retrieveDeviceDetailsError'), t);
        this.setState({
          isUserAuthorized: true,
          loading: false,
        });
      });
  };

  render() {
    const { deviceIdentifier, deviceType } = this.props.match.params;
    const defaultPlatformIcons = this.config.defaultPlatformIcons;
    let { data, isUserAuthorized, loading } = this.state;
    let { t } = this.props;
    let icon;
    switch (deviceType) {
      case 'android':
        icon = <AndroidFilled />;
        break;
      case 'ios':
        icon = <AppleFilled />;
        break;
      case 'windows':
        icon = <WindowsFilled />;
        break;
      default:
        icon = <HddFilled />;
    }
    const iconColor = defaultPlatformIcons.hasOwnProperty(deviceType)
      ? defaultPlatformIcons[deviceType].color
      : defaultPlatformIcons.default.color;

    if (loading) {
      return <Spin />;
    } else if (!isUserAuthorized) {
      return (
        <Result
          status="403"
          title={t('noPerm_accessPageTitle')}
          subTitle={t('noPerm_contactSysAdmin')}
        />
      );
    } else if (data.name) {
      return (
        <div>
          <HtmlComments
            permission={
              '/permission/admin/device-mgt/devices/owning-device/view'
            }
          />
          <div>
            <PageHeader
              className={styles.pageHeader}
              title={
                <Title level={1}>
                  <span className={styles.icon}>
                    <HistoryOutlined />
                  </span>
                  {t('title_locationHistory')} -{' '}
                  {`${data.name.charAt(0).toUpperCase() + data.name.slice(1)}`}{' '}
                  <span style={{ color: iconColor }}>{icon}</span>
                </Title>
              }
              ghost={false}
              breadcrumb={
                <BreadcrumbComponent
                  breadcrumbList={routes(
                    data.name,
                    this.config.appName,
                    deviceType,
                    deviceIdentifier,
                    t,
                  )}
                />
              }
            />
            <div className={styles.container}>
              <GeoDashboard
                deviceIdentifier={deviceIdentifier}
                deviceType={deviceType}
              />
            </div>
          </div>
        </div>
      );
    }
    return <Result status="warning" title={t('noDeviceLocationLoading_Txt')} />;
  }
}

export default withConfigContext(withTranslation()(GeoHistory));
