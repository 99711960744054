/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useContext, useEffect } from 'react';
import { Col } from 'antd';
import { PolicyDetailsContext } from '../../index';
import SelectPolicyType from '../../../../components/SelectPolicyType';

const CorrectiveInfo = () => {
  const policyDetails = useContext(PolicyDetailsContext);
  useEffect(() => {
    // Update the current route's key
    policyDetails.setCurrentRoute('corrective-information');
  }, []);

  return (
    <div style={{ marginTop: 20 }}>
      <Col span={18} offset={3}>
        {policyDetails.policy && (
          <SelectPolicyType
            activeFeatures={policyDetails.policy.profile.profileFeaturesList}
            policyData={policyDetails.policy}
            disabled={true}
          />
        )}
      </Col>
    </div>
  );
};

export default CorrectiveInfo;
