/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Card, Col, Divider, Row, Typography } from 'antd';
import { withConfigContext } from '../../../../components/ConfigContext';

const { Meta } = Card;
const { Text } = Typography;

class SelectPlatform extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
    };
  }

  deviceTypes = this.props.context.deviceTypes.map(type => {
    if (type.toLowerCase() in this.props.context.defaultPlatformIcons) {
      type = {
        type,
        ...this.props.context.defaultPlatformIcons[type.toLowerCase()],
      };
    } else {
      type = { type, ...this.props.context.defaultPlatformIcons.default };
    }
    return type;
  });

  // set select device type
  onClickCard = (e, type) => {
    this.props.getDeviceType(type);
  };

  // itemCardNew = this.deviceTypesTemp

  itemCard = this.deviceTypes.map((deviceType, index) => (
    <Col span={25} key={index}>
      <a>
        <Card
          size="default"
          style={{ width: 150, marginTop: 5 }}
          bordered={true}
          onClick={e => this.onClickCard(e, deviceType.type)}
          cover={
            <LegacyIcon
              type={deviceType.icon}
              key="device-types"
              style={{
                color: '#ffffff',
                backgroundColor: '#4b92db',
                fontSize: '100px',
                padding: '20px',
              }}
            />
          }
        >
          <Meta style={{ textAlign: 'center' }} title={deviceType.type} />
        </Card>
      </a>
    </Col>
  ));

  render() {
    return (
      <div>
        {this.props.description && (
          <div>
            <Text type="secondary">{this.props.description}</Text>
            <Divider />
          </div>
        )}

        <Row gutter={16}>{this.itemCard}</Row>
      </div>
    );
  }
}

export default withConfigContext(SelectPlatform);
