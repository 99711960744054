/*
 * Copyright (C) 2021. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useState } from 'react';
import { Modal, Button, Tag, List } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const PolicyAction = props => {
  const [isModalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();
  let assignees,
    listItems = '';

  let groups = props.selectedPolicyData.deviceGroups.map(group => (
    <Tag key={group.id}>{group.name}</Tag>
  ));

  if (groups.length === 0) {
    groups = <Tag>NONE</Tag>;
  }

  if (props.selectedPolicyData.users.length !== 0) {
    listItems = props.selectedPolicyData.users.map(user_ => (
      <Tag key={user_}>{user_}</Tag>
    ));
    assignees = 'Users';
  } else if (props.selectedPolicyData.roles.length !== 0) {
    listItems = props.selectedPolicyData.roles.map(role_ => (
      <Tag key={role_}>{role_}</Tag>
    ));
    assignees = 'Roles';
  }

  const showModal = () => {
    setModalVisible(true);
  };

  const handleOk = () => {
    setModalVisible(false);
  };

  return (
    <div>
      <Button size="small" icon={<TeamOutlined />} onClick={showModal}>
        {t('label_assignees')}
      </Button>
      <Modal
        title={<b>{t('label_assignees')}</b>}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            {t('label_ok')}
          </Button>,
        ]}
      >
        {t(`home_menu_${assignees.toLowerCase()}`)}
        <List bordered style={{ padding: '5px', marginBottom: '8px' }}>
          {listItems}
        </List>
        {t('home_menu_groups')}
        <List bordered style={{ padding: '5px', marginBottom: '8px' }}>
          {groups}
        </List>
      </Modal>
    </div>
  );
};

export default PolicyAction;
