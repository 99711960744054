/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Button } from 'antd';
import ProgressBar from '../ProgressBar';

const EnrollmentMode = props => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const [pageIndex, setPageIndex] = useState('enrollmentMode');

  const onFinish = () => {
    let obj = {};
    obj.title = 'Suggested enrollment Mode';
    obj.key = 'enrollmentMode';
    obj.value = props.enrolmentModeText;
    props.callback(obj, pageIndex);
  };

  const navigateBack = () => {
    props.callBackBackTrigger(pageIndex, props.prevPageIndex);
  };

  return (
    <div style={{ paddingBottom: 40 }}>
      <h2>{props.enrolmentModeText}</h2>
      <div style={{ float: 'right' }}>
        <Button
          type="primary"
          onClick={navigateBack}
          style={{ marginRight: 30 }}
        >
          {t('label_back')}
        </Button>
        <Button type="primary" onClick={onFinish} style={{ marginBottom: 30 }}>
          {t('label_next')}
        </Button>
      </div>
      <ProgressBar progress={80} />
    </div>
  );
};

export default withTranslation()(EnrollmentMode);
