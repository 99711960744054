/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, {useContext, useEffect, useState, useForm} from 'react';
import axios from 'axios';
import {
    CloseCircleOutlined,
    DesktopOutlined,
    EditOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import {Button, Card, Form, Input, Space, Alert, Tooltip, notification, Select} from 'antd';
import TimeAgo from 'javascript-time-ago';
// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';
import {useTranslation} from 'react-i18next';
import {RoutesContext} from "../../../../../../../../index";
import {MinusCircleOutlined, PlusOutlined, DeleteTwoTone} from '@ant-design/icons';
import {handleApiError} from "../../../../../../../../../../services/utils/errorHandler";
import ConfigContext from "../../../../../../../../../../components/ConfigContext";
import DeviceTypeEventForm from "./components/DeviceTypeEventForm";

const {TextArea} = Input;

const CreateDeviceEvent = (props) => {
    const {config} = useContext(ConfigContext);
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [deviceTypeName, setDeviceTypeName] = useState("");
    const [formList, setFormList] = useState({});

    const onBlurDeviceTypeNameInput = (e, index) => {
        let forms = {...formList}
        forms[index]["eventName"] = e.target.value
        setFormList(forms)
    }

    const onChangeEventTopicSelect = (value, index) => {
        let forms = {...formList}
        forms[index]["eventTopicStructure"] = value
        setFormList(forms)
    }

    const onChangeTransportSelect = (value, index) => {
        let forms = {...formList}
        forms[index]["transport"] = value
        setFormList(forms)
    }

    const onBlurAttributeNameInput = (e, k, index) => {
        let forms = {...formList}
        if (forms[index]["attributes"] === undefined) {
            forms[index]["attributes"] = {}
        }
        if (forms[index]["attributes"][k] === undefined) {
            forms[index]["attributes"][k] = {}
        }
        forms[index]["attributes"][k]["name"] = e.target.value
        if (forms[index]["attributes"][k]["type"] === undefined) {
            forms[index]["attributes"][k]["type"] = "STRING"
        }
        setFormList(forms)
    }

    const onChangeAttributeTypeInput = (value, k, index) => {
        let forms = {...formList}
        if (forms[index]["attributes"] === undefined) {
            forms[index]["attributes"] = {}
        }
        if (forms[index]["attributes"][k] === undefined) {
            forms[index]["attributes"][k] = {}
        }
        forms[index]["attributes"][k]["type"] = value
        setFormList(forms)
    }

    const addEvent = () => {
        let forms = {...formList}
        if (Object.keys(forms).length === 0) {
            forms[0] = {
                key: 0
            }
        } else {
            const nextItem = Math.max(...Object.keys(forms))
            forms[nextItem + 1] = {
                key: nextItem + 1
            }
        }
        setFormList(forms)
    }

    const onClickDeleteEvent = (index) => {
        let forms = {...formList}
        delete forms[index]
        setFormList(forms)
    }

    const onRemoveAttribute = (k, index) => {
        let forms = {...formList}
        if (forms[index]["attributes"] !== undefined) {
            delete forms[index]["attributes"][k]
            setFormList(forms)
        }
    }

    const eventFormCallback = (eventForm, index) => {
        let forms = {...formList}
        forms[index]["formRef"] = eventForm
        setFormList(forms)
    }

    const onClickSubmit = async () => {
        const eventList = []
        let isValid = true;
        for (const form of Object.values(formList)) {
            try {
                const values = await form.formRef.validateFields();
                let eventObj = {
                    "eventName": form.eventName,
                    "eventTopicStructure": form.eventTopicStructure,
                    "transport": form.transport ? form.transport : "HTTP"
                }
                if (form.attributes) {
                    eventObj.eventAttributes = {
                        "attributes": Object.values(form.attributes)
                    }
                }
                eventList.push(eventObj)
            } catch (errorInfo) {
                isValid = false
            }

        }

        if (isValid) {
            setIsLoading(true);
            axios
                .post(
                    window.location.origin +
                    config.serverConfig.invoker.uri +
                    config.serverConfig.invoker.deviceMgt +
                    '/events/' + props.deviceTypeData.name,
                    JSON.stringify(eventList),
                    {headers: {'Content-Type': 'application-json'}}
                )
                .then(res => {
                    if (res.status === 200) {
                        setIsLoading(false);
                        props.skipCallback()
                        notification.success({
                            message: t('api_successMsg'),
                            duration: 4,
                            description: t('device_type_add_event_success'),
                        });
                    }
                })
                .catch(error => {
                    handleApiError(error, null, t, `${this.constructor.name}AddUser`);
                    setIsLoading(false);
                });
        } else {
            console.log("not valid")
        }
    }

    const onClickSkip = () => {
        props.skipCallback()
    }

    return (
        <>
            <Alert
                message={t('machine_type_add_machine_type_success')}
                type="success"
                showIcon
                closable
                style={{marginBottom: 10}}
            />
            {
                Object.values(formList).length > 0 && Object.values(formList).map((form, index) => {
                    return (
                        <Card key={form.key}
                              style={{borderRadius: 10, margin: 10, paddingTop: 10, paddingLeft: 10, paddingRight: 10}}
                              title={t('device_type_event_details_label')} extra={<DeleteTwoTone twoToneColor="#f00707" style={{fontSize: 17}}
                                                                          onClick={() => onClickDeleteEvent(form.key)}/>}>
                            <DeviceTypeEventForm
                                deviceTypeData={props.deviceTypeData}
                                form={form}
                                eventFormCallback={(eventForm) => eventFormCallback(eventForm, form.key)}
                                onBlurDeviceTypeNameInput={(value) => onBlurDeviceTypeNameInput(value, form.key)}
                                onChangeEventTopicSelect={(value) => onChangeEventTopicSelect(value, form.key)}
                                onChangeTransportSelect={(value) => onChangeTransportSelect(value, form.key)}
                                onBlurAttributeNameInput={(value, key) => onBlurAttributeNameInput(value, key, form.key)}
                                onChangeAttributeTypeInput={(value, key) => onChangeAttributeTypeInput(value, key, form.key)}
                                onRemoveAttribute={(key) => onRemoveAttribute(key, form.key)}/>
                        </Card>
                    )
                })
            }
            <Button type="dashed" onClick={() => addEvent()} block icon={<PlusOutlined/>}>
                Add Event
            </Button>
            <Space style={{display: "flex", justifyContent: "flex-end", marginTop: 10}}>
                <Button style={{marginLeft: "auto", marignRight: 0}} onClick={onClickSkip}>
                    Skip
                </Button>
                <Button loading={isLoading} type="primary" style={{marginLeft: "auto", marignRight: 0}}
                        onClick={onClickSubmit}>
                    Submit
                </Button>
            </Space>
        </>
    );
};

export default CreateDeviceEvent;
