/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Collapse, Result, Space, Spin, Tooltip } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import axios from 'axios';
import {handleApiError} from '../../../../services/utils/errorHandler';
import { RoutesContext } from '../../index';
import {withConfigContext} from '../../../../components/ConfigContext';
import moment from 'moment';
import 'moment-timezone';
import MeterInventoryTable from './MeterInventoryTable';
import styles from './styles.module.css';
import {ReloadOutlined, SearchOutlined,} from '@ant-design/icons';
import Filter from '../../components/Filter';
import {withTranslation} from 'react-i18next';
import {Link} from "react-router-dom";
import BreadcrumbComponent from "../../../../components/BreadCrumb";
import HtmlComments from "../../../../components/ReactHtmlComments/HtmlComments";
import Authorized from "../../../../components/Authorized";
import EditDevice from './components/EditDevice';
import { isAuthorized } from '../../../../services/utils/authorizationHandler';
import { CSVLink } from "react-csv";
const {Panel} = Collapse;

const routes = (appName, t) => {
    return [
        {
            path: `/${appName}`,
            breadcrumbName: t('home_menu_home'),
        },
        {
            breadcrumbName: t('home_menu_meterInventory'),
        },
    ];
};

class MeterInventory extends React.Component {
    filterValues = '';
    constructor(props) {
        super(props);
        this.config = this.props.context;
        this.state = {
            devices: [],
            count: 0,
            pagination: {},
            loading: false,
            selectedRows: [],
            searchFields: [],
            exportDataList: [],
            isModalVisible: false,
        };
        const {t} = this.props;
        let link = null;
        this.columns = [
            {
                title: t('label_deviceName'),
                dataIndex: 'name',
                key: 'name',
                render: (name, record) => (
                    <Link to={`/facility-monitoring/device/view/${record.type}/${record.deviceId}`} target="_blank">{name}</Link>
                ),
            },
            {
                title: t('label_state'),
                dataIndex: 'state',
                key: 'state',
                // eslint-disable-next-line react/display-name
                render: state => {
                    const status = state.toLowerCase();
                    let color;
                    let backGroundColor;
                    switch (status) {
                        case 'active':
                            backGroundColor = '#c4e0c6';
                            color = '#14403e';
                            break;
                        case 'created':
                            backGroundColor = '#8fc4ff';
                            color = '#294083';
                            break;
                        case 'removed':
                            backGroundColor = '#ff9999';
                            color = '#821212';
                            break;
                        case 'inactive':
                            backGroundColor = '#f9dd9e';
                            color = '#91681a';
                            break;
                        case 'blocked':
                            backGroundColor = '#333333';
                            color = '#ffffff';
                            break;
                        case 'unreachable':
                            backGroundColor = '#cccccc';
                            color = '#333333';
                            break;
                        case 'unclaimed':
                            backGroundColor = '#cccccc';
                            color = '#333333';
                            break;
                        case 'configured':
                            backGroundColor = '#b2d3fa';
                            color = '#0f3b72';
                            break;
                        case 'assigned':
                            backGroundColor = '#c4e0c6';
                            color = '#14403e';
                            break;
                        case 'defective':
                            backGroundColor = '#cccccc';
                            color = '#333333';
                            break;
                        case 'disenrollment_requested':
                            backGroundColor = '#cccccc';
                            color = '#333333';
                            break;
                        case 'ready_to_connect':
                            backGroundColor = '#cccccc';
                            color = '#333333';
                            break;
                        case 'returned':
                            backGroundColor = '#cccccc';
                            color = '#333333';
                            break;
                        case 'return_pending':
                            backGroundColor = '#cccccc';
                            color = '#333333';
                            break;
                        case 'suspended':
                            backGroundColor = '#ff9999';
                            color = '#821212';
                            break;
                        case 'warranty_pending':
                            backGroundColor = '#cccccc';
                            color = '#333333';
                            break;
                        case 'warranty_replaced':
                            backGroundColor = '#cccccc';
                            color = '#333333';
                            break;
                        case 'warranty_sent':
                            backGroundColor = '#cccccc';
                            color = '#333333';
                            break;
                    }
                    return (
                        <span
                            style={{
                                display: 'inline-block',
                                width: 120,
                                textAlign: 'center',
                                color: color,
                                background: backGroundColor,
                                padding: '1px 8px',
                                margin: '8px',
                                borderRadius: 7,
                            }}
                        >
              {t(`label_${status}`)}
            </span>
                    );
                },
            },
        ];
        // Conditionally add Action column if user has permission
        if (isAuthorized(this.config.scopes, ['dm:device:modify'])) {
            this.columns.push({
                title: 'Action',
                dataIndex: 'deviceIdentifier',
                key: 'actions',
                align: 'right',
                width: 25,
                render: (data, row) => {
                    return (
                        <Space>
                            <EditDevice
                                view={'meterInventoryTable'}
                                device={row}
                                callback={this.reloadMeterInventoryTable}
                                disabled={false}
                            />
                        </Space>
                    );
                },
            });
        }
        this.exportData = this.exportData.bind(this);
        this.csvLinkEl = React.createRef();
    }

    reloadMeterInventoryTable = () => {
        this.getMeters();
    };

    componentDidMount() {
        this.context.setCurrentRoute('allMeterInventory');
        const { t } = this.props;
        this.setState({ loading: true });
        const fields = [
            {
                name: 'meterStatus',
                label: t('label_status'),
                placeholder: t('label_status'),
                type: 'multipleSelect',
                values: Object.keys(this.config.meterStatus).map(
                    key => this.config.meterStatus[key],
                ),
            },
            {
                name: 'name',
                label: t('label_deviceName'),
                placeholder: t('label_deviceName'),
                type: 'input',
            },
        ];

        this.setState({
            searchFields: fields,
        });

        this.getMeters({ page: 1 });
    }

    fetchOperationFilters = (params, values) => {
        const inventoryFilters = {};
        if (values.hasOwnProperty('subTypeId')) {
            inventoryFilters.subTypeId = values.subTypeId;
        }
        if (values.hasOwnProperty('meterStatus')) {
            inventoryFilters.status = values.meterStatus;
        }
        if (values.hasOwnProperty('name') && values.name.trim() !== '') {
            inventoryFilters.name = values.name.trim();
        }
        if (values.hasOwnProperty('deviceId')) {
            inventoryFilters.deviceId = values.deviceId;
        }

        this.filterValues = inventoryFilters;
        this.setState({ pagination: { current: 1 } });
        this.getMeters(
            {
                page: 1,
            },
            inventoryFilters,
        );

    };
    exportData() {
        this.setState({exportData: true});
        this.getData();
        // this.getMeters()
    }

    getData() {
        const {t} = this.props;
        this.setState({loading: true});

        const getAllRecords = async (offset = 0, limit = 100) => {
            try {
                const response = await axios.get(
                    window.location.origin +
                    this.config.serverConfig.invoker.uri +
                    `/device-mgt/v1.0/devices?type=atmm&requireDeviceInfo=true&offset=${offset}&limit=${limit}&` +
                    Object.entries(this.filterValues)
                        .map(([key, value]) => {
                            if (Array.isArray(value)) {
                                return value.map(val => `${key}=${val}`).join('&');
                            } else {
                                return `${key}=${value}`;
                            }
                        })
                        .join('&')
                );

                if (response.status === 200) {
                    const exportData = response.data.data;

                    let data = [];

                    if (exportData.devices && exportData.devices.length > 0) {
                        exportData.devices.forEach((device, index) => {

                            if (device) {

                                data.push({
                                    name:device.name,
                                    deviceId: device.deviceIdentifier,
                                    status: device.enrolmentInfo.status,
                                    type: device.type,
                                });
                            }
                        });
                    }

                    return data;
                }
            } catch (error) {
                handleApiError(
                    error,
                    t('api_loadError', {label: t('label_export_data')}),
                    t,
                );
                throw error;
            }
        };
    const fetchData = async () => {
        let allRecords = [];
        let offset = 0;
        const limit = 100;

        try {
            while (true) {
                const records = await getAllRecords(offset, limit);

                if (records.length === 0) {
                    break;
                }

                allRecords = [...allRecords, ...records];
                offset += limit;
            }

            this.setState({exportDataList: allRecords, loading: false}, () => {
                setTimeout(() => {
                    this.csvLinkEl.current.link.click();
                });
            });
        } catch (error) {
            this.setState({loading: false});
        }
    };

    fetchData();
}

    getMeters = (params = {}, inventoryFilters = {}) => {
        const {t} = this.props;
        this.setState({loading: true});

        const currentPage =
            params.hasOwnProperty('page') && params.page ? params.page : 1;

        // Set default limit
        let limit = 10;
        if (params.results) {
            limit = params.results;
        }

        const offset = limit * (currentPage - 1);

        const extraParams = {
            offset: offset,
            limit: limit,
        };

        let urlParams = new URLSearchParams(extraParams);

        // Add inventory filters
        Object.entries(inventoryFilters).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach(val => urlParams.append(key, val));
            } else {
                urlParams.append(key, value);
            }
        });

        // Add status parameters from URL if present
        const urlSearchParams = new URLSearchParams(window.location.search);
        const statusParams = urlSearchParams.getAll('status');
        statusParams.forEach(status => urlParams.append('status', status));

        if (!inventoryFilters.hasOwnProperty('status') ) {
            ['ACTIVE', 'INACTIVE', 'CREATED', 'UNREACHABLE'].forEach(status =>
                urlParams.append('status', status)
            );
        }
        if (statusParams.length > 0) {
            urlParams.set('status', statusParams.join(','));
        }

        const apiUrl = `${window.location.origin}${this.config.serverConfig.invoker.uri}/device-mgt/v1.0/devices?type=atmm&requireDeviceInfo=true&${urlParams}`;

        axios
            .get(apiUrl)
            .then(res => {
                if (res.status === 200) {
                    const pagination = { ...this.state.pagination };
                    const metersData = res.data.data.devices.map(device => ({
                        deviceId: device.deviceIdentifier,
                        name: device.name,
                        imei: device.properties?.find(prop => prop.name === 'COM_MODULE_IMEI')?.value || '-',
                        branch: device.properties?.find(prop => prop.name === 'BRANCH')?.value || '-',
                        depot: device.properties?.find(prop => prop.name === 'DEPOT')?.value || '-',
                        substationId: device.properties?.find(prop => prop.name === 'SUBSTATION_ID')?.value || '-',
                        hasExport: device.properties?.find(prop => prop.name === 'HAS_EXPORT')?.value || '-',
                        hasEBilling: device.properties?.find(prop => prop.name === 'HAS_E_BILLING')?.value || '-',
                        isFeederEnd: device.properties?.find(prop => prop.name === 'IS_FEEDER_END')?.value || '-',
                        poleID: device.properties?.find(prop => prop.name === 'POLE_ID')?.value || '-',
                        assignedOn: device.properties?.find(prop => prop.name === 'ASSIGNED_ON')?.value || '-',
                        fwVersion: device.properties?.find(prop => prop.name === 'METER_FW_VER')?.value || '-',
                        subtype: device.properties?.find(prop => prop.name === 'SUB_TYPE_ID')?.value || '-',
                        state: device.enrolmentInfo.status,
                        ipAddress: device.properties?.find(prop => prop.name === 'IP_ADDRESS')?.value || '-',
                        nodeType: device.properties?.find(prop => prop.name === 'NODE_TYPE')?.value || '-',
                        accountNo: device.properties?.find(prop => prop.name === 'CUSTOMER_REF')?.value || '-',
                        properties: device.properties,
                        description: device.description,
                        type: device.type,
                    }));
                    this.setState({
                        devices: metersData,
                        count: res.data.data.count,
                        pagination,
                        loading: false,
                        exportDataList: metersData, // Set export data to state
                    });
                }
            })
            .catch(error => {
                handleApiError(error, t('api_getInventoryError'), t);
                this.setState({ loading: false });
            });
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        pager.pageSize = pagination.pageSize;
        this.setState({
            pagination: pager,
        });
        this.getMeters(
            {
                results: pagination.pageSize,
                page: pagination.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters,
            },
            this.filterValues,
        );
    };

    render() {
        const {devices, pagination, loading, selectedNodeType} = this.state;
        const {t} = this.props;

        const data = this.state.exportDataList;
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0');
        let yyyy = today.getFullYear();
        today = mm + '-' + dd + '-' + yyyy;
        const filename = `MeterInventory_${moment().format('YYYY-MM-DD')}.csv`;
        let path = `/facility-monitoring/public/documents/${this.config.userDomain}/`;

        const headers = [
            {label: 'DEVICE_ID', key: 'deviceId', width: 100},
            {label: 'NAME', key: 'name'},
            {label: 'TYPE', key: 'type'},
            {label: 'STATUS', key: 'status'},
        ];

        // Define dynamic category options based on nodeType
        const categoryOptions =
            selectedNodeType === 'INTERNAL'
                ? ['INTERNAL_DISTRIBUTION', 'INTERNAL_TESTING']
                : selectedNodeType === 'BILLING'
                    ? ['BILLING_BULK', 'BILLING_PAID']
                    : [];

        return (
            <div>
                <HtmlComments permission={'/permission/admin/device-mgt/devices/owning-device/view'}/>
                <Authorized
                    scope={['dm:devices:view']}
                    yes={
                        <div>
                            <PageHeader
                                className={styles.pageHeader}
                                ghost={false}
                                breadcrumb={
                                    <BreadcrumbComponent
                                        breadcrumbList={routes(this.config.appName, t)}
                                    />
                                }
                            >
                            </PageHeader>
                            <div className={styles.actionsContainer}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}>
                                    <div style={{flex: '1'}}>
                                        <Collapse
                                            bordered={false}
                                            expandIcon={() => <SearchOutlined/>}
                                            className={styles.searchCollapse}
                                        >
                                            <Panel
                                                header={t('form_searchDevices')}
                                                key="1"
                                                style={{borderColor: '#fff'}}
                                            >
                                                <Filter
                                                    filters={this.state.searchFields}
                                                    callback={(params, values) =>
                                                        this.fetchOperationFilters({}, values)
                                                    }
                                                    onNodeTypeChange={(selectedNodeType) =>
                                                        this.setState({selectedNodeType})
                                                    }
                                                    categoryOptions={categoryOptions}
                                                />
                                            </Panel>
                                        </Collapse>
                                    </div>
                                    <div>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            onClick={this.exportData}
                                            disabled={this.state.count === 0}
                                            className={styles.csvExport}
                                            size="small"
                                        >
                                            Export CSV
                                        </Button>
                                        <CSVLink
                                            filename={filename}
                                            data={data}
                                            headers={headers}
                                            ref={this.csvLinkEl}
                                        />
                                        <Tooltip
                                            placement="bottom"
                                            title={t('label_refreshDeviceInventory')}
                                            autoAdjustOverflow={true}
                                        >
                                            <Button
                                                type="link"
                                                shape="circle"
                                                icon={<ReloadOutlined/>}
                                                onClick={this.getMeters}
                                                size={'default'}
                                                style={{marginLeft: '15px'}}
                                            >
                                                {t('label_refresh')}
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.table}>
                                <div>
                                    {loading && (
                                        <div style={{textAlign: 'center', marginRight: '20px'}}>
                                            <Spin/>
                                        </div>
                                    )}
                                    {!loading > 0 && (
                                        <MeterInventoryTable
                                            devices={devices}
                                            columns={this.columns}
                                            pagination={pagination}
                                            loading={loading}
                                            count={this.state.count}
                                            handleTableChange={this.handleTableChange}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                    no={
                        <Result
                            status="403"
                            title={t('noPerm_accessPageTitle')}
                            subTitle={t('noPerm_contactSysAdmin')}
                        />
                    }
                />
            </div>
        );
    }
}

MeterInventory.contextType = RoutesContext;
export default withConfigContext(withTranslation()(MeterInventory));
