/*
 * Copyright (C) 2021. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 *  Licensed under the Entgra Commercial License,
 *  Version 1.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *
 *  Unless required by applicable law or agreed to in writing,
 *  software distributed under the License is distributed on an
 *  "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 *  KIND, either express or implied.  See the License for the
 *  specific language governing permissions and limitations
 *  under the License.
 *
 *  You may obtain a copy of the License at
 *  https://entgra.io/licenses/entgra-commercial/1.0
 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import txtEnUS from './locales/en_US.json';
import antEnUS from 'antd/es/locale/en_US';
import txtFrFR from './locales/fr_FR.json';
import antfrFR from 'antd/es/locale/fr_FR';
import txtJaJP from './locales/ja_JP.json';
import antJaJp from 'antd/es/locale/ja_JP';
import txtEsES from './locales/es_ES.json';
import antEsES from 'antd/es/locale/es_ES';
import txtSiLK from './locales/si_LK.json';
import antSiLK from 'antd/es/locale/si_LK';

// Add new locales to this object
const supportedLocals = {
  enUS: {
    displayText: '🇺🇸 English',
    textTranslations: txtEnUS,
    antLocale: antEnUS,
  },
  frFR: {
    displayText: '🇫🇷 French',
    textTranslations: txtFrFR,
    antLocale: antfrFR,
  },
  jaJP: {
    displayText: '🇯🇵 Japanese',
    textTranslations: txtJaJP,
    antLocale: antJaJp,
  },
  esES: {
    displayText: '🇪🇸 Spanish',
    textTranslations: txtEsES,
    antLocale: antEsES,
  },
  siLK: {
    displayText: '🇱🇰 Sinhala',
    textTranslations: txtSiLK,
    antLocale: antSiLK,
  },
};
const initializeI18n = () => {
  i18n.use(initReactI18next).init({
    resources: {
      enUS: {
        translations: supportedLocals.enUS.textTranslations,
      },
      frFR: {
        translations: supportedLocals.frFR.textTranslations,
      },
      jaJP: {
        translations: supportedLocals.jaJP.textTranslations,
      },
      esES: {
        translations: supportedLocals.esES.textTranslations,
      },
      siLK: {
        translations: supportedLocals.siLK.textTranslations,
      },
    },
    fallbackLng: 'enUS',
    debug: true,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
      format: function(value, format, lng) {
        if (format === 'uppercase') {
          return value.toUpperCase();
        }
        if (format === 'lowercase') {
          return value.toLowerCase();
        }
        return value;
      },
    },
    react: { wait: true },
  });
  return i18n;
};
export { initializeI18n, supportedLocals };
