/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { MailOutlined } from '@ant-design/icons';
import { Modal, Alert, Form, Button, Tooltip, Checkbox, Select } from 'antd';
import Authorized from '../../../../../../../../components/Authorized';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

const buttonItemLayout = {
  wrapperCol: { span: 24, offset: 19 },
};

class UserBulkActionBar extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      selectedMultiple: false,
      selectedSingle: false,
      isPolicyActive: true,
      selectedDeviceTypes: [],
      isDeviceTypeSelectorVisible: false,
      isError: false,
    };
  }

  onClickInviteUser = () => {
    this.setState({
      isDeviceTypeSelectorVisible: true,
    });
  };

  onFinish = values => {
    let enrollmentTypes = [];
    let isError = false;
    this.state.selectedDeviceTypes.forEach(element => {
      let key = `enrollmentType_${element}`;
      if (values[key]) {
        let enrollmentType = {
          deviceType: element,
          enrollmentType: values[key],
        };
        enrollmentTypes.push(enrollmentType);
      } else {
        isError = true;
      }
    });
    if (this.state.selectedDeviceTypes.length > 0 && !isError) {
      this.props.inviteUsers(enrollmentTypes);
      this.setState({
        isDeviceTypeSelectorVisible: false,
        selectedDeviceTypes: [],
        isError,
      });
      this.formRef.current.resetFields();
    } else {
      this.setState({
        isError,
      });
    }
  };

  onCancelHandler = () => {
    this.setState({
      isDeviceTypeSelectorVisible: false,
    });
  };

  onChangeDeviceType = e => {
    let selectedDeviceTypes = [...this.state.selectedDeviceTypes];
    if (e.target.checked) {
      selectedDeviceTypes.push(e.target.id);
    } else {
      const indexOfDeviceType = selectedDeviceTypes.indexOf(e.target.id);
      selectedDeviceTypes.splice(indexOfDeviceType, 1);
    }
    this.setState({
      selectedDeviceTypes,
    });
  };

  render() {
    const isSelected = this.props.selectedRows.length > 0;
    const { selectedDeviceTypes, isError } = this.state;
    const { t } = this.props;
    return (
      <div>
        <Authorized
          scope={['perm:users:send-invitation']}
          yes={
            <Tooltip
              placement="bottom"
              title={t('label_inviteSelected')}
              autoAdjustOverflow={true}
            >
              <Button
                type="link"
                shape="circle"
                icon={<MailOutlined />}
                onClick={this.onClickInviteUser}
                size={'default'}
                style={{ margin: '2px' }}
                disabled={!isSelected}
              >
                <span>
                  <HtmlComments
                    permission={'/permission/admin/device-mgt/users/manage'}
                  />
                </span>
                {t('label_inviteUsers')}
              </Button>
            </Tooltip>
          }
        />
        <Modal
          title={t('label_selectEnrollmentTypes')}
          open={this.state.isDeviceTypeSelectorVisible}
          onCancel={this.onCancelHandler}
          footer={null}
          maskClosable={false}
        >
          {isError && (
            <Alert
              message={t('form_checkEnrollmentsTypesAvailabilityMsg')}
              type="error"
            />
          )}
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <div>
              <Form.Item name="android" style={{ marginBottom: -2 }}>
                <Checkbox onChange={this.onChangeDeviceType}>
                  {t('label_android')}
                </Checkbox>
              </Form.Item>
              <Form.Item name="enrollmentType_android">
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder={t('form_enrollmentsTypeRequiredMsg')}
                  disabled={!selectedDeviceTypes.includes('android')}
                >
                  <Option key="BYOD">BYOD</Option>
                  <Option key="COPE">COPE</Option>
                  <Option key="COSU">COSU (KIOSK)</Option>
                  <Option key="WORK_PROFILE">WORK PROFILE</Option>
                  <Option key="GOOGLE_WORK_PROFILE">GOOGLE_WORK_PROFILE</Option>
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item name="ios" style={{ marginBottom: -2 }}>
                <Checkbox onChange={this.onChangeDeviceType}>
                  {t('label_ios')}
                </Checkbox>
              </Form.Item>
              <Form.Item name="enrollmentType_ios">
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder={t('form_enrollmentsTypeRequiredMsg')}
                  disabled={!selectedDeviceTypes.includes('ios')}
                >
                  <Option key="BYOD">BYOD</Option>
                  <Option key="DEP">DEP</Option>
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item name="windows" style={{ marginBottom: -2 }}>
                <Checkbox onChange={this.onChangeDeviceType}>
                  {t('label_windows')}
                </Checkbox>
              </Form.Item>
              <Form.Item name="enrollmentType_windows">
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder={t('form_enrollmentsTypeRequiredMsg')}
                  disabled={!selectedDeviceTypes.includes('windows')}
                >
                  <Option key="BYOD">BYOD</Option>
                  <Option key="DEP">DEP</Option>
                </Select>
              </Form.Item>
            </div>
            <Form.Item {...buttonItemLayout}>
              <Button type="primary" htmlType="submit">
                {t('label_send')}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(UserBulkActionBar);
