/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Result } from 'antd';
import { withTranslation } from 'react-i18next';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import { Link } from 'react-router-dom';

const EnrollmentResult = ({ payload, config, t }) => {
  const device = payload.deviceIdentifier;
  const deviceType = payload.type;

  const viewDeviceRoute = `/${config.appName}/devices/${deviceType}/${device}/statistics`;

  return (
    <div>
      <Result
        status="success"
        title={t("form_deviceEnrollSuccess")}
        extra={[
          <Link to={viewDeviceRoute} key="viewDevice">
            <Button type="primary">{t("form_btnViewDevice")}</Button>
          </Link>
        ]}
      />
    </div>
  );
};

export default withTranslation()(withConfigContext(EnrollmentResult));
