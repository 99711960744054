/*
 * Copyright (C) 2021. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { RoutesContext } from '../../index';
import { withConfigContext } from '../../../../components/ConfigContext';
import { Col, Row } from 'antd';
import IframeWrapper from '../../components/IframeWrapper';
import styles from './styles.module.css';
import { withTranslation } from 'react-i18next';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.config = props.context;
    this.state = {
      selectedLanguage: this.props.i18n.language,
    };
  }

  componentDidMount() {
    this.context.setCurrentRoute('dashboard');
  }

  render() {
    const grafanaEmbedLinks = this.config.grafanaConfig;
    const { selectedLanguage } = this.state;
    return (
      <div className={styles.content}>
        <Row>
          <Col xl={8} xs={24}>
            <div className={styles.iFrameContainer}>
              <IframeWrapper
                iframeSrc={
                  grafanaEmbedLinks.deviceInventory.overview +
                  '&panelId=2' +
                  '&var-label_panelName2=' +
                  selectedLanguage
                }
              />
            </div>
          </Col>
          <Col xl={8} xs={24}>
            <div className={styles.iFrameContainer}>
              <IframeWrapper
                iframeSrc={
                  grafanaEmbedLinks.deviceInventory.overview +
                  '&panelId=4' +
                  '&var-label_panelName4=' +
                  selectedLanguage
                }
              />
            </div>
          </Col>
          <Col xl={8} xs={24}>
            <div className={styles.iFrameContainer}>
              <IframeWrapper
                iframeSrc={
                  grafanaEmbedLinks.deviceHardware.overview +
                  '&panelId=2' +
                  '&var-label_panelName2=' +
                  selectedLanguage
                }
              />
            </div>
          </Col>
          <Col xl={12} xs={24}>
            <div className={styles.iFrameContainer}>
              <IframeWrapper
                iframeSrc={
                  grafanaEmbedLinks.deviceFirmware.overview +
                  '&panelId=2' +
                  '&var-label_panelName2=' +
                  selectedLanguage
                }
              />
            </div>
          </Col>
          <Col xl={12} xs={24}>
            <div className={styles.iFrameContainer}>
              <IframeWrapper
                iframeSrc={
                  grafanaEmbedLinks.applications.overview +
                  '&panelId=2' +
                  '&var-label_panelName2=' +
                  selectedLanguage
                }
              />
            </div>
          </Col>
          <Col xl={12} xs={24}>
            <div className={styles.iFrameContainer}>
              <IframeWrapper
                iframeSrc={
                  grafanaEmbedLinks.deviceConnectivity.overview +
                  '&panelId=6' +
                  '&var-connectionType=MOBILE' +
                  '&var-label_panelName6=' +
                  selectedLanguage
                }
              />
            </div>
          </Col>
          <Col xl={12} xs={24}>
            <div className={styles.iFrameContainer}>
              <IframeWrapper
                iframeSrc={
                  grafanaEmbedLinks.deviceAssignment.overview +
                  '&panelId=5' +
                  '&var-label_panelName5=' +
                  selectedLanguage +
                  '&var-label_ungrouped=' +
                  selectedLanguage +
                  '&var-label_deviceCount=' +
                  selectedLanguage
                }
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
Dashboard.contextType = RoutesContext;
export default withConfigContext(withTranslation()(Dashboard));
