/*
 * Copyright (C) 2022. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 *  Licensed under the Entgra Commercial License,
 *  Version 1.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *
 *  Unless required by applicable law or agreed to in writing,
 *  software distributed under the License is distributed on an
 *  "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 *  KIND, either express or implied.  See the License for the
 *  specific language governing permissions and limitations
 *  under the License.
 *
 *  You may obtain a copy of the License at
 *  https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Breadcrumb } from 'antd';
import 'react-jsx-html-comments';
import { Link } from 'react-router-dom';
import { RoutesContext } from '../../scenes/Home';
import { withTranslation } from 'react-i18next';
import { HomeOutlined } from '@ant-design/icons';

class BreadcrumbComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let breadcrumbList = this.props.breadcrumbList;
    const first = breadcrumbList[0];
    const firstBreadCrumb = (
      <Breadcrumb.Item key={first.breadcrumbName}>
        <Link to={first.path}>
          <HomeOutlined /> {first.breadcrumbName}
        </Link>
      </Breadcrumb.Item>
    );
    let lastBreadcrumbSet = this.props.breadcrumbList.slice(1);
    return (
      <Breadcrumb style={{ fontSize: '14px' }}>
        {firstBreadCrumb}
        {lastBreadcrumbSet.map(item => (
          // eslint-disable-next-line react/jsx-key
          <Breadcrumb.Item key={first.breadcrumbName}>
            {item.path !== '' && item.path !== undefined ? (
              <Link to={item.path}>{item.breadcrumbName}</Link>
            ) : (
              <>{item.breadcrumbName}</>
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    );
  }
}

BreadcrumbComponent.contextType = RoutesContext;
export default withTranslation()(BreadcrumbComponent);
