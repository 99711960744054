import { createReportTable } from "../../../Util/createReportTable";
import { addStamp } from "../../../Util/addStamp";

export function getDailyConsumptionTable(doc, pdfConfig, param) {
    //Daily Consumption table
    pdfConfig.totalHeight += pdfConfig.lineHeight;
    pdfConfig.totalHeight += pdfConfig.lineHeight;
    doc.setTextColor(pdfConfig.colorGray);
    doc.setFontSize(pdfConfig.fieldTextSize);
    if (param.reportBody.DailyConsumptionLabel) {
        doc.text(10, pdfConfig.totalHeight, param.reportBody.DailyConsumptionLabel);
    }
    const dailyConsumptionTable = {
        headerBorder: param.reportBody?.DailyConsumptionHeaderBorder || false,
        tableBodyBorder: param.reportBody?.DailyConsumptionTableBodyBorder || false,
        header: param.reportBody?.DailyConsumptionHeader || [],
        table: param.reportBody?.DailyConsumptionTable || [],
    };
    createReportTable(dailyConsumptionTable, doc, pdfConfig, param);
    //end Daily Consumption table
    //PAGE BREAKER
    var checkAndAddPageLandscape = function () {
        if (!param.orientationLandscape && pdfConfig.totalHeight > 270) {
            doc.addPage();
            pdfConfig.totalHeight = 10;
        }
    };

    var checkAndAddPageNotLandscape = function (heightLimit = 173) {
        if (param.orientationLandscape && pdfConfig.totalHeight > heightLimit) {
            doc.addPage();
            pdfConfig.totalHeight = 10;
        }
    };
    var checkAndAddPage = function () {
        checkAndAddPageNotLandscape();
        checkAndAddPageLandscape();
    };
    //end PAGE BREAKER
    checkAndAddPage();

    doc.setTextColor(pdfConfig.colorBlack);
    doc.setFontSize(pdfConfig.labelTextSize);
    pdfConfig.totalHeight += pdfConfig.lineHeight;

    checkAndAddPage();

    doc.setTextColor(pdfConfig.colorBlack);
    pdfConfig.totalHeight += pdfConfig.subLineHeight;
    pdfConfig.totalHeight += pdfConfig.subLineHeight;
    doc.setFontSize(pdfConfig.labelTextSize);
    //Add num of pages at the bottom
    if (doc.getNumberOfPages() > 1) {
        for (let i = 1; i <= doc.getNumberOfPages(); i++) {
            doc.setFontSize(pdfConfig.fieldTextSize - 2);
            doc.setTextColor(pdfConfig.colorGray);

            if (param.pageEnable) {
                doc.text(pdfConfig.docWidth / 2, pdfConfig.docHeight - 10, param.footer.text, "center");
                doc.setPage(i);
                doc.text(
                    param.pageLabel + " " + i + " / " + doc.getNumberOfPages(),
                    pdfConfig.docWidth - 20,
                    doc.internal.pageSize.height - 6
                );
            }

            checkAndAddPageNotLandscape(183);
            checkAndAddPageLandscape();
            addStamp(param, doc, pdfConfig);
        }
    }
    //end Add num of pages at the bottom
    addStamp(param, doc, pdfConfig);

    //Add num of first page at the bottom
    if (doc.getNumberOfPages() === 1 && param.pageEnable) {
        doc.setFontSize(pdfConfig.fieldTextSize - 2);
        doc.setTextColor(pdfConfig.colorGray);
        doc.text(pdfConfig.docWidth / 2, pdfConfig.docHeight - 10, param.footer.text, "center");
        doc.text(
            param.pageLabel + "1 / 1",
            pdfConfig.docWidth - 20,
            doc.internal.pageSize.height - 6
        );
    }
    //end Add num of first page at the bottom
    return {
        doc: doc,
        pdfConfig: pdfConfig
    };
}
