import { jsPDF } from "jspdf";
import { getCompanyName } from "../../CommonDetails/CompanyInfo/getCompanyName";
import { getCompanyDetails } from "../../CommonDetails/CompanyInfo/getCompanyDetails";
import { getCustomerInfo } from "../../CommonDetails/CustomerInfo/getCustomerInfo";
import { getBasicTable } from "./InvoiceReportBody/getBasicTable";
import { getPaymentTable } from "./InvoiceReportBody/getPaymentTable";
import { getCurrentMonthTable } from "./InvoiceReportBody/getCurrentMonthTable";

export { jsPDF };
/**
 *
 * @param { {
*   fileName: string,
*   orientationLandscape?: boolean,
*   stamp?: {
*      inAllPages?: boolean,
*      src?: string,
*      type?: string,
*      width?: number,
*      height?: number,
*      margin?: {
*        top?: number,
*        left?: number
*      }
*   },
*   company?: {
*       name?: string,
*       address_no?: string,
*       address_road?: string,
*       address_city?: string,
*       postalCode?: string,
*       phone?: string,
*       email?: string,
*       email_1?: string,
*       website?: string,
*   },
*   customerInfo?: {
*       label?: string,
*       name?: string,
*       accountNumber?: string,
*       address?: string,
*   },
*   reportBody?: {
*       label?: string,
*       num?: number,
*       invDate?: string,
*       invGenDate?: string,
*       headerBorder?: boolean,
*       tableBodyBorder?: boolean,
*       header?: 
*        {
*          title: string, 
*          style?: { width?: number }
*        }[],
*       table?: any,
*       totalPayment?: string,
*   },
*   footer?: {
*       text?: string,
*   },
*   pageEnable?: boolean,
*   pageLabel?: string, } } props
*/

export function getInvoiceViewTemplate(props) {
    //param preparation  
    const param = {
        fileName: props.fileName || "",
        orientationLandscape: props.orientationLandscape || false,
        stamp: {
            inAllPages: props.stamp?.inAllPages || false,
            src: props.stamp?.src || "",
            width: props.stamp?.width || "",
            height: props.stamp?.height || "",
            margin: {
                top: props.stamp?.margin?.top || 0,
                left: props.stamp?.margin?.left || 0,
            },
        },
        company: {
            name: props.company?.name || "",
            address_no: props.company?.address_no || "",
            address_road: props.company?.address_road || "",
            address_city: props.company?.address_city || "",
            postalCode: props.company?.postalCode || "",
            phone: props.company?.phone || "",
            email: props.company?.email || "",
            email_1: props.company?.email_1 || "",
            website: props.company?.website || "",
        },
        customerInfo: {
            label: props.customerInfo?.label || "",
            name: props.customerInfo?.name || "",
            accountNumber: props.customerInfo?.accountNumber || "",
            address: props.customerInfo?.address || "",
        },
        reportBody: {
            label: props.reportBody?.label || "",
            num: props.reportBody?.num || "",
            invDate: props.reportBody?.invDate || "",
            invGenDate: props.reportBody?.invGenDate || "",
            basicHeaderBorder: props.reportBody?.basicHeaderBorder || false,
            basicTableBodyBorder: props.reportBody?.basicTableBodyBorder || false,
            basicHeader: props.reportBody?.basicHeader || [],
            basicTable: props.reportBody?.basicTable || [],
            paymentLabel: props.reportBody?.paymentLabel || "",
            paymentHeaderBorder: props.reportBody?.paymentHeaderBorder || false,
            paymentTableBodyBorder: props.reportBody?.paymentTableBodyBorder || false,
            paymentHeader: props.reportBody?.paymentHeader || [],
            paymentTable: props.reportBody?.paymentTable || [],
            totalOutstanding: props.reportBody?.totalOutstanding || "",
            currentMonthLabel: props.reportBody?.currentMonthLabel || "",
            currentMonthHeaderBorder: props.reportBody?.currentMonthHeaderBorder || false,
            currentMonthTableBodyBorder: props.reportBody?.currentMonthTableBodyBorder || false,
            currentMonthHeader: props.reportBody?.currentMonthHeader || [],
            currentMonthTable: props.reportBody?.currentMonthTable || [],
        },
        footer: {
            text: props.footer?.text || "",
        },
        pageEnable: props.pageEnable || false,
        pageLabel: props.pageLabel || "Page",
    };


    //jsPdf Options
    const options = {
        orientation: param.orientationLandscape ? "landscape" : ""
    };
    //end jsPdf Options

    //doc initiation
    var doc = new jsPDF(options);


    //pdf variable initiation
    var pdfConfig = {
        headerTextSize: 20,
        labelTextSize: 12,
        fieldTextSize: 10,
        lineHeight: 6,
        subLineHeight: 4,
        docWidth: doc.internal.pageSize.width,
        docHeight: doc.internal.pageSize.height,
        colorBlack: "#000000",
        colorGray: "#4d4e53",
        colorRed: "#ff0000",
        totalHeight: 15

    };

    getCompanyName(doc, pdfConfig, param);
    getCompanyDetails(doc, pdfConfig, param);
    //line breaker
    if (param.reportBody.basicHeader.length) {
        pdfConfig.totalHeight += pdfConfig.subLineHeight;
        doc.line(10, pdfConfig.totalHeight, pdfConfig.docWidth - 10, pdfConfig.totalHeight);
    }
    //end line breaker
    getCustomerInfo(doc, pdfConfig, param);
    getBasicTable(doc, pdfConfig, param);
    getPaymentTable(doc, pdfConfig, param);
    getCurrentMonthTable(doc, pdfConfig, param);
    doc.save(param.fileName);

}

export default getInvoiceViewTemplate;




