/*
 * Copyright (C) 2018 - 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Col,
  DatePicker,
  Result,
  Row,
  Select,
  Card,
  Button,
  Space,
  Divider,
  Descriptions,
  Typography,
  Statistic
} from 'antd';
import {
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { withConfigContext } from '../../../../components/ConfigContext';
import { RoutesContext } from '../../index';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import { timeRanges } from '../../../../services/utils/commonUtils';
import moment from 'moment';
import DownloadOutlined from '@ant-design/icons/es/icons/DownloadOutlined';
import PaymentsTable from '../../components/PaymentsTable';
import AdjustmentsTable from '../../components/AdjustmentsTable';
import { getInvoiceViewTemplate } from '../../../../components/ReportTemplate/Templates/InvoiceViewTemplate/index';


const { Title, Text } = Typography;
let month = '';
const url = "https://mgt.sg.entgra.net/gdashboard/d-solo/c6a72c8e-4d28-4b49-b746-5e2d7a106d5f/consumption?orgId=1&from=1689763798870&to=1689785398870&panelId=1"
const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      breadcrumbName: t('home_menu_invoice'),
    },
    {
      breadcrumbName: t('home_menu_invoiceView'),
    },
  ];
};

class InvoiceView extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    const { t } = this.props;
    const currentDate = new Date();
    currentDate.setHours(8, 0, 0, 0);
    this.timeRangeOptions = timeRanges(t);

    month = moment().subtract(1, 'months').endOf('month')
    this.state = {
      month: month,
      isDashboardAccessible: false,
      isLoading: true,
      shiftType: 'all',
      from: currentDate.getTime(),
      range: this.timeRangeOptions[7].value,
    };
  }

  checkDashboardAccess = async (dashboardUrl) => {
    const accessible = true;
    this.setState({ isDashboardAccessible: accessible, isLoading: false });
  };

  componentDidMount() {
    this.context.setCurrentRoute('allInvoiceView');
    this.checkDashboardAccess(url);

  }

  onClickDownloadReport = () => {
    const obj = {
      fileName: "Invoice_View",
      orientationLandscape: false,
      company: {
        name: "Entgra Private Limited",
        address_no: "2nd Floor, No 106, Bernard's Business Park,",
        address_road: "Dutugemunu St,",
        postalCode: "10250",
        phone: " 077 301 7743",
        email: "contact@entgra.io",
        email_1: "",
        website: "https://entgra.io/",
      },
      customerInfo: {
        label: "Customer Information",
        name: "Name: Entgra Private Limited",
        accountNumber: "AccountNumber : 126156577",
        address: "Address : 2nd Floor, No 106, Bernard's Business Park, Dutugemunu St.",
      },
      reportBody: {
        label: "2023 - August",
        num: "",
        invDate: "Invoice Date : 31/07/2023",
        invGenDate: "",
        basicLabel: "Summary",
        basicHeaderBorder: true,
        basicTableBodyBorder: true,
        basicHeader: [
          { title: "Previous Due" },
          { title: "Payments" },
          { title: "Credits" },
          { title: "Debits" },
          { title: "This Month Charge" },
          { title: "Total Due" }],
        basicTable: [["14,222.50", "18,222.55", "0.00", "0.00", "12,776.41", "8,776.41"]],
        paymentLabel: "Payments",
        paymentHeaderBorder: false,
        paymentTableBodyBorder: false,
        paymentHeader: [{ title: "Date" }, { title: "Payment" }],
        paymentTable: [
          [moment().subtract(45, 'days').calendar(), "Rs. 8,250.00"],
          [moment().subtract(40, 'days').calendar(), "Rs. 5,000.00"],
          [moment().subtract(38, 'days').calendar(), "Rs. 4,972.00"],
          ["Total payment as at 31 / August / 2023", "Rs. 18,222.55"]],
        totalOutstanding: "Total outstanding as at 31 / August / 2023 : Rs. -4,000.00",
        currentMonthLabel: "2023 - August Details",
        currentMonthHeaderBorder: false,
        currentMonthTableBodyBorder: false,
        currentMonthHeader: [{ title: "Description" }, { title: "Amount" }],
        currentMonthTable: [
          ["No of Units Consumed", "231"],
          ["Charge for Electricity Consumed(Rs)", "Rs. 12,457.00"],
          ["Levy Addition", "Rs. 598.55"],
          ["Deduction", "Rs. 0.00"],
          ["Total after Adjustments", "Rs. 12776.41"],
          ["Total Amount Due(Rs.)", "Rs. 8776.41"]
        ]
      },
      footer: { text: "Entgra ATM Monitoring | © 2023 , All Rights Reserved." },
      pageEnable: true,
      pageLabel: "",
    };
    getInvoiceViewTemplate(obj);
  };

  onMonthChange = (dates, dateString) => {
    this.state.month = dates;
    const fromTime = Date.parse(dateString[0]);
    const toTime = Date.parse(dateString[1]);

  };

  render() {
    const config = this.props.context;
    const { Option } = Select;
    const { t } = this.props;
    const { shiftType, from, range, isDashboardAccessible, isLoading } = this.state;
    const dashboardUrl = `${this.config.grafanaConfig.output}&${range}`;
    //Todo: Back end need to be implemented for range to be used
    const timeRangeOptions = this.timeRangeOptions;
    const { Meta } = Card;

    const handleChange = (value) => {
      console.log(`selected ${value}`);
    };

    return (
      <div>
        <Authorized
          scope={['perm:devices:view']}
          yes={
            <div>
              <PageHeader className={styles.pageHeader} ghost={false}>
                <div className={styles.positioning}>
                  <Row justify="space-between" align="middle" gutter={8}>
                    <Col>
                      <BreadcrumbComponent breadcrumbList={routes(config.appName, t)} />
                    </Col>
                    <Col>
                      <Row align="middle" gutter={[8, 8]}>
                        <Col className={styles.col}>
                          <Space>
                            <DatePicker
                              picker={"month"}
                              defaultValue={this.state.month}
                              onChange={this.onMonthChange} />
                          </Space>
                        </Col>
                        <Col>
                          <Space wrap>
                            <Select
                              defaultValue="Meter 01"
                              style={{
                                width: 120,
                              }}
                              onChange={handleChange}
                              options={[
                                {
                                  value: 'Meter 01',
                                  label: 'Meter 01',
                                },
                                {
                                  value: 'Meter 02',
                                  label: 'Meter 02',
                                },
                                {
                                  value: 'Meter 03',
                                  label: 'Meter 03',
                                }
                              ]}
                            />
                          </Space>
                        </Col>
                        <Col>
                          <div></div>
                          <Button
                            style={{ marginRight: 10 }}
                            type="primary"
                            icon={<DownloadOutlined />}
                            onClick={this.onClickDownloadReport}
                          >
                            {t('label_downloadReport')}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </PageHeader>
              <Row>
                <Col xs={30} sm={30} md={16} lg={16} xl={16} key={'customerInfo'} className={styles.sidePadding}>
                  <Descriptions
                    column={{ sm: 3, md: 3 }}
                    layout="vertical"
                    size="small"
                    bordered="true"
                    title="Customer Information">
                    <Descriptions.Item
                      label={<Text strong>{t('Name')}</Text>}
                    >
                      {'Entgra (Pvt) Ltd'}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<Text strong>{t('Account Number')}</Text>}
                    >
                      {'126156577'}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<Text strong>{t('Address')}</Text>}
                    >
                      {'106, Bernard Boteju Business Park, Dutugemunu Street, Dehiwala'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col xs={18} sm={18} md={8} lg={8} xl={8} key={'invoice'} className={styles.sidePadding}>
                  <Descriptions
                    column={{ sm: 2, md: 2 }}
                    layout="vertical"
                    size="small"
                    bordered="true"
                    title="Invoice">
                    <Descriptions.Item
                      label={<Text strong>{t('Month')}</Text>}
                    >
                      {(`${this.state.month.format('YYYY')} - ${this.state.month.format('MMMM')}`)}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<Text strong>{t('Invoice Date')}</Text>}
                    >
                      {'31/07/2023'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
              <Divider />
              <Row justify="space-between">
                <Col xs={48} sm={48} md={24} lg={24} xl={24}>
                  <Card
                    bordered="true"
                    style={{
                      borderRadius: 10,
                      marginRight: 30,
                      marginTop: 10,
                      marginBottom: 10,
                      marginLeft: 30,
                      background: '#FFF6DC',
                      width: '95%',
                      textAlign: 'center',
                      borderWidth: 5,
                      borderColor: '#FFF6DC'
                    }}>
                    <Card.Grid bordered="true" style={{ width: '13%', textAlign: 'center' }}>
                      <Statistic
                        title="Previous Due"
                        value={14222.5}
                        precision={2}
                        valueStyle={{
                          color: '#7393B3',
                          fontSize: "14px"
                        }}
                      />
                    </Card.Grid>
                    <Card.Grid bordered="true" hoverable={false} style={{ width: '1%', textAlign: 'center' }}>
                      <MinusOutlined />
                    </Card.Grid>
                    <Card.Grid style={{ width: '13%', textAlign: 'center' }}>
                      <Statistic
                        title="Payments"
                        value={18222.55}
                        precision={2}
                        valueStyle={{
                          color: '#7393B3',
                          fontSize: "14px",
                        }}
                      />
                    </Card.Grid>
                    <Card.Grid hoverable={false} style={{ width: '1%', textAlign: 'center' }}>
                      <MinusOutlined />
                    </Card.Grid>
                    <Card.Grid style={{ width: '13%', textAlign: 'center' }}>
                      <Statistic
                        title="Credits"
                        value={0.00}
                        precision={2}
                        valueStyle={{
                          color: '#7393B3',
                          fontSize: "14px"
                        }}
                      />
                    </Card.Grid>
                    <Card.Grid hoverable={false} style={{ width: '1%', textAlign: 'center' }}>
                      <PlusOutlined />
                    </Card.Grid>
                    <Card.Grid style={{ width: '13%', textAlign: 'center' }}>
                      <Statistic
                        title="Debits"
                        value={0.00}
                        precision={2}
                        valueStyle={{
                          color: '#7393B3',
                          fontSize: "14px"
                        }}
                      />
                    </Card.Grid>
                    <Card.Grid
                      hoverable={false} style={{ width: '1%', textAlign: 'center' }}>
                      <PlusOutlined />
                    </Card.Grid>
                    <Card.Grid style={{ width: '13%', textAlign: 'center' }}>
                      <Statistic
                        title="This Month Charge"
                        value={12776.41}
                        precision={2}
                        valueStyle={{
                          color: '#cf1322',
                          fontSize: "14px"
                        }}
                      />
                    </Card.Grid>
                    <Card.Grid
                      justify-content='center'
                      hoverable={false} style={{ width: '1%', textAlign: 'center' }}>
                      <p>=</p>
                    </Card.Grid>
                    <Descriptions
                      column={{ sm: 1, md: 1 }}
                      layout="vertical"
                      size="small"
                      bordered="false"
                    >
                      <Descriptions.Item
                        label={<Text strong>{t('Total Due')}</Text>}
                      >
                        {'8776.41'}
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} key={'prevPayments'} className={styles.sidePadding}>
                  <Descriptions column={{ sm: 1, md: 1 }} layout="horizontal" size="small" bordered="true">
                    <Descriptions.Item
                      label={<Text strong>{t('Previous Due Amount')}</Text>}
                    >
                      {'Rs. 14,222.55'}
                    </Descriptions.Item>
                  </Descriptions>
                  <PaymentsTable />
                  <Descriptions column={{ sm: 1, md: 1 }} layout="horizontal" size="small" bordered="true">
                    <Descriptions.Item
                      label={<Text strong>{t(`Total payment as at ${this.state.month.format('DD/MMMM/YYYY')}`)}</Text>}
                    >
                      {'Rs. 18,222.55'}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<Text strong>{t(`Total outstanding as at ${this.state.month.format('DD/MMMM/YYYY')}`)}</Text>}
                    >
                      {'Rs. -4000.00'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} key={'currentCharge'} className={styles.sidePadding}>
                  <Descriptions column={{ sm: 1, md: 1 }} layout="horizontal" size="small" bordered="true">
                    <Descriptions.Item
                      label={<Text strong>{t('No of Units Consumed')}</Text>}
                    >
                      {'231'}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<Text strong>{t('Charge for Electricity Consumed(Rs)')}</Text>}
                    >
                      {'Rs. 12,457.00'}
                    </Descriptions.Item>
                  </Descriptions>
                  <AdjustmentsTable />
                  <Descriptions column={{ sm: 1, md: 1 }} layout="horizontal" size="small" bordered="true">
                    <Descriptions.Item
                      label={<Text strong>{t('Total after Adjustments')}</Text>}
                    >
                      {'Rs. 12776.41'}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<Text strong>{t('Total Amount Due(Rs.)')}</Text>}
                    >
                      {'Rs. 8776.41'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </div>
          }
          no={
            <Result
              status="403"
              title={t('noPerm_accessPageTitle')}
              subTitle={t('noPerm_contactSysAdmin')}
            />
          }
        />
      </div>
    );
  }
}

InvoiceView.contextType = RoutesContext;
export default withConfigContext(withTranslation()(InvoiceView));
