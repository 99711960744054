/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import axios from 'axios';
import { Button, Result, Table, Space } from 'antd';
// Load locale-specific relative date/time formatting rules.
import { withConfigContext } from '../../../../components/ConfigContext';
import BulkActionBar from './components/BulkActionBar';
import { Link } from 'react-router-dom';
import { handleApiError } from '../../../../services/utils/errorHandler';
import styles from './styles.module.css';
import EditDevice from './components/EditDevice';
import { withTranslation } from 'react-i18next';

class DeviceTable extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      data: [],
      pagination: {},
      loading: false,
      selectedRows: [],
      selectedRowKeys: [],
      advancedSearchPayload: {
        conditions: [],
      },
      loadSearchBar: false,
    };
  }

  componentDidMount() {
    this.fetch(false);
  }

  // Rerender component when parameters change
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.apiUrl !== this.props.apiUrl ||
      JSON.stringify(prevProps.filters) !== JSON.stringify(this.props.filters)
    ) {
      const pagination = { ...this.state.pagination };
      pagination.page = 1;
      pagination.current = 1;
      this.setState({ pagination });
      this.fetch(true, {}, this.props.filters);
    } else if (prevProps.advancedSearchData !== this.props.advancedSearchData) {
      this.fetchAdvancedSearchData(this.props.advancedSearchData);
    }
    if (this.props.refreshTable) {
      if (prevProps.refreshTable !== this.props.refreshTable) {
        this.fetch(true, {}, this.props.filters);
      }
    }
  }

  // fetch data from api
  fetch = (loadSearchBar, params = {}, filters = {}) => {
    const { t } = this.props;
    this.setState({ loading: true });
    // get current page
    const currentPage = params.hasOwnProperty('page') ? params.page : 1;
    filters = this.props.filters;
    if (
      filters.hasOwnProperty('status') &&
      filters.status.toUpperCase() === 'ALL'
    ) {
      delete filters.status;
    }

    if (
      filters.hasOwnProperty('type') &&
      filters.type.toUpperCase() === 'ALL'
    ) {
      delete filters.type;
    }

    if (
      filters.hasOwnProperty('ownership') &&
      filters.ownership.toUpperCase() === 'ALL'
    ) {
      delete filters.ownership;
    }

    if (this.props.username) {
      filters.user = this.props.username;
    } else if (this.props.groupId) {
      filters.groupId = this.props.groupId;
    }

    let limit = 10;
    if (params.results) {
      limit = params.results;
    }

    const extraParams = {
      offset: limit * (currentPage - 1), // calculate the offset
      limit: limit,
      requireDeviceInfo: true,
      ...filters,
    };

    let encodedExtraParams = Object.keys(extraParams)
      .map(key => key + '=' + extraParams[key])
      .join('&');

    if (!filters.hasOwnProperty('status')) {
      encodedExtraParams =
        encodedExtraParams +
        '&status=ACTIVE&status=INACTIVE&status=CREATED&status=UNREACHABLE';
    }
    // send request to the invoker
    axios
      .get(this.props.apiUrl + encodedExtraParams)
      .then(res => {
        if (res.status === 200) {
          const pagination = { ...this.state.pagination };
          this.setState({
            loading: false,
            data: res.data.data,
            pagination,
            loadSearchBar: loadSearchBar,
          });
          if (!loadSearchBar && res.data.data.count === 0) {
            this.props.searchBarCallback(false);
          } else {
            this.props.searchBarCallback(true);
          }
        }
      })
      .catch(error => {
        handleApiError(error, t('label_error_load_machine'), t);
        this.setState({ loading: false });
      });
  };

  // fetch data from api
  fetchAdvancedSearchData = (payload, params = {}, filters = {}) => {
    const { t } = this.props;
    this.setState({ loading: true });
    // get current page
    const currentPage = params.hasOwnProperty('page') ? params.page : 1;

    const extraParams = {
      offset: 10 * (currentPage - 1), // calculate the offset
      limit: 10,
    };

    const encodedExtraParams = Object.keys(extraParams)
      .map(key => key + '=' + extraParams[key])
      .join('&');

    // send request to the invoker
    axios
      .post(
        window.location.origin +
          this.config.serverConfig.invoker.uri +
          this.config.serverConfig.invoker.deviceMgt +
          '/devices/search-devices?' +
          encodedExtraParams,
        payload,
      )
      .then(res => {
        if (res.status === 200) {
          const pagination = { ...this.state.pagination };
          this.setState({
            loading: false,
            data: res.data.data,
            pagination,
            loadSearchBar: true,
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('label_error_load_machine'), t);
        this.setState({ loading: false });
      });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { showAdvancedSearch } = this.props;
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    if (!showAdvancedSearch) {
      this.fetch(true, {
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    } else {
      this.fetchAdvancedSearchData(this.props.advancedSearchPayload, {
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    }
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys,
      selectedRows: selectedRows,
    });
    if (this.props.deviceListCallback) {
      this.props.deviceListCallback(selectedRows);
    }
  };

  reloadDeviceTable = () => {
    this.fetch(true);
  };

  onClickAssignDevicesToGroup = () => {
    this.props.assignDevicesCallback();
  };

  render() {
    const { t } = this.props;
    const {
      data,
      pagination,
      loading,
      selectedRows,
      selectedRowKeys,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      selectedRows,
      onChange: this.onSelectChange,
    };

    const columns = [
      {
        title: t('label_machine'),
        dataIndex: 'name',
        width: 150,
        // eslint-disable-next-line react/display-name
        render: (data, row) => {
          const { type, deviceIdentifier, name } = row;
          return (
            <Link
              to={`/${this.config.appName}/devices/${type}/${deviceIdentifier}/statistics`}
              target="_blank"
            >
              <p>{name}</p>
            </Link>
          );
        },
      },
      {
        title: t('label_status'),
        dataIndex: 'enrolmentInfo',
        key: 'status',
        align: 'center',
        width: 175,
        // eslint-disable-next-line react/display-name
        render: enrolmentInfo => {
          const status = enrolmentInfo.status.toLowerCase();
          let color;
          let backGroundColor;
          switch (status) {
            case 'active':
              backGroundColor = '#c4e0c6';
              color = '#14403e';
              break;
            case 'created':
              backGroundColor = '#8fc4ff';
              color = '#294083';
              break;
            case 'removed':
              backGroundColor = '#ff9999';
              color = '#821212';
              break;
            case 'inactive':
              backGroundColor = '#f9dd9e';
              color = '#91681a';
              break;
            case 'blocked':
              backGroundColor = '#333333';
              color = '#ffffff';
              break;
            case 'unreachable':
              backGroundColor = '#cccccc';
              color = '#333333';
              break;
          }
          return (
            <span
              style={{
                display: 'inline-block',
                width: 150,
                textAlign: 'center',
                color: color,
                background: backGroundColor,
                padding: '3px 10px 3px 10px',
                borderRadius: 7,
              }}
            >
              {t(`label_${status}`)}
            </span>
          );
        },
        // todo add filtering options
      },
      {
        title: t('label_description'),
        dataIndex: 'description',
        key: 'description',
        // eslint-disable-next-line react/display-name
        render: (data, row) => {
          const { description } = row;
          return (typeof description !== 'undefined' ? description : '');
        },
      },
      {
        title: '',
        dataIndex: 'deviceIdentifier',
        key: 'actions',
        align: 'right',
        width: 25,
        // eslint-disable-next-line react/display-name
        //Do not remove unused data variable
        render: (data, row) => {
          return (
            <Space>
              <EditDevice
                view={'deviceTable'}
                device={row}
                callback={this.reloadDeviceTable}
                disabled={false}
              />
            </Space>
          );
        },
      },
    ];

    return (
      <div className={styles.tableContainer}>
        {data.count !== 0 && (
          <div>
            <Table
              className={styles.customTable}
              columns={columns}
              rowKey={e => e.id}
              dataSource={data.devices}
              pagination={{
                ...pagination,
                size: 'small',
                total: data.count,
                disabled: loading,
                showTotal: (total, range) =>
                  t('table_pagination', {
                    range1: range[0],
                    range2: range[1],
                    total: total,
                    data: t('home_menu_machines'),
                  }),
              }}
              loading={loading}
              onChange={this.handleTableChange}
              rowSelection={rowSelection}
            />
          </div>
        )}
        {data.count === 0 && (
          // todo Change Result icon #issue592
          <Result
            status="404"
            title={this.props.emptyDevicePage.title}
            subTitle={this.props.emptyDevicePage.subTitle}
            extra={
              this.props.groupId ? (
                <Button
                  type="primary"
                  onClick={this.onClickAssignDevicesToGroup}
                >
                  {this.props.emptyDevicePage.buttonName}
                </Button>
              ) : (
                <Link
                  to={`/${this.config.appName}${this.props.emptyDevicePage.url}`}
                >
                  <Button type="primary">
                    {this.props.emptyDevicePage.buttonName}
                  </Button>
                </Link>
              )
            }
          />
        )}
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(DeviceTable));
