/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Button,
  Form,
  Row,
  Tooltip,
  Upload,
  Typography,
  Divider,
  Alert,
} from 'antd';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import CustomAlerts from '../CustomAlerts';
import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
const { Paragraph, Title, Text } = Typography;
import styles from './styles.module.css';

class CustomUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { fileList: [], uploadContent: '' };
  }

  componentDidMount() {
    const item = { ...this.props.itemData };
    this.setState({ uploadContent: item.value });
  }

  beforeUpload = file => {
    const item = { ...this.props.itemData };
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = e => {
      this.setState({ uploadContent: e.target.result });
      item.value = e.target.result;
      this.props.callback(item, this.state.fileList[0].name);
    };
    // Prevent upload
    return false;
  };

  onUploadChange = info => {
    let fileList = [...info.fileList];

    // Limit the number of uploaded files
    fileList = fileList.slice(-1);
    this.setState({ fileList });
  };

  onChangeUploadContent = uploadContent => {
    const item = { ...this.props.itemData };
    item.value = uploadContent;
    this.props.callback(item, this.state.fileList[0].name);
  };

  render() {
    const { itemData, defaultValue, t } = this.props;
    const { fileList, uploadContent } = this.state;
    const { value } = itemData;
    return (
      <div>
        {itemData.divider && (
          <Divider plain>
            <Text type="secondary">{t(itemData.divider)}</Text>
          </Divider>
        )}
        {itemData.subTitle && <Title level={4}> {t(itemData.subTitle)} </Title>}
        <CustomAlerts notifications={itemData.notifications} />
        <div
          style={{
            minHeight: 78,
          }}
        >
          <Row>
            <span
              style={{
                display: itemData.label && itemData.tooltip ? 'inline' : 'none',
              }}
            >
              {t(`${itemData.label}`)}&nbsp;
              {itemData.tooltip && (
                <Tooltip
                  title={
                    <div>
                      <div>{t(`${itemData.tooltip}`)}</div>
                      <div className={styles.docLink}>
                        {itemData.docLink && (
                          <a
                            onClick={() => {
                              window.open(itemData.docLink, '_blank');
                            }}
                          >
                            {' '}
                            {t('label_SeeMoreDetails')}
                          </a>
                        )}
                      </div>
                    </div>
                  }
                  placement="right"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              )}
            </span>
          </Row>
          <Row>
            <Form.Item name={itemData.key}>
              <Upload
                accept={itemData.upload.fileTypes}
                disabled={this.props.disabled}
                key={itemData.key}
                defaultValue={defaultValue ? defaultValue : value}
                onChange={this.onUploadChange}
                beforeUpload={this.beforeUpload}
                fileList={fileList}
              >
                <Button
                  className={styles.uploadButton}
                  disabled={this.props.disabled}
                >
                  <UploadOutlined /> {t('label_ChooseFile')}
                </Button>
              </Upload>
            </Form.Item>
            <div
              style={{
                display:
                  itemData.upload.showContent && uploadContent !== ''
                    ? 'inline'
                    : 'none',
              }}
            >
              <Paragraph
                disabled={this.props.disabled}
                editable={{
                  editing: !this.props.disabled,
                  onChange: this.onChangeUploadContent,
                }}
              >
                {uploadContent}
              </Paragraph>
            </div>
          </Row>
          <Alert
            style={{
              marginTop: 5,
              display:
                uploadContent === '' &&
                itemData.error &&
                itemData.error.required
                  ? 'flex'
                  : 'none',
            }}
            message={
              value === '' &&
              itemData.error &&
              itemData.error.required &&
              itemData.error.required
            }
            type="error"
          />
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(CustomUpload));
