/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import {
  Form,
  Typography,
  Row,
  Col,
  Input,
  Button,
  Alert,
  notification,
  Card,
} from 'antd';
import styles from './styles.module.css';
import axios from 'axios';
import { withConfigContext } from '../../components/ConfigContext';
import CookiePolicy from './components/CookiePolicy';
import PrivacyPolicy from './components/PrivacyPolicy';
import ATMlogoColored from '../../../public/images/ATMlogoColored.svg';

const { Title, Paragraph, Text } = Typography;

class Login extends React.Component {
  render() {
    return (
      <div>
        <div className={styles.background} />
        <div className={styles.content}>
          <Row>
            <Col xs={3} sm={3} md={9} />
            <Col xs={18} sm={18} md={6}>
              <Card style={{ borderRadius: '10px', marginTop: 66 }}>
                <div style={{ textAlign: 'center' }}>
                  <img
                    style={{
                      height: 60,
                      marginBottom: 20
                    }}
                    src={ATMlogoColored}
                    alt={'Entgra Facility Monitoring'}
                  />
                  <Title level={4}>Sign In</Title>
                </div>
                <div>
                  <WrappedNormalLoginForm />
                </div>
              </Card>
              <Alert
                style={{ borderRadius: '10px', marginTop: 20 }}
                description={
                  <Typography>
                    <Paragraph>
                      Entgra Facility Monitoring portal uses cookies. By logging on to the Facility Monitoring
                      portal, you are agreeing on the usage of cookies. For more
                      information, refer to our{' '}
                      <a key={'cookie-policy-drawer'} href="https://entgra.io/privacy-policy/" target="_blank">cookie policy</a> and{' '}
                      <a key={'privacy-policy-drawer'} href="https://entgra.io/privacy-policy/" target="_blank">privacy policy</a>.
                    </Paragraph>
                  </Typography>
                }
                type="info"
                showIcon
              />
            </Col>
            <Col xs={3} sm={3} md={9} />
          </Row>
        </div>
      </div>
    );
  }
}

class NormalLoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inValid: false,
      loading: false,
      isPrivacyPolicyVisible: false,
      isCookiePolicyVisible: false,
    };
  }

  onFinish = values => {
    const thisForm = this;
    const config = this.props.context;
    thisForm.setState({
      inValid: false,
      loading: true,
    });

    const parameters = {
      username: values.username,
      password: values.password,
    };

    const request = Object.keys(parameters)
      .map(key => key + '=' + parameters[key])
      .join('&');

    axios
      .post(window.location.origin + config.serverConfig.loginUri, request)
      .then(res => {
        if (res.status === 200) {
          let redirectUrl = window.location.origin + `/${config.appName}`;
          const searchParams = new URLSearchParams(window.location.search);
          if (searchParams.has('redirect')) {
            redirectUrl = searchParams.get('redirect');
          }
          window.location = redirectUrl;
        }
      })
      .catch(function(error) {
        if (error.hasOwnProperty('response') && error.response.status === 401) {
          thisForm.setState({
            loading: false,
            inValid: true,
          });
        } else {
          notification.error({
            message: 'There was a problem',
            duration: 10,
            description: '',
          });
          thisForm.setState({
            loading: false,
            inValid: false,
          });
        }
      });
  };

  showPrivacyPolicy = () => {
    this.setState({
      isPrivacyPolicyVisible: true,
    });
  };

  hidePrivacyPolicy = () => {
    this.setState({
      isPrivacyPolicyVisible: false,
    });
  };

  showCookiePolicy = () => {
    this.setState({
      isCookiePolicyVisible: true,
    });
  };

  hideCookiePolicy = () => {
    this.setState({
      isCookiePolicyVisible: false,
    });
  };

  render() {
    let errorMsg = '';
    if (this.state.inValid) {
      errorMsg = <Text type="danger">Invalid Login Details</Text>;
    }
    let loading = '';
    if (this.state.loading) {
      loading = <Text type="secondary">Loading..</Text>;
    }
    return (
      <div>
        <Form
          name="normal_login"
          onFinish={this.onFinish}
          className="login-form"
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input
              name="username"
              autoFocus
              style={{ height: 32 }}
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input
              name="password"
              style={{ height: 32 }}
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          {loading}
          {errorMsg}
          <Form.Item>
            <Button
              loading={this.state.loading}
              block
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
        <CookiePolicy
          visible={this.state.isCookiePolicyVisible}
          onClose={this.hideCookiePolicy}
          showPrivacyPolicy={this.showPrivacyPolicy}
        />
        <PrivacyPolicy
          visible={this.state.isPrivacyPolicyVisible}
          onClose={this.hidePrivacyPolicy}
        />
      </div>
    );
  }
}

const WrappedNormalLoginForm = withConfigContext(NormalLoginForm);

export default withConfigContext(Login);
