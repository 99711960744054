import {BRANCHES, SUB_TYPE_ID} from '../../constant/TableData';
import { subHours, subDays, subMinutes } from 'date-fns';

export const getBranchNameByCode = (branchCode) => {
  if (branchCode === 'ALL') {
    return branchCode;
  } else if (branchCode) {
    return BRANCHES[branchCode]?.name ? BRANCHES[branchCode]?.name : '-';
  } else {
    return '-';
  }
};

export const meterReadings = {
  // '0.0.0_0': { text: 'Meter Serial', order: 1 },
  // customerRef: { text: 'Account No', order: 2 },
  // branch: { text: 'Branch', order: 3 },
  // depot: { text: 'Depot', order: 4 },
  // substationId: { text: 'SubStation', order: 5 },
  // '0.9.2_0': { text: 'Date', order: 6 },
  // '0.9.1_0': { text: 'Time', order: 7 },
  '1.8.0_0': { text: 'Total Import (kWh)', order: 8 },
  '2.8.0_0': { text: 'Total Export (kWh)', order: 9 },
  '1.8.0*01_0': { text: 'Total Import - PV1 (kWh)', order: 10 },
  '2.8.0*01_0': { text: 'Total Export - PV1 (kWh)', order: 11 },
  '1.8.1_0': { text: 'TR1 Total Import (kWh)', order: 12 },
  '1.8.2_0': { text: 'TR2 Total Import (kWh)', order: 13 },
  '1.8.3_0': { text: 'TR3 Total Import (kWh)', order: 14 },
  '2.8.1_0': { text: 'TR1 Total Export (kWh)', order: 15 },
  '2.8.2_0': { text: 'TR2 Total Export (kWh)', order: 16 },
  '2.8.3_0': { text: 'TR3 Total Export (kWh)', order: 17 },
  '1.8.1*01_0': { text: 'TR1 Total Import - PV1 (kWh)', order: 18 },
  '1.8.2*01_0': { text: 'TR2 Total Import - PV1 (kWh)', order: 19 },
  '1.8.3*01_0': { text: 'TR3 Total Import - PV1 (kWh)', order: 20 },
  '2.8.1*01_0': { text: 'TR1 Total Export - PV1 (kWh)', order: 21 },
  '2.8.2*01_0': { text: 'TR2 Total Export - PV1 (kWh)', order: 22 },
  '2.8.3*01_0': { text: 'TR3 Total Export - PV1 (kWh)', order: 23 },
  '9.6.0_0': { text: 'Max Demand Import (kVA)', order: 24 },
  '10.6.0_0': { text: 'Max Demand Export (kVA)', order: 25 },
  '9.6.0*01_0': { text: 'Max Demand Import - PV1 (kVA)', order: 26 },
  '10.6.0*01_0': { text: 'Max Demand Export - PV1 (kVA)', order: 27 },
  '31.7.0_0': { text: 'Phase A Current (A)', order: 28 },
  '32.7.0_0': { text: 'Phase A Voltage (V)', order: 29 },
  '51.7.0_0': { text: 'Phase B Current (A)', order: 30 },
  '52.7.0_0': { text: 'Phase B Voltage (V)', order: 31 },
  '71.7.0_0': { text: 'Phase C Current (A)', order: 32 },
  '72.7.0_0': { text: 'Phase C Voltage (V)', order: 33 },
  '14.7.0_0': { text: 'Frequency (Hz)', order: 34 },
  CSRQ_RL: { text: 'Remote Meter Relay Status', order: 35 },
  subTypeId: { text: 'Sub Type', order: 36 },
  nodeType: { text: 'Node Type', order: 37 },
  category: { text: 'Category', order: 38 },
  hasEbilling: { text: 'E-Billing', order: 39 },
  hasExport: { text: 'Has Export', order: 40 },
  '0.4.2_0': { text: 'CT Ratio Primary', order: 41 },
  '0.4.5_0': { text: 'CT Ratio Secondary', order: 42 },
  '0.4.3_0': { text: 'PT Ratio Primary', order: 43 },
  '0.4.6_0': { text: 'PT Ratio Secondary', order: 44 },
  'CO_0.9.0_0': { text: 'Recording hour start/Event start timestamp', order: 45 },
  'CO_96.50.21': { text: 'Event end timestamp', order: 46 },
  'CO_96.50.22': { text: 'Timestamp of Min/Max voltage event happen', order: 47 },
  'CO_128.0.11': { text: 'Faulty Phase/s', order: 48 },
  'CO_128.0.12': { text: 'Disturbance Type', order: 49 },
  'CO_128.40.0': { text: 'No. of Interruptions occurred', order: 50 },
  'CO_128.32.0': { text: 'No. of Sags occurred', order: 51 },
  'CO_128.36.0': { text: 'No. of Swells occurred', order: 52 },
  'CO_128.0.10': { text: 'Duration of disturbance voltage (min)', order: 53 },
  'CO_12.26.0': { text: 'Maximum voltage occurred (V)', order: 54 },
  'CO_12.23.0': { text: 'Minimum voltage occurred (V)', order: 55 },
  'CO_32.128.0': { text: 'V(A) when min / max occurred (V)', order: 56 },
  'CO_52.128.0': { text: 'V(B) when min / max occurred (V)', order: 57 },
  'CO_72.128.0': { text: 'V(C) when min / max occurred (V)', order: 58 },
  'CO_32.7.0': { text: 'Phase A Inst. Voltage (V)', order: 59 },
  'CO_52.7.0': { text: 'Phase B Inst. Voltage (V)', order: 60 },
  'CO_72.7.0': { text: 'Phase C Inst. Voltage (V)', order: 61 },
  'LP_1.5.0': { text: 'Avg. Import kW (kW)', order: 62 },
  'LP_1.7.0': { text: 'Inst. Import kW (kW)', order: 63 },
  'LP_1.8.0': { text: 'Import kWh (kWh)', order: 64 },
  'LP_2.5.0': { text: 'Avg. Export kW (kW)', order: 65 },
  'LP_2.7.0': { text: 'Inst. Export kW (kW)', order: 66 },
  'LP_2.8.0': { text: 'Export kWh (kWh)', order: 67 },
  'LP_3.5.0': { text: 'Import kvar (kvar)', order: 68 },
  'LP_3.8.0': { text: 'Import kvarh (kvarh)', order: 69 },
  'LP_4.8.0': { text: 'Export kvarh (kvarh)', order: 70 },
  'LP_9.5.0': { text: 'Avg. Import kVA (kVA)', order: 71 },
  'LP_9.7.0': { text: 'Inst. Import kVA (kVA)', order: 72 },
  'LP_10.5.0': { text: 'Avg. Export kVA (kVA)', order: 73 },
  'LP_10.7.0': { text: 'Inst. Export kVA (kVA)', order: 74 },
  'LP_11.25.0': { text: 'Avg. Current (V)', order: 75 },
  'LP_31.7.0': { text: 'Phase A Inst. Current (A)', order: 76 },
  'LP_51.7.0': { text: 'Phase B Inst. Current (A)', order: 77 },
  'LP_71.7.0': { text: 'Phase C Inst. Current (A)', order: 78 },
  'LP_12.25.0': { text: 'Avg. Voltage (V)', order: 79 },
  'LP_32.7.0': { text: 'Phase A Inst. Voltage (V)', order: 80 },
  'LP_52.7.0': { text: 'Phase B Inst. Voltage (V)', order: 81 },
  'LP_72.7.0': { text: 'Phase C Inst. Voltage (V)', order: 82 },
  'LP_13.5.0': { text: 'Phase ABC Power factor', order: 83 },
  'LP_13.7.0': { text: 'Inst. Power factor', order: 84 },
  'LP_33.7.0': { text: 'Phase A Power factor', order: 85 },
  'LP_43.7.0': { text: 'Phase B Power factor', order: 86 },
  'LP_63.7.0': { text: 'Phase C Power factor', order: 87 },
  '96.6.0_0': { text: 'Battery use time counter (Minutes)', order: 88 },
  '96.6.3_0': { text: 'Int. Battery Voltage (V)', order: 89 },
  '96.6.3_1': { text: 'Ext. Battery Voltage (V)', order: 90 },
  '96.10.5_0': { text: 'Fraud status register', order: 91 },
  '97.97.0_0': { text: 'Error object register', order: 92 },
  '96.10.8_0': { text: 'Power status register', order: 93 },
  '96.2.4_0': { text: 'Security switches register', order: 94 },
  '97.98.0_0': { text: 'Alarm object register', order: 95 },
};

export const getSubTypeName = (key) => {
  return SUB_TYPE_ID[key] || '';
};

export const getSubtypeId = (name) => {
  const SUBTYPES = {
    "Anteleco - DLMS SinglePhase": "1",
    "MicroStar - DLMS Bulk": "2",
    "Anteleco -DLMS 3Phase": "3",
    "Anteleco - IEC SinglePhase": "4",
    "MicroStar - IEC Bulk": "5",
    "Anteleco - IEC 3Phase": "6",
    "Anteleco - NMD": "7",
    "Iskra - IEC Bulk": "8"
  };

  const subtypeId = Object.entries(SUBTYPES)
      .find(([key, value]) => value === name)?.[0] || '';

  return subtypeId;
};

// to get the name for a given branch key
export const getBranchName = (key) => {
  return BRANCHES[key] ? BRANCHES[key].name : '';
};

// to get the name for a given depot key
export const getDepotName = (branchKey, depotKey) => {
  if (BRANCHES[branchKey] && BRANCHES[branchKey].depots) {
    return BRANCHES[branchKey].depots[depotKey] || '';
  }
  return '';
};


export const sortMeterReadings = (res) => {
  let sortedReadings = [];
  Object.keys(res).forEach((key) => {
    if (meterReadings.hasOwnProperty(key)) {
      sortedReadings.push({
        label: meterReadings[key].text,
        value: res[key],
        order: meterReadings[key].order,
      });
    }
  });
  return [...sortedReadings].sort((a, b) => a.order - b.order);
};

export const getUnixTime = (date) => {
  if (isNaN(date)) {
    return date;
  } else {
    return parseInt(new Date(date).getTime().toFixed(0));
  }
};

export const removeEmpty = (obj = {}) => {
  let newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop]) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export function serialize(obj) {
  let str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
}

export const makeOptionArray = (array) => {
  let objectArray = [];
  if (array && array.length > 0) {
    for (let i = 0; i < array.length; i++) {
      objectArray.push({ label: array[i], value: array[i] });
    }
  }
  return objectArray;
};

export const makeClearableOptionArray = (array) => {
  let objectArray = [];
  if (array && array.length === 1) {
    objectArray.push({ label: array[0], value: array[0], isFixed: true });
  } else {
    objectArray = makeOptionArray(array);
  }
  return objectArray;
};

export const convertObjectArray = (array, label, value, all) => {
  let objectArray = [];
  for (let i = 0; i < array.length; i++) {
    objectArray.push({ label: array[i][label], value: array[i][value] });
  }
  if (all) {
    objectArray.push({ label: all, value: '' });
  }
  return objectArray;
};

// export const makeBranchOptionArray = (userBranchesArray) => {
//   let objectArray = [];
//   if (userBranchesArray && userBranchesArray.length > 0) {
//     console.log(userBranchesArray);
//     for (let i = 0; i < userBranchesArray.length; i++) {
//       if (userBranchesArray[i] === 'ALL' || userBranchesArray[i] === 'TOTAL') {
//         objectArray.push({ label: userBranchesArray[i], value: userBranchesArray[i] });
//       } else {
//         objectArray.push({
//           label: BRANCHES[userBranchesArray[i]]?.name,
//           value: userBranchesArray[i],
//         });
//       }
//     }
//     console.log("!!!!!!!!!!!!");
//     console.log(objectArray);
//
//   }
//   return objectArray;
// };
export const makeBranchOptionArray = (userBranchesArray) => {
  let optionArray = [];
  if (userBranchesArray && userBranchesArray.length > 0) {
    for (let i = 0; i < userBranchesArray.length; i++) {
      if (userBranchesArray[i] === 'ALL' || userBranchesArray[i] === 'TOTAL') {
        optionArray.push(userBranchesArray[i]);
      } else {
        optionArray.push(BRANCHES[userBranchesArray[i]]?.name);
      }
    }
  }
  console.log(optionArray);
  return optionArray;
};


export const convertObjectArrayWith2Labels = (array, label1, label2, value, all) => {
  let objectArray = [];
  for (let i = 0; i < array.length; i++) {
    if (label2 === null) {
      objectArray.push({
        label: array[i][label1],
        value: array[i][value],
      });
    } else {
      objectArray.push({
        label: array[i][label1] + ' - ' + array[i][label2],
        value: array[i][value],
      });
    }
  }
  if (all) {
    objectArray.push({ label: all, value: '' });
  }
  return objectArray;
};

export const getDefaultStartDate = (type) => type === "Alerts" ? subHours(new Date(), 12) : subMinutes(new Date(), 5);

export const timeRanges = [
  { label: '5 minutes', start: subMinutes(new Date(), 5), end: new Date() },
  { label: '30 minutes', start: subMinutes(new Date(), 30), end: new Date() },
  { label: '1 hour', start: subHours(new Date(), 1), end: new Date() },
  { label: '6 hours', start: subHours(new Date(), 6), end: new Date() },
  { label: '12 hours', start: subHours(new Date(), 12), end: new Date() },
  { label: '24 hours', start: subHours(new Date(), 24), end: new Date() },
  { label: '2 days', start: subDays(new Date(), 2), end: new Date() },
  { label: '7 days', start: subDays(new Date(), 7), end: new Date() },
  { label: '30 days', start: subDays(new Date(), 30), end: new Date() },
  { label: '90 days', start: subDays(new Date(), 90), end: new Date() },
];

export const fromMapping = {
  'now-5m': '5 minutes',
  'now-30m': '30 minutes',
  'now-1h': '1 hour',
  'now-6h': '6 hours',
  'now-12h': '12 hours',
  'now-24h': '24 hours',
  'now-2d': '2 days',
  'now-7d': '7 days',
  'now-30d': '30 days',
  'now-90d': '90 days',
};

export const makeTimeRange = (label, setCurrentDateRange, setStartDate, setEndDate, getRange, startDate, endDate) => {
  if (label === 'Custom') {
    setCurrentDateRange(label);
    getRange({ from: getUnixTime(startDate), to: getUnixTime(endDate) });
  } else {
    const relativeStart = Object.keys(fromMapping).find(key => fromMapping[key] === label) || 'now-5m';
    setStartDate(relativeStart);
    setEndDate('now');
    setCurrentDateRange(label.startsWith('Last ') ? label : 'Last ' + label);
    getRange({ from: relativeStart, to: 'now' });
  }
};

export const determineRefreshParam = (from, to, refreshRate) => {
  // Check if 'from' and 'to' are numeric values or not recent range
  const isNumericFrom = !isNaN(from) && from !== '';
  const isNumericTo = !isNaN(to) && to !== '';
  const isNotRecentRange = from !== 'now-5m' && from !== 'now-30m' && to !== 'now-5m' && to !== 'now-30m';

  // Use '9999d' for refresh if not a recent range or numeric values
  return (isNumericFrom && isNumericTo || isNotRecentRange) ? '&refresh=9999d' : `&refresh=${refreshRate}`;
};

export const getUnixTimestampFromRelativeTime = (relativeTime) => {
  const now = new Date();
  if (relativeTime === 'now') {
    return getUnixTime(now);
  }
  if (relativeTime.startsWith('now')) {
    const timeValue = relativeTime.split('-')[1]; // Extract the time value after "now-"

    if (timeValue.endsWith('m')) {
      // Handle minutes (e.g., "now-5m")
      const minutes = parseInt(timeValue.replace('m', ''), 10);
      return getUnixTime(subMinutes(now, minutes));
    } else if (timeValue.endsWith('h')) {
      // Handle hours (e.g., "now-1h")
      const hours = parseInt(timeValue.replace('h', ''), 10);
      return getUnixTime(subHours(now, hours));
    }
  }
  return getUnixTime(now);
};
