/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useContext } from 'react';
import {
    Alert,
    Button,
    Col,
    Collapse,
    Empty,
    notification, Result, Row, Space,
    Spin,
    Table,
    Tag,
    Tooltip, Typography,
} from 'antd';
import axios from 'axios';
import {handleApiError} from '../../../../services/utils/errorHandler';
import {RoutesContext} from '../../index';
import {withConfigContext} from '../../../../components/ConfigContext';
import moment from 'moment';
import 'moment-timezone';
import ProfileReadingTable from './ProfileReadingTable';
import styles from './styles.module.css';
import {
    ControlOutlined,
    EyeOutlined, OneToOneOutlined,
    ReloadOutlined,
    SearchOutlined, SettingOutlined,
} from '@ant-design/icons';
import Filter from '../../components/Filter';
import {withTranslation} from 'react-i18next';
import {Link} from "react-router-dom";
import BreadcrumbComponent from "../../../../components/BreadCrumb";
import {
    getBranchName,
    getDepotName,
    getSubtypeId,
    getSubTypeName,
    meterReadings
} from '../../../../services/utils/functions';
import {SUBTYPE_MAPPING} from "../../../../constant/TableData";
import {CSVLink} from "react-csv";
import HtmlComments from "../../../../components/ReactHtmlComments/HtmlComments";
import Authorized from "../../../../components/Authorized";

const {Title, Paragraph} = Typography;
const {Panel} = Collapse;

const routes = (appName, t) => {
    return [
        {
            path: `/${appName}`,
            breadcrumbName: t('home_menu_home'),
        },
        {
            breadcrumbName: t('home_menu_profileHistoricalReading'),
        },
    ];
};

class ProfileHistoricalReading extends React.Component {
    filterValues = '';

    constructor(props) {
        super(props);
        this.config = this.props.context;
        this.state = {
            devices: [],
            count: 0,
            pagination: {},
            loading: false,
            selectedRows: [],
            searchFields: [],
            exportDataList: [],
            isModalVisible: false,
        };
        const {t} = this.props;
        this.columns = [
            // {
            //     title: t('label_meter_name'),
            //     dataIndex: 'name',
            //     key: 'name',
            // },
            {
                title: t('label_serial'),
                dataIndex: 'serial',
                key: 'serial',
                render: (serial) => (
                    <Link to={`/facility-monitoring/device/view?serial=${serial}`} target="_blank">{serial}</Link>
                ),
            },
            {
                title: t('label_facility'),
                dataIndex: 'branch',
                key: 'branch',
                render: (branch) => (getBranchName(branch))
            },
            // {
            //     title: t('label_accountNo'),
            //     dataIndex: 'customerRef',
            //     key: 'customerRef',
            // },
            {
                title: t('label_loadProfileTypes'),
                dataIndex: 'obis',
                key: 'obis',
            },

            {
                title: t('label_date'),
                dataIndex: 'date',
                key: 'date',
            },
            {
                title: t('label_time'),
                dataIndex: 'time',
                key: 'time',
            },
        ];

        // bind the exportData function to the current instance
        this.exportData = this.exportData.bind(this);

        // Create the ref
        this.csvLinkEl = React.createRef();
    }

    componentDidMount() {
        this.context.setCurrentRoute('allProfileHistoricalReading');
        const {t} = this.props;
        // initial loading state to true
        this.setState({loading: true});

        // Set default startTimestamp as 12 AM today
        const startTimestamp = moment().startOf('day').valueOf();

        // Set default endTimestamp as the current timestamp
        const endTimestamp = moment().valueOf();

        const fields = [
            {
                name: 'subTypeId',
                label: t('label_subTypeId'),
                placeholder: t('label_subTypeId'),
                type: 'multipleSelect',
                values: Object.keys(this.config.subTypeId).map(
                    key => this.config.subTypeId[key],
                ),
            },
            {
                name: 'serial',
                label: t('label_serials'),
                placeholder: t('label_serials'),
                type: 'creatableSelect',
                mode: 'tags',
                values: []
            },
            {
                name: 'operationId',
                label: t('label_operationId'),
                placeholder: t('label_operationId'),
                type: 'input',
            },
            {
                name: 'createdDay',
                label: t('label_startDate'),
                placeholder: t('label_startDate'),
                type: 'calender',
            },
            {
                name: 'updatedDay',
                label: t('label_endDate'),
                placeholder: t('label_endDate'),
                type: 'calender',
            },
        ];

        this.setState({
            searchFields: fields,
        });

        // fetch data based on query parameters if has
        this.fetchOperationFilters(
            {},
            {
                createdDay: moment(startTimestamp),
                updatedDay: moment(endTimestamp),
            }
        );
    }

    calenderDates = (day, propertyName) => {
        let readingFilters = '';

        if (propertyName === 'createdDay') {
            // Set default startTimestamp as 12 AM today if day is not provided
            const selectedFromDate = day || moment().startOf('day');
            readingFilters = readingFilters + '&startTimestamp=' + selectedFromDate.valueOf();
            return readingFilters;
        }

        if (propertyName === 'updatedDay') {
            // Set default endTimestamp as the current timestamp if day is not provided
            const selectedToDate = day || moment();
            readingFilters = readingFilters + '&endTimestamp=' + selectedToDate.valueOf();
            return readingFilters;
        }
    };


    fetchOperationFilters = (params, values) => {
        let readingFilters = '';

        if (values.hasOwnProperty('subTypeId')) {
            for (let i = 0; i < values.subTypeId.length; i++) {
                const subtypeKey = SUBTYPE_MAPPING[values.subTypeId[i]];
                readingFilters = readingFilters + '&subTypeId=' + subtypeKey;
            }
        }
        if (values.hasOwnProperty('serial')) {
            for (let i = 0; i < values.serial.length; i++) {
                readingFilters =
                    readingFilters + '&serial=' + values.serial[i];
            }
        }
        if (values.hasOwnProperty('operationId') && values.operationId.trim() !== '') { //with this values.operationId.trim() !== '' avoided null value set to operationId
            readingFilters = readingFilters + '&operationId=' + values.operationId.trim();
        }

        if (!values.hasOwnProperty('createdDay')) {
            // Set default startTimestamp as 12 AM today
            const startTimestamp = moment().startOf('day').valueOf();
            readingFilters += `&startTimestamp=${startTimestamp}`;
        }

        if (!values.hasOwnProperty('updatedDay')) {
            // Set default endTimestamp as the current timestamp
            const endTimestamp = moment().valueOf();
            readingFilters += `&endTimestamp=${endTimestamp}`;
        }
        for (let property in values) {
            if (property === 'createdDay' && values[property]) {
                let selectedFromDate = moment(values.createdDay);
                readingFilters +=
                    this.calenderDates(selectedFromDate, property);
            }
            if (property === 'updatedDay' && values[property]) {
                let selectedToDate = moment(values.updatedDay);
                readingFilters +=
                    this.calenderDates(selectedToDate, property);
            }
        }
        this.filterValues = readingFilters;
        this.setState({pagination: {current: 1}});
        this.getMeters(
            {
                // page: 1,
            },
            readingFilters,
        );

    };

    exportData() {
        this.setState({exportData: true});
        this.getData();
    }

    // to retrieve all records from the axios call without limiting the number of records, can set the limit parameter to a high value
    getData() {
        const {t} = this.props;
        this.setState({loading: true});

        const getAllRecords = async (offset = 0, limit = 100) => {
            try {
                const response = await axios.get(
                    window.location.origin +
                    this.config.serverConfig.invoker.uri +
                    `/device-mgt/power-meter-dlms/v1.0/admin/devices/load-profiles?${this.filterValues}&offset=${offset}&limit=${limit}`
                );

                if (response.status === 200) {
                    const exportData = response.data.data;

                    let data = [];

                    if (exportData.profileReadings && exportData.profileReadings.length > 0) {
                        exportData.profileReadings.forEach((profileReading, index) => {
                            console.log(`Processing Profile Reading #${index + 1}:`, profileReading);

                            if (profileReading.profileData) {
                                console.log(`Profile Reading #${index + 1} has profileData`);

                                const profileData = profileReading.profileData;
                                data.push({
                                    serial: profileReading.serial,
                                    customerRef: profileReading?.customerRef,
                                    date: profileData['0.9.2_0'],
                                    time: profileData['0.9.1_0'],
                                    timestamp: profileReading.timestamp,
                                    obis: profileReading.obis,
                                    importkWh: profileData['LP_1.8.0'],
                                    exportkWh: profileData['LP_2.8.0'],
                                    Importkvarh: profileData['LP_3.8.0'],
                                    Exportkvarh: profileData['LP_4.8.0'],
                                    PhaseAInstCurrent: profileData['LP_31.7.0'],
                                    PhaseBInstCurrent: profileData['LP_51.7.0'],
                                    PhaseCInstCurrent: profileData['LP_71.7.0'],
                                    PhaseAInstVoltage: profileData['CO_32.7.0'],
                                    PhaseBInstVoltage: profileData['LP_52.7.0'],
                                    PhaseCInstVoltage: profileData['CO_72.7.0'],
                                    PhaseABCPowerfactor: profileData['LP_13.5.0'],
                                    AvgImportkW: profileData['LP_1.5.0'],
                                    AvgExportkW: profileData['LP_2.5.0'],
                                    AvgCurrent: profileData['LP_11.25.0'],
                                    AvgVoltage: profileData['LP_12.25.0'],
                                    AvgImportkVA: profileData['LP_9.5.0'],
                                    AvgExportkVA: profileData['LP_10.5.0'],
                                });
                            } else {
                                console.log(`Profile Reading #${index + 1} has no profileData`);
                            }
                        });
                    } else {
                        console.log("No profile readings found");
                    }

                    return data;
                }
            } catch (error) {
                handleApiError(
                    error,
                    t('api_loadError', {label: t('label_Usage')}),
                    t,
                );
                throw error;
            }
        };

        const fetchData = async () => {
            let allRecords = [];
            let offset = 0;
            const limit = 100; //can adjust this value if needed

            try {
                while (true) {
                    const records = await getAllRecords(offset, limit);

                    if (records.length === 0) {
                        break;
                    }

                    allRecords = [...allRecords, ...records];
                    offset += limit;
                }

                this.setState({exportDataList: allRecords, loading: false}, () => {
                    setTimeout(() => {
                        this.csvLinkEl.current.link.click();
                    });
                });
            } catch (error) {
                this.setState({loading: false});
            }
        };

        fetchData();
    }

    getMeters = (params = {}, filters = '') => {
        const {t} = this.props;
        this.setState({loading: true});

        const currentPage = params.hasOwnProperty('page') && params.page ? params.page : 1;

        let limit = 10;
        if (params.results) {
            limit = params.results;
        }

        const offset = limit * (currentPage - 1);

        const extraParams = {
            offset: offset,
            limit: limit,
            ...params, // include any additional params passed to the function
        };

        let encodedExtraParams = Object.keys(extraParams)
            .map(key => key + '=' + extraParams[key])
            .join('&');

        if (filters !== '') {
            encodedExtraParams = encodedExtraParams + filters;
        }

        axios
            .get(
                window.location.origin +
                this.config.serverConfig.invoker.uri +
                '/device-mgt/power-meter-dlms/v1.0/admin/devices/load-profiles?' +
                encodedExtraParams,
            )
            .then(res => {
                if (res.status === 200) {
                    const pagination = {...this.state.pagination};
                    const metersData = res.data.data.profileReadings.map(profileReading => ({
                        key: profileReading.id,
                        serial: profileReading.serial,
                        // name: profileReading.name,
                        branch: profileReading?.branch,
                        customerRef: profileReading?.customerRef,
                        obis: profileReading?.obis,
                        hasEbilling: profileReading.hasEbilling,
                        hasExport: profileReading.hasExport,
                        subTypeId: profileReading.subTypeId,
                        timestamp: profileReading.timestamp,
                        profileData: profileReading.profileData,
                        date: profileReading.profileData['0.9.2_0'],
                        time: profileReading.profileData['0.9.1_0'],
                    }));
                    this.setState({
                        devices: metersData,
                        count: res.data.data.count,
                        pagination,
                        loading: false,
                        exportDataList: metersData, // Set export data to state
                    });
                }
            })
            .catch(error => {
                handleApiError(error, t('api_getOperationError'), t);
                this.setState({loading: false});
            });
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        pager.pageSize = pagination.pageSize;
        this.setState({
            pagination: pager,
        });
        this.getMeters(
            {
                results: pagination.pageSize,
                page: pagination.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters,
            },
            this.filterValues,
        );
    };

    showModal = () => {
        this.setState({
            isModalVisible: true,
        });
    };

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
        });
    };

    getDetailsUI = (profileData) => {
        if (!profileData || Object.keys(profileData).length === 0) {
            return (
                <Empty
                    className={styles.customEmpty}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
            );
        }

        return (
            <div>
                <div className={styles.responseRow}>
                    <div className={styles.flexContainer}>
                        {Object.keys(meterReadings)
                            .filter((key) => profileData[key] !== undefined) //filter and show the only profileData fields that coming from the 'profileData'
                            .map((key, index) => (
                                <p key={index}>
                                    <strong>{meterReadings[key].text}&nbsp;: </strong>
                                    {profileData[key]}
                                </p>
                            ))}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const {devices, pagination, loading, selectedNodeType} = this.state;
        const {t} = this.props;
        const data = this.state.exportDataList;
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0');
        let yyyy = today.getFullYear();
        today = mm + '-' + dd + '-' + yyyy;
        const filename = `ProfileReadings_${moment().format('YYYY-MM-DD')}.csv`;
        let path = `/facility-monitoring/public/documents/${this.config.userDomain}/`;

        const headers = [
            {label: 'SERIAL', key: 'serial', width: 100},
            {label: 'CUSTOMER_REF', key: 'customerRef'},
            {label: 'TIMESTAMP', key: 'timestamp'},
            {label: 'OBIS', key: 'obis'},
            {label: 'Date', key: 'date'},
            {label: 'Time', key: 'time'},
            {label: 'Import kWh (kWh)', key: 'importkWh'},
            {label: 'Export kWh (kWh)', key: 'exportkWh'},
            {label: 'Import kvarh (kvarh)', key: 'Importkvarh'},
            {label: 'Export kvarh (kvarh)', key: 'Exportkvarh'},
            {label: 'Phase A Inst. Current (A)', key: 'PhaseAInstCurrent'},
            {label: 'Phase B Inst. Current (A)', key: 'PhaseBInstCurrent'},
            {label: 'Phase C Inst. Current (A)', key: 'PhaseCInstCurrent'},
            {label: 'Phase A Inst. Voltage (V)', key: 'PhaseAInstVoltage'},
            {label: 'Phase B Inst. Voltage (V)', key: 'PhaseBInstVoltage'},
            {label: 'Phase C Inst. Voltage (V)', key: 'PhaseCInstVoltage'},
            {label: 'Phase ABC Power factor', key: 'PhaseABCPowerfactor'},
            {label: 'Avg. Import kW (kW)', key: 'AvgImportkW'},
            {label: 'Avg. Export kW (kW)', key: 'AvgExportkW'},
            {label: 'Avg. Current (V)', key: 'AvgCurrent'},
            {label: 'Avg. Voltage (V)', key: 'AvgVoltage'},
            {label: 'Avg. Import kVA (kVA)', key: 'AvgImportkVA'},
            {label: 'Avg. Export kVA (kVA)', key: 'AvgExportkVA'},
        ];

        const categoryOptions =
            selectedNodeType === 'INTERNAL'
                ? ['INTERNAL_DISTRIBUTION', 'INTERNAL_TESTING']
                : selectedNodeType === 'BILLING'
                    ? ['BILLING_BULK', 'BILLING_PAID']
                    : [];

        return (
            <div>
                <HtmlComments permission={'/permission/admin/device-mgt/rmr/device/admin/view-meter-reading'}/>
                <Authorized
                    scope={['perm:rmr:admin:view-meter-reading']}
                    yes={
                        <div>
                            <PageHeader
                                className={styles.pageHeader}
                                title={
                                    <Title level={1}>
                                        <span className={styles.icon}>
                                          <OneToOneOutlined/>
                                        </span>
                                        {t('home_menu_profile_readings')}
                                    </Title>
                                }
                                ghost={false}
                                breadcrumb={
                                    <BreadcrumbComponent
                                        breadcrumbList={routes(this.config.appName, t)}
                                    />
                                }
                            >
                                {/*<Paragraph className={styles.description}>*/}
                                {/*    {t('meter_inventory_description')}*/}
                                {/*</Paragraph>*/}
                            </PageHeader>
                            <div className={styles.table}>
                                <Collapse
                                    bordered={false}
                                    expandIcon={() => <SearchOutlined/>}
                                    className={styles.searchCollapse}
                                >
                                    <Panel
                                        header={t('form_searchOperations')}
                                        key="1"
                                        style={{borderColor: '#fff'}}
                                    >
                                        <Filter
                                            filters={this.state.searchFields}
                                            callback={(params, values) =>
                                                this.fetchOperationFilters({}, values)
                                            }
                                            onNodeTypeChange={(selectedNodeType) =>
                                                this.setState({selectedNodeType})
                                            }
                                            categoryOptions={categoryOptions}
                                        />
                                    </Panel>
                                </Collapse>
                                <div>
                                    <Row justify="end" style={{marginBottom: 10}}>
                                        <Col>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                onClick={this.exportData}
                                                disabled={this.state.count === 0}
                                            >
                                                Export CSV
                                            </Button>
                                            <CSVLink
                                                filename={filename}
                                                data={data}
                                                headers={headers}
                                                ref={this.csvLinkEl}
                                            />
                                        </Col>
                                    </Row>
                                    {loading &&
                                        <div style={{textAlign: 'center'}}>
                                            <Spin/>
                                        </div>}
                                    {!loading > 0 && (
                                        <ProfileReadingTable
                                            devices={devices}
                                            columns={this.columns}
                                            pagination={pagination}
                                            loading={loading}
                                            count={this.state.count}
                                            handleTableChange={this.handleTableChange}
                                            getDetailsUI={this.getDetailsUI}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                    no={
                        <Result
                            status="403"
                            title={t('noPerm_accessPageTitle')}
                            subTitle={t('noPerm_contactSysAdmin')}
                        />
                    }
                />
            </div>
        );
    }
}

ProfileHistoricalReading.contextType = RoutesContext;
export default withConfigContext(withTranslation()(ProfileHistoricalReading));
