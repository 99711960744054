/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Button,
  Divider,
  Form,
  Input,
  Select,
  Tooltip,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 10 },
    xl: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 12 },
    xl: { span: 12 },
  },
};

const buttonFormItem = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 16 },
    lg: { span: 22 },
    xl: { span: 22 },
  },
};

class KioskProvisioning extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
    };
  }

  componentDidMount() {
    const { platformConfigData } = this.props;
    let initialValues = {};
    for (let config of platformConfigData) {
      if (
        config.name ===
        'android.app.extra.PROVISIONING_DEVICE_ADMIN_COMPONENT_NAME'
      ) {
        initialValues[
          'android.app.extra.PROVISIONING_DEVICE_ADMIN_COMPONENT_NAME'
        ] = config.value;
      }
      if (
        config.name ===
        'android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_CHECKSUM'
      ) {
        initialValues[
          'android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_CHECKSUM'
        ] = config.value;
      }
      if (
        config.name ===
        'android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_DOWNLOAD_LOCATION'
      ) {
        initialValues[
          'android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_DOWNLOAD_LOCATION'
        ] = config.value;
      }
      if (config.name === 'android.app.extra.serverIp') {
        initialValues['android.app.extra.serverIp'] = config.value;
      }
      if (config.name === 'android.app.extra.PROVISIONING_SKIP_ENCRYPTION') {
        initialValues['android.app.extra.PROVISIONING_SKIP_ENCRYPTION'] =
          config.value;
      }
      if (
        config.name ===
        'android.app.extra.PROVISIONING_LEAVE_ALL_SYSTEM_APPS_ENABLED'
      ) {
        initialValues[
          'android.app.extra.PROVISIONING_LEAVE_ALL_SYSTEM_APPS_ENABLED'
        ] = config.value;
      }
      if (config.name === 'android.app.extra.defaultOwner') {
        initialValues['android.app.extra.defaultOwner'] = config.value;
      }
    }
    this.formRef.current.setFieldsValue(initialValues);
  }

  onFinish = values => {
    this.props.callback(values);
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div>
          <Divider>
            <PageHeader
              className="site-page-header-responsive"
              subTitle={t('label_KIOSKProvisioningConfigs')}
            />
          </Divider>
        </div>
        <div>
          <Form
            {...formItemLayout}
            name="AndroidKioskProvisioningForm"
            onFinish={this.onFinish}
            ref={this.formRef}
          >
            <Form.Item
              label={
                <span>
                  {t('label_provisioningComponent')}&nbsp;
                  <Tooltip title={t('provisioningComponent_infoTxt')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="android.app.extra.PROVISIONING_DEVICE_ADMIN_COMPONENT_NAME"
            >
              <Input
                placeholder={t('ex_Txt', {
                  exampleTxt:
                    'io.entgra.iot.agent/org.wso2.iot.agent.services.AgentDeviceAdminReceiver',
                })}
              />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {t('label_packageChecksum')}&nbsp;
                  <Tooltip title={t('packageChecksum_infoTxt')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_CHECKSUM"
            >
              <Input
                placeholder={t('ex_Txt', {
                  exampleTxt: 'gJD2YwtOiWJHkSMkkIfLRlj-quNqG1fb6v100QmzM9w=',
                })}
              />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {t('label_downloadLocation')}&nbsp;
                  <Tooltip title={t('downloadLocation_infoTxt')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_DOWNLOAD_LOCATION"
            >
              <Input
                placeholder={t('ex_Txt', {
                  exampleTxt: 'http://192.168.8.20:8080/android.apk',
                })}
              />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {t('label_serverAddress')}&nbsp;
                  <Tooltip
                    title={`${t('label_serverAddress')} ${t('ex_Txt', {
                      exampleTxt: 'www.abc.com:8280',
                    })}`}
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="android.app.extra.serverIp"
            >
              <Input
                placeholder={t('ex_Txt', { exampleTxt: 'www.abc.com:8280' })}
              />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {t('label_enableEncryption')}&nbsp;
                  <Tooltip title={t('enableEncryption_infoTxt')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="android.app.extra.PROVISIONING_SKIP_ENCRYPTION"
              initialValue="false"
            >
              <Select>
                <Option value="false">{t('label_false')}</Option>
                <Option value="true">{t('label_true')}</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {t('label_enableAllSystemApps')}&nbsp;
                  <Tooltip title={t('enableAllSystemApps_infoTxt')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="android.app.extra.PROVISIONING_LEAVE_ALL_SYSTEM_APPS_ENABLED"
              initialValue="true"
            >
              <Select>
                <Option value="false">{t('label_false')}</Option>
                <Option value="true">{t('label_true')}</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {t('label_ownershipType')}&nbsp;
                  <Tooltip title={t('ownershipType_infoTxt')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="android.app.extra.defaultOwner"
              initialValue="BYOD"
            >
              <Select>
                <Option value="BYOD">BYOD</Option>
                <Option value="COPE">COPE</Option>
                <Option value="COSU">COSU</Option>
                <Option value="WORK_PROFILE">WORK_PROFILE</Option>
                <Option value="GOOGLE_WORK_PROFILE">GOOGLE_WORK_PROFILE</Option>
              </Select>
            </Form.Item>
            <Form.Item {...buttonFormItem}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ float: 'right' }}
              >
                {t('label_save')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(KioskProvisioning);
