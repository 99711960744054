/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { DeploymentUnitOutlined } from '@ant-design/icons';
import { Button, Tooltip, Select, Modal, notification, Alert } from 'antd';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import axios from 'axios';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import { isAuthorized } from '../../../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

class AddToGroup extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      groupModalVisible: false,
      selectedGroupId: [],
      deviceGroups: [],
    };
  }

  handleOk = e => {
    const { t } = this.props;
    if (
      this.state.selectedGroupId &&
      isAuthorized(this.config.scopes, [
        ['perm:groups:assign', 'perm:groups:devices-add'],
      ])
    ) {
      this.addDevicesToGroup(this.state.selectedGroupId);
    } else if (
      !isAuthorized(this.config.scopes, [
        ['perm:groups:assign', 'perm:groups:devices-add'],
      ])
    ) {
      notification.error({
        message: t('label_error_no_permission_view_group'),
        duration: 0,
        description: t('label_please_contact_admin'),
      });
    } else {
      notification.error({
        message: t('label_error_group_not_selected'),
        duration: 0,
        description: t('label_error_group_not_selected_description'),
      });
    }
  };

  handleCancel = e => {
    this.setState({
      groupModalVisible: false,
      selectedGroupId: [],
    });
  };

  onGroupSelectChange = value => {
    this.setState({ selectedGroupId: value });
  };

  onDeviceGroupCall = () => {
    const config = this.props.context;
    this.setState({
      groupModalVisible: true,
    });

    // check request is authorized
    if (
      !isAuthorized(this.config.scopes, [
        ['perm:admin-groups:view', 'perm:groups:groups'],
      ])
    ) {
      return;
    }

    let url;
    if (isAuthorized(this.config.scopes, ['perm:admin-groups:view'])) {
      url =
        window.location.origin +
        config.serverConfig.invoker.uri +
        config.serverConfig.invoker.deviceMgt +
        '/admin/groups';
    } else if (isAuthorized(this.config.scopes, ['perm:groups:groups'])) {
      url =
        window.location.origin +
        config.serverConfig.invoker.uri +
        config.serverConfig.invoker.deviceMgt +
        '/groups';
    }
    url += '?offset=0&limit=99';
    // send request to the invoker
    axios
      .get(url)
      .then(res => {
        this.setState({ deviceGroups: res.data.data.deviceGroups });
      })
      .catch(error => {
        const { t } = this.props;
        handleApiError(error, t('label_error_receiving_device_group'), t);
      });
  };

  addDevicesToGroup = groupId => {
    const config = this.props.context;
    const { t } = this.props;

    let apiUrl;
    let deviceData;
    if (this.props.selectedRows.length === 1) {
      apiUrl =
        window.location.origin +
        config.serverConfig.invoker.uri +
        config.serverConfig.invoker.deviceMgt +
        '/groups/device/assign';
      deviceData = {
        deviceIdentifier: {
          id: this.props.selectedRows[0].deviceIdentifier,
          type: this.props.selectedRows[0].type,
        },
        deviceGroupIds: groupId,
      };
    } else if (!groupId[0]) {
      apiUrl =
        window.location.origin +
        config.serverConfig.invoker.uri +
        config.serverConfig.invoker.deviceMgt +
        '/groups/id/' +
        groupId +
        '/devices/add';
      deviceData = this.props.selectedRows.map(obj => ({
        id: obj.deviceIdentifier,
        type: obj.type,
      }));
    } else {
      apiUrl =
        window.location.origin +
        config.serverConfig.invoker.uri +
        config.serverConfig.invoker.deviceMgt +
        '/groups/id/' +
        groupId[0] +
        '/devices/add';
      deviceData = this.props.selectedRows.map(obj => ({
        id: obj.deviceIdentifier,
        type: obj.type,
      }));
    }

    // send request to the invoker
    axios
      .post(apiUrl, deviceData, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(res => {
        if (res.status === 200) {
          notification.success({
            message: t('label_done'),
            duration: 4,
            description: t('label_device_added_to_group'),
          });
          this.setState({
            groupModalVisible: false,
            selectedGroupId: [],
          });
          this.props.callback();
        }
      })
      .catch(error => {
        handleApiError(error, t('label_error_device_added_to_group'), t);
      });
  };

  render() {
    const isSelectedSingle = this.props.selectedRows.length === 1;
    const { t } = this.props;
    let selectedText;
    if (isSelectedSingle) {
      selectedText = t('label_1_device_selected');
    } else {
      selectedText =
        t('label_you_have_selected') +
        this.props.selectedRows.length +
        t('label_devices');
    }

    let items = this.state.deviceGroups.map(data => (
      <Select.Option value={data.id} key={data.id}>
        {data.name}
      </Select.Option>
    ));
    return (
      <div>
        <Tooltip
          placement="bottom"
          title={
            !this.props.disabled
              ? t('device_bulk_operation_valid_tooltip')
              : t('device_bulk_operation_invalid_tooltip')
          }
        >
          <Button
            type="link"
            shape="circle"
            size={'default'}
            onClick={this.onDeviceGroupCall}
            style={{ margin: '2px' }}
            disabled={
              this.props.disabled ||
              !isAuthorized(this.config.scopes, [
                ['perm:groups:assign', 'perm:groups:devices-add'],
              ])
            }
          >
            <HtmlComments
              permission={
                '/permission/admin/device-mgt/groups/view OR\n' +
                '/permission/admin/device-mgt/admin/groups/view\n' +
                '/permission/admin/device-mgt/groups/devices/add'
              }
            />
            <DeploymentUnitOutlined />
          </Button>
        </Tooltip>
        <Modal
          title={t('label_grouping_devices')}
          width="350px"
          open={this.state.groupModalVisible}
          okText={t('label_ok')}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          maskClosable={false}
        >
          {!isAuthorized(this.config.scopes, [
            ['perm:groups:groups', 'perm:admin-groups:view'],
          ]) && (
            <div>
              <HtmlComments
                permission={
                  '/permission/admin/device-mgt/groups/view OR\n' +
                  '/permission/admin/device-mgt/admin/groups/view'
                }
              />
              <Alert
                message={t('label_alert_disabled_no_permissions')}
                banner
                style={{ marginBottom: 15 }}
              />
            </div>
          )}
          <p>{selectedText}</p>
          <div>
            <HtmlComments
              permission={
                '/permission/admin/device-mgt/groups/view OR\n' +
                '/permission/admin/device-mgt/admin/groups/view'
              }
            />
            <Select
              mode={isSelectedSingle ? 'multiple' : 'default'}
              showSearch
              style={{ display: 'block' }}
              placeholder={t('form_selectGroups')}
              optionFilterProp="children"
              onChange={this.onGroupSelectChange}
              value={this.state.selectedGroupId}
              disabled={
                !isAuthorized(this.config.scopes, [
                  ['perm:groups:groups', 'perm:admin-groups:view'],
                ])
              }
            >
              {items}
            </Select>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(AddToGroup));
