/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Drawer, Table, Typography } from 'antd';

const { Title, Paragraph, Text } = Typography;

const cookiesColumns = [
  {
    title: 'Cookie Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Purpose',
    dataIndex: 'purpose',
    key: 'purpose',
  },
  {
    title: 'Retention',
    dataIndex: 'retention',
    key: 'retention',
  },
];

const cookiesData = [
  {
    key: 1,
    name: 'JSESSIONID',
    purpose:
      'To keep your session data in order to give you a good user experience.',
    retention: 'Session',
  },
  {
    key: 2,
    name: 'commonAuthId',
    purpose: 'Used to authenticate the the logged in session.',
    retention: 'Session',
  },
  {
    key: 3,
    name: 'samlssoTokenId',
    purpose: 'Used to map the logged in user with the SAML token.',
    retention: 'Request',
  },
  {
    key: 4,
    name: 'requestedURI',
    purpose: 'The URI you are accessing.',
    retention: 'Session',
  },
];

class CookiePolicy extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Drawer
        width={640}
        placement="right"
        title={<Title level={3}>COOKIE POLICY</Title>}
        onClose={this.props.onClose}
        open={this.props.visible}
      >
        <Title level={3}>About Entgra IoT Server</Title>
        <Paragraph>
          Entgra IoT Server 3.8.0 is a complete solution that enables device
          manufacturers and enterprises to connect and manage their devices,
          build apps, manage events, secure devices and data, and visualize
          sensor data in a scalable manner.
        </Paragraph>
        <Paragraph>
          It also offers a complete and secure Enterprise Mobility Management
          (EMM/MDM) solution that aims to address mobile computing challenges
          faced by enterprises today. Supporting iOS, Android, and Windows
          devices, it helps organizations deal with both Corporate Owned,
          Personally Enabled (COPE) and employee-owned devices with the Bring
          Your Own Device (BYOD) concept.
        </Paragraph>
        <Paragraph>
          Entgra IoT Server 3.8.0 comes with advanced analytics, enabling users
          to analyze speed, proximity, and geo-fencing information of devices
          including details of those in motion and stationary state..
        </Paragraph>
        <Title level={2}>Cookie Policy</Title>
        <Paragraph>
          IoT Server uses cookies to provide you with the best user experience,
          and to securely identify you. You can disable cookies if you so wish .
          However you might not be able to access some of the services if you
          disable cookies.
        </Paragraph>
        <Title level={3}>What is a cookie?</Title>
        <Paragraph>
          A browser cookie is a small piece of data that is stored on your
          device to help websites and mobile apps remember things about you.
          Other technologies, including Web storage and identifiers associated
          with your device, may be used for similar purposes. In this policy, we
          use the term “cookies” to discuss all of these technologies.
        </Paragraph>

        <Title level={3}>
          How does Entgra IoT Server 3.8.0 process cookies?
        </Title>
        <Paragraph>
          Entgra IoT Server 3.8.0 uses cookies to store and retrieve information
          on your browser. This information is used to provide a better user
          experience. Some cookies serve the purpose of allowing a user to log
          in to the system, maintain sessions, and keep track of activities
          within the login session.
        </Paragraph>
        <Paragraph>
          Some cookies in Entgra IoT Server 3.8.0 are used to personally
          identify you. However, the cookie lifetime ends once your session
          ends, i.e., after you log-out, or after the session expiry time has
          elapsed.
        </Paragraph>
        <Paragraph>
          Some cookies are simply used to give you a more personalised web
          experience, and these cannot be used to identify you or your
          activities personally.
        </Paragraph>
        <Paragraph>
          This Cookie Policy is part of the IoT Server{' '}
          <a onClick={this.props.showPrivacyPolicy}>privacy policy</a>
        </Paragraph>

        <Title level={3}>
          What does Entgra IoT Server 3.8.0 use cookies for?
        </Title>
        <Paragraph>
          Cookies are used for two purposes in Entgra IoT Server 3.8.0.
        </Paragraph>
        <ol>
          <li>To identify you and provide security</li>
          <li> provide a satisfying user experience.</li>
        </ol>

        <Text underline strong>
          Preferences
        </Text>
        <Paragraph>
          Entgra IoT Server 3.8.0 uses cookies to remember your settings and
          preferences and to auto-fill the fields to make your interactions with
          the site easier.
        </Paragraph>
        <Paragraph>
          These cookies can not be used to personally identify you.
        </Paragraph>

        <Text underline strong>
          Security
        </Text>
        <ol>
          <li>
            <Paragraph>
              Entgra IoT Server 3.8.0 uses selected cookies to identify and
              prevent security risks. For example, Entgra IoT Server 3.8.0 may
              use cookies to store your session information to prevent others
              from changing your password without your username and password.
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              Entgra IoT Server 3.8.0 uses session cookie to maintain your
              active session.
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              Entgra IoT Server 3.8.0 may use a temporary cookie when performing
              multi-factor authentication and federated authentication.
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              Entgra IoT Server 3.8.0 may use permanent cookies to detect the
              devices you have logged in previously. This is to to calculate the{' '}
              <Text strong>risk level</Text> associated with your current login
              attempt. Using these cookies protects you and your account from
              possible attacks.
            </Paragraph>
          </li>
        </ol>

        <Text underline strong>
          Performance
        </Text>
        <Paragraph>
          Entgra IoT Server 3.8.0 may use cookies to allow{' '}
          <Text strong>Remember Me</Text> functionalities.
        </Paragraph>

        <Text underline strong>
          Analytics
        </Text>
        <Paragraph>
          Entgra IoT Server 3.8.0 as a product does not use cookies for
          analytical purposes.
        </Paragraph>

        <Text underline strong>
          Third party cookies
        </Text>
        <Paragraph>
          Using Entgra IoT Server 3.8.0 may cause third-party cookie to be set
          in your browser. Entgra IoT Server 3.8.0 has no control over how any
          of them operate. The third-party cookies that maybe set include:
        </Paragraph>
        <ol>
          <li>
            <Paragraph>
              Any social login sites. For example, third-party cookies may be
              set when Entgra IoT Server 3.8.0 is configured to use “social” or
              “federated” login, and you opt to login with your “Social
              Account”.
            </Paragraph>
          </li>
          <li>
            <Paragraph>Any third party federated login.</Paragraph>
          </li>
        </ol>
        <Paragraph>
          Entgra strongly advises you to refer the respective cookie policies of
          such sites carefully as Entgra has no knowledge or use on these
          cookies.
        </Paragraph>
        <Title level={3}>
          What type of cookies does Entgra IoT Server 3.8.0 use?
        </Title>
        <Paragraph>
          Entgra IoT Server 3.8.0 uses persistent cookies and session cookies. A
          persistent cookie helps Entgra IS 3.8.0 to recognize you as an
          existing user so that it is easier to return to Entgra or interact
          with Entgra IS 3.8.0 without signing in again. After you sign in, a
          persistent cookie stays in your browser and will be read by Entgra IoT
          Server 3.8.0 when you return to Entgra IoT Server 3.8.0.
        </Paragraph>
        <Paragraph>
          A session cookie is a cookie that is erased when the user closes the
          Web browser. The session cookie is stored in temporarily and is not
          retained after the browser is closed. Session cookies do not collect
          information from the user’s computer.
        </Paragraph>

        <Title level={3}>How do I control my cookies?</Title>
        <Paragraph>
          Most browsers allow you to control cookies through settings. However,
          if you limit the given ability for websites to set cookies, you may
          worsen your overall user experience since it will no longer be
          personalized to you. It may also stop you from saving customized
          settings like login information. Most likely, disabling cookies will
          make it unable for you to use authentication and authorization
          functionalities offered by Entgra IoT Server 3.8.0.
        </Paragraph>
        <Paragraph>
          If you have any questions or concerns regarding the use of cookies,
          please contact the entity or individuals (or their data protection
          officer, if applicable) running this Entgra IoT Server 3.8.0 instance.
        </Paragraph>

        <Title level={3}>What are the cookies used?</Title>
        <Table
          columns={cookiesColumns}
          dataSource={cookiesData}
          pagination={false}
        />

        <Title level={3}>Disclaimer</Title>
        <Paragraph>
          This cookie policy is only for illustrative purposes of the product
          Entgra IoT Server 3.8.0. The content in the policy is technically
          correct at the time of the product shipment. The entity,organization
          or individual that runs this Entgra IoT Server 4.0.0 instance has full
          authority and responsibility with regard to the effective Cookie
          Policy. Entgra, its employees, partners, and affiliates do not have
          access to and do not require, store, process or control any of the
          data, including personal data contained in Entgra IoT Server 4.0.0.
          All data, including personal data is controlled and processed by the
          entity, organization or individual running Entgra IoT Server 4.0.0.
          Entgra, its employees partners and affiliates are not a data processor
          or a data controller within the meaning of any data privacy
          regulations. Entgra does not provide any warranties or undertake any
          responsibility or liability in connection with the lawfulness or the
          manner and purposes for which Entgra IoT Server 4.0.0 is used by such
          entities, organizations or persons.
        </Paragraph>
      </Drawer>
    );
  }
}

export default CookiePolicy;
