/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useContext, useState } from 'react';
import { Modal, notification } from 'antd';
import HtmlComments from '../../../../../../components/ReactHtmlComments/HtmlComments';
import axios from 'axios';
import { useTranslation, withTranslation } from 'react-i18next';
import ConfigContext from '../../../../../../components/ConfigContext';
import DeviceOwner from './components/DeviceOwner';
import Employee from './components/Employee';
import EnrollmentMode from './components/EnrollmentMode';
import DataSeparation from './components/DataSeparation';
import Feedback from './components/Feedback';
import Employer from './components/Employer';
import InFieldDevices from './components/InFieldDevices';
import GoogleBasedInbox from './components/GoogleBasedInbox';
import DeviceFactoryReset from './components/DeviceFactoryReset';
import OsVersion from './components/OsVersion';
import AlternativeEnrollment from './components/AlternativeEnrollment';
import AlternativeEnrollmentSuggestion from './components/AlternativeEnrollmentSuggestion';
import Authorized from '../../../../../../components/Authorized';
import { handleApiError } from '../../../../../../services/utils/errorHandler';

export const EnrollmentGuideContext = React.createContext();
const EnrollmentGuideProvider = EnrollmentGuideContext.Provider;

const EnrollmentGuide = props => {
  const { t } = useTranslation();
  const { config } = useContext(ConfigContext);

  const [pageIndex, setPageIndex] = useState('deviceOwner');
  const [contextPayload, setContextPayload] = useState({
    payload: [],
    data: {},
  });
  const [enrolmentModeText, setEnrolmentModeText] = useState('');
  const [factoryResetUnavailable, setFactoryResetUnavailable] = useState(false);
  const [
    alternativeEnrollmentModeText,
    setAlternativeEnrollmentModeText,
  ] = useState('');
  const [
    alternativeEnrollmentModeSuggestionText,
    setAlternativeEnrollmentModeSuggestionText,
  ] = useState('');
  const [prevPageIndex, setPrevPageIndex] = useState('');
  const [
    isAlternativeEnrollmentMode,
    setIsAlternativeEnrollmentMode,
  ] = useState(false);

  const createPayload = (values, page) => {
    let obj = {};
    if (
      page !== 'enrollmentMode' &&
      page !== 'feedBack' &&
      page !== 'alternativeEnrollment' &&
      page !== 'alternativeEnrollmentMode'
    ) {
      setPrevPageIndex(page);
    }

    if (page === 'alternativeEnrollmentMode') {
      setIsAlternativeEnrollmentMode(true);
    } else {
      setIsAlternativeEnrollmentMode(false);
    }

    const result = contextPayload.payload.find(({ key }) => key === values.key);
    if (result === undefined) {
      if (page === 'feedBack') {
        for (const index of values) {
          if (index.key === 'features') {
            let feature = {};
            feature.key = index.key;
            feature.title = index.title;
            feature.value = index.value;
            contextPayload.payload.push(feature);
          } else if (index.key === 'feedback') {
            let feedback = {};
            feedback.key = index.key;
            feedback.title = index.title;
            feedback.value = index.value;
            contextPayload.payload.push(feedback);
          }
        }
      } else {
        obj.key = values.key;
        obj.title = values.title;
        obj.value = values.value;
        if (obj.key === 'alternativeEnrollment') {
          obj.alternativeEnrollmentTitle = 'Suggested enrollment Mode';
          obj.alternativeEnrollment = values.alternativeEnrollmentSuggestion;
        }
        contextPayload.payload.push(obj);
      }
    } else {
      contextPayload.payload.find(({ key }) => key === values.key).value =
        values.value;
      result.value = values.value;
    }

    if (
      page === 'deviceOwner' &&
      values.value === t('label_enrollment_guide_employee')
    ) {
      contextPayload.data.deviceOwner = values.value;
      setPageIndex('employee');
      setContextPayload(contextPayload);
    } else if (page === 'employee' && values.value === t('label_no')) {
      contextPayload.data.employee = values.value;
      setPageIndex('enrollmentMode');
      setContextPayload(contextPayload);
      setEnrolmentModeText(t('label_enrollment_guide_dlp_suggestion'));
    } else if (page === 'employee' && values.value === t('label_yes')) {
      contextPayload.data.employee = values.value;
      setPageIndex('dlp');
      setContextPayload(contextPayload);
    } else if (page === 'dlp' && values.value === t('label_yes')) {
      contextPayload.data.dlp = values.value;
      setPageIndex('enrollmentMode');
      setContextPayload(contextPayload);
      setEnrolmentModeText(t('label_enrollment_guide_gmail_based_suggestion'));
    } else if (page === 'dlp' && values.value === t('label_no')) {
      contextPayload.data.dlp = values.value;
      setPageIndex('enrollmentMode');
      setContextPayload(contextPayload);
      setEnrolmentModeText(
        t('label_enrollment_guide_not_gmail_based_suggestion'),
      );
    } else if (page === 'enrollmentMode') {
      setPageIndex('feedBack');
    } else if (page === 'feedBack') {
      contextPayload.data.feedback = values;
      setContextPayload(contextPayload);
      sendEmailToSupportGroup();
    } else if (
      page === 'deviceOwner' &&
      values.value === t('label_enrollment_guide_employer')
    ) {
      contextPayload.data.deviceOwner = values.value;
      setPageIndex('workSeparation');
      setContextPayload(contextPayload);
    } else if (page === 'workSeparation' && values.value === t('label_yes')) {
      contextPayload.data.workSeparation = values.value;
      setPageIndex('inFieldDevices');
      setContextPayload(contextPayload);
    } else if (page === 'inFieldDevices' && values.value === t('label_yes')) {
      contextPayload.data.inFieldDevices = values.value;
      setPageIndex('enrollmentMode');
      setContextPayload(contextPayload);
      setEnrolmentModeText(
        t('label_enrollment_guide_has_devices_in_field_suggestion'),
      );
    } else if (page === 'inFieldDevices' && values.value === t('label_no')) {
      contextPayload.data.inFieldDevices = values.value;
      setPageIndex('enrollmentMode');
      setContextPayload(contextPayload);
      setEnrolmentModeText(
        t('label_enrollment_guide_no_devices_in_field_suggestion'),
      );
    } else if (page === 'workSeparation' && values.value === t('label_no')) {
      contextPayload.data.workSeparation = values.value;
      setPageIndex('googleBasedInbox');
      setContextPayload(contextPayload);
    } else if (
      page === 'googleBasedInbox' &&
      (values.value === t('label_yes') || values.value === t('label_no'))
    ) {
      if (values.value === t('label_no')) {
        setFactoryResetUnavailable(true);
      } else {
        setFactoryResetUnavailable(false);
      }
      contextPayload.data.googleBasedInbox = values.value;
      setPageIndex('deviceFactoryRest');
      setContextPayload(contextPayload);
    } else if (
      page === 'deviceFactoryRest' &&
      values.value === t('label_yes')
    ) {
      contextPayload.data.deviceFactoryRest = values.value;
      setPageIndex('osVersion');
      setContextPayload(contextPayload);
    } else if (page === 'osVersion' && values.value === t('label_yes')) {
      contextPayload.data.osVersion = values.value;
      if (factoryResetUnavailable) {
        setPageIndex('enrollmentMode');
        setContextPayload(contextPayload);
        setEnrolmentModeText(
          t(
            'label_enrollment_guide_not_google_based_cannot_factory_reset_suggestion',
          ),
        );
      } else {
        setPageIndex('enrollmentMode');
        setContextPayload(contextPayload);
        setEnrolmentModeText(
          t('label_enrollment_guide_os_version_above_suggestion'),
        );
      }
    } else if (page === 'osVersion' && values.value === t('label_no')) {
      contextPayload.data.osVersion = values.value;
      if (factoryResetUnavailable) {
        setPageIndex('alternativeEnrollment');
        setContextPayload(contextPayload);
        setAlternativeEnrollmentModeText(
          t(
            'label_enrollment_guide_not_google_based_can_factory_reset_suggestion',
          ),
        );
      } else {
        setPageIndex('alternativeEnrollment');
        setContextPayload(contextPayload);
        setAlternativeEnrollmentModeText(
          t('label_enrollment_guide_os_version_below_alternative_suggestion'),
        );
      }
    } else if (
      page === 'alternativeEnrollment' &&
      values.value === t('label_alternativeOptionOneEnrollmentGuide')
    ) {
      contextPayload.data.alternativeEnrollment = values.value;
      setPageIndex('alternativeEnrollmentMode');
      setContextPayload(contextPayload);
      setAlternativeEnrollmentModeSuggestionText(
        t(
          'label_enrollment_guide_os_version_five_or_above_alternative_suggestion',
        ),
      );
    } else if (
      page === 'alternativeEnrollment' &&
      values.value === t('label_alternativeOptionTwoEnrollmentGuide')
    ) {
      contextPayload.data.alternativeEnrollment = values.value;
      setPageIndex('alternativeEnrollmentMode');
      setContextPayload(contextPayload);
      setAlternativeEnrollmentModeSuggestionText(
        t(
          'label_enrollment_guide_os_version_seven_and_above_alternative_suggestion',
        ),
      );
    } else if (page === 'deviceFactoryRest' && values.value === t('label_no')) {
      contextPayload.data.deviceFactoryRest = values.value;
      setPageIndex('alternativeEnrollment');
      setContextPayload(contextPayload);
      setAlternativeEnrollmentModeText(
        t('label_enrollment_guide_cannot_factory_reset_suggestion'),
      );
    } else if (page === 'alternativeEnrollmentMode') {
      contextPayload.data.alternativeEnrollmentSuggestion = values.value;
      setPageIndex('feedBack');
      setContextPayload(contextPayload);
    }
  };

  const triggerBackBtn = (page, prevPage) => {
    contextPayload.payload.pop();
    if (page === 'deviceOwner') {
      setPageIndex('mainGuide');
    } else if (page === 'employee') {
      setPageIndex('deviceOwner');
    } else if (page === 'enrollmentMode') {
      if (prevPage === 'dlp') {
        setPageIndex('dlp');
      } else if (prevPage === 'employee') {
        setPageIndex('employee');
      } else if (prevPage === 'inFieldDevices') {
        setPageIndex('inFieldDevices');
      } else if (prevPage === 'osVersion') {
        setPageIndex('osVersion');
      } else if (prevPage === 'alternativeEnrollment') {
        setPageIndex('alternativeEnrollment');
      }
    } else if (page === 'dlp') {
      setPageIndex('employee');
    } else if (page === 'feedBack') {
      setPageIndex(prevPage);
    } else if (page === 'workSeparation') {
      setPageIndex('deviceOwner');
    } else if (page === 'inFieldDevices') {
      setPageIndex('workSeparation');
    } else if (page === 'googleBasedInbox') {
      setPageIndex('workSeparation');
    } else if (page === 'deviceFactoryRest') {
      setPageIndex('googleBasedInbox');
    } else if (page === 'osVersion') {
      setPageIndex('deviceFactoryRest');
    } else if (page === 'alternativeEnrollment') {
      if (prevPage === 'osVersion') {
        setPageIndex('osVersion');
      } else if (prevPage === 'deviceFactoryRest') {
        setPageIndex('deviceFactoryRest');
      }
    } else if (page === 'alternativeEnrollmentMode') {
      setPageIndex('alternativeEnrollment');
    }
  };

  const sendEmailToSupportGroup = () => {
    let enrolmentGuide =
      'Details of User<br /><br />' +
      'Tenant Domain: ' +
      config.userDomain +
      '<br />' +
      'Logged in User: ' +
      config.user +
      '<br /><br />' +
      'Enrollment Guide Questions &amp; Answers<br /><br />';

    for (const key in contextPayload.payload) {
      let obj = contextPayload.payload[key];
      if (obj.key === 'alternativeEnrollment') {
        enrolmentGuide =
          enrolmentGuide + 'Suggested Enrollment Mode' + '<br />';
        enrolmentGuide =
          enrolmentGuide + obj.alternativeEnrollment + '<br />' + '<br />';
        enrolmentGuide = enrolmentGuide + obj.title + '<br />';
        enrolmentGuide = enrolmentGuide + obj.value + '<br />' + '<br />';
      } else {
        enrolmentGuide = enrolmentGuide + obj.title + '<br />';
        enrolmentGuide = enrolmentGuide + obj.value + '<br />' + '<br />';
      }
    }

    axios
      .post(
        window.location.origin +
          config.serverConfig.invoker.uri +
          config.serverConfig.invoker.deviceMgt +
          '/devices/enrollment/guide',
        enrolmentGuide,
      )
      .then(res => {
        notification.success({
          message: t('api_successMsg'),
          duration: 4,
          description: t('api_completeEnrollmentGuide'),
        });
      })
      .catch(error => {
        onCancelModel();
        console.log(error);
        handleApiError(error, t('api_enrollmentGuideError'), t);
      });
    onCancelModel();
  };

  const onCancelModel = e => {
    setPageIndex('deviceOwner');
    setContextPayload({ payload: [], data: {} });
    props.hideSelfGuidedModal();
  };

  return (
    <Authorized
      scope={['perm:devices:enrollment-guide:view']}
      yes={
        <EnrollmentGuideProvider value={contextPayload}>
          <div>
            <div>
              <Modal
                destroyOnClose={true}
                title={t('label_enrollment_guide_title')}
                open={props.showSelfGuidedModal}
                onCancel={onCancelModel}
                footer={null}
              >
                <div style={{ marginTop: 20 }}>
                  <div>
                    <HtmlComments
                      permission={'/permission/admin/device-mgt/metadata/view'}
                    />
                    {pageIndex === 'deviceOwner' ? (
                      <DeviceOwner
                        callback={createPayload}
                        callBackBackTrigger={triggerBackBtn}
                      />
                    ) : null}
                    {pageIndex === 'employee' ? (
                      <Employee
                        callback={createPayload}
                        callBackBackTrigger={triggerBackBtn}
                      />
                    ) : null}
                    {pageIndex === 'enrollmentMode' ? (
                      <EnrollmentMode
                        prevPageIndex={prevPageIndex}
                        callback={createPayload}
                        callBackBackTrigger={triggerBackBtn}
                        enrolmentModeText={enrolmentModeText}
                      />
                    ) : null}
                    {pageIndex === 'dlp' ? (
                      <DataSeparation
                        callback={createPayload}
                        callBackBackTrigger={triggerBackBtn}
                      />
                    ) : null}
                    {pageIndex === 'feedBack' ? (
                      <Feedback
                        isAlternativeEnrollmentMode={
                          isAlternativeEnrollmentMode
                        }
                        prevPageIndex={prevPageIndex}
                        callback={createPayload}
                        callBackBackTrigger={triggerBackBtn}
                      />
                    ) : null}
                    {pageIndex === 'workSeparation' ? (
                      <Employer
                        callback={createPayload}
                        callBackBackTrigger={triggerBackBtn}
                      />
                    ) : null}
                    {pageIndex === 'inFieldDevices' ? (
                      <InFieldDevices
                        callback={createPayload}
                        callBackBackTrigger={triggerBackBtn}
                      />
                    ) : null}
                    {pageIndex === 'googleBasedInbox' ? (
                      <GoogleBasedInbox
                        callback={createPayload}
                        callBackBackTrigger={triggerBackBtn}
                      />
                    ) : null}
                    {pageIndex === 'deviceFactoryRest' ? (
                      <DeviceFactoryReset
                        factoryResetUnavailable={factoryResetUnavailable}
                        callback={createPayload}
                        callBackBackTrigger={triggerBackBtn}
                      />
                    ) : null}
                    {pageIndex === 'osVersion' ? (
                      <OsVersion
                        callback={createPayload}
                        callBackBackTrigger={triggerBackBtn}
                      />
                    ) : null}
                    {pageIndex === 'alternativeEnrollment' ? (
                      <AlternativeEnrollment
                        prevPageIndex={prevPageIndex}
                        callback={createPayload}
                        callBackBackTrigger={triggerBackBtn}
                        alternativeEnrollmentModeText={
                          alternativeEnrollmentModeText
                        }
                      />
                    ) : null}
                    {pageIndex === 'alternativeEnrollmentMode' ? (
                      <AlternativeEnrollmentSuggestion
                        prevPageIndex={prevPageIndex}
                        callback={createPayload}
                        callBackBackTrigger={triggerBackBtn}
                        alternativeEnrollmentModeText={
                          alternativeEnrollmentModeSuggestionText
                        }
                      />
                    ) : null}
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </EnrollmentGuideProvider>
      }
      no={null}
    />
  );
};

export default withTranslation()(EnrollmentGuide);
