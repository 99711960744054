/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

export default function ModalActionButtons(props) {
  const { t } = useTranslation();
  function navigateBack() {
    props.navigateBack();
  }

  return (
    <div style={{ float: 'right' }}>
      <Button type="primary" onClick={navigateBack} style={{ marginRight: 30 }}>
        {t('label_back')}
      </Button>
      <Button type="primary" htmlType="submit">
        {t('label_next')}
      </Button>
    </div>
  );
}
