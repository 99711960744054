/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import { Card, Modal, Checkbox, Avatar } from 'antd';
import './../../style.css';
import APIModalContent from './components/APIModalContent';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

const colors = [
  '#f0932b',
  '#badc58',
  '#6ab04c',
  '#22a6b3',
  '#0abde3',
  '#e84393',
  '#9b59b6',
  '#eb4d4b',
  '#3498db',
  '#f9ca24',
];

class APICard extends React.Component {
  checkboxRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      modalVisible: false,
      modalData: {},
      contentPayloadArray: [],
      loading: false,
      fullContentArray: [],
    };
  }

  valuesArray = [];

  componentDidMount() {
    const { itemData } = this.props;

    // fullContentItem is a clone of the object which passed to Content component,
    // Content component is used to build whats inside in the modal
    let fullContentItem = { ...itemData.apiTable.card };

    // fullContentArray is a state variable which contains the default values of all app modals,
    // in the format of payload
    let fullContentArray = [];

    // contentItem is a single app modal content in the format of paylad
    let contentItem = {};
    // subContentItem represesnt a single sub content item of the subcontents array of fullContentItem
    let subContentItem = {};

    // To convert fullContentItem to the payload format, we have to loop through it and assign each value to each key
    fullContentItem.items.forEach(item => {
      contentItem[item.key] = item.value;
    });

    // Loop through all the subcontents and assign it as a json string to the relevent subcontent key in contentItem
    fullContentItem.subContents.forEach(subContent => {
      subContent.items.forEach(item => {
        subContentItem[item.key] = item.value;
      });
      contentItem[subContent.key] = JSON.stringify(subContentItem);
    });

    // Push those default values which are in payload format to fullContentArray which represents all apps.
    for (let i = 0; i < this.props.itemCount; i++) {
      fullContentArray.push(contentItem);
    }

    // itemData.value is the payload which sent to device. but it doesnt tally with the indexes of the apps,
    // but each object in itemData.value array has an attribute called index which represents the
    // particuler index of the given app.
    // So to make itemData.value array compatilble with app indexes, loop through itemData.value and add them to
    // relevant index of a seperate array called valuesArray
    if (itemData.value !== '') {
      itemData.value.forEach(payloadItem => {
        this.valuesArray[payloadItem.index] = payloadItem;
        if (payloadItem.index === this.props.index) {
          this.checkboxRef.current.state.checked = true;
        }
      });
    }
    this.setState({ fullContentArray });
  }

  onModalOk = () => {
    this.setState({ modalVisible: false });
  };

  onModalCancel = () => {
    if (!this.props.disabled) {
      this.checkboxRef.current.state.checked = false;
    }
    this.setState({ modalVisible: false });
  };

  setUpdatedContentData = content => {
    this.props.callback(true, content, this.props.index);
  };

  onCheckboxChange = e => {
    const { itemData } = this.props;
    let modalData = { ...itemData.apiTable.card };

    let modalDataItems = [];

    modalData.apiContents.forEach(modalDataItem => {
      modalDataItem.item.value = _.get(
        this.props.apiItem,
        modalDataItem.apiContentKey,
      );
      modalDataItems.push(modalDataItem.item);
    });

    let items = [];
    // valuesArray has all the updated apps which sent to the payload.
    // This part is used to check if the clicked app is in the values array and if its there,
    // get updated values from valuesArray and set them to item objects and push into items array
    // if app is not in the payload, get default values from fullContentArray and set them to item objects
    // and push into items array
    if (this.valuesArray[this.props.index]) {
      modalData.items.forEach(modalDataItem => {
        Object.keys(this.valuesArray[this.props.index]).forEach(valueKey => {
          if (modalDataItem.key === valueKey) {
            modalDataItem.value = this.valuesArray[this.props.index][valueKey];
            items.push(modalDataItem);
          }
        });
      });
      modalData.subContents.forEach(subContent => {
        subContent.items.forEach(item => {
          item.value = JSON.parse(
            this.valuesArray[this.props.index][subContent.key],
          )[item.key];
        });
      });
    } else {
      modalData.items.forEach(modalDataItem => {
        Object.keys(this.state.fullContentArray[this.props.index]).forEach(
          valueKey => {
            if (modalDataItem.key === valueKey) {
              modalDataItem.value = this.state.fullContentArray[
                this.props.index
              ][valueKey];
              items.push(modalDataItem);
            }
          },
        );
      });
      modalData.subContents.forEach(subContent => {
        subContent.items.forEach(item => {
          item.value = JSON.parse(
            this.state.fullContentArray[this.props.index][subContent.key],
          )[item.key];
        });
      });
    }
    modalData.items = modalDataItems.concat(items);

    if (e.target.checked) {
      this.setState({
        modalVisible: true,
        modalData: modalData,
      });
    } else {
      this.setState({
        modalData: modalData,
      });
    }

    let contentPayload = {};
    let subContentPayload = {};

    // Set each items and sub content items to contentPayload object in the format of payload
    // and sent through the callback
    modalData.items.forEach(item => {
      contentPayload[item.key] = item.value;
    });
    modalData.subContents.forEach(subContent => {
      subContent.items.forEach(item => {
        subContentPayload[item.key] = item.value;
      });
      contentPayload[subContent.key] = JSON.stringify(subContentPayload);
    });

    // Set a new attribute called index in the contentPayload object to identify the relevant index of the app
    // Because null values of the payload array is removed in the parent component so it becomes unmatched with
    // app indexes, so this index is used to identify which index this app is belonged to
    contentPayload.index = this.props.index;
    this.props.callback(e.target.checked, contentPayload, this.props.index);
  };

  onCardClick = () => {
    if (this.props.disabled) {
      const { itemData } = this.props;
      let modalData = { ...itemData.apiTable.card };

      let modalDataItems = [];

      modalData.apiContents.forEach(modalDataItem => {
        modalDataItem.item.value = _.get(
          this.props.apiItem,
          modalDataItem.apiContentKey,
        );
        modalDataItems.push(modalDataItem.item);
      });

      let items = [];
      // valuesArray has all the updated apps which sent to the payload.
      // This part is used to check if the clicked app is in the values array and if its there,
      // get updated values from valuesArray and set them to item objects and push into items array
      // if app is not in the payload, get default values from fullContentArray and set them to item objects
      // and push into items array
      if (this.valuesArray[this.props.index]) {
        modalData.items.forEach(modalDataItem => {
          Object.keys(this.valuesArray[this.props.index]).forEach(valueKey => {
            if (modalDataItem.key === valueKey) {
              modalDataItem.value = this.valuesArray[this.props.index][
                valueKey
              ];
              items.push(modalDataItem);
            }
          });
        });
        modalData.subContents.forEach(subContent => {
          subContent.items.forEach(item => {
            item.value = JSON.parse(
              this.valuesArray[this.props.index][subContent.key],
            )[item.key];
          });
        });
      } else {
        modalData.items.forEach(modalDataItem => {
          Object.keys(this.state.fullContentArray[this.props.index]).forEach(
            valueKey => {
              if (modalDataItem.key === valueKey) {
                modalDataItem.value = this.state.fullContentArray[
                  this.props.index
                ][valueKey];
                items.push(modalDataItem);
              }
            },
          );
        });
        modalData.subContents.forEach(subContent => {
          subContent.items.forEach(item => {
            item.value = JSON.parse(
              this.state.fullContentArray[this.props.index][subContent.key],
            )[item.key];
          });
        });
      }
      modalData.items = modalDataItems.concat(items);

      this.setState({
        modalVisible: true,
        modalData: modalData,
      });

      let contentPayload = {};
      let subContentPayload = {};

      // Set each items and sub content items to contentPayload object in the format of payload
      // and sent through the callback
      modalData.items.forEach(item => {
        contentPayload[item.key] = item.value;
      });
      modalData.subContents.forEach(subContent => {
        subContent.items.forEach(item => {
          subContentPayload[item.key] = item.value;
        });
        contentPayload[subContent.key] = JSON.stringify(subContentPayload);
      });
    }
  };

  render() {
    const { modalData, modalVisible } = this.state;
    const { item, index, itemData, t } = this.props;
    return (
      <div>
        <Card
          className="appCard"
          key={index}
          hoverable
          onClick={this.onCardClick}
        >
          <div>
            <Checkbox
              ref={this.checkboxRef}
              onChange={this.onCheckboxChange}
              disabled={this.props.disabled}
            />
            <Avatar
              style={{
                backgroundColor:
                  colors[item[itemData.apiTable.card.title].length % 10],
                marginRight: 10,
                marginLeft: 10,
              }}
              shape="square"
              size="large"
              className="avatar"
            >
              {item[itemData.apiTable.card.title].charAt(0).toUpperCase()}
            </Avatar>

            {item[itemData.apiTable.card.title]}
          </div>
        </Card>
        <Modal
          title={t('label_appSettings')}
          open={modalVisible}
          onOk={this.onModalOk}
          okText={t('label_ok')}
          onCancel={this.onModalCancel}
          maskClosable={false}
        >
          <APIModalContent
            modalData={modalData}
            index={index}
            itemData={itemData}
            callback={content => this.setUpdatedContentData(content)}
            disabled={this.props.disabled}
          />
        </Modal>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(APICard));
