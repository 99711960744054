/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Input, Form, Upload } from 'antd';
import { withConfigContext } from '../../../../../../../../../../components/ConfigContext';
import { InboxOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 19 },
  },
};

const buttonItemLayout = {
  wrapperCol: { span: 24, offset: 19 },
};

class CertificateForm extends React.Component {
  formRef = React.createRef();

  cert = '';
  certName = '';

  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      isModalVisible: false,
      loading: false,
      FileList: [],
    };
  }

  componentDidMount() {
    if (this.props.values) {
      this.formRef.current.setFieldsValue(this.props.values);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let params = new URL(window.location).searchParams;
    let name = params.get('add-new-certificate');
    if (JSON.parse(name) && prevState === this.state) {
      this.formRef.current.resetFields();
      this.setState({ FileList: [] });
    }
  }

  onFinish = values => {
    const cert = this.cert
      .replace('-----BEGIN CERTIFICATE-----', '')
      .replace('-----END CERTIFICATE-----', '');
    values.pem = cert;
    this.props.callback(values);
  };

  beforeUpload = file => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = e => {
      this.cert = e.target.result;
    };
    // Prevent upload
    return false;
  };

  handleFileChange = info => {
    let fileList = [...info.fileList];

    // Limit the number of uploaded files
    fileList = fileList.slice(-1);
    this.setState({ FileList: fileList });
    if (fileList.length === 0) {
      this.certName = '';
      this.cert = '';
    } else {
      this.certName = fileList[0].name;
    }
  };

  render() {
    const { t } = this.props;
    const { loading, FileList } = this.state;
    return (
      <div>
        <Form
          {...formItemLayout}
          name="addCertificate"
          onFinish={this.onFinish}
          ref={this.formRef}
        >
          <Form.Item
            name="serial"
            label={t('label_serial')}
            rules={[
              {
                required: true,
                message: t('label_certificate_number'),
              },
            ]}
          >
            <Input placeholder={t('label_certificate_number_placeholder')} />
          </Form.Item>
          <Form.Item
            label={t('label_certificate')}
            rules={[
              {
                required: true,
                message: t('label_certificate_cannot_empty'),
              },
            ]}
            name="pem"
          >
            <Upload.Dragger
              beforeUpload={this.beforeUpload}
              onChange={this.handleFileChange}
              fileList={FileList}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                {t('uploadCertificate_infoTxt')}
              </p>
            </Upload.Dragger>
          </Form.Item>
          <Form.Item {...buttonItemLayout}>
            <Button loading={loading} type="primary" htmlType="submit">
              {t('label_save')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(CertificateForm));
