import React from 'react';
import styles from './styles.module.css';

const MeterDetails = ({ record }) => {
    console.log(record);
    return (
        <div className={styles.sectionContainer}>
            <div className={styles.column}>
                <p>Total Import-PV1 (kWh) : {record.totalImportPV1}</p>
                <p>Total Export-PV1 (kWh) : {record.totalExportPV1}</p>
                <p>TR1 Total Import (kWh) : {record.TR1TotalImportkWh}</p>
                <p>TR2 Total Import (kWh) : {record.TR2TotalImportkWh}</p>
                <p>TR3 Total Import (kWh) : {record.TR3TotalImportkWh}</p>
                <p>TR1 Total Export (kWh) : {record.TR1TotalExportkWh}</p>
                <p>TR2 Total Export (kWh) : {record.TR2TotalExportkWh}</p>
                <p>TR3 Total Export (kWh) : {record.TR3TotalExportkWh}</p>
                <p>TR1 Total Import - PV1 (kWh) : {record.TR1TotalImportPV1kWh}</p>
                <p>TR2 Total Import - PV1 (kWh) : {record.TR2TotalImportPV1kWh}</p>
                <p>TR3 Total Import - PV1 (kWh) : {record.TR3TotalImportPV1kWh}</p>
                <p>TR1 Total Export - PV1 (kWh) : {record.TR1TotalExportPV1kWh}</p>
                <p>TR2 Total Export - PV1 (kWh) : {record.TR2TotalExportPV1kWh}</p>
                <p>TR3 Total Export - PV1 (kWh) : {record.TR3TotalExportPV1kWh}</p>
                <p>Phase A Current A: {record.phaseACurrentA}</p>
                <p>Phase A Voltage V: {record.phaseAVoltageV}</p>
                <p>Phase B Current (A) : {record.phaseBCurrentA}</p>
                <p>Phase B Voltage (V) : {record.phaseBVoltageV}</p>
                <p>Phase C Current (A) : {record.phaseCCurrentA}</p>
                <p>Phase C Voltage (V) : {record.phaseCVoltageV}</p>
                <p>Frequency (Hz) : {record.frequency}</p>
            </div>
            <div className={styles.column}>
                <p>Facility : {record.branch}</p>
                <p>Depot : {record.depot}</p>
                <p>Substation ID : {record.substationId}</p>
                <p>Sub Type ID : {record.subTypeId}</p>
                <p>Node Type : {record.nodeType}</p>
                <p>Category : {record.category}</p>
                <p>Has E-Billing : {record.hasEbilling ? "Yes" : "No"}</p>
                <p>Has Export : {record.hasExport ? "Yes" : "No"}</p>
            </div>
        </div>
    );
};

export default MeterDetails;
