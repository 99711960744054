/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {
    DesktopOutlined,
    EditOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import { Steps, Button, message } from 'antd';
import TimeAgo from 'javascript-time-ago';
// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';
import { useTranslation } from 'react-i18next';
import {RoutesContext} from "../../../../../../index";
import CreateDeviceType from "./components/CreateDeviceType";
import './styles.module.css';
import styles from './styles.module.css';
import CreateDeviceEvent from "./components/CreateDeviceEvent";


const { Step } = Steps;

const AddDeviceTypeForm = (props) => {
    const { t } = useTranslation();
    const [deviceTypeData, setDeviceTypeData] = useState({});


    console.log(props.modalVisibility)
    const [current, setCurrent] = useState(0);


    useEffect(() => {
        console.log(props.modalVisibility)
        if(props.modalVisibility) {
            setCurrent(0)
        }
    },[props.modalVisibility])

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };
    // const items = steps.map(item => ({ key: item.title, title: item.title }));

    const deviceTypeCallback = (deviceTypeValues) => {
        setCurrent(current + 1);
        setDeviceTypeData(deviceTypeValues)
    }

    const skipCallback = () => {
        props.closeModalCallback()
        setCurrent(0)
    }

    const steps = [
        {
            title: t('machine_type_create'),
            content: <CreateDeviceType modalVisibility={props.modalVisibility} deviceTypeCallback={deviceTypeCallback}/>,
        },
        {
            title: t('machine_type_create_events'),
            content: <CreateDeviceEvent modalVisibility={props.modalVisibility} deviceTypeData={deviceTypeData} skipCallback={skipCallback}/>,
        },
    ];



    return (
        <>
            <Steps style={{padding: 20}} current={current}>
                {steps.map(item => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>
            <div style={{marginTop:30}}>{steps[current].content}</div>
        </>
    );
};

export default AddDeviceTypeForm;
