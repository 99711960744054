/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Alert, Input, Row, Tooltip, Typography, Divider } from 'antd';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import CustomAlerts from '../CustomAlerts';
import axios from 'axios';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import styles from './styles.module.css';
import { withTranslation } from 'react-i18next';
const { Title, Text } = Typography;

const buildRulesArray = (itemData, t) => {
  let rules = [];
  if (itemData.input.hasOwnProperty('rules')) {
    itemData.input.rules.forEach(rule => {
      rules.push({
        pattern: new RegExp(`${rule.logic}`),
        message: rule.hasOwnProperty('validationMessage')
          ? t(`${rule.validationMessage}`)
          : null,
      });
    });
  }
  return rules;
};

class CustomInput extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const config = this.props.context;
    const { itemData } = this.props;
    if (itemData.input.apiUrl) {
      const apiUrl =
        window.location.origin +
        config.serverConfig.invoker.uri +
        itemData.input.apiUrl;
      this.fetchData(apiUrl);
    }
  }

  // get nested object value when get key as string
  objectByString = (object, arrayPath) => {
    const { itemData } = this.props;
    let paths = arrayPath.split('/');
    let queryParam;
    if (paths[paths.length - 1].includes('?')) {
      let paramData = paths[paths.length - 1].split('?');
      paths[paths.length - 1] = paramData[0];
      queryParam = paramData[1];
    }

    for (let i = 0, n = paths.length; i < n; ++i) {
      if (paths[i] in object) {
        object = object[paths[i]];
      } else {
        return;
      }
    }

    if (queryParam) {
      queryParam = queryParam.split('=');
      let dataIndex = object.findIndex(x => x[queryParam[0]] === queryParam[1]);
      if (dataIndex >= 0) {
        return object[dataIndex][itemData.input.dataKey];
      }
      return null;
    }
    return object;
  };

  // fetch data from api
  fetchData = apiUrl => {
    // send request to the invokerss
    const item = { ...this.props.itemData };
    const { t } = this.props;
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          const data = res.data.data;
          item.value = this.objectByString(data, item.input.arrayPath);
          this.props.callback(item);
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_data') }),
          t,
        );
        this.setState({ loading: false });
      });
  };

  onBlur = e => {
    const item = { ...this.props.itemData };
    item.value = e.target.value;

    this.formRef.current
      .validateFields()
      .then(values => {
        if (item.hasOwnProperty('error')) {
          delete item.error;
        }
      })
      .catch(errorInfo => {
        item.error = {};
        errorInfo.errorFields.forEach(err => {
          item.error[err.name] = err.errors;
        });
      });
    this.props.callback(item);
  };

  render() {
    const { itemData, defaultValue, t } = this.props;
    const { value } = itemData;
    return (
      <div>
        {itemData.divider && (
          <Divider plain>
            <Text type="secondary">{t(itemData.divider)}</Text>
          </Divider>
        )}
        {itemData.subTitle && <Title level={4}> {t(itemData.subTitle)} </Title>}
        <CustomAlerts notifications={itemData.notifications} />
        <div
          className={styles.inputContainer}
          style={{
            display: itemData.isHidden ? 'none' : 'block',
          }}
        >
          <Row>
            <span
              style={{
                display: itemData.label && itemData.tooltip ? 'inline' : 'none',
              }}
            >
              {t(`${itemData.label}`)}&nbsp;
              {itemData.tooltip && (
                <Tooltip
                  title={
                    <div>
                      <div>{t(`${itemData.tooltip}`)}</div>
                      <div style={{ textAlign: 'right' }}>
                        {itemData.docLink && (
                          <a
                            onClick={() => {
                              window.open(itemData.docLink, '_blank');
                            }}
                          >
                            {' '}
                            {t('label_SeeMoreDetails')}
                          </a>
                        )}
                      </div>
                    </div>
                  }
                  placement="right"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              )}
            </span>
          </Row>

          <Form ref={this.formRef}>
            <Form.Item
              name={itemData.key}
              initialValue={defaultValue ? defaultValue : value}
              rules={buildRulesArray(itemData, t)}
            >
              <Input
                key={itemData.key}
                placeholder={t(itemData.input.placeholderValue)}
                disabled={this.props.disabled}
                onBlur={this.onBlur}
                type={itemData.input.type}
              />
            </Form.Item>

            <Alert
              style={{
                marginTop: 5,
                display:
                  value === '' && itemData.error && itemData.error.required
                    ? 'flex'
                    : 'none',
              }}
              message={
                value === '' &&
                itemData.error &&
                itemData.error.required &&
                itemData.error.required
              }
              type="error"
            />
          </Form>
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(CustomInput));
