/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import { RoutesContext } from '../../index';
import React, { useState, useEffect, useContext } from 'react';
import { Col, Result, Row, Select, Tooltip, Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { withConfigContext } from '../../../../components/ConfigContext';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import axios from 'axios';
import { subDays } from 'date-fns';
import IframeWrapper from '../../components/IframeWrapper';
import DateRangeDropdown from "../../components/common/DateRangeDropdown";
import { getUnixTime, determineRefreshParam } from "../../../../services/utils/functions";
import configs from "../../../../../public/conf/config.json";
import HtmlComments from "../../../../components/ReactHtmlComments/HtmlComments";
import {ExportOutlined, ReloadOutlined} from "@ant-design/icons";
import { handleApiError } from "../../../../services/utils/errorHandler";

const { Option } = Select;

const DeviceView = (props) => {
    const config = props.context;
    const { t } = props;
    const routesContext = useContext(RoutesContext);
    const rightNow = new Date();
    const [range, setRange] = useState({
        from: getUnixTime(subDays(rightNow, 2)),
        to: getUnixTime(rightNow),
    });
    const [isLoading, setIsLoading] = useState(true);
    const [meterSerial, setSerialValue] = useState('');
    const [panelUrl1, setPanelUrl1] = useState('');
    const [panelUrl2, setPanelUrl2] = useState('');
    const [panelUrl3, setPanelUrl3] = useState('');
    const [refreshRate, setRefreshRate] = useState('30s');
    const [deviceName, setDeviceName] = useState('');
    const queryParams = new URLSearchParams(window.location.search);
    const fromParam = queryParams.get('from');
    const toParam = queryParams.get('to');

    useEffect(() => {
        routesContext.setCurrentRoute('allDeviceView');

        const refreshParam = determineRefreshParam(range.from, range.to, refreshRate);
        const queryParams = `&var-device=${meterSerial}&from=${range.from}&to=${range.to}${refreshParam}`;

        setPanelUrl1(`${configs.grafanaConfig.deviceView.lastReadingInfo}${queryParams}`);
        setPanelUrl2(`${configs.grafanaConfig.deviceView.liveDeviceData}${queryParams}`);
        setPanelUrl3(`${configs.grafanaConfig.deviceView.deviceLocation}${queryParams}`);

    }, [meterSerial, range.from, range.to, refreshRate]);

    useEffect(() => {
        handleDeviceInfoSearch();
    }, []);

    useEffect(() => {

        if (fromParam && toParam) {
            setRange({
                from: parseInt(fromParam),
                to: parseInt(toParam)
            });
        }
    }, []);

    useEffect(() => {

        const path = window.location.pathname;
        const parts = path.split('/');
        const idIndex = parts.indexOf('atmm') + 1;
        const paramId = parts[idIndex];
        setSerialValue(paramId);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (meterSerial) {
            handleDeviceInfoSearch(meterSerial);
        }
    }, [meterSerial]);

    const navigateDeviceLocationView = () => {
        let path = '../atmm/' + meterSerial + '/location?from=' + range.from + '&to=' + range.to;
        window.open(path, '_blank', 'noopener,noreferrer');
    };

    const handleDeviceInfoSearch = (paramId) => {
        if (paramId) {
            axios({
                method: 'GET',
                url: window.location.origin + config.serverConfig.invoker.uri + `/device-mgt/v1.0/devices/atmm/${paramId}`,
            })
                .then((response) => {
                    const deviceName = response.data.data.name;
                    setIsLoading(false);
                    setDeviceName(deviceName);
                })
                .catch((error) => {
                    handleApiError(
                        error,
                        t('api_loadError', { label: t('label_device') }),
                        t,
                    );
                });
        }
    }

    const routes = (appName, deviceName) => [
        {
            path: `/${appName}`,
            breadcrumbName: t('home_menu_home'),
        },
        {
            path: `/${appName}/device/inventory`,
            breadcrumbName: t('home_menu_devices'),
        },
        {
            breadcrumbName: deviceName,
        },
    ];

    return (
        <div>
            <HtmlComments permission={'/permission/admin/device-mgt/devices/owning-device/view'} />
            <Authorized
                scope={['dm:devices:view']}
                yes={
                    <div>
                        <PageHeader className={styles.pageHeader} ghost={false}>
                            <div className={styles.positioning}>
                                <Row justify="space-between" align="middle" gutter={8}>
                                    <Col>
                                        <BreadcrumbComponent breadcrumbList={routes(config.appName, deviceName)} />
                                    </Col>

                                    <Col>
                                        <Row align="middle" gutter={[8, 8]}>
                                            <Col>
                                                <DateRangeDropdown
                                                    alignDropdown="right"
                                                    getRange={(data) => setRange(data)}
                                                    // disabled={fromParam && toParam} // Disable if 'from' and 'to' are present
                                                    closeOnBackground
                                                />
                                            </Col>
                                            <Col className={styles.refreshRatePicker}>
                                                <Tooltip title="Refresh rate">
                                                    <Select
                                                        value={range.from !== 'now-5m' && range.from !== 'now-30m' ? 'off' : refreshRate}
                                                        className={styles.refreshRateSelect}
                                                        onChange={(value) => setRefreshRate(value)}
                                                        disabled={range.from !== 'now-5m' && range.from !== 'now-30m'}
                                                    >
                                                        <Option value="5s">5s</Option>
                                                        <Option value="10s">10s</Option>
                                                        <Option value="30s">30s</Option>
                                                        <Option value="1m">1m</Option>
                                                        <Option value="off">Off</Option>
                                                    </Select>
                                                </Tooltip>
                                                <ReloadOutlined className={styles.refreshIcon}/>
                                            </Col>
                                            <Col>
                                                <Button
                                                    onClick={navigateDeviceLocationView}
                                                    style={{marginRight: 10}}
                                                    type="primary"
                                                    icon={<ExportOutlined/>}>
                                                    {t('label_trackLocation')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </PageHeader>
                        {meterSerial !== '' && (
                            <div className={styles.content}>
                                <Row>
                                    <Col xl={24} xs={24}>
                                        <div className={styles.iFrameContainer}>
                                            <IframeWrapper
                                                iframeSrc={`${panelUrl1}`}
                                                height={'100px'}
                                            />
                                        </div>
                                    </Col>
                                    <Col xl={18} xs={24}>
                                        <div className={styles.iFrameContainer}>
                                            <IframeWrapper
                                                iframeSrc={`${panelUrl2}`}
                                                height={'600px'}
                                            />
                                        </div>
                                    </Col>
                                    <Col xl={6} xs={24}>
                                        <div className={styles.iFrameContainer}>
                                            <IframeWrapper
                                                iframeSrc={`${panelUrl3}`}
                                                height={'600px'}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </div>
                }
                no={
                    <Result
                        status="403"
                        title={t('noPerm_accessPageTitle')}
                        subTitle={t('noPerm_contactSysAdmin')}
                    />
                }
            />
        </div>
    );
};

export default withConfigContext(withTranslation()(DeviceView));
