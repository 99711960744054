import { setTextSpaceAllocation } from "./setTextSpaceAllocation";

export function createReportTable(reportTable, doc, pdfConfig, param) {
    if (reportTable.table && reportTable.table.length) {
        if (reportTable.table[0].length != reportTable.header.length)
            throw Error("Table header size and table column size are mismatched.");
    }

    var tdWidth = (doc.getPageWidth() - 20) / reportTable.header.length;

    //TD WIDTH
    if (reportTable.header.length > 2) { //add style for 2 or more columns
        const customColumnNo = reportTable.header.map(x => x?.style?.width || 0).filter(x => x > 0);
        let customWidthOfAllColumns = customColumnNo.reduce((a, b) => a + b, 0);
        tdWidth = (doc.getPageWidth() - 20 - customWidthOfAllColumns) / (reportTable.header.length - customColumnNo.length);
    }
    //end TD WIDTH
    //TABLE HEADER BORDER
    var addTableHeaderBorder = () => {
        pdfConfig.totalHeight += 2;
        const lineHeight = 7;
        let startWidth = 0;
        for (let i = 0; i < reportTable.header.length; i++) {
            const currentTdWidth = reportTable.header[i]?.style?.width || tdWidth;
            if (i === 0) doc.rect(10, pdfConfig.totalHeight, currentTdWidth, lineHeight);
            else {
                const previousTdWidth = reportTable.header[i - 1]?.style?.width || tdWidth;
                const widthToUse = currentTdWidth == previousTdWidth ? currentTdWidth : previousTdWidth;
                startWidth += widthToUse;
                doc.rect(startWidth + 10, pdfConfig.totalHeight, currentTdWidth, lineHeight);
            }
        }
        pdfConfig.totalHeight -= 2;
    };
    //end TABLE HEADER BORDER
    //TABLE BODY BORDER
    var addTableBodyBorder = (lineHeight) => {
        let startWidth = 0;
        for (let i = 0; i < reportTable.header.length; i++) {
            const currentTdWidth = reportTable.header[i]?.style?.width || tdWidth;
            if (i === 0) doc.rect(10, pdfConfig.totalHeight, currentTdWidth, lineHeight);
            else {
                const previousTdWidth = reportTable.header[i - 1]?.style?.width || tdWidth;
                const widthToUse = currentTdWidth == previousTdWidth ? currentTdWidth : previousTdWidth;
                startWidth += widthToUse;
                doc.rect(startWidth + 10, pdfConfig.totalHeight, currentTdWidth, lineHeight);
            }
        }
    };
    //end TABLE BODY BORDER
    //TABLE HEADER
    var addTableHeader = () => {
        if (reportTable.headerBorder) addTableHeaderBorder();

        pdfConfig.totalHeight += pdfConfig.subLineHeight;
        doc.setTextColor(pdfConfig.colorBlack);
        doc.setFontSize(pdfConfig.fieldTextSize);

        if (reportTable.headerBorder) {
            doc.setDrawColor(pdfConfig.colorGray);
        }
        pdfConfig.totalHeight += 2;

        let startWidth = 0;
        reportTable.header.forEach(function (row, index) {
            if (index == 0) doc.text(row.title, 11, pdfConfig.totalHeight);
            else {
                const currentTdWidth = row?.style?.width || tdWidth;
                const previousTdWidth = reportTable.header[index - 1]?.style?.width || tdWidth;
                const widthToUse = currentTdWidth == previousTdWidth ? currentTdWidth : previousTdWidth;
                startWidth += widthToUse;
                doc.text(row.title, startWidth + 11, pdfConfig.totalHeight);
            }
        });

        pdfConfig.totalHeight += pdfConfig.subLineHeight - 1;
        doc.setTextColor(pdfConfig.colorGray);
    };
    //end TABLE HEADER
    addTableHeader();

    //#region TABLE BODY
    var tableBodyLength = reportTable.table.length;
    reportTable.table.forEach(function (row, index) {
        doc.line(10, pdfConfig.totalHeight, pdfConfig.docWidth - 10, pdfConfig.totalHeight);

        //get max height for the current row
        var getRowsHeight = function () {
            let rowsHeight = [];
            row.forEach(function (rr, index) {
                const widthToUse = reportTable.header[index]?.style?.width || tdWidth;

                let item = setTextSpaceAllocation(doc, rr.toString(), widthToUse - 1); //minus 1, to fix the padding issue between borders
                rowsHeight.push(item.height);
            });

            return rowsHeight;
        };

        var maxHeight = Math.max(...getRowsHeight());

        //body borders
        if (reportTable.tableBodyBorder) addTableBodyBorder(maxHeight + 1);

        let startWidth = 0;
        row.forEach(function (rr, index) {
            const widthToUse = reportTable.header[index]?.style?.width || tdWidth;
            let item = setTextSpaceAllocation(doc, rr.toString(), widthToUse - 1); //minus 1, to fix the padding issue between borders

            if (index == 0) doc.text(item.text, 11, pdfConfig.totalHeight + 4);
            else {
                const currentTdWidth = rr?.style?.width || tdWidth;
                const previousTdWidth = reportTable.header[index - 1]?.style?.width || tdWidth;
                const widthToUse = currentTdWidth == previousTdWidth ? currentTdWidth : previousTdWidth;
                startWidth += widthToUse;
                doc.text(item.text, 11 + startWidth, pdfConfig.totalHeight + 4);
            }
        });

        pdfConfig.totalHeight += maxHeight - 4;

        //td border height
        pdfConfig.totalHeight += 5;

        //pre-increase totalHeight to check the height based on next row
        if (index + 1 < tableBodyLength) pdfConfig.totalHeight += maxHeight;

        if (param.orientationLandscape &&
            (pdfConfig.totalHeight > 185 ||
                (pdfConfig.totalHeight > 178 && doc.getNumberOfPages() > 1))) {
            doc.addPage();
            pdfConfig.totalHeight = 10;
            if (index + 1 < tableBodyLength) addTableHeader();
        }

        if (!param.orientationLandscape &&
            (pdfConfig.totalHeight > 265 ||
                (pdfConfig.totalHeight > 255 && doc.getNumberOfPages() > 1))) {
            doc.addPage();
            pdfConfig.totalHeight = 10;
            if (index + 1 < tableBodyLength) addTableHeader();
            //else
            //totalHeight += pdfConfig.subLineHeight + 2 + pdfConfig.subLineHeight - 1; //same as in addtableHeader
        }

        //reset the height that was increased to check the next row
        if (index + 1 < tableBodyLength && pdfConfig.totalHeight > 30)
            // check if new page
            pdfConfig.totalHeight -= maxHeight;
    });
    //doc.line(10, totalHeight, docWidth - 10, totalHeight); //if we want to show the last table line 
    //#endregion
    return {
        doc: doc,
        pdfConfig: pdfConfig
    };
}
