/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Modal, Tabs } from 'antd';
import TimeAgo from 'javascript-time-ago';
// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import AssignDevices from '../AssignDevices';
import AlreadyAssignedDevices from '../AlreadyAssignedDevices';
import { withTranslation } from 'react-i18next';

const { TabPane } = Tabs;

class GroupDevicesModal extends React.Component {
  constructor(props) {
    super(props);
    TimeAgo.addLocale(en);
    this.state = {
      visible: false,
      refreshTable: false,
      activeTab: '1',
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.refreshTable !== prevProps.refreshTable) {
      this.setState({ refreshTable: this.props.refreshTable });
    }
  }

  openDrawer = () => {
    this.setState({
      visible: true,
      activeTab: '1',
    });
    this.props.refreshDeviceCallback(true);
  };

  handleModalCancel = () => {
    this.setState({
      visible: false,
    });
    this.props.refreshDeviceCallback(true);
  };

  onChangeTabs = e => {
    this.setState({ activeTab: e });
  };

  refreshDeviceCallback = status => {
    this.props.refreshDeviceCallback(false);
    this.setState({ visible: false, refreshTable: true });
  };

  onAssignDevicesCallback = () => {
    this.setState({ activeTab: '2' });
  };

  render() {
    const { visible, activeTab } = this.state;
    const { t } = this.props;
    return (
      <div>
        <Button type="link" size="small" onClick={this.openDrawer}>
          {t('home_menu_devices')}
        </Button>
        <Modal
          title={t('title_groupDevices')}
          width="80%"
          open={visible}
          onCancel={this.handleModalCancel}
          maskClosable={false}
          footer={[
            <Button key="cancel" onClick={this.handleModalCancel}>
              {t('label_cancel')}
            </Button>,
            <Button key="ok" type="primary" onClick={this.handleModalCancel}>
              {t('label_ok')}
            </Button>,
          ]}
        >
          <Tabs
            defaultActiveKey={activeTab}
            activeKey={activeTab}
            onChange={this.onChangeTabs}
          >
            <TabPane tab={t('label_alreadyAssignedDevices')} key="1">
              <AlreadyAssignedDevices
                groupData={this.props.groupData}
                refreshTable={this.state.refreshTable}
                refreshDeviceCallback={status =>
                  this.refreshDeviceCallback(status)
                }
                assignDevicesCallback={this.onAssignDevicesCallback}
              />
            </TabPane>
            <TabPane tab={t('label_assignNewDevices')} key="2">
              <AssignDevices
                groupData={this.props.groupData}
                refreshDeviceCallback={status =>
                  this.refreshDeviceCallback(status)
                }
              />
            </TabPane>
          </Tabs>
        </Modal>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(GroupDevicesModal));
