/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 *  Licensed under the Entgra Commercial License,
 *  Version 1.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *
 *  Unless required by applicable law or agreed to in writing,
 *  software distributed under the License is distributed on an
 *  "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 *  KIND, either express or implied.  See the License for the
 *  specific language governing permissions and limitations
 *  under the License.
 *
 *  You may obtain a copy of the License at
 *  https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Menu,
  Dropdown,
  Button,
  Divider,
  Select,
  Card,
  Spin,
  Alert,
  Space,
  Checkbox,
  Row,
  Col,
  Form,
} from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import axios from 'axios';
import QRCode from 'qrcode.react';
import QRPlaceholder from '../../../../../../../../../public/images/qr-code.png';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import WiFiConfiguration from './components/WiFiConfiguration';
import { isAuthorized } from '../../../../../../../../services/utils/authorizationHandler';
import Authorized from '../../../../../../../../components/Authorized';
import QRExpTime from './components/QRExpTime';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { Trans, withTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { Link } from 'react-router-dom';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6, offset: 2 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 9 },
  },
};

class QREnrollment extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    TimeAgo.addLocale(en);
    this.lastFetchId = 0;
    this.state = {
      isSelected: false,
      loading: false,
      payload: {},
      isPayloadValid: false,
      invalidPlatformConfigs: [],
      optionalPlatformConfigs: [],
      viewWiFiConfigModal: false,
      isWifiConfigLoaded: false,
      wifiConfig: null,
      data: [],
      value: [],
      fetching: false,
      userSelected: false,
      enrollmentModeSelected: false,
      enrollCurrentUserModeSelected: false,
    };
    const { t } = this.props;
    const items = [this.getItem(t('label_configureWifi'), 'wifi-config')];
    this.menu = <Menu onClick={this.handleMenuClick} items={items} />;
    this.fetchUser = debounce(this.fetchUser, 800);
  }

  componentDidMount() {
    if (isAuthorized(this.config.scopes, ['perm:metadata:view'])) {
      this.getWifiConfigs();
    }
  }

  handleMenuClick = e => {
    if (e.key === 'wifi-config') {
      this.setState({ viewWiFiConfigModal: true });
    }
  };

  handleWiFiConfigModalActions = (modalVisibility, response) => {
    this.setState({
      viewWiFiConfigModal: modalVisibility,
      wifiConfig: response,
    });
  };

  // fetch data from api
  generateQRCode = () => {
    const { deviceType, t } = this.props;
    this.setState({ loading: true });

    let apiUrl =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      '/device-mgt/' +
      deviceType +
      '/v1.0/configuration/enrollment-qr-config/' +
      this.enrollmentMode +
      '?username=' +
      this.username;

    // send request to the invoker
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          if (res.data.data.invalidPlatformConfigs.length !== 0) {
            this.setState({
              invalidPlatformConfigs: res.data.data.invalidPlatformConfigs,
              optionalPlatformConfigs: res.data.data.optionalPlatformConfigs,
              isPayloadValid: false,
              loading: false,
              isSelected: true,
            });
          } else {
            this.setState({
              loading: false,
              payload: res.data.data.payload,
              isSelected: true,
              invalidPlatformConfigs: res.data.data.invalidPlatformConfigs,
              optionalPlatformConfigs: res.data.data.optionalPlatformConfigs,
              isPayloadValid: true,
            });
          }
        }
      })
      .catch(error => {
        handleApiError(error, t('api_getQRCodePayloadError'), t);
        this.setState({ loading: false });
      });
  };

  enrollmentMode = '';
  onSelectEnrollmentMode = value => {
    this.enrollmentMode = value;
    this.setState({ enrollmentModeSelected: true });
  };

  onSelectUserMode = e => {
    let value;
    if (e.target.checked) {
      let username = this.config.user.split('@')[0];
      value = { value: username };
      this.username = username;
    } else {
      value = [];
    }
    this.setState({
      value,
      enrollCurrentUserModeSelected: e.target.checked,
    });
  };

  fetchUser = value => {
    const config = this.props.context;
    const { t } = this.props;
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ data: [], fetching: true });
    axios
      .get(
        window.location.origin +
          config.serverConfig.invoker.uri +
          config.serverConfig.invoker.deviceMgt +
          '/users/search/usernames?filter=' +
          value +
          '&domain=all',
      )
      .then(res => {
        if (res.status === 200) {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return;
          }
          const userData = res.data.data;
          const data = userData.map(user => ({
            text: user.username,
            value: user.username,
          }));
          this.setState({ data, fetching: false });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_users') }),
          t,
        );
        if (
          Object.prototype.hasOwnProperty.call(error, 'response') &&
          error.response.status === 403
        ) {
          this.setState({
            isForbidden: true,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      });
  };

  username = '';

  handleChange = value => {
    this.username = value.value;
    this.setState({
      value,
      data: [],
      fetching: false,
      userSelected: true,
    });
  };

  getWifiConfigs = () => {
    const { t } = this.props;
    const metakey = this.config.user.includes('@')
      ? this.config.user.replace('@', '_') + '_WiFi_Configuration'
      : this.config.user + '_carbon.super_WiFi_Configuration';
    axios
      .get(
        window.location.origin +
          this.config.serverConfig.invoker.uri +
          '/device-mgt/v1.0/metadata/' +
          metakey,
      )
      .then(res => {
        if (res.status === 200) {
          const data = res.data.data;
          this.setState({
            isWifiConfigLoaded: true,
            wifiConfig: JSON.parse(data.metaValue),
          });
        }
      })
      .catch(error => {
        if (error.hasOwnProperty('response') && error.response.status !== 404) {
          handleApiError(
            error,
            t('api_loadError', { label: t('label_wifiConfiguration') }),
            t,
          );
        }
        this.setState({ isWifiConfigLoaded: true, wifiConfig: null });
      });
  };

  onClick = () => {
    this.props.goBack();
  };

  getItem = (label, key, icon, children, className, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
      className,
    };
  };

  render() {
    const {
      payload,
      isSelected,
      loading,
      invalidPlatformConfigs,
      optionalPlatformConfigs,
      isPayloadValid,
      value,
      fetching,
      data,
      userSelected,
      enrollmentModeSelected,
      enrollCurrentUserModeSelected,
    } = this.state;
    const { t } = this.props;
    const invalidConfigsList = invalidPlatformConfigs.map((item, index) => (
      <li key={index}>{item}</li>
    ));
    const optionalConfigsList = optionalPlatformConfigs.map((item, index) => (
      <li key={index}>{item}</li>
    ));

    return (
      <div>
        <Divider style={{ marginBottom: 40 }}>
          {t('generateQR_infoTxt')}
        </Divider>
        <Row>
          <Col span={10} offset={2} xs={20} sm={20} md={20} lg={10}>
            <div style={{ marginBottom: 10 }}>
              <p
                style={{
                  marginBottom: 20,
                  fontSize: 14,
                  textAlign: 'justify',
                  marginRight: 40,
                }}
              >
                <Trans i18nKey="android_qr_agent_description">
                  {t('android_qr_agent_description')}
                </Trans>
              </p>
              <div style={{ textAlign: 'center', marginBottom: 10 }}>
                <Checkbox onChange={this.onSelectUserMode}>
                  {t('label_enrollUnderLoggedInUser')}
                </Checkbox>
              </div>
              <Form {...formItemLayout}>
                <Form.Item
                  label={t('label_username')}
                  style={{ textAlign: 'left' }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    style={{ width: '100%', textAlign: 'center' }}
                    showSearch
                    labelInValue
                    value={value}
                    placeholder={t('form_enterUsername')}
                    notFoundContent={fetching ? <Spin size="small" /> : null}
                    filterOption={false}
                    onSearch={this.fetchUser}
                    onChange={this.handleChange}
                    disabled={enrollCurrentUserModeSelected}
                  >
                    {data.map(d => (
                      <Option key={d.value}>{d.text}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t('label_ownership')}
                  style={{ textAlign: 'left' }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  initialValue={'None'}
                >
                  <Select
                    showSearch
                    style={{ width: '100%', textAlign: 'center' }}
                    placeholder={t('form_selectDeviceOwnership')}
                    optionFilterProp="children"
                    onChange={this.onSelectEnrollmentMode}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option key={'byod'} value={'BYOD'}>
                      {t('label_byod')}
                    </Option>
                    <Option key={'cope'} value={'COPE'}>
                      {t('label_cope')}
                    </Option>
                    <Option key={'COSU'} value={'COSU'}>
                      {t('label_cosu')}
                    </Option>
                    <Option key={'WORK PROFILE'} value={'WORK_PROFILE'}>
                      {t('label_workProfile')}
                    </Option>
                    <Option
                      key={'GOOGLE_ENTERPRISE'}
                      value={'GOOGLE_ENTERPRISE'}
                    >
                      {t('label_googleWorkProfile')}
                    </Option>
                    <Option key={'FULLY_MANAGED'} value={'FULLY_MANAGED'}>
                      {t('label_fullyManaged')}
                    </Option>
                    <Option key={'DEDICATED_DEVICE'} value={'DEDICATED_DEVICE'}>
                      {t('label_dedicatedDevice')}
                    </Option>
                  </Select>
                </Form.Item>
              </Form>
              <div style={{ marginBottom: 10 }}>
                <span>
                  <HtmlComments
                    permission={'/permission/admin/device-mgt/metadata/view'}
                  />
                  <Authorized
                    scope={['perm:metadata:view']}
                    yes={
                      <>
                        <WiFiConfiguration
                          viewWiFiConfig={this.state.viewWiFiConfigModal}
                          wifiConfig={this.state.wifiConfig}
                          callback={this.handleWiFiConfigModalActions}
                        />

                        <span>
                          {this.state.wifiConfig === null ? (
                            <Alert
                              message={t('configureWifi_infoTxt')}
                              type="info"
                              action={
                                <Space direction="vertical">
                                  <Button
                                    size="small"
                                    type="primary"
                                    disabled={
                                      !isAuthorized(this.config.scopes, [
                                        'perm:metadata:create',
                                      ])
                                    }
                                    onClick={() => {
                                      this.setState({
                                        viewWiFiConfigModal: true,
                                      });
                                    }}
                                  >
                                    <HtmlComments
                                      permission={
                                        '/permission/admin/device-mgt/metadata/create'
                                      }
                                    />
                                    {t('label_configureWifi')}
                                  </Button>
                                </Space>
                              }
                            />
                          ) : (
                            <Dropdown overlay={this.menu}>
                              <Button
                                type="primary"
                                icon={<SettingOutlined />}
                                style={{ marginRight: 10 }}
                              />
                            </Dropdown>
                          )}
                        </span>
                      </>
                    }
                    no={
                      <Alert
                        message={t('noPerm_wifiConfigurations')}
                        banner
                        style={{ marginBottom: 15 }}
                      />
                    }
                  />
                </span>
                <Button
                  loading={loading}
                  disabled={
                    !(
                      (userSelected || enrollCurrentUserModeSelected) &&
                      enrollmentModeSelected
                    )
                  }
                  type={'primary'}
                  style={{ width: '30%', marginTop: 10 }}
                  onClick={this.generateQRCode}
                >
                  {t('label_generateQR')}
                </Button>
              </div>
            </div>
          </Col>
          <Divider type="vertical" style={{ height: 370 }} />
          <Col span={10} offset={0} xs={24} sm={24} md={24} lg={10}>
            <Spin spinning={loading}>
              <div
                style={{
                  display:
                    isSelected && isPayloadValid ? 'inline-block' : 'none',
                  marginTop: 30,
                }}
              >
                <QRExpTime
                  payload={
                    payload[
                      'android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE'
                    ]
                  }
                />
                <Card hoverable>
                  <QRCode size={300} value={JSON.stringify(payload)} />
                </Card>
              </div>
              <br />
              <div
                style={{
                  display:
                    isSelected && !isPayloadValid ? 'inline-block' : 'none',
                  textAlign: 'left',
                  marginTop: 50,
                }}
              >
                <Alert
                  message={t('QRGenerate_errorTxt')}
                  description={
                    <div>
                      <p>{t('platformConfigs_infoTxt')}.</p>
                      <b>{t('label_mandatory')}</b>
                      <ul>{invalidConfigsList}</ul>
                      <b
                        style={{
                          display:
                            optionalPlatformConfigs.length > 0
                              ? 'inline'
                              : 'none',
                        }}
                      >
                        {t('label_optional')}
                      </b>
                      <ul>{optionalConfigsList}</ul>
                    </div>
                  }
                  type="warning"
                  showIcon
                />
              </div>
              <div
                style={{
                  display:
                    isSelected &&
                    isPayloadValid &&
                    optionalPlatformConfigs.length > 0
                      ? 'inline-block'
                      : 'none',
                  textAlign: 'left',
                  marginTop: 10,
                }}
              >
                <Alert
                  message={t('optionalPlatformConfigs_infoTxt')}
                  description={
                    <div>
                      <p>{t('QREnrollment_infoTxt')}</p>
                      <ul>{optionalConfigsList}</ul>
                    </div>
                  }
                  type="warning"
                  showIcon
                />
              </div>
              <div
                style={{
                  display: !isSelected ? 'inline-block' : 'none',
                }}
              >
                <Card hoverable>
                  <img
                    height={300}
                    width={300}
                    src={QRPlaceholder}
                    alt={t('label_QRPlaceholder')}
                  />
                </Card>
              </div>
            </Spin>
          </Col>
        </Row>
        <div style={{ textAlign: 'right', marginTop: 50, marginRight: 200 }}>
          <Button
            style={{ marginRight: 10 }}
            type="default"
            size={'default'}
            onClick={this.onClick}
          >
            {t('label_back')}
          </Button>
          <Link to={`/${this.props.appName}/devices/`} key="btnFinish">
            <Button type="primary">{t('label_finish')}</Button>
          </Link>
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(QREnrollment));
