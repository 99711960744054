/*
 * Copyright (C) 2021. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 *  Licensed under the Entgra Commercial License,
 *  Version 1.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *
 *  Unless required by applicable law or agreed to in writing,
 *  software distributed under the License is distributed on an
 *  "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 *  KIND, either express or implied.  See the License for the
 *  specific language governing permissions and limitations
 *  under the License.
 *
 *  You may obtain a copy of the License at
 *  https://entgra.io/licenses/entgra-commercial/1.0
 */

// Generic function for updating the platform config value for all the three platforms (android, ios, windows)
export function updatePlatformConfigurationValues(values, platformConfigDatas) {
  let newConfigs = [];
  let existingConfig = false;
  let platformConfigData = platformConfigDatas;

  for (let key of Object.keys(values)) {
    for (let [index, platformConfig] of platformConfigData.entries()) {
      if (
        platformConfig.name === key &&
        (typeof values[key] === 'boolean' || values[key])
      ) {
        platformConfig.value = values[key];
        existingConfig = true;
        break;
      }
      if (
        platformConfig.name === key &&
        typeof values[key] !== 'boolean' &&
        !values[key]
      ) {
        platformConfigData.splice(index, 1);
      }
    }
    if (!existingConfig && (typeof values[key] === 'boolean' || values[key])) {
      newConfigs.push({
        name: key,
        value: values[key],
      });
    }
    existingConfig = false;
  }

  platformConfigData.push(...newConfigs);
  return platformConfigData;
}
