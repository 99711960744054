/*
 * Copyright (C) 2021. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Modal, Input, Button } from 'antd';
import { withConfigContext } from '../../../../../../../../../../../../components/ConfigContext';
import { withTranslation } from 'react-i18next';

class TokenModal extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      isModalVisible: false,
    };
  }

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  handleOk = () => {
    this.props.tokenCallback(this.token);
    this.setState({ isModalVisible: false });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  token = '';

  onTokenChange = e => {
    this.token = e.target.value;
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <p>
          {this.props.labelText}{' '}
          <Button type="link" onClick={this.showModal}>
            {t('label_clickHere')}
          </Button>
        </p>
        <Modal
          title={t('label_setToken')}
          open={this.state.isModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          maskClosable={false}
        >
          <Input onBlur={this.onTokenChange} />
        </Modal>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(TokenModal));
