/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Result } from 'antd';
import { withTranslation } from 'react-i18next';
import { withConfigContext } from '../../../../../../../../../../components/ConfigContext';
import { isAuthorized } from '../../../../../../../../../../services/utils/authorizationHandler';
import ThemeConfigurationForm from './components/ThemeConfigurationForm';

class Theme extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t, context } = this.props;
    return (
      <>
        {isAuthorized(context.scopes, [
          'perm:whitelabel:view',
          'perm:whitelabel:update',
        ]) ? (
          <ThemeConfigurationForm />
        ) : (
          <Result
            status="403"
            title={t('label_perm_theme_update')}
            subTitle={t('label_please_contact_admin')}
          />
        )}
      </>
    );
  }
}

export default withConfigContext(withTranslation()(Theme));
