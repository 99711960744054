/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Alert,
  Typography,
  notification,
  Popconfirm,
  Table,
  Tooltip,
} from 'antd';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { DeleteOutlined } from '@ant-design/icons';
import { isAuthorized } from '../../../../../../../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { handleApiError } from '../../../../../../../../../../../../services/utils/errorHandler';
import { withConfigContext } from '../../../../../../../../../../../../components/ConfigContext';

const { Text } = Typography;

function EvaluateTenantTable(props) {
  const { t } = props;
  const config = props.config;
  const data = props.metaData;
  const loading = props.loading;
  const renderInfo = (id, row) => (
    <Tooltip placement="bottom" title={t('label_deleteEvaluateTenant')}>
      <Popconfirm
        placement="top"
        title={t('label_areYouSure')}
        onConfirm={() => {
          deleteMetaEntry(row);
        }}
        okText={t('label_ok')}
        cancelText={t('label_cancel')}
      >
        <a>
          <Text type="danger">
            <DeleteOutlined />
          </Text>
        </a>
      </Popconfirm>
    </Tooltip>
  );
  const columns = [
    {
      title: 'Tenant Domain',
      dataIndex: 'tenantDomain',
      width: 100,
    },
    {
      title: 'Actions',
      dataIndex: 'tenantId',
      width: 100,
      render: renderInfo,
    },
  ];

  const deleteMetaEntry = rowData => {
    let index = data.findIndex(function(obj) {
      if (obj.tenantDomain === rowData.tenantDomain) {
        return obj.tenantDomain === rowData.tenantDomain;
      }
    });
    data.splice(index, 1);
    const requestPayload = {
      dataType: 'STRING',
      metaKey: 'EVALUATE_TENANTS',
      metaValue: JSON.stringify(data),
    };
    let apiUrl =
      window.location.origin +
      config.serverConfig.invoker.uri +
      '/device-mgt/v1.0/metadata/';
    axios
      .put(apiUrl, requestPayload, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(res => {
        props.callback();
        notification.success({
          message: t('api_successMsg'),
          duration: 4,
          description: t('api_deleteEvaluateTenant'),
        });
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_deleteError', { label: t('label_Evaluate_Tenant') }),
          t,
        );
      });
  };

  return (
    <>
      {data[0] !== null && (
        <div>
          {!isAuthorized(config.scopes, [
            'perm:metadata:view',
            'perm:metadata:create',
            'perm:metadata:update',
          ]) && (
            <div>
              <HtmlComments
                permission={
                  '/permission/admin/device-mgt/metadata/view OR\n' +
                  '/permission/admin/device-mgt/metadata/create OR\n' +
                  '/permission/admin/device-mgt/metadata/update'
                }
              />
              <Alert
                message={t('noPerm_manageEvaluateTenants')}
                banner
                style={{ marginBottom: 15 }}
              />
            </div>
          )}
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            bordered
          />
        </div>
      )}
    </>
  );
}

export default withConfigContext(withTranslation()(EvaluateTenantTable));
