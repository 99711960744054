/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import styles from './styles.module.css';

const AddNewDeviceForm = ({onFinish, deviceTypeProperties, onClickBack, t}) => {
  const labelCol = { span: 4 };
  const wrapperCol = { span: 18 };

  const StaticFormItems = () => {
    return (
      <div>
        <Form.Item
          label={t("label_deviceName")}
          name="deviceName"
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          rules={[
            {
              required: true,
              message: t("form_requiredDeviceName")
            }
          ]}
        >
          <Input placeholder={t("form_enterDeviceName")} />
        </Form.Item>
        <Form.Item
          label={t("label_deviceIdentifier")}
          name="deviceIdentifier"
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          rules={[
            {
              required: true,
              message: t("form_requiredDeviceIdentifier")
            }
          ]}
        >
          <Input placeholder={t("form_enterDeviceIdentifier")} />
        </Form.Item>
        <Form.Item
          label={t("label_deviceDescription")}
          name="deviceDescription"
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          rules={[
            {
              required: true,
              message: t("form_requiredDescription")
            }
          ]}
        >
          <Input placeholder={t("form_enterDeviceDescription")} />
        </Form.Item>
      </div>
    );
  };

  const DevicePropertiesFormItems = () => {
    if (deviceTypeProperties.deviceType.properties) {
      return deviceTypeProperties.deviceType.properties.map(property => {
        return (
          <Form.Item
            key={property}
            label={formatWord(property)}
            name={property}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            rules={[
              {
                required: true,
                message: t("form_requiredDeviceProperty")
              }
            ]}
          >
            <Input placeholder={t(`Enter ${formatWord(property)}`)} />
          </Form.Item>
        );
      });
    } else {
      return null;
    }
  };

  const formatWord = word => {
    const parts = word.split(/(?=[A-Z])/);
    const capitalizedParts = parts.map(
      part => part.charAt(0).toUpperCase() + part.slice(1)
    );
    return capitalizedParts.join(" ");
  };

  const handleSubmit = values => {
    onFinish(values);
  };

  return (
    <Form name="AddNewDeviceForm" onFinish={handleSubmit}>
      <Row>
        <Col span={24}>
          <StaticFormItems />
          <DevicePropertiesFormItems />
          <Form.Item className={styles.buttonRow}>
            <Row>
              <div>
                <Button
                  type="default"
                  htmlType="button"
                  onClick={e => onClickBack(e)}
                >
                  {t("label_back")}
                </Button>
              </div>
              <div className={styles.button}>
                <Button type="primary" htmlType="submit">
                  {t("label_save")}
                </Button>
              </div>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default withTranslation()(withConfigContext(AddNewDeviceForm));
