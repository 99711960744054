/*
 * Copyright (C) 2018 - 2024 Entgra (Pvt) Ltd, Inc - All Rights Reserved.
 *
 * Unauthorised copying/redistribution of this file, via any medium is strictly prohibited.
 *
 * Licensed under the Entgra Commercial License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *       https://entgra.io/licenses/entgra-commercial/1.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import React from 'react';
import { Button, Collapse, Result, Space, Spin, Tooltip, notification } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import axios from 'axios';
import { handleApiError } from '../../../../services/utils/errorHandler';
import { RoutesContext } from '../../index';
import { withConfigContext } from '../../../../components/ConfigContext';
import 'moment-timezone';
import AlertsTable from './AlertsTable';
import styles from './styles.module.css';
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import Filter from '../../components/Filter';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from "../../../../components/BreadCrumb";
import HtmlComments from "../../../../components/ReactHtmlComments/HtmlComments";
import Authorized from "../../../../components/Authorized";
import AcknowledgeAlert from './components/AcknowledgeAlert';
import moment from "moment";
import NormalTempIMG from '../../../../../public/images/alerts/NormalTemp.png';
import HighTempIMG from '../../../../../public/images/alerts/HighTemp.png';
import DoorOpenIMG from '../../../../../public/images/alerts/DoorOpen.png';
import DoorCloseIMG from '../../../../../public/images/alerts/DoorClose.png';
import GridPowerYesIMG from '../../../../../public/images/alerts/GridPowerYes.png';
import GridPowerNoIMG from '../../../../../public/images/alerts/GridPowerNo.png';
import MotionNoIMG from '../../../../../public/images/alerts/MotionNo.png';
import MotionYesIMG from '../../../../../public/images/alerts/MotionYes.png';
import { Link } from "react-router-dom";
import beepSound from '../../../../../public/sounds/sound.mp3';
import { ALERT_TYPES } from "../../../../constant/TableData";

const { Panel } = Collapse;

const routes = (appName, t) => {
    return [
        {
            path: `/${appName}`,
            breadcrumbName: t('home_menu_home'),
        },
        {
            breadcrumbName: t('home_menu_device_alert_overview'),
        },
    ];
};

class DeviceAlertOverview extends React.Component {
    filterValues = '';

    constructor(props) {
        super(props);
        this.config = this.props.context;
        this.state = {
            alertsForTable: [], // State for table display
            alertsForNotification: [], // State for notifications
            count: 0,
            pagination: {},
            loading: false,
            selectedRows: [],
            searchFields: [],
            exportDataList: [],
            isModalVisible: false,
            currentDeviceName: null,
            currentAlertType: null,
            currentAlerts: [],
            isSearchPanelOpen: false,  // State to track search panel status
            notifiedDevices: [],
            lastFetchedTimestamp: moment().subtract(12, 'hours').valueOf(), //State to track last fetched timestamp
            initialFetchCompleted: false,
        };
        const { t } = this.props;
        this.columns = [
            {
                title: t('label_deviceName'),
                dataIndex: 'deviceName',
                key: 'deviceName',
                render: (deviceName, record) => (
                    <Link to={`/facility-monitoring/device/view/atmm/${record.deviceId}`} target="_blank">{deviceName}</Link>
                ),
            },
            {
                title: t('label_door'),
                key: 'doorAndAction',
                width: 150,
                render: (text, row) => this.renderAlertColumn(t, row, DoorCloseIMG, DoorOpenIMG, 'ackDoorAlertsCount', 'doorAlertCount', 'door')
            },
            {
                title: t('label_temperature'),
                key: 'temperatureAndAction',
                width: 150,
                render: (text, row) => this.renderAlertColumn(t, row, NormalTempIMG, HighTempIMG, 'ackTemperatureAlertsCount', 'temperatureAlertCount', 'temperature')
            },
            {
                title: t('label_gridPower'),
                key: 'gridPowerAndAction',
                width: 150,
                render: (text, row) => this.renderAlertColumn(t, row, GridPowerYesIMG, GridPowerNoIMG, 'ackGridPowerAlertsCount', 'gridPowerAlertsCount', 'gridPower')
            },
            {
                title: t('label_shock'),
                key: 'shockAndAction',
                width: 150,
                render: (text, row) => this.renderAlertColumn(t, row, MotionNoIMG, MotionYesIMG, 'ackShockAlertsCount', 'shockAlertsCount', 'shock')
            },
        ];
        this.beep = new Audio(beepSound);
    }

    renderAlertColumn = (t, row, imgYes, imgNo, ackCount, alertCount, alertType) => (
        <Space>
            {row[ackCount] === row[alertCount] || (row[ackCount] === 0 && row[alertCount] === 0) ? (
                <img className={styles.alertImg} src={imgYes} alt={`${alertType}Yes`} />
            ) : (
                <img className={styles.alertImg} src={imgNo} alt={`${alertType}No`} />
            )}
            <span
                style={{
                    color: row[ackCount] === row[alertCount] || (row[ackCount] === 0 && row[alertCount] === 0) ? '#026602' : '#ff3838',
                    textDecoration: 'underline'
                }}
                onClick={() => this.handleAcknowledgeClick(row.deviceName, alertType, row[`${alertType}Alerts`])}
            >
                {row[ackCount]}/{row[alertCount]}
            </span>
        </Space>
    );

    handleAcknowledgeClick = (deviceName, alertType, alerts) => {
        this.setState({
            currentDeviceName: deviceName,
            currentAlertType: alertType,
            currentAlerts: alerts,
            isModalVisible: true,
        });
    };

    componentDidMount() {
        this.context.setCurrentRoute('allDeviceAlertOverview');
        const { t } = this.props;
        this.setState({ loading: true });

        const fields = [
            {
                name: 'alertLevels',
                label: t('label_level'),
                placeholder: t('label_level'),
                type: 'multipleSelect',
                values: Object.keys(this.config.alertLevels).map(
                    key => this.config.alertLevels[key],
                ),
            },
            {
                name: 'device',
                label: t('label_deviceName'),
                placeholder: t('label_deviceName'),
                type: 'input',
            },
            {
                name: 'alertedDate',
                label: t('label_alertedDate'),
                placeholder: t('label_alertedDate'),
                type: 'calender',
            },
        ];

        this.setState({
            searchFields: fields,
        });
        this.fetchAlertsForNotification();
        this.fetchAlertsForTable();
        this.periodicAlertCheck();
        // Preload the audio
        this.beep.load();
    }

    fetchAlertFilters = (params, values) => {
        let alertFilters = {}, dayTo, dayFrom;

        if (values.hasOwnProperty('alertLevels')) {
            alertFilters.level = values.alertLevels;
        }
        if (values.hasOwnProperty('device') && values.device.trim() !== '') {
            alertFilters.device = values.device.trim();
        }
        if (values.hasOwnProperty('alertedDate') && values.alertedDate.length === 2) {
            dayFrom = values.alertedDate[0];
            dayTo = values.alertedDate[1];

            const millisecondsFrom = dayFrom.valueOf();
            const millisecondsTo = dayTo.valueOf();

            alertFilters.from = millisecondsFrom;
            alertFilters.to = millisecondsTo;
        }

        this.filterValues = alertFilters;
        this.setState({ filterValues: alertFilters, pagination: { current: 1 } }, () => {        // this.getTableData({ page: 1 }, alertFilters);
        this.fetchAlertsForTable();
        });
    };

    fetchAlertsForNotification = () => {
        const alertFilters = {
            from: moment().subtract(30, 'seconds').valueOf(),
            to: moment().valueOf(),
        };
        this.getTableData({}, alertFilters, true);
    };

    fetchAlertsForTable = () => {
        const { filterValues } = this.state;
        this.getTableData({}, filterValues, false);
    };

    getTableData = (params = {}, alertFilters = {}, forNotification = false) => {
        const { t } = this.props;
        //this.setState({ loading: true });
        const defaultStartTime = moment().subtract(12, 'hours').valueOf();
        const defaultEndTime = moment().valueOf();

        if (!forNotification) {
            alertFilters.from = alertFilters.from || defaultStartTime;
            alertFilters.to = alertFilters.to || defaultEndTime;
        }

        const currentPage = params.page || 1;
        let limit = params.results || 15;
        const offset = limit * (currentPage - 1);

        const extraParams = {
            offset: offset,
            limit: limit,
        };

        const urlParams = new URLSearchParams(extraParams);
        // Add alert filters
        Object.entries(alertFilters).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach(val => urlParams.append(key, val));
            } else {
                urlParams.append(key, value);
            }
        });

        const apiUrl = `${window.location.origin}${this.config.serverConfig.invoker.uri}/factory-mgt/v1.0/alerts/grouped-by-device?${urlParams}`;

        axios
            .get(apiUrl)
            .then(res => {
                if (res.status === 200) {
                    const pagination = { ...this.state.pagination };
                    const alertGroups = res.data.data.groupedAlerts;

                    const aggregatedAlerts = alertGroups.map(group => {
                        const doorAlerts = group.alerts.filter(alert => alert.sensor === 'Door');
                        const ackDoorAlerts = doorAlerts.filter(alert => alert.ackInfo && alert.ackInfo.ackStatus === true);
                        const temperatureAlerts = group.alerts.filter(alert => alert.sensor === 'Temperature');
                        const ackTemperatureAlerts = temperatureAlerts.filter(alert => alert.ackInfo && alert.ackInfo.ackStatus === true);
                        const gridPowerAlerts = group.alerts.filter(alert => alert.sensor === 'GridPower');
                        const ackGridPowerAlerts = gridPowerAlerts.filter(alert => alert.ackInfo && alert.ackInfo.ackStatus === true);
                        const shockAlerts = group.alerts.filter(alert => alert.sensor === 'Shock');
                        const ackShockAlerts = shockAlerts.filter(alert => alert.ackInfo && alert.ackInfo.ackStatus === true);

                        return {
                            deviceId: group.alerts[0].metaDeviceId,
                            deviceName: group.deviceName,
                            alertCount: group.alerts.length,
                            doorAlerts: doorAlerts,
                            ackDoorAlertsCount: ackDoorAlerts.length,
                            ackTemperatureAlertsCount: ackTemperatureAlerts.length,
                            ackGridPowerAlertsCount: ackGridPowerAlerts.length,
                            ackShockAlertsCount: ackShockAlerts.length,
                            temperatureAlerts: temperatureAlerts,
                            gridPowerAlerts: gridPowerAlerts,
                            doorAlertCount: doorAlerts.length,
                            temperatureAlertCount: temperatureAlerts.length,
                            gridPowerAlertsCount: gridPowerAlerts.length,
                            shockAlerts: shockAlerts,
                            shockAlertsCount: shockAlerts.length,
                        };
                    });
                    if (forNotification && this.state.initialFetchCompleted) {
                        this.setState({
                            alertsForNotification: aggregatedAlerts,
                            lastFetchedTimestamp: defaultEndTime,
                            loading: false,
                        });
                        this.checkForUnacknowledgedAlerts(aggregatedAlerts);
                    } else {
                        this.setState({
                            alertsForTable: aggregatedAlerts,
                            count: res.data.data.count,
                            pagination,
                            loading: false,
                            initialFetchCompleted: true,
                        });
                    }
                }
            })
            .catch(error => {
                handleApiError(error, t('api_getAlertsError'), t);
                this.setState({ loading: false });
            });
    };

    checkForUnacknowledgedAlerts = (alerts) => {
        alerts.forEach(alert => {
            const alertTypes = [
                { type: ALERT_TYPES.DOOR, alerts: alert.doorAlerts },
                { type: ALERT_TYPES.TEMPERATURE, alerts: alert.temperatureAlerts },
                { type: ALERT_TYPES.GRID_POWER, alerts: alert.gridPowerAlerts },
                { type: ALERT_TYPES.SHOCK, alerts: alert.shockAlerts }
            ];

            alertTypes.forEach(alertType => {
                if (alertType.alerts.some(a => !a.ackInfo.ackStatus)) {
                    this.playBeepSound();

                    notification.warning({
                        message: 'Unacknowledged Alerts',
                        description: (
                            <span>
                            Device: <span style={{color: '#04802d'}}>{alert.deviceName}</span> has unacknowledged {alertType.type} alerts.
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault(); // Prevent the default link behavior
                                    this.handleAcknowledgeClick(alert.deviceName, alertType.type, alertType.alerts); // Use the dynamic alert type and alerts
                                }}
                                style={{marginLeft: 10, color: '#1890ff'}}
                            >
                                View
                            </a>
                        </span>
                        ),
                        duration: 10,
                    });
                    // Add deviceId to notifiedDevices
                    this.setState(prevState => ({
                        notifiedDevices: [...prevState.notifiedDevices, alert.deviceId]
                    }));
                }
            });
        });
    };

    periodicAlertCheck = () => {
        this.alertCheckInterval = setInterval(() => {
            if (!this.state.isSearchPanelOpen) { // Check if search panel is closed
                this.fetchAlertsForNotification();
                this.fetchAlertsForTable();
            }
        }, 30000);
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        pager.pageSize = pagination.pageSize;
        this.setState({
            pagination: pager,
        });
        this.getTableData(
            {
                results: pagination.pageSize,
                page: pagination.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters,
            },
            this.filterValues,
        );
    };

    handleOk = () => {
        this.setState({ isModalVisible: false });
        this.fetchAlertsForTable();
    };

    handleCancel = () => {
        this.setState({ isModalVisible: false });
    };

    componentWillUnmount() {
        clearInterval(this.alertCheckInterval); // Clear interval when component unmounts
    }

    playBeepSound = () => {
        this.beep.play().catch(error => console.log('Audio playback error:', error));
    };

    render() {
        const { pagination, loading, searchFields, isModalVisible, currentDeviceName, currentAlerts } = this.state;
        const { t } = this.props;

        return (
            <div>
                <HtmlComments permission={'/permission/admin/factory-mgt/alerts/view'} />
                <Authorized
                    scope={['perm:factory:alerts:view']}
                    yes={
                        <div>
                            <PageHeader
                                className={styles.pageHeader}
                                ghost={false}
                                breadcrumb={
                                    <BreadcrumbComponent
                                        breadcrumbList={routes(this.config.appName, t)}
                                    />
                                }
                            />
                            <div className={styles.actionsContainer}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}>
                                    <div style={{flex: '1'}}>
                                        <Collapse
                                            bordered={false}
                                            expandIcon={() => <SearchOutlined/>}
                                            className={styles.searchCollapse}
                                            onChange={(key) => this.setState({isSearchPanelOpen: !!key.length})} // Update search panel state
                                            // defaultActiveKey={['1']} // search panel to be open
                                        >
                                            <Panel
                                                header={t('form_searchAlerts')}
                                                key="1"
                                                style={{borderColor: '#fff'}}
                                            >
                                                <Filter
                                                    filters={searchFields}
                                                    callback={(params, values) =>
                                                        this.fetchAlertFilters({}, values)
                                                    }
                                                />
                                            </Panel>
                                        </Collapse>
                                    </div>
                                    <div>
                                        <Tooltip
                                            placement="bottom"
                                            title={t('label_refreshDeviceInventory')}
                                            autoAdjustOverflow={true}
                                        >
                                            <Button
                                                type="link"
                                                shape="circle"
                                                icon={<ReloadOutlined/>}
                                                onClick={this.getTableData}
                                                size={'default'}
                                                style={{marginLeft: '15px'}}
                                            >
                                                {t('label_refresh')}
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.table}>
                                <div>
                                    {loading && (
                                        <div style={{textAlign: 'center', marginRight: '20px'}}>
                                            <Spin/>
                                        </div>
                                    )}
                                    {!loading && (
                                        <AlertsTable
                                            alerts={this.state.alertsForTable}
                                            columns={this.columns}
                                            pagination={pagination}
                                            loading={loading}
                                            count={this.state.count}
                                            handleTableChange={this.handleTableChange}
                                        />
                                    )}
                                </div>
                            </div>
                            {currentDeviceName && (
                                <AcknowledgeAlert
                                    visible={isModalVisible}
                                    onCancel={this.handleCancel}
                                    onVisibilityChange={visible => this.setState({isModalVisible: visible})}
                                    alert={currentAlerts}
                                    deviceName={currentDeviceName}
                                    callback={this.handleOk}
                                    className={styles.ackModal}
                                />
                            )}
                        </div>
                    }
                    no={
                        <Result
                            status="403"
                            title={t('noPerm_accessPageTitle')}
                            subTitle={t('noPerm_contactSysAdmin')}
                        />
                    }
                />
            </div>
        );
    }
}

DeviceAlertOverview.contextType = RoutesContext;
export default withConfigContext(withTranslation()(DeviceAlertOverview));
