/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { withConfigContext } from '../../../../../../../../../../components/ConfigContext';
import './../../../../style.css';
import Content from '../../../../../Content';

class APIModalContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      modalVisible: false,
      modalData: {},
      contentPayloadArray: [],
      loading: false,
    };
  }

  componentDidMount() {
    let modalData = { ...this.props.modalData };
    this.setState({ modalData: modalData });
  }

  // Callback of the Content component
  setUpdatedContentData = content => {
    let contentPayload = {};
    let subContentPayload = {};

    // Set each items and sub content items to contentPayload object in the format of payload
    // and sent through the callback
    content.items.forEach(item => {
      contentPayload[item.key] = item.value;
    });
    content.subContents.forEach(subContent => {
      subContent.items.forEach(item => {
        subContentPayload[item.key] = item.value;
      });
      contentPayload[subContent.key] = JSON.stringify(subContentPayload);
    });

    // Set a new attribute called index in the contentPayload object to identify the relevant index of the app
    // Because null values of the payload array is removed in the parent component so it becomes unmatched with
    // app indexes, so this index is used to identify which index this app is belonged to
    contentPayload.index = this.props.index;
    this.props.callback(contentPayload);
    this.setState({ modalData: content });
  };

  render() {
    const { modalData } = this.state;
    return (
      <div>
        {modalData.items && (
          <Content
            content={modalData}
            callback={content => this.setUpdatedContentData(content)}
            disabled={this.props.disabled}
          />
        )}
      </div>
    );
  }
}

export default withConfigContext(APIModalContent);
