/*
 * Copyright (C) 2018 - 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Table } from 'antd';
// Load locale-specific relative date/time formatting rules.
import { withConfigContext } from '../../../../components/ConfigContext';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import { withTranslation } from 'react-i18next';
import prodList from '../../../../../public/conf/products.json';

class ProductsTable extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      data: [],
      pagination: {},
      loading: false,
      selectedRows: [],
      selectedRowKeys: [],
    };
  }

  componentDidMount() {
    this.fetch(false);
  }

  // fetch data from api
  fetch = () => {
    const { t } = this.props;
    this.setState({ loading: true });
    const productList = {
      count : prodList.PRODUCTS.length,
      products: prodList.PRODUCTS,
    }

    const pagination = { ...this.state.pagination };
    this.setState({
      loading: false,
      data: productList,
      pagination,
    });
    
  };


  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
  };
  reloadDeviceTable = () => {
    this.fetch(true);
  };

  render() {
    const { t } = this.props;
    const {
      data,
      pagination,
      loading,
    } = this.state;


    const columns = [
      {
        title: t('label_product_name'),
        dataIndex: 'PRODUCT',
        width: 150,
        // eslint-disable-next-line react/display-name
        render: (data, row) => {
          const { PRODUCT_ID, PRODUCT } = row;
          return (
            <Link
              to={`/${this.config.appName}/products/${PRODUCT_ID}`}
              target="_blank"
            >
              <p>{PRODUCT}</p>
            </Link>
          );
        },
      },
      {
        title: t('label_product_machines'),
        dataIndex: 'STANDARD_HOURLY_PRODUCTION',
        key: 'STANDARD_HOURLY_PRODUCTION',
        width: 150,
        // eslint-disable-next-line react/display-name
        render: (data, row) => {
          const { STANDARD_HOURLY_PRODUCTION } = row;
          
          if(typeof STANDARD_HOURLY_PRODUCTION !== 'undefined') {
            // console.log(STANDARD_HOURLY_PRODUCTION);
            const getStandardHourlyProduction = (standardHourlyProduction) => {
              if (standardHourlyProduction.length === 0) {
                return '-';
              }
          
              const keys = standardHourlyProduction.map((e) =>
                Object.keys(e)[0]
              );
              
              return keys.join(', ');              
              
            };
            const getStandardHourlyProductions = getStandardHourlyProduction(STANDARD_HOURLY_PRODUCTION);
            return (getStandardHourlyProductions);
          } else {
            return ('-');
          }
        },
      },
    ];

    return (
      <div className={styles.tableContainer}>
        {data.count !== 0 && (
          <div> 
            <Table
              className={styles.customTable}
              columns={columns}
              rowKey={e =>
                e.PRODUCT_ID
              }
              dataSource={data.products}
              pagination={{
                ...pagination,
                size: 'small',
                total: data.count,
                disabled: loading,
                showTotal: (total, range) =>
                  t('table_pagination', {
                    range1: range[0],
                    range2: range[1],
                    total: total,
                    data: t('home_menu_products'),
                  }),
              }}
              loading={loading}
              onChange={this.handleTableChange}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(ProductsTable));
