/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useState, useEffect } from 'react';
import { Button, Result } from 'antd';
import { withTranslation } from 'react-i18next';
import EvaluateTable from './components/EvaluateTenantTable';
import AddEvaluate from './components/AddEvaluateTenant';
import axios from 'axios';
import HtmlComments from '../../../../../../../../../../components/ReactHtmlComments/HtmlComments';
import Authorized from '../../../../../../../../../../components/Authorized';
import { handleApiError } from '../../../../../../../../../../services/utils/errorHandler';
import { withConfigContext } from '../../../../../../../../../../components/ConfigContext';

function EvaluateTenant(props) {
  const config = props.context;
  const [
    addEvaluateTenantVisibility,
    setAddEvaluateTenantVisibility,
  ] = useState(false);
  const [metaData, setMetaData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = props;

  useEffect(() => {
    loadMetaData();
  }, []);

  const handleAdd = () => {
    setAddEvaluateTenantVisibility(true);
  };

  const loadMetaData = () => {
    setLoading(true);
    closeAddEvaluateTenantModal();
    const metaKey = 'EVALUATE_TENANTS';
    axios
      .get(
        window.location.origin +
          config.serverConfig.invoker.uri +
          '/device-mgt/v1.0/metadata/' +
          metaKey,
      )
      .then(res => {
        if (res.data.data && res.data.data.metaValue) {
          const data = JSON.parse(res.data.data.metaValue);
          setMetaData(data);
        }
        setLoading(false);
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_Evaluate_Tenant') }),
          t,
        );
        setLoading(false);
      });
  };

  const closeAddEvaluateTenantModal = () => {
    setAddEvaluateTenantVisibility(false);
  };

  return (
    <div>
      <HtmlComments permission={'/permission/admin/device-mgt/metadata/view'} />
      <Authorized
        scope={[['perm:admin:metadata:view']]}
        yes={
          config.userDomain === 'carbon.super' ? (
            <div>
              <div>
                {metaData[0] !== null && (
                  <>
                    <HtmlComments
                      permission={'/permission/admin/device-mgt/metadata/view'}
                    />
                    <Button
                      onClick={() => handleAdd()}
                      type="primary"
                      style={{
                        marginBottom: 40,
                        marginTop: 25,
                        float: 'right',
                      }}
                    >
                      Add a Tenant
                    </Button>
                  </>
                )}
                <>
                  <HtmlComments
                    permission={'/permission/admin/device-mgt/metadata/view'}
                  />
                  <EvaluateTable
                    config={config}
                    callback={() => loadMetaData()}
                    metaData={metaData}
                    loading={loading}
                    handleAdd={() => handleAdd()}
                  />
                </>
              </div>
              {addEvaluateTenantVisibility && (
                <>
                  <HtmlComments
                    permission={'/permission/admin/device-mgt/metadata/view'}
                  />
                  <AddEvaluate
                    config={config}
                    metaData={metaData}
                    closeAddEvaluateTenantModal={() =>
                      closeAddEvaluateTenantModal()
                    }
                    callback={() => loadMetaData()}
                  />
                </>
              )}
            </div>
          ) : (
            <Result
              status="403"
              title={t('noPerm_accessPageTitle')}
              subTitle={t('noPerm_contactSysAdmin')}
            />
          )
        }
        no={
          <Result
            status="403"
            title={t('noPerm_accessPageTitle')}
            subTitle={t('noPerm_contactSysAdmin')}
          />
        }
      />
    </div>
  );
}

export default withConfigContext(withTranslation()(EvaluateTenant));
