/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import { withConfigContext } from '../../../../../../components/ConfigContext';
import React from 'react';
import { Layout, Menu, Result, Typography } from 'antd';
import { Link, Redirect, Switch } from 'react-router-dom';
import RouteWithSubRoutes from '../../../../../../components/RouteWithSubRoutes';
import { RoutesContext } from '../../../../index';
import styles from './styles.module.css';
import { SettingOutlined } from '@ant-design/icons';
import Authorized from '../../../../../../components/Authorized';
import { isAuthorized } from '../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../../../components/BreadCrumb';

const { SubMenu } = Menu;
const { Content, Sider } = Layout;

const { Title } = Typography;

const breadcrumbRoutes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      breadcrumbName: t('home_menu_configurations'),
    },
    {
      breadcrumbName: t('label_platformConfiguration'),
    },
  ];
};

class PlatformConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
  }

  componentDidMount() {
    this.context.setCurrentRoute('platform-configuration');
  }

  createMenuItems = (route, t) => {
    let menuItems = [];
    if (route.hasOwnProperty('routes') && route.routes.length > 0) {
      for (let subRoute of route.routes) {
        let menuItem = (
          <Menu.Item key={subRoute.path}>
            <Link to={subRoute.path}>
              <span>{t(subRoute.name)}</span>
            </Link>
          </Menu.Item>
        );
        menuItems.push(menuItem);
      }
    } else {
      let menuItem = (
        <Menu.Item key={route.path}>
          <Link to={route.path}>
            <span>{t(route.name)}</span>
          </Link>
        </Menu.Item>
      );
      menuItems.push(menuItem);
    }
    return menuItems;
  };

  createSubMenu = (routes, t) => {
    let subMenus = [];
    for (let route of routes) {
      if (isAuthorized(this.config.scopes, route.scope)) {
        let subMenu = (
          <SubMenu key={route.path} title={t(route.name)}>
            {this.createMenuItems(route, t)}
          </SubMenu>
        );
        subMenus.push(subMenu);
      }
    }
    return subMenus;
  };

  getOpenKeysInMenu = routes => {
    let location = this.props.location.pathname;
    let defaultOpenKeys = [];
    for (let route of routes) {
      if (location.toString().includes(route.path)) {
        defaultOpenKeys.push(route.path);
      }
    }
    return defaultOpenKeys;
  };

  getItem = (label, key, icon, children, className, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
      className,
    };
  };

  getMenuItem = (route, t) => {
    let menuItems = [];
    if (route.hasOwnProperty('routes') && route.routes.length > 0) {
      for (let subRoute of route.routes) {
        let menuItem = this.getItem(t(subRoute.name), subRoute.path);
        menuItems.push(menuItem);
      }
    } else {
      let menuItem = this.getItem(t(route.name), route.path);
      menuItems.push(menuItem);
    }

    return menuItems;
  };

  sliderClick = item => {
    this.props.history.push(item.key);
  };

  render() {
    let { routes, t } = this.props;
    let defaultOpenKey = this.getOpenKeysInMenu(routes);
    let selectedKeys = [this.props.location.pathname];

    let items = [];
    for (let route of routes) {
      if (isAuthorized(this.config.scopes, route.scope)) {
        let subMenu = this.getItem(
          t(route.name),
          route.path,
          null,
          this.getMenuItem(route, t),
        );
        items.push(subMenu);
      }
    }

    //Remove android, ios, Windows configuration
    items = items.filter((item) => {
      if (
        item.key === "/facility-monitoring/configurations/platform-configuration/android" ||
        item.key === "/facility-monitoring/configurations/platform-configuration/ios" ||
        item.key === "/facility-monitoring/configurations/platform-configuration/windows"
      ) {
        return false;
      } else if (item.children) {
        item.children = item.children.filter((child) => {
          return !(child.key.startsWith("/facility-monitoring/configurations/platform-configuration/android") ||
            child.key.startsWith("/facility-monitoring/configurations/platform-configuration/ios") ||
            child.key.startsWith("/facility-monitoring/configurations/platform-configuration/windows"));
        });
      }
      return true;
    });

    return (
      <div>
        <HtmlComments
          permission={
            '/permission/admin/device-mgt/platform-configurations/view,\n' +
            '/permission/admin/device-mgt/devices/enroll/android'
          }
        />
        <Authorized
          scope={[
            [
              'perm:view-configuration',
              'perm:android:view-configuration',
              'perm:ios:view-configuration',
              'perm:windows:view-configuration',
            ],
          ]}
          yes={
            <div>
              <PageHeader
                className={styles.pageHeader}
                title={
                  <Title level={1}>
                    <span className={styles.icon}>
                      <SettingOutlined />
                    </span>
                    {t('label_platformConfiguration')}
                  </Title>
                }
                ghost={false}
                breadcrumb={
                  <BreadcrumbComponent
                    breadcrumbList={breadcrumbRoutes(this.config.appName, t)}
                  />
                }
              />
              <Content className={styles.container}>
                <Layout className={styles.layout}>
                  {defaultOpenKey.length > 0 && (
                    <Sider width={250}>
                      <Menu
                        mode="inline"
                        onClick={this.sliderClick}
                        selectedKeys={selectedKeys}
                        defaultOpenKeys={defaultOpenKey}
                        style={{ height: '100%' }}
                        items={items}
                      />
                    </Sider>
                  )}
                  <Content>
                    <Switch>
                      <Redirect
                        exact
                        from={`/${this.config.appName}/configurations/platform-configuration`}
                        to={`/${this.config.appName}/configurations/platform-configuration/general-config/general`}
                      />
                      {this.props.routes.map(route => (
                        <RouteWithSubRoutes key={route.path} {...route} />
                      ))}
                    </Switch>
                  </Content>
                </Layout>
              </Content>
            </div>
          }
          no={
            <Result
              status="403"
              title={t('noPerm_accessPageTitle')}
              subTitle={t('noPerm_contactSysAdmin')}
            />
          }
        />
      </div>
    );
  }
}

PlatformConfiguration.contextType = RoutesContext;
export default withConfigContext(withTranslation()(PlatformConfiguration));
