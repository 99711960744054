/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, {useContext, useState} from 'react';
import axios from 'axios';
import {Button, Select, Form, Input, Space, Switch, Alert} from 'antd';
import {useTranslation} from 'react-i18next';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import './styles.module.css';
import {handleApiError} from "../../../../../../../../../../services/utils/errorHandler";
import ConfigContext from "../../../../../../../../../../components/ConfigContext";

const {TextArea} = Input;

const UpdateDeviceType = (props) => {
    const { config } = useContext(ConfigContext);
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [eventForm] = Form.useForm();
    const selectedData = props.selectedData;
    const [longLivedToken, setLongLivedToken] = useState(selectedData.deviceTypeMetaDefinition.longLivedToken);
    const handleLongLivedTokenChange = (checked) => {setLongLivedToken(checked);};
    const [alertType, setAlertType] = useState(null);
    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const onFinish = (values) => {
        setIsLoading(true);
        const propertyValues = values.properties &&  values.properties.map(item => item.property);
        const mqttEventTopicStructuresValues = values.mqttEventTopicStructures;
        let deviceTypePayload = {
            "name": selectedData.name,
            "deviceTypeMetaDefinition": {
                "mqttEventTopicStructures": mqttEventTopicStructuresValues,
                "longLivedToken": values.longLivedToken,
                "description": values.description,
                "properties": propertyValues,
                "features": values.features
            }
        }
        if (values.pushNotificationTransport === "mqtt") {
            deviceTypePayload.pushNotificationConfig = {
                "scheduled": true,
                "type": "MQTT"
            }
        }
        
        axios
            .put(
                window.location.origin +
                config.serverConfig.invoker.uri +
                config.serverConfig.invoker.deviceMgt +
                '/admin/device-types/'+selectedData.name,
                deviceTypePayload,
                {headers: {'Content-Type': 'application-json'}}
            )
            .then(res => {
                if (res.status === 200) {
                    setAlertType('success');
                } else {
                    setAlertType('warning');
                }
            })
            .catch(error => {
                setAlertType('warning');
                handleApiError(error, null, t, `${this.constructor.name}AddUser`);
            });
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        setAlertType('warning');
        setIsLoading(false);
    }; 
    
    return (
        <>
            {alertType !== null && (
                <Alert
                    message={t(alertType === 'success' ? 'machine_type_update_machine_type_success' : 'machine_type_update_machine_type_fail')}
                    type={alertType}
                    showIcon
                    closable
                    style={{marginBottom: 10}}
                />
            )}

            <Form
                form={eventForm}
                name="basic"
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                initialValues={{remember: true}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label={t('device_type_description_input_label')}
                    name="description"
                    initialValue={selectedData.deviceTypeMetaDefinition.description}
                    rules={[{required: true, message: t('device_type_description_input_label_error')}]}
                >
                    <TextArea rows={4}/>
                </Form.Item>

                <Form.Item
                    label={t('device_type_event_topic_select_label')}
                    name="mqttEventTopicStructures"
                    initialValue={selectedData.deviceTypeMetaDefinition.mqttEventTopicStructures}
                    rules={[{required: true, message: t('device_type_event_topic_select_label_error')}]}
                >
                    <Select
                        mode="tags"
                        style={{
                            width: '100%',
                        }}
                        placeholder={t('device_type_event_topic_select_placeholder')}
                    />
                </Form.Item>

                <Form.Item
                    label={t('device_type_ll_token_switch_label')}
                    name="longLivedToken"
                    initialValue={selectedData.deviceTypeMetaDefinition.longLivedToken}
                >
                    <Switch checked={longLivedToken} onChange={handleLongLivedTokenChange} />
                </Form.Item>

                <Form.Item
                    label={t('device_type_push_noti_transport_select_label')}
                    name="pushNotificationTransport"
                    initialValue={typeof selectedData.deviceTypeMetaDefinition.pushNotificationConfig !== 'undefined' 
                        ? selectedData.deviceTypeMetaDefinition.pushNotificationConfig.type.toLowerCase() : 'none'}
                    rules={[{required: true, message: t('device_type_push_noti_transport_select_label_error')}]}
                >
                    <Select
                        placeholder={t('device_type_push_noti_transport_select_placeholder')}
                        style={{
                            width: 220
                        }}
                        onChange={handleChange}
                        options={[
                            {
                                value: 'none',
                                label: 'NONE',
                            },
                            {
                                value: 'mqtt',
                                label: 'MQTT',
                            },
                        ]}
                    />
                </Form.Item>

                <Form.Item label={t('device_type_features_label')} name="features">
                    <Form.List name="features">
                        {(fields, { add, remove }) => (
                        <>
                            {fields.length === 0 && (
                            <>
                                {typeof selectedData.deviceTypeMetaDefinition.features !== 'undefined' && selectedData.deviceTypeMetaDefinition.features.map(({ code, name, description }, index) => (
                                <Space
                                    key={`${name}_${index}`}
                                    style={{
                                    display: 'flex',
                                    marginBottom: 8,
                                    }}
                                    align="baseline"
                                >
                                    <Form.Item
                                    name={[index, 'name']}
                                    initialValue={name}
                                    rules={[
                                        {
                                        required: true,
                                        message: t('device_type_feature_name_missing'),
                                        },
                                    ]}
                                    >
                                    <Input placeholder={t('device_type_feature_name_placeholder')} />
                                    </Form.Item>
                                    <Form.Item
                                    name={[index, 'code']}
                                    initialValue={code}
                                    rules={[
                                        {
                                        required: true,
                                        message: t('device_type_feature_missing_code'),
                                        },
                                    ]}
                                    >
                                    <Input placeholder={t('device_type_feature_code_placeholder')} />
                                    </Form.Item>
                                    <Form.Item
                                    name={[index, 'description']}
                                    initialValue={description}
                                    rules={[
                                        {
                                        required: true,
                                        message: t('device_type_feature_missing_description'),
                                        },
                                    ]}
                                    >
                                    <Input placeholder={t('device_type_feature_description_placeholder')} />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(index)} />
                                </Space>
                                ))}
                            </>
                            )}
                            {fields.map(({ key, name, fieldKey, ...restField }) => (
                            <Space
                                key={key}
                                style={{
                                display: 'flex',
                                marginBottom: 8,
                                }}
                                align="baseline"
                            >
                                <Form.Item
                                {...restField}
                                name={[name, 'name']}
                                fieldKey={[fieldKey, 'name']}
                                rules={[
                                    {
                                    required: true,
                                    message: t('device_type_feature_name_missing'),
                                    },
                                ]}
                                >
                                <Input placeholder={t('device_type_feature_name_placeholder')} />
                                </Form.Item>
                                <Form.Item
                                {...restField}
                                name={[name, 'code']}
                                fieldKey={[fieldKey, 'code']}
                                rules={[
                                    {
                                    required: true,
                                    message: t('device_type_feature_missing_code'),
                                    },
                                ]}
                                >
                                <Input placeholder={t('device_type_feature_code_placeholder')} />
                                </Form.Item>
                                <Form.Item
                                {...restField}
                                name={[name, 'description']}
                                fieldKey={[fieldKey, 'description']}
                                rules={[
                                    {
                                    required: true,
                                    message: t('device_type_feature_missing_description'),
                                    },
                                ]}
                                >
                                <Input placeholder={t('device_type_feature_description_placeholder')} />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                            </Space>
                            ))}
                            <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                {t('device_type_add_feature_button_text')}
                            </Button>
                            </Form.Item>
                        </>
                        )}
                    </Form.List>
                </Form.Item>


                <Form.Item label={t('device_type_properties_label')} name="properties">
                    <Form.List name="properties">
                        {(fields, { add, remove }) => (
                        <>
                        
                            {fields.length === 0 && (
                            <>
                                {typeof selectedData.deviceTypeMetaDefinition.properties !== 'undefined' && selectedData.deviceTypeMetaDefinition.properties.map((field, index) => (
                                <Space
                                    key={`${field}_${index}`}
                                    style={{
                                    display: 'flex',
                                    marginBottom: 8,
                                    }}
                                    align="baseline"
                                >
                                    <Form.Item name={[index, 'property']} initialValue={field}>
                                    <Input placeholder={t('device_type_property_input_label')} />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(index)} />
                                </Space>
                                ))}
                            </>
                            )}
                            {fields.map((field, index) => (
                            <Space
                                key={field.key}
                                style={{
                                display: 'flex',
                                marginBottom: 8,
                                }}
                                align="baseline"
                            >
                                <Form.Item
                                {...field}
                                name={[field.name, 'property']}
                                fieldKey={[field.fieldKey, 'property']}
                                >
                                <Input placeholder={t('device_type_property_input_label')} />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                            </Space>
                            ))}
                            <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                {t('device_type_add_attribute_button_text')}
                            </Button>
                            </Form.Item>
                        </>
                        )}
                    </Form.List>
                </Form.Item>

                <Form.Item wrapperCol={{offset: 8, span: 16}}>
                    <Button loading={isLoading} type="primary" htmlType="submit" style={{float: "right"}}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default UpdateDeviceType;
