/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Input, Form, notification, Divider } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import axios from 'axios';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import { withTranslation } from 'react-i18next';
import { splitUserDomain } from '../../../../../../../../services/utils/commonHandler';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const buttonItemLayout = {
  wrapperCol: { span: 24, offset: 16 },
};

class PasswordResettingForm extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.config = this.props.context;
  }

  componentDidMount() {
    this.formRef.current.resetFields();
  }

  onFinish = values => {
    const { t } = this.props;
    let userDomain = splitUserDomain(this.props.username);
    const password = {
      newPassword: values.password,
    };
    axios
      .post(
        window.location.origin +
          this.config.serverConfig.invoker.uri +
          this.config.serverConfig.invoker.deviceMgt +
          '/admin/users/credentials?username=' +
          userDomain[0] +
          '&domain=' +
          userDomain[1],
        password,
        { headers: { 'Content-Type': 'application-json' } },
      )
      .then(res => {
        if (res.status === 200) {
          this.formRef.current.resetFields();
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_passwordResetMsg'),
          });
        }
      })
      .catch(error => {
        console.log(error);
        handleApiError(error, t('api_resetPasswordError'), t);
      });
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <Divider>
          <PageHeader
            className="site-page-header-responsive"
            subTitle={t('title_ResetUserPassword')}
          />
        </Divider>
        <Form
          name="resetPassword"
          onFinish={this.onFinish}
          {...formItemLayout}
          ref={this.formRef}
        >
          <Form.Item
            name="password"
            label={t('label_newPassword')}
            rules={[
              {
                required: true,
                message: t('form_fieldRequiredMsg'),
              },
              {
                pattern: new RegExp('^(((?!(\\s)).){5,})*$'),
                message: t('form_passwordNotValidMsg'),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label={t('label_retypeNewPassword')}
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: t('form_fieldRequiredMsg'),
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(t('form_passwordNotMatchMsg2'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item {...buttonItemLayout}>
            <Button htmlType="submit" type="primary">
              {t('label_resetPassword')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(PasswordResettingForm));
