/*
 * Copyright (C) 2021. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 *  Licensed under the Entgra Commercial License,
 *  Version 1.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *
 *  Unless required by applicable law or agreed to in writing,
 *  software distributed under the License is distributed on an
 *  "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 *  KIND, either express or implied.  See the License for the
 *  specific language governing permissions and limitations
 *  under the License.
 *
 *  You may obtain a copy of the License at
 *  https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Row, Col, Typography, Button, Collapse } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import styles from '../../styles.module.css';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import IframeWrapper from '../../../../components/IframeWrapper';
import { ReconciliationOutlined, SearchOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import { RoutesContext } from '../../../../index';
import moment from 'moment';
import Filter from '../../../../components/Filter';
import BreadcrumbComponent from '../../../../../../components/BreadCrumb';

const { Title } = Typography;
const { Panel } = Collapse;
let start = '';
let end = '';

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      path: `/${appName}/analytics`,
      breadcrumbName: t('home_menu_analytics'),
    },
    {
      breadcrumbName: t('label_deviceConnectivity'),
    },
  ];
};

let setSearchFields = t => {
  return [
    {
      name: 'connectionType',
      placeholder: t('label_connection_type'),
      type: 'select',
      values: [],
      label: t('label_connection_type'),
    },
  ];
};

class DeviceConnectivity extends React.Component {
  constructor(props) {
    super(props);
    this.config = props.context;
    end = moment(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
        new Date().getMilliseconds(),
      ),
    );
    end = end.add(1, 'hours');
    start = moment().startOf('day');
    start = start.add(-1, 'weeks');
    this.state = {
      start: start,
      end: end,
      connectionType: ['MOBILE', 'WIFI'],
      selectedConnectionType: 'MOBILE',
      filterFields: [],
      selectedLanguage: this.props.i18n.language,
    };
  }

  componentDidMount() {
    this.context.setCurrentRoute('deviceConnectivity');
    this.getDeviceFilters();
  }

  fetchFilterData = (params, values) => {
    this.onChangeFilters(values);
  };

  onChangeFilters = filters => {
    let connectionType;

    if (filters.hasOwnProperty('connectionType')) {
      connectionType = filters.connectionType;
    } else {
      connectionType = 'MOBILE';
    }

    this.setState({
      selectedConnectionType: connectionType,
    });
  };

  getDeviceFilters = () => {
    const { t } = this.props;
    const { connectionType } = this.state;

    let searchFields = setSearchFields(t);
    searchFields.map(searchField => {
      if (searchField.name === 'connectionType') {
        searchField.values.push(...connectionType);
      }
    });
    this.setState({ filterFields: searchFields });
  };

  render() {
    const { t } = this.props;
    const {
      selectedConnectionType,
      filterFields,
      selectedLanguage,
    } = this.state;
    const grafanaEmbedLinks = this.config.grafanaConfig.deviceConnectivity;
    const fromInMills = moment(this.state.start).unix() * 1000;
    const ToInMills = moment(this.state.end).unix() * 1000;
    return (
      <div>
        <div>
          <PageHeader
            className={styles.pageHeader}
            title={
              <Title level={1}>
                <span className={styles.icon}>
                  <ReconciliationOutlined />
                </span>
                {t('label_deviceConnectivity')}
              </Title>
            }
            ghost={false}
            breadcrumb={
              <BreadcrumbComponent
                breadcrumbList={routes(this.config.appName, t)}
              />
            }
          />
          <div className={styles.container}>
            <div className={styles.controllerDiv}>
              <Collapse
                bordered={false}
                expandIcon={() => <SearchOutlined />}
                className={styles.searchCollapse}
              >
                <Panel
                  header={t('form_searchDevices')}
                  key="1"
                  style={{ borderColor: '#fff' }}
                >
                  <Row>
                    <Col span={23}>
                      <Filter
                        filters={filterFields}
                        callback={(params, values) =>
                          this.fetchFilterData({}, values)
                        }
                      />
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </div>
            <Row>
              <Col xl={12} xs={24}>
                <div className={styles.iframeContainer}>
                  <IframeWrapper
                    iframeSrc={
                      grafanaEmbedLinks.overview +
                      '&panelId=4' +
                      '&var-label_panelName4=' +
                      selectedLanguage
                    }
                  />
                </div>
              </Col>
              <Col xl={12} xs={24}>
                <div className={styles.iframeContainer}>
                  <IframeWrapper
                    iframeSrc={
                      grafanaEmbedLinks.overview +
                      '&panelId=6' +
                      '&var-connectionType=' +
                      selectedConnectionType +
                      '&var-label_panelName6=' +
                      selectedLanguage
                    }
                  />
                </div>
              </Col>
              <Col xl={12} xs={24}>
                <div className={styles.iframeContainer}>
                  <IframeWrapper
                    iframeSrc={
                      grafanaEmbedLinks.networkTypeOverTime +
                      '&panelId=4' +
                      '&var-label_panelName4=' +
                      selectedLanguage
                    }
                    duration={`&from=${fromInMills}&to=${ToInMills}`}
                  />
                  <Button
                    type="primary"
                    href={`${this.props.location.pathname}/network-type-overtime`}
                    target="_blank"
                  >
                    {t('label_clickForMoreDetails')}
                  </Button>
                </div>
              </Col>
              <Col xl={12} xs={24}>
                <div className={styles.iframeContainer}>
                  <IframeWrapper
                    iframeSrc={
                      grafanaEmbedLinks.signalStrengthOverTime +
                      '&panelId=4' +
                      '&var-connectionType=' +
                      selectedConnectionType +
                      '&var-label_panelName4=' +
                      selectedLanguage
                    }
                    duration={`&from=${fromInMills}&to=${ToInMills}`}
                  />
                  <Button
                    type="primary"
                    href={`${this.props.location.pathname}/signal-strength?operator=All`}
                    target="_blank"
                  >
                    {t('label_clickForMoreDetails')}
                  </Button>
                </div>
              </Col>
              <Col span={24}>
                <div className={styles.iframeContainer}>
                  <IframeWrapper
                    iframeSrc={
                      grafanaEmbedLinks.simChange +
                      '&panelId=2' +
                      '&var-label_panelName2=' +
                      selectedLanguage +
                      '&var-label_deviceCount=' +
                      selectedLanguage
                    }
                    duration={`&from=${fromInMills}&to=${ToInMills}`}
                  />
                  <Button
                    type="primary"
                    href={`${this.props.location.pathname}/sim-change`}
                    target="_blank"
                  >
                    {t('label_clickForMoreDetails')}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

DeviceConnectivity.contextType = RoutesContext;
export default withConfigContext(withTranslation()(DeviceConnectivity));
