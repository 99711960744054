
export function getCurrentMonthConsumption(doc, pdfConfig, param) {
    //current month consumption
    pdfConfig.totalHeight += pdfConfig.lineHeight;
    pdfConfig.totalHeight += pdfConfig.lineHeight;
    doc.setTextColor(pdfConfig.colorBlack);
    doc.setFontSize(pdfConfig.fieldTextSize);
    doc.text(10, pdfConfig.totalHeight, param.reportBody.CurrentMonthConsumption);
    //end current month consumption

    return {
        doc: doc,
        pdfConfig: pdfConfig
    };
}
