/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { withConfigContext } from '../../../../components/ConfigContext';

class IframeWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.reportBase = this.config.grafanaConfig.reportBase;
  }

  render() {
    let iframeSrc = this.reportBase + '/' + this.props.iframeSrc;
    if (this.props.duration) {
      iframeSrc += this.props.duration;
    }

    const height = this.props.height !== 'undefined' && this.props.height ? this.props.height : '100%';

    return (
      <div>
        <iframe
          style={{
            borderRadius: '6px',
            border: '1px solid #eee',
          }}
          className="embed-responsive-item"
          src={iframeSrc}
          height={height}
          width="100%"
          frameBorder="0"
          scrolling={'yes'}
        />
      </div>
    );
  }
}

export default withConfigContext(IframeWrapper);
