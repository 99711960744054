/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Card, Col, Divider, notification, Row } from 'antd';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import QRCode from 'qrcode.react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import styles from './styles.module.css';
import QRPlaceholder from '../../../../../../../public/images/qr-code.png';
import IOSAgentQRForm from '../IOSAgentQRForm';
import StepOne from './components/StepOne';
import EnrollmentGuide from '../EnrollmentGuide';
import Authorized from '../../../../../../components/Authorized';
import HtmlComments from '../../../../../../components/ReactHtmlComments/HtmlComments';

class CombinedSteps extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    TimeAgo.addLocale(en);
    this.state = {
      pagination: {},
      loading: false,
      showIoSEnrollQR: false,
      showInitialQR: false,
      apiUrl: '',
      selectedRows: [],
      skipButtonTitle: 'Skip',
      iOSAgentAppId: '',
      downloadAgentTooltip: '',
      downloadAgentDisabled: false,
      showSelfGuidedModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedDeviceType !== prevProps.selectedDeviceType) {
      this.setState({
        showInitialQR: false,
      });
      if (this.props.selectedDeviceType === 'iOS') {
        this.getDEPConfigAgentId();
      } else {
        this.setState({
          downloadAgentTooltip: '',
          downloadAgentDisabled: false,
        });
      }
    }
  }

  changeShowQR = payload => {
    this.setState({
      showInitialQR: true,
      showIoSEnrollQR: true,
      apiUrl: payload,
    });
  };

  showDownloadQR = () => {
    this.setState({
      showInitialQR: true,
      showIoSEnrollQR: false,
    });
  };

  onClickDownloadAgent = () => {
    this.setState({
      skipButtonTitle: 'Next',
    });
  };

  getAgentDownloadURL = () => {
    const { selectedDeviceType } = this.props;

    if (!this.state.showIoSEnrollQR) {
      let apiUrl =
        window.location.origin +
        '/api/application-mgt/v1.0/artifact/' +
        this.props.deviceType +
        '/agent';

      if (selectedDeviceType === 'iOS') {
        if (this.state.iOSAgentAppId.length > 0) {
          apiUrl =
            'itms-services://?action=download-manifest&url=' +
            window.location.origin +
            '/api/application-mgt/v1.0/artifact/plist/' +
            this.state.iOSAgentAppId;
        } else {
          apiUrl = '#!';
        }
      }
      return apiUrl;
    }
    return JSON.stringify(this.state.apiUrl);
  };

  getDEPConfigAgentId = () => {
    const { t } = this.props;
    axios
      .get(
        window.location.origin +
          this.config.serverConfig.invoker.uri +
          '/device-mgt/ios/v1.0/configuration',
      )
      .then(res => {
        if (res.status === 200) {
          let responseData = res.data.data.configuration;
          if (responseData) {
            for (let config of responseData) {
              if (config.name === 'agentPackageName') {
                this.setState({ iOSAgentAppId: config.value });
                break;
              }
            }
            if (this.state.iOSAgentAppId.length === 0) {
              notification.error({
                message: t('api_errorMsg'),
                description: t('label_dep_agent_id_not_found'),
              });
              setTimeout(() => {
                notification.error({
                  message: t('api_errorMsg'),
                  description: t('label_dep_agent_id_not_found_description'),
                });
              }, 1000);
              this.setState({
                downloadAgentDisabled: true,
                downloadAgentTooltip: t(
                  'label_dep_agent_id_not_found_description',
                ),
              });
            }
          }
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_iOSPlatformConfigs') }),
          t,
        );
        this.setState({
          downloadAgentDisabled: true,
          downloadAgentTooltip: t('api_loadError', {
            label: t('label_iOSPlatformConfigs'),
          }),
        });
      });
  };

  onEnrollWithQR = () => {
    this.props.getEnrollmentData('qr');
  };

  onEnrollManually = () => {
    this.props.getEnrollmentData('manual');
  };

  hideSelfGuidedModal = () => {
    this.setState({
      showSelfGuidedModal: false,
    });
  };

  showSelfGuidedModal = () => {
    this.setState({
      showSelfGuidedModal: true,
    });
  };

  render() {
    const { downloadAgentTooltip, downloadAgentDisabled } = this.state;
    const { selectedDeviceType, deviceType, t } = this.props;
    return (
      <>
        <Row>
          {deviceType === 'ios' ? (
            <Col
              span={4}
              xs={24}
              sm={24}
              md={24}
              lg={24}
              className={styles.paraContainer}
            >
              <StepOne
                downloadAgentTooltip={downloadAgentTooltip}
                downloadAgentDisabled={downloadAgentDisabled}
                deviceType={deviceType}
                selectedDeviceType={selectedDeviceType}
                getAgentDownloadURL={this.getAgentDownloadURL}
                onClickDownloadAgent={this.onClickDownloadAgent}
                getDEPConfigAgentId={this.getDEPConfigAgentId}
                showDownloadQR={this.showDownloadQR}
                enrollmentGuideId={`enroll-${deviceType}`}
              />
            </Col>
          ) : (
            <Col
              span={4}
              xs={22}
              sm={22}
              md={22}
              lg={16}
              className={styles.paraContainer}
            >
              <StepOne
                downloadAgentTooltip={downloadAgentTooltip}
                downloadAgentDisabled={downloadAgentDisabled}
                deviceType={deviceType}
                selectedDeviceType={selectedDeviceType}
                getAgentDownloadURL={this.getAgentDownloadURL}
                onClickDownloadAgent={this.onClickDownloadAgent}
                getDEPConfigAgentId={this.getDEPConfigAgentId}
                showDownloadQR={this.showDownloadQR}
                enrollmentGuideId={
                  deviceType === 'windows'
                    ? `enroll-${deviceType}`
                    : `enroll-${deviceType}-devices`
                }
              />
            </Col>
          )}
          {deviceType !== 'ios' ? (
            <>
              {' '}
              <Col
                className={styles.cardContainer}
                span={4}
                xs={22}
                sm={22}
                md={22}
                lg={4}
              >
                {deviceType !== 'windows' ? (
                  <>
                    <HtmlComments
                      permission={'/device-mgt/devices/enrollment-guide/view"'}
                    />
                    <Authorized
                      scope={['perm:devices:enrollment-guide:view']}
                      yes={
                        <Button
                          type="primary"
                          size={'default'}
                          style={{ marginLeft: 40, marginBottom: 20 }}
                          onClick={this.showSelfGuidedModal}
                        >
                          Self Enrollment Guide
                        </Button>
                      }
                      no={null}
                    />
                  </>
                ) : null}
                <span
                  style={{
                    marginBottm: 10,
                    display: 'inline-block',
                  }}
                >
                  <Card hoverable>
                    <QRCode size={200} value={this.getAgentDownloadURL()} />
                  </Card>
                </span>
              </Col>
              <Divider
                style={{
                  borderTopWidth: 2,
                  borderTopStyle: 'solid',
                  borderTopColor: 'rgba(0, 0, 0, 0.06)',
                }}
              />
            </>
          ) : null}
        </Row>
        {deviceType === 'ios' ? (
          <Row style={{ marginTop: 30 }}>
            <IOSAgentQRForm
              showIoSEnrollQR={this.state.showIoSEnrollQR}
              deviceType={selectedDeviceType.toLowerCase()}
              changeShowQR={this.changeShowQR}
            />
            <Col
              className={styles.cardContainer}
              offset={1}
              span={4}
              xs={22}
              sm={22}
              md={22}
              lg={4}
            >
              <span
                style={{
                  display: 'inline-block',
                }}
              >
                <div
                  style={{
                    display: this.state.showInitialQR ? 'inline-block' : 'none',
                  }}
                >
                  <Card hoverable className={styles.iosCardContainer}>
                    <QRCode size={300} value={this.getAgentDownloadURL()} />
                    {this.state.showIoSEnrollQR ? (
                      <>
                        <div
                          style={{
                            textAlign: 'center',
                            marginTop: 10,
                          }}
                        >
                          {t('completeWithoutScan_Txt')}
                          <a href={this.state.apiUrl}>
                            {' '}
                            {t('iOSWebAgent_linkTxt')}
                          </a>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            textAlign: 'center',
                            marginTop: 10,
                          }}
                        >
                          {t('downloadQR_infoTxt')}
                        </div>
                      </>
                    )}
                  </Card>
                </div>
                <br />
                <div
                  style={{
                    display: !this.state.showInitialQR
                      ? 'inline-block'
                      : 'none',
                  }}
                >
                  <Card hoverable className={styles.iosCardContainer}>
                    <img
                      height={300}
                      width={300}
                      src={QRPlaceholder}
                      alt={t('label_QRPlaceholder')}
                    />
                  </Card>
                </div>
              </span>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={16}>
              <h3>
                {' '}
                {t('title_enrollAgent', {
                  deviceType: t(`label_${selectedDeviceType.toLowerCase()}`),
                })}
              </h3>
              <div>
                <p> {t('enrollAgent_infoTxt')}</p>
              </div>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={6} offset={2}>
              {deviceType === 'android' && (
                <div style={{ marginTop: 24 }}>
                  <Button
                    type="primary"
                    size={'default'}
                    onClick={this.onEnrollWithQR}
                    style={{ marginRight: 10 }}
                  >
                    {t('label_enrollUsingQR')}
                  </Button>
                  <Button
                    style={{ marginTop: 5 }}
                    type="primary"
                    size={'default'}
                    onClick={this.onEnrollManually}
                  >
                    {t('label_enrollManually')}
                  </Button>
                </div>
              )}
              {deviceType === 'windows' && (
                <div style={{ marginTop: 24 }}>
                  <Button
                    type="primary"
                    size={'default'}
                    onClick={this.onEnrollManually}
                  >
                    {t('label_enrollManually')}
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        )}
        <div>
          <EnrollmentGuide
            showSelfGuidedModal={this.state.showSelfGuidedModal}
            hideSelfGuidedModal={this.hideSelfGuidedModal}
          />
        </div>
      </>
    );
  }
}

export default withConfigContext(withTranslation()(CombinedSteps));
