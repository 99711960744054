/*
 * Copyright (C) 2018 - 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Table } from 'antd';
// Load locale-specific relative date/time formatting rules.
import { withConfigContext } from '../../../../components/ConfigContext';
import styles from './styles.module.css';
import { withTranslation } from 'react-i18next';

class UsageHistoryTable extends React.Component {
    constructor(props) {
        super(props);
        this.config = this.props.context;

        this.state = {
            data: {
                count: 12,
                operators: this.props.usageHistory
            },
            pagination: {},
            loading: false,
            selectedRows: [],
            selectedRowKeys: [],
        };
    }

    handleTableChange = (pagination) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
          pagination: pager,
        });
      };

    render() {
        const { t } = this.props;
        const {
            data,
            pagination,
            loading,
        } = this.state;


        const columns = [
              {
                title: "Month",
                dataIndex: 'MONTH_NAME',
                width: 150,
                // eslint-disable-next-line react/display-name
                render: (data, row) => {
                  const { MONTH_NAME } = row;
                  return (
                      <p>{MONTH_NAME}</p>
                  );
                },
              },
            {
                title: "Consumption",
                dataIndex: 'CONSUMPTION',
                key: 'CONSUMPTION',
                width: 150,
                // eslint-disable-next-line react/display-name
                render: (data, row) => {
                    const { CONSUMPTION } = row;
                    return (typeof CONSUMPTION !== 'undefined' ? CONSUMPTION : '');
                },
            },
            {
                title: "Total Reading",
                dataIndex: 'TOTAL_READING',
                key: 'TOTAL_READING',
                width: 150,
                // eslint-disable-next-line react/display-name
                render: (data, row) => {
                    const { TOTAL_READING } = row;
                    return (typeof TOTAL_READING !== 'undefined' ? TOTAL_READING : '');
                },
            },
        ];

        return (
            <div className={styles.tableContainer}>
                {data.count !== 0 && (
                    <div>
                        <Table
                            className={styles.customTable}
                            columns={columns}
                            rowKey={e => e.MONTH_ID}
                            dataSource={data.operators}
                            pagination={{
                                ...pagination,
                                size: 'small',
                                total: data.count,
                                // disabled: loading,
                                showTotal: (total, range) =>
                                    t('table_pagination', {
                                        range1: range[0],
                                        range2: range[1],
                                        total: total,
                                        data: t('home_menu_historicalUsage'),
                                    }),
                            }}
                            // loading={loading}
                          onChange={this.handleTableChange}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default withConfigContext(withTranslation()(UsageHistoryTable));
