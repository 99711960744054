import React from 'react';
import { Table } from 'antd';
import 'moment-timezone';

const MeterInventoryTable = ({ devices, count, columns, pagination, loading, handleTableChange }) => {
    return (
        <Table
            rowKey={(record) => `${record.serial}`}
            dataSource={devices}
            columns={columns}
            pagination={{
                ...pagination,
                size: 'small',
                total: count,
                disabled: loading,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
            onChange={handleTableChange}
            loading={loading}
            bordered
        />
    );
};

export default MeterInventoryTable;
