/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import { Form, Button, Col, Input } from 'antd';
import styles from './styles.module.css';
import { withTranslation } from 'react-i18next';
const { TextArea } = Input;

class PublishDevices extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      isPublishablePolicy: false,
    };
  }

  onClickSavePolicy = (event, isActivePolicy) => {
    let publishData = this.formRef.current.getFieldsValue();
    publishData.active = isActivePolicy;
    if (!publishData.description) {
      publishData.description = '';
    }
    if (publishData.policyName && publishData.policyName !== '') {
      this.props.setPublishData({ publishDevicesData: publishData });
    }
  };

  onChangePolicyName = event => {
    if (event.target.value !== '') {
      this.setState({ isPublishablePolicy: true });
    } else {
      this.setState({ isPublishablePolicy: false });
    }
  };

  render() {
    const { policyData, t } = this.props;
    return (
      <div>
        <Form
          name="publishDevices"
          ref={this.formRef}
          initialValues={{
            policyName: policyData ? policyData.policyName : null,
            description: policyData ? policyData.description : null,
          }}
        >
          <Form.Item
            style={{ display: 'block' }}
            name="policyName"
            label={t('form_SetAPolicyName')}
            rules={[
              {
                pattern: new RegExp('^.{1,30}$'),
                message: t('form_policyNameLengthNotValidMsg'),
              },
              {
                required: true,
                message: t('form_policyNameRequiredMsg'),
              },
            ]}
          >
            <Input
              placeholder={t('form_policyNameLengthNotValidMsg')}
              onChange={this.onChangePolicyName}
            />
          </Form.Item>
          <Form.Item
            style={{ display: 'block' }}
            name="description"
            label={t('label_addDescription')}
          >
            <TextArea rows={8} />
          </Form.Item>
          <Col span={16} offset={15}>
            <div className={styles.buttons}>
              <Form.Item>
                <Button
                  style={{ marginRight: 8 }}
                  onClick={this.props.setPrevStep}
                >
                  {t('label_back')}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    policyData ? false : !this.state.isPublishablePolicy
                  }
                  style={{ marginRight: 8 }}
                  onClick={e => this.onClickSavePolicy(e, true)}
                >
                  {t('label_saveAndPublish')}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    policyData ? false : !this.state.isPublishablePolicy
                  }
                  onClick={e => this.onClickSavePolicy(e, false)}
                >
                  {t('label_save')}
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Form>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(PublishDevices));
