import { createReportTable } from "../../../Util/createReportTable";

export function getAverageConsumptionTable(doc, pdfConfig, param) {
    pdfConfig.totalHeight += pdfConfig.lineHeight;
    pdfConfig.totalHeight += pdfConfig.lineHeight;
    doc.setTextColor(pdfConfig.colorGray);
    doc.setFontSize(pdfConfig.fieldTextSize);
    if (param.reportBody.AverageConsumptionLabel) {
        doc.text(10, pdfConfig.totalHeight, param.reportBody.AverageConsumptionLabel);
    }
    const averageConsumptionTable = {
        headerBorder: param.reportBody?.AverageConsumptionHeaderBorder || false,
        tableBodyBorder: param.reportBody?.AverageConsumptionTableBodyBorder || false,
        header: param.reportBody?.AverageConsumptionHeader || [],
        table: param.reportBody?.AverageConsumptionTable || [],
    };
    createReportTable(averageConsumptionTable, doc, pdfConfig, param);

    return {
        doc: doc,
        pdfConfig: pdfConfig
    };
}
