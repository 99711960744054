/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {HomeOutlined} from '@ant-design/icons';
import { Typography, Breadcrumb, Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { Link } from 'react-router-dom';
import DeviceTypesTable from './components/DeviceTypesTable';
import { RoutesContext } from '../../index';
import { withTranslation } from 'react-i18next';
import {withConfigContext} from "../../../../components/ConfigContext";

const { Paragraph } = Typography;

class DeviceTypes extends React.Component {

    constructor(props) {
        super(props);
        this.config = props.context;
        this.state = {
            filters: {},
        };
    }
  componentDidMount() {
    this.context.setCurrentRoute('deviceTypes');
  }
  render() {
    const { t } = this.props;
    return (
      <div>
        <PageHeader
            style={{ paddingTop: '16px' }}
            title={
                <h3>{t('label_machineTypes')}</h3>
            }
            extra={[
              <Button
                onClick={() => {this.props.history.push(`/${this.config.appName}/devices/types?add-device-type=true`)}} style={{marginRight:'50px'}} type={"primary"}
                key={'addDeviceType'}
              >
                {t('add_machine_type')}
              </Button>,
            ]}
        >
          <Breadcrumb style={{ paddingBottom: 16 }}>
            <Breadcrumb.Item>
              <Link to="/entgra">
                <HomeOutlined /> {t('home_menu_home')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t('label_machineTypes')}</Breadcrumb.Item>
          </Breadcrumb>
          <Paragraph>{t('allMachineTypes_infoTxt')}</Paragraph>
          <DeviceTypesTable />
        </PageHeader>
      </div>
    );
  }
}

DeviceTypes.contextType = RoutesContext;
export default withConfigContext(withTranslation()(DeviceTypes));
