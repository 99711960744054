/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useState, useEffect } from 'react';
import { Modal, notification } from 'antd';
import { withTranslation } from 'react-i18next';
import EvaluateForm from '../EvaluateTenantForm';
import HtmlComments from '../../../../../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withConfigContext } from '../../../../../../../../../../../../components/ConfigContext';
import axios from 'axios';
import { handleApiError } from '../../../../../../../../../../../../services/utils/errorHandler';

function AddEvaluateTenant(props) {
  const [addModalVisiblity, setAddModalVisiblity] = useState(false);
  const { t } = props;

  useEffect(() => {
    changeModelVisibility();
  }, []);

  const changeModelVisibility = () => {
    setAddModalVisiblity(true);
  };

  const addEvauateTenant = values => {
    props.metaData.push(values);
    const requestPayload = {
      dataType: 'STRING',
      metaKey: 'EVALUATE_TENANTS',
      metaValue: JSON.stringify(props.metaData),
    };
    let apiUrl =
      window.location.origin +
      props.config.serverConfig.invoker.uri +
      '/device-mgt/v1.0/metadata/';
    axios
      .put(apiUrl, requestPayload, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        props.callback();
        props.closeAddEvaluateTenantModal();
        notification.success({
          message: t('api_successMsg'),
          duration: 4,
          description: t('api_addEvaluateTenant'),
        });
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_addError', { label: t('label_Evaluate_Tenant') }),
          t,
        );
      });
  };

  const hideTenantAddingModal = () => {
    props.closeAddEvaluateTenantModal();
  };

  const fetchNewEvaluateTenantData = values => {
    addEvauateTenant(values);
  };

  return (
    <Modal
      title={t('title_addEvaluateTenant')}
      open={addModalVisiblity}
      onCancel={() => hideTenantAddingModal()}
      footer={null}
      maskClosable={false}
    >
      <HtmlComments
        permission={'/permission/admin/device-mgt/metadata/create'}
      />
      <EvaluateForm
        config={props.config}
        action={'Add'}
        modalVisibility={addModalVisiblity}
        callback={values => fetchNewEvaluateTenantData(values)}
        metaData={props.metaData}
      />
    </Modal>
  );
}

export default withConfigContext(withTranslation()(AddEvaluateTenant));
