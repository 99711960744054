import { createReportTable } from "../../../Util/createReportTable";
import { addStamp } from "../../../Util/addStamp";

export function getCurrentMonthTable(doc, pdfConfig, param) {
    //current month table
    pdfConfig.totalHeight += pdfConfig.lineHeight;
    doc.setTextColor(pdfConfig.colorGray);
    doc.setFontSize(pdfConfig.fieldTextSize);
    if (param.reportBody.currentMonthLabel) {
        doc.text(10, pdfConfig.totalHeight, param.reportBody.currentMonthLabel);
    }
    const currentMonthTable = {
        headerBorder: param.reportBody?.currentMonthHeaderBorder || false,
        tableBodyBorder: param.reportBody?.currentMonthTableBodyBorder || false,
        header: param.reportBody?.currentMonthHeader || [],
        table: param.reportBody?.currentMonthTable || [],
    };
    createReportTable(currentMonthTable, doc, pdfConfig, param);
    //end current month table
    addStamp(param, doc, pdfConfig);

    //Add num of first page at the bottom
    if (doc.getNumberOfPages() === 1 && param.pageEnable) {
        doc.setFontSize(pdfConfig.fieldTextSize - 2);
        doc.setTextColor(pdfConfig.colorGray);
        doc.text(pdfConfig.docWidth / 2, pdfConfig.docHeight - 10, param.footer.text, "center");
        doc.text(
            param.pageLabel + "1 / 1",
            pdfConfig.docWidth - 20,
            doc.internal.pageSize.height - 6
        );
    }
    //end Add num of first page at the bottom

    return {
        doc: doc,
        pdfConfig: pdfConfig
    }
}
