/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, {useEffect} from 'react';
import {Button, Form, Input, Select, Space} from 'antd';
import {useTranslation} from 'react-i18next';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';


const DeviceTypeEventForm = (props) => {

    const [eventForm] = Form.useForm();
    const {t} = useTranslation();

    useEffect(() => {
        props.eventFormCallback(eventForm)
    },[])

    const onBlurDeviceTypeNameInput = (value) => {
        props.onBlurDeviceTypeNameInput(value);
    }

    const onBlurEventTopicInput = (value) => {
        props.onBlurEventTopicInput(value);
    }

    const onChangeTransportSelect = (value) => {
        props.onChangeTransportSelect(value)
    }

    const onBlurAttributeNameInput = (value, key) => {
        props.onBlurAttributeNameInput(value, key)
    }

    const onChangeAttributeTypeInput = (value, key) => {
        props.onChangeAttributeTypeInput(value, key)
    }

    const onRemoveAttribute = (key) => {
        props.onRemoveAttribute(key)
    }
    const changeEventTopicOptions = props.deviceTypeData.deviceTypeMetaDefinition.mqttEventTopicStructures.map((option) => ({
        value: option,
        label: option
    }));

    const changeTransportOptions = [ 'STRING', 'LONG', 'BOOL', 'INT', 'FLOAT', 'DOUBLE' ];

    return (
        <Form
            form={eventForm}
            name="basic"
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
            initialValues={{remember: true}}
            autoComplete="off"
        >
            <Form.Item
                label={t('device_type_event_name_input_label')}
                name="eventName"
                rules={[{required: true, message: t('device_type_event_name_input_error')}]}
                initialValue={props.form.eventName ? props.form.eventName : ""}
            >
                <Input onBlur={(value) => onBlurDeviceTypeNameInput(value)}/>
            </Form.Item>

            <Form.Item
                label={t('device_type_event_topic_input_label')}
                name="eventTopicStructure"
                rules={[{required: true, message: t('device_type_event_topic_input_error')}]}
            >
                <Select
                    style={{
                        width: '100%'
                    }}
                    onChange={(value) => onChangeEventTopicSelect(value)}
                    defaultValue={changeEventTopicOptions.length > 0 ? changeEventTopicOptions[0] : null}
                    options={changeEventTopicOptions}
                />
            </Form.Item>

            <Form.Item
                label={t('device_type_event_transport_select_label')}
                name="transport"
            >
                <Select
                    style={{
                        width: '100%'
                    }}
                    onChange={(value) => onChangeTransportSelect(value)}
                    defaultValue={props.form.transport ? props.form.transport : "HTTP"}
                    options={[
                        {
                            value: 'HTTP',
                            label: 'HTTP',
                        },
                        {
                            value: 'MQTT',
                            label: 'MQTT',
                        },
                    ]}
                />
            </Form.Item>

            <Form.Item
                label={t('device_type_event_attributes_label')}
                name="attributes"
                initialValue={props.form.attributes ? props.form.attributes : [{value:"", type:"STRING"}]}
            >
                <Form.List name="attributes">
                    {(fields, {add, remove}) => (
                        <>
                            {fields.map(({key, name, ...restField}) => (
                                <Space
                                    key={key}
                                    style={{
                                        display: 'flex',
                                    }}
                                    align="baseline"
                                >
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'name']}
                                        rules={[{required: true, message: t('device_type_event_attributes_name_error')}]}
                                    >
                                        <Input placeholder="Name" onBlur={(value) => onBlurAttributeNameInput(value, key)}/>
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'type']}
                                        rules={[{required: true, message: t('device_type_event_attributes_type_error')}]}
                                    >
                                        <Select
                                            style={{
                                                width: 220
                                            }}
                                            placeholder={t('device_type_event_attribute_type_select_placeholder')}
                                            onChange={(value) => onChangeAttributeTypeInput(value, key)}
                                            options={changeTransportOptions.map(option => ({ value: option, label: option }))}
                                        />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => {
                                        remove(name);
                                        onRemoveAttribute(key)
                                    }}/>
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                    Add attribute
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form.Item>
        </Form>
    );
};

export default DeviceTypeEventForm;
