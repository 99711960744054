/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Button,
  Card,
  Col,
  Divider,
  notification,
  Row,
  Typography,
} from 'antd';
import axios from 'axios';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import styles from './styles.module.css';
import PolicyList from '../PolicyList';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import { isAuthorized } from '../../../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

const { Text } = Typography;

class PolicyPriority extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      policies: [],
      loading: false,
      isApplyChangesDisable: false,
    };
  }

  componentDidMount() {
    this.fetchPolicies();
  }

  fetchPolicies = () => {
    const { t } = this.props;
    this.setState({ loading: true });
    const apiUrl =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      this.config.serverConfig.invoker.deviceMgt +
      '/policies';

    // send request to the invokerss
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            loading: false,
            policies: res.data.data.policies,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_policies') }),
          t,
        );

        this.setState({ loading: false });
      });
  };

  onChangeList = policies => {
    this.setState({
      policies,
      isApplyChangesDisable: true,
    });
  };

  onSavePriorityChanges = () => {
    const { t } = this.props;
    const payload = this.state.policies.map((policy, index) => {
      return {
        id: policy.id,
        priority: index + 1,
      };
    });
    const apiUrl =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      this.config.serverConfig.invoker.deviceMgt +
      '/policies/priorities';

    // send request to the invokerss
    axios
      .put(apiUrl, payload)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            isApplyChangesDisable: false,
          });
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_updatePolicyPriorityMsg'),
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_policies') }),
          t,
        );
      });
  };

  applyChanges = () => {
    const { t } = this.props;
    // send request to the invoker
    axios
      .put(
        window.location.origin +
          this.config.serverConfig.invoker.uri +
          this.config.serverConfig.invoker.deviceMgt +
          '/policies/apply-changes',
        'null',
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then(res => {
        if (res.status === 200) {
          this.fetchPolicies();
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_changeAppliedMsg'),
          });
          window.setTimeout(() => {
            window.location.href =
              window.location.origin + `/${this.config.appName}/policies`;
          }, 1000);
        }
      })
      .catch(error => {
        handleApiError(error, t('api_applyChangesToDeviceError'), t);
      });
  };

  render() {
    const { isApplyChangesDisable } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div>
          <Button
            type="primary"
            size={'default'}
            onClick={this.onSavePriorityChanges}
            className={styles.priorityOrder}
            disabled={
              !isAuthorized(this.config.scopes, ['perm:policies:priorities']) ||
              !isApplyChangesDisable
            }
          >
            <HtmlComments
              permission={'/permission/admin/device-mgt/policies/manage'}
            />
            {t('label_saveNewPriorityOrder')}
          </Button>
          <Button
            type="primary"
            size={'default'}
            onClick={this.applyChanges}
            className={styles.applyChangesButton}
            disabled={
              !isAuthorized(this.config.scopes, ['perm:policies:changes']) ||
              isApplyChangesDisable
            }
          >
            <HtmlComments
              permission={'/permission/admin/device-mgt/policies/manage'}
            />
            {t('label_applyChangesToDevice')}
          </Button>
        </div>
        <div>
          <Card
            size="small"
            className={styles.policyListHeader}
            style={
              isAuthorized(this.config.scopes, [
                'perm:policies:priorities',
                'perm:policies:changes',
              ])
                ? {
                    marginBottom: '5px',
                    marginLeft: '38px',
                    background: '#fafafa',
                  }
                : {
                    pointerEvents: 'none',
                    opacity: '0.5',
                    marginBottom: '5px',
                    marginLeft: '38px',
                    background: '#fafafa',
                  }
            }
          >
            <HtmlComments
              permission={'/permission/admin/device-mgt/policies/manage'}
            />
            <Row justify="space-around" align="middle">
              <Col span={2} style={{ paddingLeft: 50 }}>
                <Text>{t('label_priorityID')}</Text>
              </Col>
              <Divider type="vertical" />
              <Col span={1}>
                <Text>{t('label_deviceType')}</Text>
              </Col>
              <Divider type="vertical" />
              <Col span={8}>
                <Text>{t('label_policyName')}</Text>
              </Col>
              <Divider type="vertical" />
              <Col span={2}>
                <Text>{t('label_ownershipType')}</Text>
              </Col>
              <Divider type="vertical" />
              <Col span={2}>
                <Text>{t('label_assignedRole')}</Text>
              </Col>
              <Divider type="vertical" />
              <Col span={2}>
                <Text>{t('label_complianceType')}</Text>
              </Col>
            </Row>
          </Card>
          <PolicyList
            policies={this.state.policies}
            onChangeList={this.onChangeList}
          />
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(PolicyPriority));
