/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';

import {
  UserOutlined,
  AimOutlined,
  AppstoreOutlined, TableOutlined, EnvironmentOutlined, AlertOutlined, ControlOutlined, HddOutlined,
} from '@ant-design/icons';
import parser from 'html-react-parser';
import { Layout, Menu, Button, Avatar, notification, Tooltip } from 'antd';
import { Switch, Link } from 'react-router-dom';
import RouteWithSubRoutes from '../../components/RouteWithSubRoutes';
import './styles.module.css';
import styles from './styles.module.css';
import { withConfigContext } from '../../components/ConfigContext';
import axios from 'axios';
import Navigator from './components/Navigator';
import { handleApiError } from '../../services/utils/errorHandler';
import { getUiConfig } from '../../services/utils/uiConfigHandler';
import { withTranslation } from 'react-i18next';
import { Footer } from 'antd/es/layout/layout';
import { randomizeImageUrlToAvoidLoadingFromCache } from '../../services/utils/urlUtils';
import { archiveHubspotConversations } from '../../services/utils/hubspotChatHandler';
import logoWhiteCropped from '../../../public/images/logoIconNew.svg';
import logoIMG from '../../../public/images/ATMlogo.svg';

const { Header, Content, Sider } = Layout;

export const RoutesContext = React.createContext();

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;

    this.state = {
      routes: props.routes,
      selectedKeys: [],
      deviceTypes: [],
      currentRoute: 'devices',
      isChangePasswordModalVisible: false,
      collapsed: true,
      tenantDomain: this.config.userDomain,
      run: true,
      stepIndex: 0,
    };
  }

  componentDidMount() {
    if (this.config.enableNotificationCheck) {
      this.interval = setInterval(() => {
        this.fetchData();
      }, this.config.notificationCheckInterval);
    }
    this.setState({
      isTrackerEnabled: false,
      trackerServer: null,
    });

    if (!localStorage.getItem('ui-tour')) {
      localStorage.setItem('ui-tour', 1);
      this.setState({ run: true });
    } else {
      this.setState({ run: false });
    }
  }

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  // fetch data from api
  fetchData = () => {
    const { t } = this.props;
    const apiUrl =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      this.config.serverConfig.invoker.deviceMgt +
      '/notifications?status=NEW&offset=0&limit=1';

    // send request to the invoker
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          const prevNotificationCookie = localStorage.getItem('notification');
          if (
            prevNotificationCookie &&
            prevNotificationCookie !== 'undefined'
          ) {
            const prevNotification = JSON.parse(prevNotificationCookie);
            if (
              prevNotification &&
              res.data.data.notifications.length > 0 &&
              prevNotification.id !== res.data.data.notifications[0].id &&
              res.data.data.notifications[0].description ===
              this.config.chatRequestDescription
            ) {
              notification.success({
                message: t('api_newNotificationMsg'),
                duration: 10,
                description: this.config.chatRequestDescription,
                btn: (
                  <Button onClick={this.onClickNotification}>
                    Go To Notifications
                  </Button>
                ),
              });
            }
          } else if (
            res.data.data.notifications.length > 0 &&
            res.data.data.notifications[0].description ===
            this.config.chatRequestDescription
          ) {
            notification.success({
              message: t('api_newNotificationMsg'),
              duration: 10,
              description: this.config.chatRequestDescription,
              btn: (
                <Button onClick={this.onClickNotification}>
                  Go To Notifications
                </Button>
              ),
            });
          }
          localStorage.setItem(
            'notification',
            JSON.stringify(res.data.data.notifications[0]),
          );
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_notifications') }),
          t,
        );
      });
  };

  onClickNotification = () => {
    window.location =
      window.location.origin +
      `/${this.config.serverConfig.platform}/notifications`;
  };

  setCurrentRoute = key => {
    this.setState({
      currentRoute: key,
    });
  };

  logout = () => {
    const { t } = this.props;
    getUiConfig(this.config, t).then(uiConfig => {
      if (uiConfig !== undefined) {
        let logoutUri;
        //Commented out due to single sign-on is not needed for the moment
        // if (uiConfig.isSsoEnable) {
        //   logoutUri =
        //     window.location.origin +
        //     this.props.context.serverConfig.ssoLogoutUri;
        // }
        // else {
          logoutUri =
            window.location.origin + this.props.context.serverConfig.logoutUri;
        // }
        axios
          .post(logoutUri)
          .then(res => {
            // if the api call status is correct then user will logout and then it goes to login page
            if (res.status === 200) {
              //Commented out due to single sign-on is not needed for the moment
              // if (uiConfig.isSsoEnable) {
              //   window.location =
              //     window.location.origin +
              //     this.config.serverConfig.ssoLoginUri +
              //     '?redirect=' +
              //     window.location.origin +
              //     `/${this.config.appName}`;
              // } else {
                window.location =
                  window.location.origin + `/${this.config.appName}/login`;
              // }
            }
          })
          .catch(function (error) {
            notification.error({
              message: t('api_errorMsg'),
              duration: 0,
              description: t('api_logoutError'),
            });
          });
      }
    });
  };

  trackerCon = () => {
    const { t } = this.props;
    getUiConfig(this.config, t).then(uiConfig => {
      let traccarUri = this.state.trackerServer + '/?token=';

      axios
        .get(
          window.location.origin +
          this.config.serverConfig.invoker.uri +
          '/traccar-mgt/v1.0/traccar/token',
        )
        .then(res => {
          if (res.status === 200) {
            this.setState({
              isModalVisible: false,
              loading: false,
            });
            notification.success({
              message: t('api_successMsg'),
              duration: 4,
              description: t('api_loadConfigMsg', {
                label: t('label_traccar'),
              }),
            });
            console.log(res);
            window.open(traccarUri + res.data.data);
          }
        })
        .catch(error => {
          handleApiError(
            error,
            t('api_loadError', { label: t('label_traccar') }),
            t,
          );
          this.setState({ loading: false });
        });
    });
  };

  onChangePassword = () => {
    this.setState({
      isChangePasswordModalVisible: true,
    });
  };

  getItem = (label, key, icon, children, className, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
      className,
    };
  };

  sliderClick = item => {
    this.setState({ sliderSelectedTab: item.key });
    let path;

    if (item.key === 'store') {
      window.open('/store');
    } else if (item.key === 'app-publisher') {
      window.open(`/${this.config.publisherAppName}`);
    } else if (item.key === 'device-tracker') {
      this.trackerCon();
    } else if (item.key === 'allFactoryFlowLayoutsPenAssembly') {
      path = '/facility-monitoring/factory/flowlayouts';
    } else if (item.key === 'allFactoryFlowlayoutsWeeklySummary') {
      path = '/facility-monitoring/factory/weeklysummary';
    } else if (item.key === 'allOutputPenAssembly') {
      path = '/facility-monitoring/output/penassembly';
    } else if (item.key === 'allUsageView') {
      path = '/facility-monitoring/usage/view';
    } else if (item.key === 'allHistoricalUsage') {
      path = '/facility-monitoring/usage/historicalusage';
    } else if (item.key === 'allInvoiceView') {
      path = '/facility-monitoring/invoice/view';
    }else if (item.key === 'allFacilityMeterAssignment') {
      path = '/facility-monitoring/facility/assignment';
    }else if (item.key === 'allOtherDevicesHistory') {
      path = '/facility-monitoring/other/${deviceType}/${deviceId}';
    }else if (item.key === 'allMeterInventory') {
      path = '/facility-monitoring/device/inventory';
    } else if (item.key === 'allDeviceAlertOverview') {
      path = '/facility-monitoring/device-alert-overview';
    } else if (item.key === 'allMeterData') {
      path = '/facility-monitoring/meter/data';
    } else if (item.key === 'allDeviceView') {
      path = '/facility-monitoring/device/view/${deviceType}/${deviceId}';
    }else if (item.key === 'allDeviceTroubleshootView') {
      path = '/facility-monitoring/device/view/${deviceType}/${deviceId}/troubleshoot';
    } else if (item.key === 'allDeviceLocationView') {
      path = '/facility-monitoring/device/view/${deviceType}/${deviceId}/location';
    } else if (item.key === 'allAlertsView') {
      path = '/facility-monitoring/alerts';
    } else if (item.key === 'allAlertsPerDevice') {
      path = '/facility-monitoring/alerts-per-device';
    } else if (item.key === 'allAlertsPerEvent') {
      path = '/facility-monitoring/alerts-per-event';
    } else if (item.key === 'allAlertsPerLevel') {
      path = '/facility-monitoring/alerts-per-level';
    } else if (item.key === 'allOtherDevicesUPS') {
      path = '/facility-monitoring/other/ups';
    } else if (item.key === 'allMeterReadingsHistorical') {
      path = '/facility-monitoring/read/historical';
    } else if (item.key === 'allMeterReadingsCurrent') {
      path = '/facility-monitoring/read/current';
    } else if (item.key === 'allMeterReading') {
      path = '/facility-monitoring/read/operations/readings';
    } else if (item.key === 'allOperationResponse') {
      path = '/facility-monitoring/read/operations/activities';
    } else if (item.key === 'allInvoiceHistory') {
      path = '/facility-monitoring/invoice/history';
    } else if (item.key === 'allProfileHistoricalReading') {
      path = '/facility-monitoring/load/historical';
    } else if (item.key === 'allProfileData') {
      path = '/facility-monitoring/load/profile-data';
    } else if (item.key === 'allBreakdowns') {
      path = '/facility-monitoring/breakdowns';
    } else if (item.key === 'allEnergy') {
      path = '/facility-monitoring/energy';
    } else if (item.key === 'allQuality') {
      path = '/facility-monitoring/quality';
    } else if (item.key === 'allOperatorsPenAssembly') {
      path = '/facility-monitoring/operators';
    } else if (item.key === 'allHourlyConsumption') {
      path = '/facility-monitoring/consumption';
    } else if (item.key === 'allProductsPenAssembly') {
      path = '/facility-monitoring/products';
    } else if (item.key === 'allDevicesPenAssembly') {
      path = '/facility-monitoring/devices';
    } else if (item.key === 'deviceTypes') {
      path = '/facility-monitoring/devices/types';
      path = '/facility-monitoring/devices/types';
    } else if (item.key === 'certificates') {
      path = '/facility-monitoring/configurations/certificates';
      path = '/facility-monitoring/configurations/certificates';
    } else if (item.key === 'platform-configuration') {
      path = '/facility-monitoring/configurations/platform-configuration/general-config/general';
    } else if (
      item.key === 'policies' ||
      item.key === 'geo-fences' ||
      item.key === 'device-locations' ||
      item.key === 'users' ||
      item.key === 'roles' ||
      item.key === 'groups' ||
      item.key === 'dashboard' ||
      item.key === 'analytics'
    ) {
      path = '/facility-monitoring/' + item.key;
      path = '/facility-monitoring/' + item.key;
    } else {
      path = '/facility-monitoring/devices/' + item.key;
      path = '/facility-monitoring/devices/' + item.key;
    }

    this.props.history.push(path);
  };

  headerClick = item => {
    let path;

    if (item.key === 'store') {
      window.open('/store');
    } else if (item.key === 'user') {
      path = `/${this.config.appName}/users?add-new-user=true`;
    } else if (item.key === 'deviceType') {
      path = `/${this.config.appName}/devices/types?add-device-type=true`;
    } else if (item.key === 'role') {
      path = `/${this.config.appName}/roles?add-new-role=true`;
    } else if (item.key === 'group') {
      path = `/${this.config.appName}/groups?add-new-group=true`;
    } else if (item.key === 'policy') {
      path = `/${this.config.appName}/policy/add`;
    } else if (item.key === 'geo-fence') {
      path = `/${this.config.appName}/geo-fences?add-new-geo-fence=true`;
    } else if (item.key === 'certificate') {
      path = `/${this.config.appName}/configurations/certificates?add-new-certificate=true`;
    } else if (item.key === 'dep-profile') {
      path = `/${this.config.appName}/devices/dep-profiles?add-dep-profile=true`;
    } else if (item.key === 'notifications') {
      path = `/${this.config.appName}/notifications`;
    } else if (item.key === 'billing') {
      path = `/${this.config.appName}/billing`;
    } else if (item.key === 'change-password') {
      this.onChangePassword();
    } else if (item.key === 'ui-tour') {
      this.setState({ run: true, stepIndex: 0 });
    } else if (item.key === 'logout') {
      archiveHubspotConversations(this.config);
      this.logout();
    }

    this.props.history.push(path);
  };

  render() {
    const { collapsed } = this.state;
    const { t, theme } = this.props;
    const logo = randomizeImageUrlToAvoidLoadingFromCache(theme.logo.logoUrl);
    const logoIcon = randomizeImageUrlToAvoidLoadingFromCache(
      theme.logoIcon.logoIconUrl,
    );

      const items = [
          this.getItem(
              t('home_menu_meterInventory'),
              'allMeterInventory',
              <AppstoreOutlined/>,
              null,
              styles.customItem
          ),
          this.getItem(
              t('home_menu_device_alert_overview'),
              'allDeviceAlertOverview',
              <HddOutlined/>,
              null,
              styles.customItem,
          )
        ,this.getItem(
            <span id="alerts">{t('home_menu_alerts')}</span>,
            'alerts',
            <TableOutlined />,
            [
              this.getItem(
                  t('home_menu_alerts_view'),
                  'allAlertsView',
                  <AimOutlined />,
                  null,
                  styles.customItem,
              ),
              this.getItem(
                  t('home_menu_alerts_per_device'),
                  'allAlertsPerDevice',
                  <EnvironmentOutlined />,
                  null,
                  styles.customItem,
              ),
              this.getItem(
                  t('home_menu_alerts_per_level'),
                  'allAlertsPerLevel',
                  <AlertOutlined />,
                  null,
                  styles.customItem,
              ),
              this.getItem(
                  t('home_menu_alerts_per_event'),
                  'allAlertsPerEvent',
                  <ControlOutlined />,
                  null,
                  styles.customItem,
              ),
            ]
        ),
      ];

    const headerItems = [
      this.getItem(
        <Avatar
          id="profile"
          className={styles.avatar}
          icon={<UserOutlined />}
        />,
        'user-avatar',
        null,
        [
          this.getItem(this.config.user, this.config.user),
          this.getItem(t('home_menu_logout'), 'logout'),
        ],
        null,
      ),
    ];

    return (
      <>
        <Layout>
          <Sider
            className="site-layout-background"
            collapsible
            collapsed={collapsed}
            onCollapse={this.onCollapse}
            width={'258px'}
            style={{ minHeight: '100vh' }}
          >
            <div>
              <Link to={`/${this.config.appName}`}>
                <div className={styles.logo}>
                  {collapsed ? (
                      <img src={logoWhiteCropped} alt="LogoCropped"/>
                  ) : (
                      <img src={logoIMG} alt="Logo"/>
                  )}
                </div>
              </Link>
            </div>
            <Menu
              theme="dark"
              mode="inline"
              onClick={this.sliderClick}
              selectedKeys={[this.state.currentRoute]}
              defaultOpenKeys={['facility-monitoring', 'devices']}
              style={{
                background: 'transparent',
              }}
              items={items}
            />
          </Sider>
          <Layout className="site-layout">
            <Header className={styles.header}>
              <AimOutlined className={styles.searchIcon} />
              <Navigator />
              <Menu
                mode="horizontal"
                className={styles.rightAlignedMenu}
                onClick={this.headerClick}
                selectedKeys={[this.state.currentRoute]}
                items={headerItems}
              />
            </Header>

            <RoutesContext.Provider
              value={{ setCurrentRoute: this.setCurrentRoute }}
            >
              <Content style={{ margin: '20px 20px' }}>
                <Layout
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 10,
                    minHeight: '80vh',
                  }}
                >
                  <Switch>
                    {this.state.routes.map(route => (
                      <RouteWithSubRoutes key={route.path} {...route} />
                    ))}
                  </Switch>
                </Layout>
              </Content>
            </RoutesContext.Provider>
            <Footer
              style={{
                textAlign: 'center',
                position: 'sticky',
                width: '100%',
                bottom: 0,
                height: 20,
                paddingBottom: 40,
              }}
            >
              {parser('Entgra Facility Monitoring | © 2024 , All Rights Reserved.')}
            </Footer>
          </Layout>
        </Layout>
      </>
    );
  }
}

export default withConfigContext(withTranslation()(Home));
