/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Card, Col, Row } from 'antd';
import installAgent from '../../../../../../../../../public/images/install_agent.png';
import setProfile from '../../../../../../../../../public/images/set_profile.png';
import registration from '../../../../../../../../../public/images/registration.png';
import register from '../../../../../../../../../public/images/register.png';
import windowsEnrollmentStepOne from '../../../../../../../../../public/images/windows_enrollment_step_1.png';
import windowsEnrollmentStepTwo from '../../../../../../../../../public/images/windows_enrollment_step_2.png';
import windowsEnrollmentStepThree from '../../../../../../../../../public/images/windows_enrollment_step_3.png';
import windowsEnrollmentStepFour from '../../../../../../../../../public/images/windows_enrollment_step_4.png';
import windowsEnrollmentStepFive from '../../../../../../../../../public/images/windows_enrollment_step_5.png';
import { Trans, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

class ManualEnrollment extends React.Component {
  onClick = () => {
    this.props.goBack();
  };

  render() {
    const { selectedDeviceType, t } = this.props;
    const screenshotHeight = 'auto';
    const screenshotWidth = '75%';
    const cardHeight = 185;

    return (
      <div>
        {selectedDeviceType.toLowerCase() === 'android' && (
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <h1>{t('label_step')} 1</h1>
              <Card
                bordered={false}
                style={{ height: cardHeight, marginBottom: 5 }}
              >
                <p>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  <Trans
                    i18nKey="manual_enrollment_step1_description"
                    selectedDeviceType={t(`label_${selectedDeviceType}`)}
                  >
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    Let's start by installing the {{ selectedDeviceType }} agent
                    on your device. Open the downloaded file, and tap INSTALL.
                  </Trans>
                </p>
              </Card>
              <img
                src={installAgent}
                height={screenshotHeight}
                width={screenshotWidth}
              />
            </Col>
            <Col span={6}>
              <h1>{t('label_step')} 2</h1>
              <Card
                bordered={false}
                style={{ height: cardHeight, marginBottom: 5 }}
              >
                <p>
                  {/* manual_enrollment_step2_description*/}
                  <Trans i18nKey="manual_enrollment_step2_description">
                    Tap Skip to proceed with the default enrollment process.
                  </Trans>
                </p>
              </Card>
              <img
                src={setProfile}
                height={screenshotHeight}
                width={screenshotWidth}
              />
            </Col>
            <Col span={6}>
              <h1>{t('label_step')} 3</h1>
              <Card
                bordered={false}
                style={{ height: cardHeight, marginBottom: 5 }}
              >
                <p>{t('enterServerAddress_infoTxt')}</p>
              </Card>
              <img
                src={registration}
                height={screenshotHeight}
                width={screenshotWidth}
              />
            </Col>
            <Col span={6}>
              <h1>{t('label_step')} 4</h1>
              <Card
                bordered={false}
                style={{ height: cardHeight, marginBottom: 5 }}
              >
                <div>
                  <p>
                    {t('form_enterYourOrganization')}: {t('label_carbonSuper')}
                  </p>
                  <p>
                    {t('label_username')}: {t('label_admin')}
                  </p>
                  <p>
                    {t('label_password')}: {t('label_yourPassword')}
                  </p>
                </div>
              </Card>
              <img
                src={register}
                height={screenshotHeight}
                width={screenshotWidth}
              />
            </Col>
          </Row>
        )}
        {selectedDeviceType.toLowerCase() === 'windows' && (
          <div style={{ paddingLeft: '10%' }}>
            <Row gutter={[16, 16]}>
              <Col span={4}>
                <h1>{t('label_step')} 1</h1>
                <Card
                  bordered={false}
                  style={{ height: cardHeight, marginBottom: 5 }}
                >
                  <p>{t('openWorkplace_infoTxt')}</p>
                </Card>
                <img
                  src={windowsEnrollmentStepOne}
                  alt={'windowsEnrollmentStepOne'}
                  height={screenshotHeight}
                  width={'90%'}
                />
              </Col>
              <Col span={4}>
                <h1>{t('label_step')} 2</h1>
                <Card
                  bordered={false}
                  style={{ height: cardHeight, marginBottom: 5 }}
                >
                  <p>{t('addAccForRegister_infoTxt')}</p>
                </Card>
                <img
                  src={windowsEnrollmentStepTwo}
                  alt={'windowsEnrollmentStepTwo'}
                  height={screenshotHeight}
                  width={'90%'}
                />
              </Col>
              <Col span={4}>
                <h1>{t('label_step')} 3</h1>
                <Card
                  bordered={false}
                  style={{ height: cardHeight, marginBottom: 5 }}
                >
                  <p>{t('enterEmail_infoTxt')}</p>
                </Card>
                <img
                  src={windowsEnrollmentStepThree}
                  alt={'windowsEnrollmentStepThree'}
                  height={screenshotHeight}
                  width={'90%'}
                />
              </Col>
              <Col span={4}>
                <h1>{t('label_step')} 4</h1>
                <Card
                  bordered={false}
                  style={{ height: cardHeight, marginBottom: 5 }}
                >
                  <p>{t('enterYour_subTxt')}:</p>
                  <div>
                    <p>
                      {t('label_username')}: {t('label_usernameInEmail')}
                    </p>
                    <p>
                      {t('label_password')}: {t('label_passwordInEmail')}
                    </p>
                  </div>
                </Card>
                <img
                  src={windowsEnrollmentStepFour}
                  alt={'windowsEnrollmentStepFour'}
                  height={screenshotHeight}
                  width={'90%'}
                />
              </Col>
              <Col span={4}>
                <h1>{t('label_step')} 5</h1>
                <Card
                  bordered={false}
                  style={{ height: cardHeight, marginBottom: 5 }}
                >
                  <p>{t('policyAgreement_infoTxt')}</p>
                </Card>
                <img
                  src={windowsEnrollmentStepFive}
                  alt={'windowsEnrollmentStepFive'}
                  height={screenshotHeight}
                  width={'90%'}
                />
              </Col>
            </Row>
          </div>
        )}
        <div style={{ textAlign: 'right', marginTop: 50 }}>
          <Button
            style={{ marginRight: 10 }}
            type="default"
            size={'default'}
            onClick={this.onClick}
          >
            {t('label_back')}
          </Button>
          <Link to={`/${this.props.appName}/devices/`} key="btnFinish">
            <Button type="primary">{t('label_finish')}</Button>
          </Link>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ManualEnrollment);
