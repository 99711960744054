import React from 'react';
import { Modal } from 'antd';

class NoAccessDescriptionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
  }

  componentDidMount() {
    this.setState({
      isModalVisible: true,
    });
  }

  onCloseModal = () => {
    this.setState({
      isModalVisible: false,
    });
    this.props.onCloseAdvancedNoAccessModal();
  };

  render() {
    const { isModalVisible } = this.state;
    const { title, content, imgSrc } = this.props;
    const screenshotHeight = 'auto';
    const screenshotWidth = '75%';
    return (
      <div>
        <Modal
          title={title}
          open={isModalVisible}
          onCancel={this.onCloseModal}
          footer={null}
          width={620}
          maskClosable={false}
        >
          {content}
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <img
              src={imgSrc}
              height={screenshotHeight}
              width={screenshotWidth}
              alt={title}
            />
          </div>
          <div></div>
        </Modal>
      </div>
    );
  }
}

export default NoAccessDescriptionModal;
