/*
 * Copyright (C) 2022. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import styles from '../../styles.module.css';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import { withTranslation } from 'react-i18next';

class StepOne extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {};
  }

  render() {
    let {
      selectedDeviceType,
      deviceType,
      t,
      downloadAgentTooltip,
      downloadAgentDisabled,
    } = this.props;
    return (
      <>
        {selectedDeviceType.toLowerCase() === 'ios' ? (
          <>
            <h3 style={{ marginTop: 5 }}>
              {t('label_step')} 01 ({t('label_optional')}) -{' '}
              {t('getAgent_infoTxt', {
                deviceType: t(`label_${selectedDeviceType.toLowerCase()}`),
              })}
            </h3>
            <p>
              {t('download_ios_qr_description', {
                selectedDeviceType: selectedDeviceType.toLowerCase(),
              })}
            </p>
            <p>
              {t('download_agent_description', {
                selectedDeviceType: selectedDeviceType.toLowerCase(),
              })}
            </p>
          </>
        ) : (
          <>
            <h3 style={{ marginTop: 5 }}>
              {t('label_step')} 01 -{' '}
              {t('getAgent_infoTxt', {
                deviceType: t(`label_${selectedDeviceType.toLowerCase()}`),
              })}
            </h3>
            <p>
              {t('download_qr_description', {
                selectedDeviceType: selectedDeviceType.toLowerCase(),
              })}
            </p>
            <p>
              {t('download_agent_description', {
                selectedDeviceType: selectedDeviceType.toLowerCase(),
              })}
            </p>
          </>
        )}
        <div style={{ marginBottom: 10 }} className={styles.btnContainer}>
          <a href={this.props.getAgentDownloadURL()}>
            <Tooltip title={downloadAgentTooltip}>
              <Button
                style={{ marginRight: 10 }}
                type="primary"
                icon={<DownloadOutlined />}
                disabled={downloadAgentDisabled}
                onClick={this.props.onClickDownloadAgent}
              >
                {t('label_downloadAgent')}
              </Button>
            </Tooltip>
          </a>
          {deviceType === 'ios' ? (
            <Button type="primary" onClick={this.props.showDownloadQR}>
              {t('label_generateDownloadQR')}
            </Button>
          ) : null}
        </div>
        <p className={styles.btnContainer}>
          {t('label_needHelp')} {t('label_read')}&nbsp;
          <a
            href={`https://docs.entgra.io/${this.config.serverVersion}/enrollment/#${this.props.enrollmentGuideId}`}
          >
            {t('label_ioTServerDoc')}
          </a>
        </p>
      </>
    );
  }
}

export default withConfigContext(withTranslation()(StepOne));
