export function getCompanyDetails(doc, pdfConfig, param) {
    //company details
    doc.setTextColor(pdfConfig.colorGray);
    doc.setFontSize(pdfConfig.fieldTextSize);
    pdfConfig.totalHeight += pdfConfig.subLineHeight;
    pdfConfig.totalHeight += pdfConfig.subLineHeight;
    if (param.company.address_no !== "") {
        doc.text(pdfConfig.docWidth - 10, pdfConfig.totalHeight, param.company.address_no, "right");
        pdfConfig.totalHeight += pdfConfig.subLineHeight;
    }
    if (param.company.address_road !== "") {
        doc.text(pdfConfig.docWidth - 10, pdfConfig.totalHeight, param.company.address_road, "right");
        pdfConfig.totalHeight += pdfConfig.subLineHeight;
    }
    if (param.company.address_city !== "") {
        doc.text(pdfConfig.docWidth - 10, pdfConfig.totalHeight, param.company.address_city, "right");
        pdfConfig.totalHeight += pdfConfig.subLineHeight;
    }
    if (param.company.postalCode !== "") {
        doc.text(pdfConfig.docWidth - 10, pdfConfig.totalHeight, param.company.postalCode, "right");
        pdfConfig.totalHeight += pdfConfig.subLineHeight;
    }
    if (param.company.phone !== "") {
        doc.text(pdfConfig.docWidth - 10, pdfConfig.totalHeight, param.company.phone, "right");
        pdfConfig.totalHeight += pdfConfig.subLineHeight;
    }
    if (param.company.email !== "") {
        doc.text(pdfConfig.docWidth - 10, pdfConfig.totalHeight, param.company.email, "right");
        pdfConfig.totalHeight += pdfConfig.subLineHeight;
    }
    if (param.company.email_1 !== "") {
        doc.text(pdfConfig.docWidth - 10, pdfConfig.totalHeight, param.company.email_1, "right");
        pdfConfig.totalHeight += pdfConfig.subLineHeight;
    }
    if (param.company.website !== "") {
        doc.text(pdfConfig.docWidth - 10, pdfConfig.totalHeight, param.company.website, "right");
        pdfConfig.totalHeight += pdfConfig.subLineHeight;
    }
    
    //end company details
    return {
        doc: doc,
        pdfConfig: pdfConfig
    };

}
