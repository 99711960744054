/*
 * Copyright (C) 2018 - 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Col,
  Result,
  Row,
  Select,
  Spin,
  Card,
  Button,
  Space
} from 'antd';
import { withConfigContext } from '../../../../components/ConfigContext';
import { RoutesContext } from '../../index';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import { timeRanges } from '../../../../services/utils/commonUtils';
import moment from 'moment';
import DownloadOutlined from '@ant-design/icons/es/icons/DownloadOutlined';
import {getUsageViewTemplate} from '../../../../components/ReportTemplate/Templates/UsageViewTemplate/index';

const url = "https://mgt.sg.entgra.net/gdashboard/d-solo/c6a72c8e-4d28-4b49-b746-5e2d7a106d5f/consumption?orgId=1&from=1689763798870&to=1689785398870&panelId=1"
const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      breadcrumbName: t('home_menu_usage'),
    },
    {
      breadcrumbName: t('home_menu_usageView'),
    },
  ];
};


class UsageView extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    const { t } = this.props;
    const currentDate = new Date();
    currentDate.setHours(8, 0, 0, 0);
    this.timeRangeOptions = timeRanges(t);
    this.state = {
      isDashboardAccessible: false,
      isLoading: true,
      shiftType: 'all',
      from: currentDate.getTime(),
      range: this.timeRangeOptions[7].value,
    };
  }

  checkDashboardAccess = async (dashboardUrl) => {
    const accessible = true;
    this.setState({ isDashboardAccessible: accessible, isLoading: false });
  };

  componentDidMount() {
    this.context.setCurrentRoute('allUsageView');
    this.checkDashboardAccess(url);
  }

  onClickDownloadReport = () => {

    const obj = {
      fileName : "Usage_View",
      orientationLandscape : false,
      company: {
        name: "Entgra Private Limited",
        address_no: "2nd Floor, No 106, Bernard's Business Park,",
        address_road: "Dutugemunu St,",
        postalCode: "10250",
        phone: " 077 301 7743",
        email: "contact@entgra.io",
        email_1: "",
        website: "https://entgra.io/",
      },
      customerInfo: {
        label: "Customer Information",
        name: "Name: Entgra Private Limited",
        address: "Address : 2nd Floor, No 106, Bernard's Business Park, Dutugemunu St.",
        accountStatus: "Account Status : Active",
      },
      reportBody: {
        label: "Daily Usage",
        num: "",
        reportGenDate: moment().calendar(),
        TotalConsumptionLabel: "Total Consumption",
        TotalConsumptionHeaderBorder: false,
        TotalConsumptionTableBodyBorder: false,
        TotalConsumptionHeader: [{title: "Total (kWh)"},{title: "Last Month (kWh)"},{title: "This month up to date (kWh)"}],
        TotalConsumptionTable: [["1228.5 kWh","103 kWh", "101 kWh"]],
        AverageConsumptionLabel: "Average Consumption",
        AverageConsumptionHeaderBorder: false,
        AverageConsumptionTableBodyBorder: false,
        AverageConsumptionHeader: [{title: "Monthly"},{title: "Daily"}],
        AverageConsumptionTable: [["102.4 kWh","3.41 kWh"]],
        DailyConsumptionLabel: "Daily Consumption",
        DailyConsumptionHeaderBorder: false,
        DailyConsumptionTableBodyBorder: false,
        DailyConsumptionHeader: [{title: "Date"},{title: "Consumption (kWh)"}],
        DailyConsumptionTable: [
        [moment().subtract(30, 'days').format('DD/MM/YYYY'),"3.4 kWh"],
        [moment().subtract(29, 'days').format('DD/MM/YYYY'),"3.4 kWh"],
        [moment().subtract(28, 'days').format('DD/MM/YYYY'),"3.1 kWh"],
        [moment().subtract(27, 'days').format('DD/MM/YYYY'),"3.2 kWh"],
        [moment().subtract(26, 'days').format('DD/MM/YYYY'),"3.5 kWh"],
        [moment().subtract(25, 'days').format('DD/MM/YYYY'),"3.0 kWh"],
        [moment().subtract(24, 'days').format('DD/MM/YYYY'),"2.9 kWh"],
        [moment().subtract(23, 'days').format('DD/MM/YYYY'),"2.7 kWh"],
        [moment().subtract(22, 'days').format('DD/MM/YYYY'),"3.1 kWh"],
        [moment().subtract(21, 'days').format('DD/MM/YYYY'),"3.6 kWh"],
        [moment().subtract(20, 'days').format('DD/MM/YYYY'),"3.7 kWh"],
        [moment().subtract(19, 'days').format('DD/MM/YYYY'),"3.6 kWh"],
        [moment().subtract(18, 'days').format('DD/MM/YYYY'),"3.8 kWh"],
        [moment().subtract(17, 'days').format('DD/MM/YYYY'),"3.2 kWh"],
        [moment().subtract(16, 'days').format('DD/MM/YYYY'),"3.1 kWh"],
        [moment().subtract(15, 'days').format('DD/MM/YYYY'),"3.0 kWh"],
        [moment().subtract(14, 'days').format('DD/MM/YYYY'),"3.2 kWh"],
        [moment().subtract(13, 'days').format('DD/MM/YYYY'),"3.4 kWh"],
        [moment().subtract(12, 'days').format('DD/MM/YYYY'),"3.6 kWh"],
        [moment().subtract(11, 'days').format('DD/MM/YYYY'),"3.7 kWh"],
        [moment().subtract(10, 'days').format('DD/MM/YYYY'),"3.5 kWh"],
        [moment().subtract(9, 'days').format('DD/MM/YYYY'),"3.3 kWh"],
        [moment().subtract(8, 'days').format('DD/MM/YYYY'),"3.1 kWh"],
        [moment().subtract(7, 'days').format('DD/MM/YYYY'),"2.9 kWh"],
        [moment().subtract(6, 'days').format('DD/MM/YYYY'),"2.2 kWh"],
        [moment().subtract(5, 'days').format('DD/MM/YYYY'),"2.1 kWh"],
        [moment().subtract(4, 'days').format('DD/MM/YYYY'),"2.6 kWh"],
        [moment().subtract(3, 'days').format('DD/MM/YYYY'),"2.8 kWh"],
        [moment().subtract(2, 'days').format('DD/MM/YYYY'),"2.7 kWh"],
        [moment().subtract(1, 'days').format('DD/MM/YYYY'),"2.6 kWh"],
        [moment().format('DD/MM/YYYY'),"2.9 kWh"],
        ],
        CurrentMonthConsumption: "This Month Consumption : 144.2 kWh",
      },
      footer: {text: "Entgra ATM Monitoring | © 2023 , All Rights Reserved."},
      pageEnable: true,
      pageLabel: "",
    };
    getUsageViewTemplate(obj);
  };

  render() {
    const config = this.props.context;
    const { Option } = Select;
    const { t } = this.props;
    const { shiftType, from, range, isDashboardAccessible, isLoading } = this.state;
    const dashboardUrl = `${this.config.grafanaConfig.output}&${range}`;
    //Todo: Back end need to be implemented for range to be used
    const timeRangeOptions = this.timeRangeOptions;
    const { Meta } = Card;

    const handleChange = (value) => {
      console.log(`selected ${value}`);
    };

    return (
      <div>
        <Authorized
          scope={['perm:devices:view']}
          yes={
            <div>
              <PageHeader className={styles.pageHeader} ghost={false}>
                <div className={styles.positioning}>
                  <Row justify="space-between" align="middle" gutter={8}>
                    <Col>
                      <BreadcrumbComponent breadcrumbList={routes(config.appName, t)} />
                    </Col>
                    <Col>
                      <Row align="middle" gutter={[8, 8]}>
                        <Col>
                          <Space wrap>
                            <Select
                              defaultValue="Meter 01"
                              style={{
                                width: 120,
                              }}
                              onChange={handleChange}
                              options={[
                                {
                                  value: 'Meter 01',
                                  label: 'Meter 01',
                                },
                                {
                                  value: 'Meter 02',
                                  label: 'Meter 02',
                                },
                                {
                                  value: 'Meter 03',
                                  label: 'Meter 03',
                                }
                              ]}
                            />
                          </Space>
                        </Col>
                        <Col>
                        <div></div>
                          <Button
                            style={{ marginRight: 10 }}
                            type="primary"
                            icon={<DownloadOutlined />}
                            onClick={this.onClickDownloadReport}
                          >
                            {t('label_downloadReport')}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </PageHeader>
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} key={'customerInfo'} className={styles.sidePadding}>
                  <Card
                    size="small"
                    style={{
                      borderRadius: 10,
                      marginRight: 100,
                      marginTop: 10,
                      background: '#f5f5f5',
                      width: '100%',
                    }}
                    // hoverable
                    bordered={true}
                    actions={[
                      <span>{t('Name')}<br /><b>Entgra (Pvt) Ltd</b> </span>,
                      <span>{t('Address')}<br /><b>106, Bernard Boteju Business Park, Dutugemunu Street, Dehiwala</b></span>
                    ]}
                  >
                    <Meta
                      title={
                        <div>{'Customer Information'}
                          {/* {range}
                          {' '}
                          <SettingOutlined key="setting" onClick={() => this.handleSettingsClick(data)} /> */}
                        </div>
                      }
                    />
                  </Card>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={4} key={'accStatus'} className={styles.sidePadding}>
                  <Card
                    size="small"
                    style={{
                      borderRadius: 10,
                      marginRight: 24,
                      marginTop: 10,
                      background: '#f5f5f5',
                      width: '100%',
                    }}
                    // hoverable
                    bordered={true}
                    actions={[
                      <span>{t('Status')}<br /><b>Active</b></span>,
                    ]}
                  >
                    <Meta
                      title={
                        <div>{'Account'}
                          {/* {range}
                          {' '}
                          <SettingOutlined key="setting" onClick={() => this.handleSettingsClick(data)} /> */}
                        </div>
                      }
                    />
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} key={'totalConsumption'} className={styles.sidePadding}>
                  <Card
                    size="small"
                    style={{
                      borderRadius: 10,
                      marginRight: 24,
                      marginTop: 10,
                      background: '#f5f5f5',
                      width: '100%',
                    }}
                    // hoverable
                    bordered={true}
                    actions={[
                      <span>{t('Total')}<br /><b>1228.5 kWh</b></span>,
                      <span>{t('Last Month')}<br /><b>103 kWh</b></span>,
                      <span>{t('This Month up to date')}<br /><b>101 kWh</b></span>,
                    ]}
                  >
                    <Meta
                      title={
                        <div>{'Total Consumption'}
                          {/* {range}
                          {' '}
                          <SettingOutlined key="setting" onClick={() => this.handleSettingsClick(data)} /> */}
                        </div>
                      }
                    />
                  </Card>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={4} key={'averageConsumption'} className={styles.sidePadding}>
                  <Card
                    size="small"
                    style={{
                      borderRadius: 10,
                      marginRight: 24,
                      marginTop: 10,
                      background: '#f5f5f5',
                      width: '100%',
                    }}
                    // hoverable
                    bordered={true}
                    actions={[
                      <span>{t('Monthly')}<br /><b>102.4 kWh</b></span>,
                      <span>{t('Daily')}<br /><b>3.41 kWh</b></span>,
                    ]}
                  >
                    <Meta
                      title={
                        <div>{'Average Consumption'}
                          {/* {range}
                          {' '}
                          <SettingOutlined key="setting" onClick={() => this.handleSettingsClick(data)} /> */}
                        </div>
                      }
                    />
                  </Card>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={4} key={'predictedConsumption'} className={styles.sidePadding}>
                  <Card
                    size="small"
                    style={{
                      borderRadius: 10,
                      marginRight: 24,
                      marginTop: 10,
                      background: '#f5f5f5',
                      width: '100%',
                    }}
                    // hoverable
                    bordered={true}
                    actions={[
                      <span><br /><b>144.2 kWh</b></span>,
                    ]}
                  >
                    <Meta
                      title={
                        <div>{'This Month Predicted'}
                          {/* {range}
                          {' '}
                          <SettingOutlined key="setting" onClick={() => this.handleSettingsClick(data)} /> */}
                        </div>
                      }
                    />
                  </Card>
                </Col>

              </Row>
              <Row style={{padding:5}}></Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div>
                    {isLoading ? (
                      <div style={{ textAlign: 'center', marginTop: 35 }}>
                        <Spin size="large" />
                      </div>
                    ) : isDashboardAccessible ? (
                      <div className={styles.sidePadding}>
                        <iframe
                          style={{
                            borderRadius: '6px',
                            border: '1px solid #eee',
                          }}
                          className="embed-responsive-item"
                          src={url}
                          height={'400px'}
                          width="100%"
                          frameBorder="0"
                          scrolling={'yes'}
                        />
                      </div>
                      // <IframeWrapper iframeSrc={dashboardUrl} height={'650px'} />
                    ) : (
                      <Result status="404" subTitle={t('label_dashboard_error')} />
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          }
          no={
            <Result
              status="403"
              title={t('noPerm_accessPageTitle')}
              subTitle={t('noPerm_contactSysAdmin')}
            />
          }
        />
      </div>
    );
  }
}

UsageView.contextType = RoutesContext;
export default withConfigContext(withTranslation()(UsageView));
