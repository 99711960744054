import React from 'react';
import { Table, Tooltip, Button, Col } from 'antd';
import moment from 'moment';
import 'moment-timezone';

const MeterReadingTable = ({ devices, count, columns, pagination, loading, handleTableChange, getDetailsUI }) => {
    console.log(devices);
    return (
        <Table
            // rowKey={(record) => `${record.operationId}-${record.serial}`}
            dataSource={devices}
            columns={columns}
            pagination={{
                ...pagination,
                size: 'small',
                total: count,
                disabled: loading,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
            onChange={handleTableChange}
            loading={loading}
            bordered
            expandable={{
                expandedRowRender: record => (
                    <span style={{ background: '#ffffff' }}>
                        <Col span={18} offset={3}>
                             {record && <div>{getDetailsUI(record.response)}</div>}
                        </Col>
                    </span>
                ),
                rowExpandable: record => record.name !== 'Not Expandable',
                expandIconColumnIndex: 9,
                expandIcon: ({ expanded, onExpand, record }) => (
                    <Tooltip placement="top" title={expanded ? 'Hide Details' : 'Show Details'}>
                        <Button
                            type="link"
                            size="small"
                            onClick={e => onExpand(record, e)}
                        >
                            {expanded ? 'Hide Details' : 'Show Details'}
                        </Button>
                    </Tooltip>
                )
            }}
        />
    );
};

export default MeterReadingTable;
