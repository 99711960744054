import { jsPDF } from "jspdf";

export { jsPDF };
//calculate text space 

export function setTextSpaceAllocation(doc, text, size) {
    var lines = doc.splitTextToSize(text, size);
    return {
        text: lines,
        height: doc.getTextDimensions(lines).h + doc.getTextDimensions(lines).h,
    };
}
