/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import axios from 'axios';
import { DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  notification,
  Popconfirm,
  Result,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import TimeAgo from 'javascript-time-ago';
// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import Moment from 'react-moment';
import styles from './styles.module.css';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import { Link } from 'react-router-dom';
import Authorized from '../../../../../../../../components/Authorized';
import AddCertificate from '../AddCertificate';
import { isAuthorized } from '../../../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

const { Paragraph, Text } = Typography;

let config = null;

class CertificateTable extends React.Component {
  constructor(props) {
    super(props);
    config = this.props.context;
    TimeAgo.addLocale(en);
    this.state = {
      data: {},
      pagination: {},
      loading: false,
    };
    const { t } = this.props;
    this.columns = [
      {
        title: t('label_serial_number'),
        dataIndex: 'serialNumber',
      },
      {
        title: t('label_username'),
        dataIndex: 'username',
      },
      {
        title: t('label_certificateVersion'),
        dataIndex: 'certificateVersion',
      },
      {
        title: t('label_certificateSerial'),
        dataIndex: 'certificateserial',
      },
      {
        title: t('label_notBefore'),
        dataIndex: 'notBefore',
        render: notBefore => (
          <Moment format="YYYY/MM/DD HH:mm" date={notBefore} />
        ),
      },
      {
        title: t('label_notAfter'),
        dataIndex: 'notAfter',
        render: notAfter => (
          <Moment format="YYYY/MM/DD HH:mm" date={notAfter} />
        ),
      },
      {
        title: t('label_subject'),
        dataIndex: 'subject',
        render: subject => (
          <Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: true }}
          >
            {subject}
          </Paragraph>
        ),
      },
      {
        title: t('label_issuer'),
        dataIndex: 'issuer',
        render: issuer => (
          <Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: true }}
          >
            {issuer}
          </Paragraph>
        ),
      },
    ];
  }

  componentDidMount() {
    this.setPermittedActions();
    this.fetch();
  }

  // fetch data from api
  fetch = (params = {}) => {
    this.setState({ loading: true });
    // get current page
    const currentPage = params.hasOwnProperty('page') ? params.page : 1;
    const { t } = this.props;
    const extraParams = {
      offset: 10 * (currentPage - 1), // calculate the offset
      limit: 10,
    };

    const encodedExtraParams = Object.keys(extraParams)
      .map(key => key + '=' + extraParams[key])
      .join('&');

    // send request to the invoker
    axios
      .get(
        window.location.origin +
          config.serverConfig.invoker.uri +
          '/certificate-mgt/v1.0/admin/certificates?' +
          encodedExtraParams,
      )
      .then(res => {
        if (res.status === 200) {
          const pagination = { ...this.state.pagination };
          this.setState({
            loading: false,
            data: res.data.data,
            pagination,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_certificates') }),
          t,
        );

        this.setState({ loading: false });
      });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  deleteCertificate = serialNumber => {
    const { t } = this.props;
    axios
      .delete(
        window.location.origin +
          config.serverConfig.invoker.uri +
          '/certificate-mgt/v1.0/admin/certificates?serialNumber=' +
          encodeURIComponent(serialNumber),
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then(res => {
        if (res.status === 200) {
          this.fetch();
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_deleteCertificateMsg'),
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_deleteCertificateError'), t);
      });
  };

  // Method to identify iOS device certificates and return delete confirmation message.
  checkCertificateSubject = serialNumber => {
    const { t } = this.props;
    let certificate = this.state.data.certificates.find(
      certificate => certificate.serialNumber === serialNumber,
    );
    if (certificate.subject.startsWith('CN=Profile Service (')) {
      return t('delete_iOSDeviceCertificate_confirmTxt');
    }
    return t('label_areYouSure');
  };

  setPermittedActions = () => {
    const { t } = this.props;
    if (isAuthorized(config.scopes, ['perm:admin:certificates:delete'])) {
      this.columns.push({
        title: t('label_actions'),
        key: 'actions',
        dataIndex: 'serialNumber',
        render: serialNumber => (
          <Tooltip placement="bottom" title={t('label_removeCertificate')}>
            <HtmlComments
              permission={
                '/permission/admin/device-mgt/admin/certificates/delete'
              }
            />
            <Popconfirm
              placement="top"
              title={this.checkCertificateSubject(serialNumber)}
              onConfirm={() => {
                this.deleteCertificate(serialNumber);
              }}
              okText={t('label_ok')}
              cancelText={t('label_cancel')}
            >
              <a>
                <Text type="danger">
                  <DeleteOutlined />
                </Text>
              </a>
            </Popconfirm>
          </Tooltip>
        ),
      });
    }
  };

  render() {
    const { data, pagination, loading } = this.state;
    const { t } = this.props;
    return (
      <div className={styles.tableContainer}>
        <AddCertificate fetch={this.fetch} />
        {data.count > 0 && (
          <div>
            <Table
              columns={this.columns}
              rowKey={record => record.serialNumber}
              dataSource={data.certificates}
              pagination={{
                ...pagination,
                size: 'small',
                // position: "top",
                total: data.count,
                disabled: loading,
                showTotal: (total, range) =>
                  t('table_pagination', {
                    range1: range[0],
                    range2: range[1],
                    total: total,
                    data: t('home_menu_certificates'),
                  }),
                // showQuickJumper: true
              }}
              loading={loading}
              onChange={this.handleTableChange}
            />
          </div>
        )}
        {data.count === 0 && (
          // todo Change Result icon #issue592
          <div>
            <HtmlComments
              permission={'/permission/admin/device-mgt/admin/certificates/add'}
            />
            <Result
              status="404"
              title={t('noCertificate_Txt')}
              subTitle={
                isAuthorized(config.scopes, ['perm:admin:certificates:add'])
                  ? t('createCertificate_infoTxt')
                  : null
              }
              extra={
                <Authorized
                  scope={['perm:admin:certificates:add']}
                  yes={
                    <div>
                      <HtmlComments
                        permission={
                          '/permission/admin/device-mgt/admin/certificates/add'
                        }
                      />
                      <Link
                        to={`/${config.appName}/configurations/certificates?add-new-certificate=true`}
                      >
                        <Button type="primary">
                          {t('label_createCertificate')}
                        </Button>
                      </Link>
                    </div>
                  }
                />
              }
            />
          </div>
        )}
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(CertificateTable));
