import { createReportTable } from "../../../Util/createReportTable";


export function getBasicTable(doc, pdfConfig, param) {
    
    const basicTable = {
        headerBorder: param.reportBody?.basicHeaderBorder || false,
        tableBodyBorder: param.reportBody?.basicTableBodyBorder || false,
        header: param.reportBody?.basicHeader || [],
        table: param.reportBody?.basicTable || [],
    };

    createReportTable(basicTable, doc, pdfConfig, param);

    return {
        doc: doc,
        pdfConfig: pdfConfig
    }
}
