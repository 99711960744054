/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useState, useEffect, useContext } from 'react';
import { handleApiError } from '../../../../services/utils/errorHandler';
import { Col, Result, Row, Divider, Select, Space, Tooltip } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { withConfigContext } from '../../../../components/ConfigContext';
import { RoutesContext } from '../../index';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import axios from 'axios';
import IframeWrapper from '../../components/IframeWrapper';
import configs from '../../../../../public/conf/config.json';
import HtmlComments from '../../../../components/ReactHtmlComments/HtmlComments';
import { grafanaUrlAccessible } from '../../../../services/utils/urlUtils';
import DateRangeDropdown from '../../components/common/DateRangeDropdown';
import {determineRefreshParam, getUnixTime} from "../../../../services/utils/functions";
import { subDays } from 'date-fns';
import { ReloadOutlined } from "@ant-design/icons";

const AlertsPerEvent = props => {
    const config = props.context;
    const { t } = props;
    const routesContext = useContext(RoutesContext);
    const [deviceIds, setDeviceIds] = useState('var-device=All');
    const [levels, setLevels] = useState('var-level=All');
    const [sensors, setSensors] = useState('var-sensor=All');
    const [isLoading, setIsLoading] = useState(true);
    const [isDashboardAccessible, setIsDashboardAccessible] = useState(false);
    const [devices, setDevices] = useState([]);
    const [count, setCount] = useState(0);
    const [deviceIdOptions, setDeviceIdOptions] = useState([{ value: 'All', label: 'All' }]);
    const [levelOptions, setLevelOptions] = useState([
        { value: 'All', label: 'All' },
        { value: 'CRITICAL', label: 'CRITICAL' },
        { value: 'WARN', label: 'WARN' },
        { value: 'INFO', label: 'INFO' }
    ]);
    const [sensorOptions, setSensorOptions] = useState([
        { value: 'All', label: 'All' },
        { value: 'GridPower', label: 'GridPower' },
        { value: 'Door', label: 'Door' },
        { value: 'Shock', label: 'Shock' },
        { value: 'Temperature', label: 'Temperature' }
    ]);
    const [selectedDeviceIds, setSelectedDeviceIds] = useState(['All']);
    const [selectedLevels, setSelectedLevels] = useState(['All']);
    const [selectedSensors, setSelectedSensors] = useState(['All']);
    const [dashboardUrl, setDashboardUrl] = useState('');
    const [selectFocused, setSelectFocused] = useState(false);
    const [refreshRate, setRefreshRate] = useState('30s');
    const rightNow = new Date();
    const [range, setRange] = useState({
        from: getUnixTime(subDays(rightNow, 2)),
        to: getUnixTime(rightNow),
    });

    const checkDashboardAccess = async () => {
        const dashboard = configs.grafanaConfig.alerts;
        const accessible = await grafanaUrlAccessible(
            `${config.grafanaConfig.reportBase}/${dashboard}`
        );
        setIsDashboardAccessible(accessible);
        setIsLoading(false);
    };

    useEffect(() => {
        // Use the context to set the current route
        routesContext.setCurrentRoute('allAlertsPerEvent');
        checkDashboardAccess();
        if (deviceIds) {
            setIsLoading(true);
            const refreshParam = determineRefreshParam(range.from, range.to, refreshRate);
            const queryParams = `&${deviceIds}&${levels}&${sensors}&from=${range.from}&to=${range.to}${refreshParam}`;

            setDashboardUrl(`${configs.grafanaConfig.alertsPerEvent}${queryParams}`);

        }
    }, [deviceIds, levels, sensors, range.from, range.to, refreshRate]);

    const handleDeviceIdChange = deviceIds => {
        if (deviceIds.includes('All')) {
            setSelectedDeviceIds(['All']);
            setDeviceIds('var-device=All');
        } else {
            setSelectedDeviceIds(deviceIds);
            const deviceIdString = deviceIds.map(deviceId => `var-device=${deviceId}`).join('&');
            setDeviceIds(deviceIdString);
        }
    };

    const handleLevelChange = levels => {
        if (levels.includes('All')) {
            setSelectedLevels(['All']);
            setLevels('var-level=All');
        } else {
            setSelectedLevels(levels);
            const levelString = levels.map(level => `var-level=${level}`).join('&');
            setLevels(levelString);
        }
    };
    const handleSensorChange = sensors => {
            if (sensors.includes('All')) {
                setSelectedSensors(['All']);
                setSensors('var-sensor=All');
            } else {
                setSelectedSensors(sensors);
                const sensorString = sensors.map(sensor => `var-sensor=${sensor}`).join('&');
                setSensors(sensorString);
            }
        };

    useEffect(() => {
        getMeters();
    }, []);

    const getMeters = () => {
        setIsLoading(true);
        axios
            .get(
                `${window.location.origin}${config.serverConfig.invoker.uri}/device-mgt/v1.0/devices?type=atmm&requireDeviceInfo=true&limit=1`
            )
            .then(res => {
                if (res.status === 200) {
                    const totalDevices = res.data.data.count;
                    setCount(totalDevices);

                    axios
                        .get(
                            `${window.location.origin}${config.serverConfig.invoker.uri}/device-mgt/v1.0/devices?type=atmm&requireDeviceInfo=true&limit=${totalDevices}`
                        )
                        .then(res => {
                            if (res.status === 200) {
                                const metersData = res.data.data.devices.map(device => ({
                                    deviceId: device.deviceIdentifier,
                                    name: device.name,
                                }));
                                setDevices(metersData);
                                setIsLoading(false);

                                const updatedOptions = [
                                    { value: 'All', label: 'All' },
                                    ...metersData.map(device => ({
                                        label: device.name,
                                        value: device.deviceId,
                                    })),
                                ];

                                setDeviceIdOptions(updatedOptions);
                            }
                        })
                        .catch(error => {
                            handleApiError(error, t('api_getInventoryError'), t);
                            setIsLoading(true);
                        });
                }
            })
            .catch(error => {
                handleApiError(error, t('api_getInventoryError'), t);
                setIsLoading(true);
            });
    };

    const routes = (appName) => [
        {
            path: `/${appName}`,
            breadcrumbName: t('home_menu_home'),
        },
        {
            breadcrumbName: t('home_menu_alerts_per_event'),
        }
    ];

    return (
        <div>
            <HtmlComments permission={'/permission/admin/device-mgt/devices/owning-device/view'}/>
            <Authorized
                scope={['dm:devices:view']}
                yes={
                    <div>
                        <PageHeader className={styles.pageHeader} ghost={false}>
                            <div className={styles.positioning}>
                                <Row justify="space-between" align="middle" gutter={8}>
                                    <Col>
                                        <BreadcrumbComponent
                                            breadcrumbList={routes(config.appName)}
                                        />
                                    </Col>
                                    <Col>
                                        <Row align="middle" gutter={[8, 8]}>
                                            <Col>
                                                <Space>
                                                    <label htmlFor="sensors" className={styles.labelText}>Sensor :</label>
                                                    <Col className={styles.selectValuesPicker}>
                                                        <Select
                                                            showSearch
                                                            filterOption={(input, option) =>
                                                                option.props.label
                                                                    .toString()
                                                                    .toLowerCase()
                                                                    .indexOf(input.toLowerCase()) >= 0 ||
                                                                option.props.value
                                                                    .toString()
                                                                    .toLowerCase()
                                                                    .indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            style={{
                                                                width: '100%',
                                                                minWidth: '150px',
                                                            }}
                                                            mode="multiple"
                                                            placeholder="Select Sensors"
                                                            defaultValue={['All']}
                                                            value={selectedSensors}
                                                            onChange={handleSensorChange}
                                                            options={sensorOptions.filter(option =>
                                                                selectedSensors.includes('All')
                                                                    ? option.value === 'All'
                                                                    : true
                                                            )}
                                                            maxTagCount={2}
                                                            maxTagPlaceholder={(omittedValues) => `+ ${omittedValues.length} more`}
                                                            onClick={() => setSelectFocused(true)}
                                                            onBlur={() => setSelectFocused(false)}
                                                        />
                                                    </Col>
                                                </Space>
                                            </Col>
                                            <Col>
                                                <Space>
                                                    <label htmlFor="deviceIds" className={styles.labelText}>Device
                                                        :</label>
                                                    <Col className={styles.selectValuesPicker}>
                                                        <Select
                                                            showSearch
                                                            filterOption={(input, option) =>
                                                                option.props.label
                                                                    .toString()
                                                                    .toLowerCase()
                                                                    .indexOf(input.toLowerCase()) >= 0 ||
                                                                option.props.value
                                                                    .toString()
                                                                    .toLowerCase()
                                                                    .indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            style={{
                                                                width: '100%',
                                                                minWidth: '150px',
                                                            }}
                                                            mode="multiple"
                                                            placeholder="Please select"
                                                            defaultValue={['All']}
                                                            value={selectedDeviceIds}
                                                            onChange={handleDeviceIdChange}
                                                            options={deviceIdOptions.filter(option =>
                                                                selectedDeviceIds.includes('All')
                                                                    ? option.value === 'All'
                                                                    : true
                                                            )}
                                                            maxTagCount={2} // limit displayed selected items
                                                            maxTagPlaceholder={(omittedValues) => `+ ${omittedValues.length} more`}
                                                            maxTagPlaceholder={(omittedValues) => `+ ${omittedValues.length} more`}
                                                            onClick={() => setSelectFocused(true)} // Set selectFocused to true on click
                                                            onBlur={() => setSelectFocused(false)} // Set selectFocused to false on blur
                                                        />
                                                    </Col>
                                                </Space>
                                            </Col>
                                            <Col>
                                                <Space>
                                                    <label htmlFor="levels" className={styles.labelText}>Level :</label>
                                                    <Col className={styles.selectValuesPicker}>
                                                        <Select
                                                            showSearch
                                                            filterOption={(input, option) =>
                                                                option.props.label
                                                                    .toString()
                                                                    .toLowerCase()
                                                                    .indexOf(input.toLowerCase()) >= 0 ||
                                                                option.props.value
                                                                    .toString()
                                                                    .toLowerCase()
                                                                    .indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            style={{
                                                                width: '100%',
                                                                minWidth: '150px',
                                                            }}
                                                            mode="multiple"
                                                            placeholder="Select Levels"
                                                            defaultValue={['All']}
                                                            value={selectedLevels}
                                                            onChange={handleLevelChange}
                                                            options={levelOptions.filter(option =>
                                                                selectedLevels.includes('All')
                                                                    ? option.value === 'All'
                                                                    : true
                                                            )}
                                                            maxTagCount={2}
                                                            maxTagPlaceholder={(omittedValues) => `+ ${omittedValues.length} more`}
                                                            onClick={() => setSelectFocused(true)} // Set selectFocused to true on click
                                                            onBlur={() => setSelectFocused(false)} // Set selectFocused to false on blur
                                                        />
                                                    </Col>
                                                </Space>
                                            </Col>
                                            <Col className={styles.dateRange}>
                                                <Space>
                                                    <DateRangeDropdown
                                                        alignDropdown="right"
                                                        getRange={(data) => setRange(data)}
                                                        closeOnBackground
                                                        type="Alerts"
                                                    />
                                                </Space>
                                            </Col>
                                            <Col className={styles.refreshRatePicker}>
                                                <Tooltip title="Refresh rate">
                                                    <Select
                                                        value={range.from !== 'now-5m' && range.from !== 'now-30m' ? 'off' : refreshRate}
                                                        className={styles.refreshRateSelect}
                                                        onChange={(value) => setRefreshRate(value)}
                                                        disabled={range.from !== 'now-5m' && range.from !== 'now-30m'}
                                                    >
                                                        <Option value="5s">5s</Option>
                                                        <Option value="10s">10s</Option>
                                                        <Option value="30s">30s</Option>
                                                        <Option value="1m">1m</Option>
                                                        <Option value="off">Off</Option>
                                                    </Select>
                                                </Tooltip>
                                                <ReloadOutlined className={styles.refreshIcon}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </PageHeader>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                {dashboardUrl !== '' &&
                                    deviceIds !== '' &&
                                    (
                                        <div className={styles.content}>
                                            <Row>
                                                <Col xl={24} xs={24}>
                                                    <div className={styles.iFrameContainer}>
                                                        <IframeWrapper
                                                            iframeSrc={`${dashboardUrl}`}
                                                            height={'680px'}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                            </Col>
                        </Row>
                        <Divider />
                    </div>
                }
                no={
                    <Result
                        status="403"
                        title={t('noPerm_accessPageTitle')}
                        subTitle={t('noPerm_contactSysAdmin')}
                    />
                }
            />
        </div>
    );
};

export default withConfigContext(withTranslation()(AlertsPerEvent));
