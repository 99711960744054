export function getCustomerInfo(doc, pdfConfig, param) {
    //customer Info part
    doc.setTextColor(pdfConfig.colorGray);
    doc.setFontSize(pdfConfig.fieldTextSize);
    pdfConfig.totalHeight += pdfConfig.lineHeight;
    if (param.customerInfo.label) {
        doc.text(10, pdfConfig.totalHeight, param.customerInfo.label);
        pdfConfig.totalHeight += pdfConfig.lineHeight;
    }

    doc.setTextColor(pdfConfig.colorBlack);
    doc.setFontSize(pdfConfig.fieldTextSize);
    if (param.customerInfo.name) {
        doc.text(10, pdfConfig.totalHeight, param.customerInfo.name);
        pdfConfig.totalHeight += pdfConfig.lineHeight;
    }
    if (param.customerInfo.accountNumber) {
        doc.text(10, pdfConfig.totalHeight, param.customerInfo.accountNumber);
        pdfConfig.totalHeight += pdfConfig.lineHeight;
    }
    if (param.customerInfo.address) {
        doc.text(10, pdfConfig.totalHeight, param.customerInfo.address);
        pdfConfig.totalHeight += pdfConfig.lineHeight;
    }
    //end customer Info part

    return {
        doc: doc,
        pdfConfig: pdfConfig
    }
}
