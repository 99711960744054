import { createReportTable } from "../../../Util/createReportTable";

export function getTotalConsumptionTable(doc, pdfConfig, param) {

    pdfConfig.totalHeight += pdfConfig.lineHeight;
    pdfConfig.totalHeight += pdfConfig.lineHeight;
    doc.setTextColor(pdfConfig.colorGray);
    doc.setFontSize(pdfConfig.fieldTextSize);
    if (param.reportBody.TotalConsumptionLabel) {
        doc.text(10, pdfConfig.totalHeight, param.reportBody.TotalConsumptionLabel);
    }
    const totalConsumptionTable = {
        headerBorder: param.reportBody?.TotalConsumptionHeaderBorder || false,
        tableBodyBorder: param.reportBody?.TotalConsumptionTableBodyBorder || false,
        header: param.reportBody?.TotalConsumptionHeader || [],
        table: param.reportBody?.TotalConsumptionTable || [],
    };
    createReportTable(totalConsumptionTable, doc, pdfConfig, param);

    return {
        doc: doc,
        pdfConfig: pdfConfig
    };
}
