/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

export function onChangeRoles(event) {
  if (event.length > 1 && event.includes('ANY')) {
    if (event[0] === 'ANY') {
      event.splice(0, 1);
    } else {
      event.splice(0, event.length - 1);
    }
  }
}

export function onChangeDeviceGroups(event) {
  if (event.length > 1 && event.includes('NONE')) {
    if (event[0] === 'NONE') {
      event.splice(0, 1);
    } else {
      event.splice(0, event.length - 1);
    }
  }
}

export function handleSetUserRoleFormItem(event, roleSelector, userSelector) {
  if (event.target.value === 'roleSelector') {
    roleSelector.current.style.cssText = 'display: block;';
    userSelector.current.style.cssText = 'display: none;';
  } else {
    roleSelector.current.style.cssText = 'display: none;';
    userSelector.current.style.cssText = 'display: block;';
  }
}
