import React, {useState, useEffect, useContext} from 'react';
import {Col, Result, Row, Divider, Input, Select, Space, Button} from 'antd';
import {withConfigContext} from '../../../../components/ConfigContext';
import {RoutesContext} from '../../index';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import {withTranslation} from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import axios from 'axios';
import ProfileDataInfo from './ProfileDataInfo';
import { subDays } from 'date-fns';
import IframeWrapper from '../../components/IframeWrapper';
import {getUnixTime, makeOptionArray} from "../../../../services/utils/functions";
import {PROFILE_DATA_OPTIONS} from "../../../../constant/TableData";
import configs from '../../../../../public/conf/config.json';
import {ExportOutlined} from "@ant-design/icons";
import meterDetails from "../MeterReadingsHistorical/MeterDetails";
import DateRangeDropdown from "../../components/common/DateRangeDropdown";
import HtmlComments from "../../../../components/ReactHtmlComments/HtmlComments";

const ProfileData = (props) => {
    const config = props.context;
    const {t} = props;
    const routesContext = useContext(RoutesContext);
    const [value, setValue] = useState('');
    const [meterSerial, setSerialValue] = useState('');
    const [outsideSerial, setOutsideSerial] = useState(false);
    const [selectedOBIS, setselectedOBIS] = useState(
        'LP'
    );
    const rightNow = new Date();
    const [meterData, setMeterData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    // const [isViewButtonDisabled, setIsViewButtonDisabled] = useState(true);
    const [showMeterDetails, setShowMeterDetails] = useState(false);
    const [dashboardUrl, setDashboardUrl] = useState('');
    const [range, setRange] = useState({
        from: getUnixTime(subDays(rightNow, 2)),
        to: getUnixTime(rightNow),
    });

    useEffect(() => {
        // Use the context to set the current route
        routesContext.setCurrentRoute('allProfileData');
        setDashboardUrl(`${configs.grafanaConfig.profileDataVisualization}?orgId=1&panelId=2&var-serial=${meterSerial}&var-obis=${selectedOBIS}&from=${range.from}&to=${range.to}`);
    }, [selectedOBIS, meterSerial, range.from, range.to]);


    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;

    const handleChange = (val) => {
        if (rx_live.test(val.target.value)) {
            setValue(val.target.value);
        }
    };

    const handleKeypress = (e) => {
        if (e.keyCode === 13) {
            if (value !== '') {
                setSerialValue(value);
            }
        }
    };

    // const navigateMeterView = () => {
    //     let path = '../meter/view?serial=' + meterSerial;
    //     window.open(path, '_blank', 'noopener,noreferrer');
    // };

    useEffect(() => {
        // This will run when the component mounts
        const str = window.location.search;
        if (str) {
            const urlParams = new URLSearchParams(window.location.search);
            const paramSerial = urlParams.get('serial');
            if (paramSerial !== null) {
                setSerialValue(paramSerial);
                setOutsideSerial(true);
                handleProfileDataSearch();
            }
        }
        // eslint-disable-next-line
    }, [config.serverConfig.invoker.uri, t]);

    useEffect(() => {
        if (meterSerial) {
            setIsLoading(true);
            handleProfileDataSearch();
        }
        // eslint-disable-next-line
    }, [meterSerial]);

    const handleProfileDataSearch = () => {
        if (meterSerial) {
            axios({
                method: 'GET',
                url: window.location.origin + config.serverConfig.invoker.uri + `/device-mgt/power-meter-dlms/v1.0/devices/device/${meterSerial}`,
            })
                .then((response) => {
                    const responseData = response.data.data;
                    const meterDetails = {
                        serial: responseData.deviceIdentifier,
                        ipAddress: responseData.properties?.find((prop) => prop.name === 'IP_ADDRESS')?.value || '-',
                        meterType: responseData.properties?.find((prop) => prop.name === 'SUB_TYPE_ID')?.value,
                        status: responseData.enrolmentInfo.status,
                        category: responseData.properties?.find((prop) => prop.name === 'CATEGORY')?.value || '-',
                        branch: responseData.properties?.find((prop) => prop.name === 'BRANCH')?.value || '-',
                        depot: responseData.properties?.find((prop) => prop.name === 'DEPOT')?.value || '-',
                        substation: responseData.properties?.find((prop) => prop.name === 'SUBSTATION_ID')?.value || '-',
                        customerRef: responseData.properties?.find((prop) => prop.name === 'CUSTOMER_REF')?.value || '-',
                    };
                    setMeterData(meterDetails);
                    setIsLoading(false);
                    // Show the MeterDetails component after successful search
                    setShowMeterDetails(true);
                })
                .catch((error) => {
                    // Handle error here
                });
        }
    }

    const routes = (appName) => [
        {
            path: `/${appName}`,
            breadcrumbName: t('home_menu_home'),
        },
        {
            breadcrumbName: t('home_menu_profile_readings'),
        },
        {
            breadcrumbName: t('home_menu_profileData'),
        },
    ];

    return (
        <div>
            <HtmlComments permission={'/permission/admin/device-mgt/devices/owning-device/view'}/>
            <Authorized
                scope={['dm:devices:view']}
                yes={
                    <div>
                        <PageHeader className={styles.pageHeader} ghost={false}>
                            <div className={styles.positioning}>
                                <Row justify="space-between" align="middle" gutter={8}>
                                    <Col>
                                        <BreadcrumbComponent breadcrumbList={routes(config.appName)}/>
                                    </Col>
                                    <Col>
                                        <Row align="middle" gutter={[8, 8]}>
                                            <Space>
                                                {!outsideSerial &&
                                                    <Input
                                                        type="text"
                                                        style={{width: 200, marginRight:5}}
                                                        placeholder="Meter Serial"
                                                        onChange={handleChange}
                                                        onKeyDown={handleKeypress}
                                                        maxLength="12"
                                                        value={value}
                                                        pattern="[+-]?\d+(?:[.,]\d+)?"
                                                    />
                                                }
                                            </Space>
                                            <Col>
                                                <Space>
                                                    <Select
                                                        value={selectedOBIS}
                                                        placeholder="Select"
                                                        style={{ width: 250 }}
                                                        onChange={(value) => setselectedOBIS(value)}
                                                    >
                                                        {meterData?.meterType === '7'
                                                            ? makeOptionArray(['C1DE', 'C2DE', 'C3DE', 'LP', 'PFEL', 'VP']).map((option) => (
                                                                <Select.Option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </Select.Option>
                                                            ))
                                                            : makeOptionArray(['LP']).map((option) => (
                                                                <Select.Option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                    <DateRangeDropdown
                                                        alignDropdown="right"
                                                        getRange={(data) => setRange(data)}
                                                        closeOnBackground
                                                    />
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    {showMeterDetails && <ProfileDataInfo meterInfo={meterData}/>}
                                </div>
                            </div>
                        </PageHeader>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                {meterSerial !== '' && selectedOBIS && range.from && range.to && (
                                    <div className="row">
                                        <div className="col-md-12">
                                            <IframeWrapper iframeSrc={dashboardUrl} height="550px" />
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Divider/>
                    </div>
                }
                no={
                    <Result
                        status="403"
                        title={t('noPerm_accessPageTitle')}
                        subTitle={t('noPerm_contactSysAdmin')}
                    />
                }
            />
        </div>
    );
};

export default withConfigContext(withTranslation()(ProfileData));
