import { createReportTable } from "../../../Util/createReportTable";
import { addStamp } from "../../../Util/addStamp";

export function getPaymentTable(doc, pdfConfig, param) {
    //payment table
    pdfConfig.totalHeight += pdfConfig.lineHeight;
    pdfConfig.totalHeight += pdfConfig.lineHeight;
    doc.setTextColor(pdfConfig.colorGray);
    doc.setFontSize(pdfConfig.fieldTextSize);
    if (param.reportBody.paymentLabel) {
        doc.text(10, pdfConfig.totalHeight, param.reportBody.paymentLabel);
    }
    const paymentTable = {
        headerBorder: param.reportBody?.paymentHeaderBorder || false,
        tableBodyBorder: param.reportBody?.paymentTableBodyBorder || false,
        header: param.reportBody?.paymentHeader || [],
        table: param.reportBody?.paymentTable || [],
    };
    createReportTable(paymentTable, doc, pdfConfig, param);
    //end payment table
    //PAGE BREAKER
    var checkAndAddPageLandscape = function () {
        if (!param.orientationLandscape && pdfConfig.totalHeight > 270) {
            doc.addPage();
            pdfConfig.totalHeight = 10;
        }
    };

    var checkAndAddPageNotLandscape = function (heightLimit = 173) {
        if (param.orientationLandscape && pdfConfig.totalHeight > heightLimit) {
            doc.addPage();
            pdfConfig.totalHeight = 10;
        }
    };
    var checkAndAddPage = function () {
        checkAndAddPageNotLandscape();
        checkAndAddPageLandscape();
    };
    //end PAGE BREAKER
    checkAndAddPage();

    doc.setTextColor(pdfConfig.colorBlack);
    doc.setFontSize(pdfConfig.labelTextSize);
    pdfConfig.totalHeight += pdfConfig.lineHeight;

    checkAndAddPage();

    doc.setTextColor(pdfConfig.colorBlack);
    pdfConfig.totalHeight += pdfConfig.subLineHeight;
    pdfConfig.totalHeight += pdfConfig.subLineHeight;
    doc.setFontSize(pdfConfig.labelTextSize);

    //Add num of pages at the bottom
    if (doc.getNumberOfPages() > 1) {
        for (let i = 1; i <= doc.getNumberOfPages(); i++) {
            doc.setFontSize(pdfConfig.fieldTextSize - 2);
            doc.setTextColor(pdfConfig.colorGray);

            if (param.pageEnable) {
                doc.text(pdfConfig.docWidth / 2, pdfConfig.docHeight - 10, param.footer.text, "center");
                doc.setPage(i);
                doc.text(
                    param.pageLabel + " " + i + " / " + doc.getNumberOfPages(),
                    pdfConfig.docWidth - 20,
                    doc.internal.pageSize.height - 6
                );
            }

            checkAndAddPageNotLandscape(183);
            checkAndAddPageLandscape();
            addStamp(param, doc, pdfConfig);
        }
    }
    //end Add num of pages at the bottom

    return {
        doc: doc,
        pdfConfig: pdfConfig
    }
}
