/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import { Button, Divider, Input, Tooltip, Form } from 'antd';
import { withTranslation } from 'react-i18next';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 10 },
    xl: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 12 },
    xl: { span: 12 },
  },
};

const buttonFormItem = {
  wrapperCol: {
    md: { span: 20 },
    lg: { span: 22 },
    xl: { span: 22 },
  },
};

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'code-block',
  'list',
  'bullet',
  'link',
  'image',
];

class GeneralConfig extends React.Component {
  formRef = React.createRef();

  componentDidMount() {
    if (this.props.windowsPlatformConfigData.length > 0) {
      let platformConfigData = {};
      this.props.windowsPlatformConfigData.forEach(config => {
        platformConfigData[config.name] = config.value;
      });
      platformConfigData.notifierFrequency = platformConfigData.notifierFrequency
        ? platformConfigData.notifierFrequency / 1000
        : null;
      this.formRef.current.setFieldsValue(platformConfigData);
    }
  }

  onFinish = values => {
    values.notifierFrequency = values.notifierFrequency * 1000;
    this.props.callback(values);
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div>
          <Divider>
            <PageHeader
              className="site-page-header-responsive"
              subTitle={t('label_windowsGeneralConfigs')}
            />
          </Divider>
        </div>
        <div>
          <Form
            {...formItemLayout}
            labelAlign="right"
            layout="horizontal"
            name={'windowsConfigurationForm'}
            onFinish={this.onFinish}
            ref={this.formRef}
          >
            <Form.Item
              label={
                <span>
                  {t('label_pollingInterval')}&nbsp;
                  <Tooltip title={t('pollingInterval_infoTxt')}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              rules={[
                {
                  required: true,
                  message: t('form_generalRequiredMsg', {
                    label: t('label_SCEPCountry'),
                  }),
                },
              ]}
              name="notifierFrequency"
            >
              <Input placeholder={t('form_configIntervalValidMsg')} />
            </Form.Item>
            <Form.Item
              label={t('label_endUserLicenseAgreement')}
              name="windowsLicense"
              initialValue={''}
            >
              <ReactQuill
                theme={'snow'}
                modules={modules}
                formats={formats}
                placeholder={t('license_infoTxt')}
              />
            </Form.Item>
            <Form.Item {...buttonFormItem}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ float: 'right' }}
              >
                {t('label_save')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(GeneralConfig);
