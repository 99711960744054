/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Row, Col, Card, Menu } from 'antd';
import Enroll from './components/Enroll';
import { RoutesContext } from '../../index';
import styles from './styles.module.css';
import { withConfigContext } from '../../../../components/ConfigContext';
import { withTranslation } from 'react-i18next';
import CombinedSteps from './components/CombinedSteps';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import BreadcrumbComponent from '../../../../components/BreadCrumb';

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      path: `/${appName}/devices`,
      breadcrumbName: t('home_menu_machines'),
    },
    {
      breadcrumbName: t('home_menu_enrollMachine'),
    },
  ];
};

class EnrollDevice extends React.Component {
  selectedDeviceType = 'android';
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      isAddDeviceModalVisible: false,
      current: 0,
      deviceType: 'android',
      enrollmentType: '',
    };
  }

  componentDidMount() {
    this.context.setCurrentRoute('enroll');
    this.getDeviceType('android');
  }

  getDeviceType = deviceType => {
    this.selectedDeviceType =
      deviceType === deviceType.toUpperCase()
        ? deviceType.charAt(0) + deviceType.slice(1).toLowerCase()
        : deviceType;
    this.setState({
      current: 0,
      deviceType: deviceType.toLowerCase(),
    });
  };

  goBackToCombinedSteps = () => {
    this.setState({
      current: 0,
    });
  };

  getEnrollmentData = enrollmentType => {
    this.setState({
      current: 1,
      enrollmentType: enrollmentType,
    });
  };

  onClickMenu = e => {
    this.getDeviceType(e.key);
  };

  getItem = (label, key, icon, children, className, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
      className,
    };
  };

  render() {
    const { current, deviceType, enrollmentType } = this.state;
    const { t } = this.props;
    const defaultPlatformIcons = this.config.defaultPlatformIcons;

    const items = [
      this.getItem(
        <>
          <span
            style={{
              marginRight: 5,
              fontSize: 20,
              color: defaultPlatformIcons.android.color,
              textAlign: 'center',
            }}
          >
            <LegacyIcon
              type={defaultPlatformIcons.android.icon}
              theme={defaultPlatformIcons.android.theme}
            />
          </span>
          Android
        </>,
        'android',
        null,
        null,
        styles.customMenuItem,
      ),
      this.getItem(
        <>
          <span
            style={{
              marginRight: 5,
              fontSize: 20,
              color: defaultPlatformIcons.ios.color,
              textAlign: 'center',
            }}
          >
            <LegacyIcon
              type={defaultPlatformIcons.ios.icon}
              theme={defaultPlatformIcons.ios.theme}
            />
          </span>
          iOS
        </>,
        'ios',
        null,
        null,
        styles.customMenuItem,
      ),
      this.getItem(
        <>
          <span
            style={{
              marginRight: 5,
              fontSize: 20,
              color: defaultPlatformIcons.windows.color,
              textAlign: 'center',
            }}
          >
            <LegacyIcon
              type={defaultPlatformIcons.windows.icon}
              theme={defaultPlatformIcons.windows.theme}
            />
          </span>
          Windows
        </>,
        'windows',
        null,
        null,
        styles.customMenuItem,
      ),
    ];

    return (
      <div>
        <div className={styles.container}>
          <Menu
            mode="horizontal"
            onClick={this.onClickMenu}
            className={styles.customMenu}
            style={{ borderTopRightRadius: 10, borderTopLeftRadius: 10 }}
            defaultSelectedKeys={['android']}
            items={items}
          />
          <PageHeader
            style={{ paddingTop: 0, backgroundColor: '#fff', marginTop: 10 }}
            breadcrumb={
              <BreadcrumbComponent
                breadcrumbList={routes(this.config.appName, t)}
              />
            }
          />
          <Row>
            <Col span={22} offset={1}>
              <Card className={styles.cardContainer}>
                <div style={{ display: current === 0 ? 'unset' : 'none' }}>
                  <CombinedSteps
                    deviceType={deviceType}
                    selectedDeviceType={this.selectedDeviceType}
                    goNext={this.goNext}
                    getEnrollmentData={this.getEnrollmentData}
                  />
                </div>
                <div style={{ display: current === 1 ? 'unset' : 'none' }}>
                  <Enroll
                    deviceType={deviceType}
                    selectedDeviceType={this.selectedDeviceType}
                    enrollmentType={enrollmentType}
                    goBack={this.goBackToCombinedSteps}
                    appName={this.config.appName}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
EnrollDevice.contextType = RoutesContext;
export default withConfigContext(withTranslation()(EnrollDevice));
