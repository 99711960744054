/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {
  DesktopOutlined,
  EditOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Modal } from 'antd';
import TimeAgo from 'javascript-time-ago';
// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';
import { ConfigContext } from '../../../../../../components/ConfigContext';
import { RoutesContext } from '../../../..';
import { useTranslation } from 'react-i18next';
import AddDeviceTypeForm from "./components/AddDeviceTypeForm";

const AddDeviceType = () => {
    // const { config } = useContext(ConfigContext);
    const routesContext = useContext(RoutesContext);
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);


    useEffect(() => {
        let params = new URL(window.location).searchParams;
        let name = params.get('add-device-type');
        if (JSON.parse(name)) {
            setIsModalVisible(true);
        }
    }, [new URL(window.location).searchParams]);

    const onCancelHandler = e => {
        window.history.pushState(null, '', location.href.split('?')[0]);
        setIsModalVisible(false);
        console.log(isModalVisible)
    };

    return (
      <div>
        <Modal
            title={t('device_type_add_new')}
            open={isModalVisible}
            onCancel={onCancelHandler}
            footer={null}
            maskClosable={false}
            width={700}
        >
            {/* <HtmlComments permission={'/permission/admin/device-mgt/roles/view'} />
            <UserForm
            action={'Add'}
            modalVisibility={this.state.isModalVisible}
            callback={values => this.addUser(values)}
            /> */}
            <AddDeviceTypeForm modalVisibility={isModalVisible} closeModalCallback={onCancelHandler}/>
        </Modal>
      </div>
    );
  };

  export default AddDeviceType;
