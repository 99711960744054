/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { HistoryOutlined } from '@ant-design/icons';
import { Result, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import styles from './styles.module.css';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import axios from 'axios';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import GeoDashboard from '../../components/GeoDashboard';
import { isAuthorized } from '../../../../../../services/utils/authorizationHandler';
import Authorized from '../../../../../../components/Authorized';
import HtmlComments from '../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../../../components/BreadCrumb';

const { Title } = Typography;

const routes = (name, appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      path: `/${appName}/groups`,
      breadcrumbName: t('home_menu_groups'),
    },
    {
      breadcrumbName: name,
    },
    {
      breadcrumbName: t('label_devicesLocationHistory'),
    },
  ];
};

class GeoGroup extends React.Component {
  routes;

  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.routes = props.routes;
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    if (isAuthorized(this.config.scopes, ['perm:groups:groups-view'])) {
      this.fetchGroup();
    }
  }

  fetchGroup = () => {
    let { t } = this.props;
    const { groupId } = this.props.match.params;
    axios
      .get(
        window.location.origin +
          this.config.serverConfig.invoker.uri +
          this.config.serverConfig.invoker.deviceMgt +
          `/groups/id/${groupId}`,
      )
      .then(result => {
        if (result.status === 200) {
          this.setState({ data: result.data.data });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_retrieveDeviceDetailsError'), t);
      });
  };

  render() {
    const { groupId } = this.props.match.params;
    let { data } = this.state;
    let { t } = this.props;
    return (
      <div>
        <HtmlComments
          permission={
            '/permission/admin/device-mgt/devices/owning-device/view,\n' +
            '/permission/admin/device-mgt/groups/view'
          }
        />
        <Authorized
          scope={['perm:devices:view']}
          yes={
            <div>
              <div>
                <PageHeader
                  className={styles.pageHeader}
                  title={
                    <Title level={1}>
                      <span className={styles.icon}>
                        <HistoryOutlined />
                      </span>
                      {`${data.name}`} - {t('label_devicesLocationHistory')}
                    </Title>
                  }
                  ghost={false}
                  breadcrumb={
                    <BreadcrumbComponent
                      breadcrumbList={routes(data.name, this.config.appName, t)}
                    />
                  }
                />
                <div className={styles.container}>
                  <GeoDashboard groupId={groupId} />
                </div>
              </div>
            </div>
          }
          no={
            <Result
              status="403"
              title={t('noPerm_accessPageTitle')}
              subTitle={t('noPerm_contactSysAdmin')}
            />
          }
        />
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(GeoGroup));
