/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Input, Form } from 'antd';
import { withTranslation } from 'react-i18next';

const buttonItemLayout = {
  wrapperCol: { span: 24, offset: 19 },
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

class GroupForm extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    if (this.props.values) {
      this.formRef.current.setFieldsValue(this.props.values);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let params = new URL(window.location).searchParams;
    let name = params.get('add-new-group');
    if (JSON.parse(name)) {
      this.formRef.current.resetFields();
    }
  }

  onFinish = values => {
    this.props.callback(values);
  };

  render() {
    const { action, t } = this.props;
    return (
      <div>
        <Form
          {...formItemLayout}
          name="groupForm"
          onFinish={this.onFinish}
          ref={this.formRef}
        >
          <Form.Item
            name="name"
            label={t('label_name')}
            rules={[
              {
                required: true,
                message: t('groupName_infoTxt'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label={t('label_description')}
            rules={[
              {
                required: true,
                message: t('inputDescription_infoTxt'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item {...buttonItemLayout}>
            <Button type="primary" htmlType="submit">
              {t(`label_${action.toLowerCase()}`)}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default withTranslation()(GroupForm);
