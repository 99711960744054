/*
 * Copyright (C) 2018 - 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useContext, useEffect, useState } from 'react';
import { Col, Result, Row, Spin } from 'antd';
import hourlyProductionStyles from '../../styles.module.css';
import { DeviceDetailsContext } from '../../index';
import { useTranslation } from 'react-i18next';
import IframeWrapper from '../../../../components/IframeWrapper';
import config from '../../../../../../../public/conf/config.json';
import { grafanaUrlAccessible } from '../../../../../../services/utils/urlUtils';

const HourlyProduction = props => {
  const { range } = props;
  const deviceDetails = useContext(DeviceDetailsContext);
  const deviceIdentifier = deviceDetails?.deviceIdentifier;
  const { t } = useTranslation();
  const [isDashboardAccessible, setIsDashboardAccessible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dashboardUrl = `${config.grafanaConfig.hourlyProductionDashboard}&var-device=${deviceIdentifier}&${range}`;
  useEffect(() => {
    // Update the current route's key
    deviceDetails.setCurrentRoute('hourly-production');
    checkDashboardAccess(dashboardUrl);
  }, []);

  const checkDashboardAccess = (dashboardUrl) => {
    const accessible = grafanaUrlAccessible(`${config.grafanaConfig.reportBase}/${dashboardUrl}`);
    setIsDashboardAccessible(accessible);
    setIsLoading(false);
  };

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <div className={hourlyProductionStyles.container}>
          {isLoading ? (
            <div style={{ textAlign: 'center', marginTop:50 }}>
              <Spin size="large" />
            </div>
          ) : isDashboardAccessible ? (
            <IframeWrapper iframeSrc={dashboardUrl} height="550px" />
          ) : (
            <Result status="404" subTitle={t('label_dashboard_error')} />
          )}
        </div>
      </Col>
    </Row>
  );
};

export default HourlyProduction;
