/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { AppstoreTwoTone } from '@ant-design/icons';
import styles from './styles.module.css';
import { Col, Row, Card } from 'antd';
import { RoutesContext } from '../../../../index';
import { Link } from 'react-router-dom';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import { withTranslation } from 'react-i18next';

class SubTypeList extends React.Component {
  tenantId = '';
  constructor(props) {
    super(props);
    this.config = this.props.context;
    const { t } = this.props;
    this.tabList = [
      {
        title: t('label_applications'),
        link: `/${this.config.appName}/analytics/applications`,
        icon: <AppstoreTwoTone twoToneColor="#08c" />,
        description: t('analytics_application_description'),
      },
      {
        title: t('label_deviceAssignment'),
        link: `/${this.config.appName}/analytics/device-assignment`,
        icon: <AppstoreTwoTone twoToneColor="#08c" />,
        description: t('analytics_deviceAssignment_description'),
      },
      {
        title: t('label_deviceConnectivity'),
        link: `/${this.config.appName}/analytics/device-connectivity`,
        icon: <AppstoreTwoTone twoToneColor="#08c" />,
        description: t('analytics_deviceConnectivity_description'),
      },
      {
        title: t('label_deviceFirmware'),
        link: `/${this.config.appName}/analytics/device-firmware`,
        icon: <AppstoreTwoTone twoToneColor="#08c" />,
        description: t('analytics_deviceFirmware_description'),
      },
      {
        title: t('label_deviceHardware'),
        link: `/${this.config.appName}/analytics/device-hardware`,
        icon: <AppstoreTwoTone twoToneColor="#08c" />,
        description: t('analytics_deviceHardware_description'),
      },
      {
        title: t('label_deviceInventory'),
        link: `/${this.config.appName}/analytics/device-inventory`,
        icon: <AppstoreTwoTone twoToneColor="#08c" />,
        description: t('analytics_deviceInventory_description'),
      },
      {
        title: t('label_operations'),
        link: `/${this.config.appName}/analytics/operations`,
        icon: <AppstoreTwoTone twoToneColor="#08c" />,
        description: t('analytics_operations_description'),
      },
      {
        title: t('home_menu_policy'),
        link: `/${this.config.appName}/analytics/policy`,
        icon: <AppstoreTwoTone twoToneColor="#08c" />,
        description: t('analytics_policyReport_description'),
      },
      {
        title: t('label_security'),
        link: `/${this.config.appName}/analytics/security`,
        icon: <AppstoreTwoTone twoToneColor="#08c" />,
        description: t('analytics_security_description'),
      },
    ];
  }

  render() {
    return (
      <div className={styles.content}>
        <Row>
          {this.tabList.map((tab, index) => {
            return (
              <Col xl={8} xs={24} key={index}>
                <div className={styles.tabContainer}>
                  <Link to={tab.link}>
                    <Card
                      bordered={true}
                      hoverable={true}
                      style={{ borderRadius: 10, marginBottom: 16 }}
                    >
                      <div align="center">
                        <Col span={24} className={styles.iconContainer}>
                          {tab.icon}
                        </Col>
                        <h2>
                          <b>{tab.title}</b>
                        </h2>
                        <p>{tab.description}</p>
                      </div>
                    </Card>
                  </Link>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}
SubTypeList.contextType = RoutesContext;
export default withConfigContext(withTranslation()(SubTypeList));
