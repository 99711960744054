const useSetRange = (shiftType, from, setFrom, setRange, timeRangeOptions, defaultTimeOptionsLen) => {
  if (shiftType === 'all') {
    setRange(timeRangeOptions[timeRangeOptions.length > defaultTimeOptionsLen ? defaultTimeOptionsLen : 7].value);
  } else {
    let addHours = 1;
    if (shiftType === 'dayShift') {
      addHours = 11;
      const fromDate = new Date(from);
      fromDate.setHours(8, 0, 0, 0);
      setFrom(fromDate.getTime());
    } else if (shiftType === 'nightShift') {
      addHours = 13;
      const fromDate = new Date(from);
      fromDate.setHours(19, 0, 0, 0);
      setFrom(fromDate.getTime());
    }
    setRange(`from=${from}&to=${from + (addHours * 60 * 60 * 1000)}`);
  }
};

export default useSetRange;