/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { EditOutlined, EyeOutlined, CopyOutlined } from '@ant-design/icons';
import { Divider, Tooltip } from 'antd';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import { Link } from 'react-router-dom';
import { isAuthorized } from '../../../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

class PolicyAction extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <div>
          {isAuthorized(this.config.scopes, ['perm:policies:update']) && (
            <Tooltip placement="top" title={t('label_editPolicy')}>
              <HtmlComments
                permission={'/permission/admin/device-mgt/policies/manage'}
              />
              <Link
                to={`/${this.config.appName}/policy/edit/${this.props.selectedPolicyData.id}`}
              >
                <EditOutlined />
              </Link>
            </Tooltip>
          )}
          <Divider type="vertical" />
          <Tooltip placement="top" title={t('label_viewPolicy')}>
            <Link
              to={`/${this.config.appName}/policy/view/${this.props.selectedPolicyData.id}`}
            >
              <EyeOutlined />
            </Link>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip placement="top" title={t('label_duplicatePolicy')}>
            <Link
              to={`/${this.config.appName}/policy/duplicate/${this.props.selectedPolicyData.id}`}
            >
              <CopyOutlined />
            </Link>
          </Tooltip>
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(PolicyAction));
