/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'antd';
import style from '../../styles.module.css';
import DeviceDetails from './components/DeviceDetails';
import axios from 'axios';
import ConfigContext from '../../../../../../components/ConfigContext';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import { DeviceDetailsContext } from '../../index';
import { useTranslation, withTranslation } from 'react-i18next';

const Maintenance = () => {
  const deviceDetails = useContext(DeviceDetailsContext);
  const { config } = useContext(ConfigContext);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [enrolmentInfo, setEnrolmentInfo] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    // Update the current route's key
    deviceDetails.setCurrentRoute('maintenance');
    fetchDeviceInfo();
  }, []);

  const fetchDeviceInfo = () => {
    axios
      .get(
        window.location.origin +
          config.serverConfig.invoker.uri +
          config.serverConfig.invoker.deviceMgt +
          `/devices/${deviceDetails.deviceType}/${deviceDetails.deviceIdentifier}`,
      )
      .then(result => {
        if (result.status === 200) {
          const propertiesObj = (result.data.data.properties).reduce((acc, obj) => {
            if (obj.name.startsWith("_maintenance_")) {
              acc[obj.name] = obj.value;
            }
            return acc;
          }, {});
          // Add all the keys inside deviceDetailsMap along with existing keys
          const deviceInfoData = result.data.data.deviceInfo;
          delete deviceInfoData.pluggedIn;
          const deviceInfo = {
            ...propertiesObj,
          };
          delete deviceInfo.deviceDetailsMap;
          setDeviceInfo(deviceInfo);
        } else {
          throw new Error();
        }
      })
      .catch(error => {
        handleApiError(error, t('api_retrieveDeviceDetailsError'), t);
      });
  };

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <div className={style.container}>
          {deviceInfo != null && 
            <DeviceDetails deviceInfo={deviceInfo} />
          }
        </div>
      </Col>
    </Row>
  );
};

export default withTranslation()(Maintenance);
