import React, {useState, useEffect, useContext} from 'react';
import {Col, Result, Row, Divider, Input, Select, Space, Button} from 'antd';
import {withConfigContext} from '../../../../components/ConfigContext';
import {RoutesContext} from '../../index';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import {withTranslation} from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import axios from 'axios';
import MeterDataInfo from './MeterDataInfo';
import IframeWrapper from '../../components/IframeWrapper';
import {makeOptionArray} from "../../../../services/utils/functions";
import { OPERATION_CODES_DATA_VISUALIZAION, MONTHS } from "../../../../constant/TableData";
import configs from '../../../../../public/conf/config.json';
import {ExportOutlined} from "@ant-design/icons";

const MeterData = (props) => {
    const config = props.context;
    const {t} = props;
    const routesContext = useContext(RoutesContext);
    const [value, setValue] = useState('');
    const [meterSerial, setSerialValue] = useState('');
    const [outsideSerial, setOutsideSerial] = useState(false);
    const currentDate = new Date();
    const defaultYear = currentDate.getFullYear().toString();
    const defaultMonth = MONTHS[currentDate.getMonth()].label; // Get the name of the default month
    const defaultMonthValue = MONTHS[currentDate.getMonth()].value;
    const [selectedOperationCode, setSelectedOperationCode] = useState(
        'BILLING_REGISTERS_RETRIEVE'
    );
    const [selectedYear, setSelectedYear] = useState(defaultYear);
    const [selectedMonth, setSelectedMonth] = useState(defaultMonth);
    const [meterData, setMeterData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isViewButtonDisabled, setIsViewButtonDisabled] = useState(true);
    const [showMeterDetails, setShowMeterDetails] = useState(false);
    const [startOfMonthTimestamp, setStartOfMonthTimestamp] = useState(0);
    const [endOfMonthTimestamp, setEndOfMonthTimestamp] = useState(0);
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const startYear = currentMonth === 12 ? currentYear - 1 : currentYear - 2;
    const startMonth = (((currentMonth - 24 + 12) % 12) + 12) % 12;
    const [yearOptions, setYearOptions] = useState([]);
    const [selectedYearMonths, setSelectedYearMonths] = useState([]);
    const [dashboardUrl, setDashboardUrl] = useState('');

    useEffect(() => {
        // Use the context to set the current route
        routesContext.setCurrentRoute('allMeterData');
        setDashboardUrl(`${configs.grafanaConfig.meterDataVisualization}?orgId=1&panelId=2&var-serial=${meterSerial}&var-operation_code=${selectedOperationCode}&var-year=${selectedYear}&var-month=${selectedMonth}&from=${startOfMonthTimestamp}&to=${endOfMonthTimestamp}`);
    }, [selectedOperationCode, selectedYear, selectedMonth, meterSerial, startOfMonthTimestamp, endOfMonthTimestamp]);

    useEffect(() => {
        const years = [];
        for (let year = startYear; year <= currentYear; year++) {
            years.push({ label: year.toString(), value: year.toString() });
        }
        setYearOptions(years);
        const maxMonth = currentYear === parseInt(selectedYear, 10) ? currentMonth : 12;
        // eslint-disable-next-line
        const startMonthRange = selectedYear == (currentYear - 2) ? startMonth + 1 : 1;
        const months = [];
        for (let month = startMonthRange; month <= maxMonth; month++) {
            months.push({
                label: MONTHS[month - 1].label,
                value: MONTHS[month - 1].value,
            });
        }
        setSelectedYearMonths(months);
    }, [currentYear, currentMonth, selectedYear, startYear, startMonth]);

    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;

    const handleChange = (val) => {
        if (rx_live.test(val.target.value)) {
            setValue(val.target.value);
        }
    };

    const handleKeypress = (e) => {
        if (e.keyCode === 13) {
            if (value !== '') {
                setSerialValue(value);
            }
        }
    };

    const navigateDeviceView = () => {
        let path = '../device/view?serial=' + meterSerial;
        window.open(path, '_blank', 'noopener,noreferrer');
    };

    useEffect(() => {
        // This will run when the component mounts
        const str = window.location.search;
        if (str) {
            const urlParams = new URLSearchParams(window.location.search);
            const paramSerial = urlParams.get('serial');
            if (paramSerial !== null) {
                setSerialValue(paramSerial);
                setOutsideSerial(true);
                handleMeterDataSearch();
            }
        }
        // eslint-disable-next-line
    }, [config.serverConfig.invoker.uri, t]);

    useEffect(() => {
        if (meterSerial) {
            setIsLoading(true);
            handleMeterDataSearch();
        }
        // eslint-disable-next-line
    }, [meterSerial]);

    useEffect(() => {
        if (defaultMonth) {
            setSelectedMonth(defaultMonthValue);
        }
    }, [defaultMonth, defaultMonthValue]);

    useEffect(() => {
        // After the Axios call is completed, set isViewButtonDisabled to false
        setIsViewButtonDisabled(false);
    }, [isLoading]);

    const handleMeterDataSearch = () => {
        if (meterSerial) {
            axios({
                method: 'GET',
                url: window.location.origin + config.serverConfig.invoker.uri + `/device-mgt/power-meter-dlms/v1.0/devices/device/${meterSerial}`,
            })
                .then((response) => {
                    const responseData = response.data.data;
                    const meterDetails = {
                        serial: responseData.deviceIdentifier,
                        ipAddress: responseData.properties?.find((prop) => prop.name === 'IP_ADDRESS')?.value || '-',
                        meterType: responseData.properties?.find((prop) => prop.name === 'SUB_TYPE_ID')?.value,
                        status: responseData.enrolmentInfo.status,
                        category: responseData.properties?.find((prop) => prop.name === 'CATEGORY')?.value || '-',
                        branch: responseData.properties?.find((prop) => prop.name === 'BRANCH')?.value || '-',
                        depot: responseData.properties?.find((prop) => prop.name === 'DEPOT')?.value || '-',
                        substation: responseData.properties?.find((prop) => prop.name === 'SUBSTATION_ID')?.value || '-',
                        customerRef: responseData.properties?.find((prop) => prop.name === 'CUSTOMER_REF')?.value || '-',
                    };
                    setMeterData(meterDetails);
                    setIsLoading(false);
                    // Show the MeterDetails component after successful search
                    setShowMeterDetails(true);
                })
                .catch((error) => {
                    // Handle error here
                });
        }
    }

    function getStartOfMonthTimestamp(year, month) {
        // JavaScript months are zero-based (0 for January, 1 for February, etc.)
        // So, we subtract 1 from the provided month to get the correct month index.
        const date = new Date(year, month - 1, 1);
        return date.getTime(); // Returns the timestamp in milliseconds
    }

    function getEndOfMonthTimestamp(year, month) {
        const date = new Date(year, month, 0); // Day 0 represents the last day of the previous month
        date.setHours(23, 59, 59, 999); // Set the time to the end of the day
        return date.getTime(); // Returns the timestamp in milliseconds
    }

    useEffect(() => {
        if (selectedMonth && selectedYear) {
            setStartOfMonthTimestamp(getStartOfMonthTimestamp(selectedYear, selectedMonth));
            setEndOfMonthTimestamp(getEndOfMonthTimestamp(selectedYear, selectedMonth));
        }
    }, [selectedYear, selectedMonth]);

    const routes = (appName) => [
        {
            path: `/${appName}`,
            breadcrumbName: t('home_menu_home'),
        },
        {
            breadcrumbName: t('home_menu_meter'),
        },
        {
            breadcrumbName: t('home_menu_meterData'),
        },
    ];

    return (
        <div>
            <Authorized
                scope={['perm:rmr:admin:meter-inventory']}
                yes={
                    <div>
                        <PageHeader className={styles.pageHeader} ghost={false}>
                            <div className={styles.positioning}>
                                <Row justify="space-between" align="middle" gutter={8}>
                                    <Col>
                                        <BreadcrumbComponent breadcrumbList={routes(config.appName)}/>
                                    </Col>
                                    <Col>
                                        <Row align="middle" gutter={[8, 8]}>
                                            <Space>
                                                {!outsideSerial &&
                                                    <Input
                                                        type="text"
                                                        style={{width: 200, marginRight:5}}
                                                        placeholder="Meter Serial"
                                                        onChange={handleChange}
                                                        onKeyDown={handleKeypress}
                                                        maxLength="12"
                                                        value={value}
                                                        pattern="[+-]?\d+(?:[.,]\d+)?"
                                                    />
                                                }
                                            </Space>
                                            <Col>
                                                <Space>
                                                    <Select
                                                        value={selectedOperationCode}
                                                        placeholder="Select"
                                                        style={{ width: 250 }}
                                                        onChange={(value) => setSelectedOperationCode(value)}
                                                    >
                                                        {makeOptionArray(OPERATION_CODES_DATA_VISUALIZAION).map((option) => (
                                                            <Select.Option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>

                                                </Space>
                                            </Col>
                                            <Col>
                                                <Space>
                                                    <Select
                                                        value={selectedYear}
                                                        placeholder="Select"
                                                        style={{ width: 250 }}
                                                        onChange={(value) => setSelectedYear(value)}
                                                    >
                                                        {yearOptions.map((yearOption) => (
                                                            <Select.Option key={yearOption.value} value={yearOption.value}>
                                                                {yearOption.label}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>

                                                </Space>
                                            </Col>
                                            <Col>
                                                <Space>
                                                    <Select
                                                        value={selectedMonth}
                                                        placeholder="Select"
                                                        style={{ width: 250, marginRight: 25 }}
                                                        onChange={(value) => setSelectedMonth(value)}
                                                    >
                                                        {selectedYearMonths.map((monthOption) => (
                                                            <Select.Option key={monthOption.value} value={monthOption.value}>
                                                                {monthOption.label}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Space>
                                            </Col>
                                            <Col>
                                                <Button
                                                    onClick={navigateDeviceView}
                                                    disabled={isLoading || isViewButtonDisabled}
                                                    style={{marginRight: 10}}
                                                    type="primary"
                                                    icon={<ExportOutlined/>}>
                                                    {t('label_visualizeDeviceView')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    {showMeterDetails && <MeterDataInfo meterInfo={meterData}/>}
                                </div>
                            </div>
                        </PageHeader>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                {meterSerial !== '' && selectedOperationCode && selectedYear && selectedMonth && (
                                    <div className="row">
                                        <div className="col-md-12">
                                            <IframeWrapper iframeSrc={dashboardUrl} height="550px" />
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Divider/>
                    </div>
                }
                no={
                    <Result
                        status="403"
                        title={t('noPerm_accessPageTitle')}
                        subTitle={t('noPerm_contactSysAdmin')}
                    />
                }
            />
        </div>
    );
};

export default withConfigContext(withTranslation()(MeterData));
