/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */
import React from 'react';
import { Form, Input, Space, Checkbox } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

class Server extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      isAuthRequired: false,
      counter: 0,
    };
  }

  onChange = e => {
    this.setState({ isAuthRequired: e.target.checked });
  };

  render() {
    const { field, remove, fields, passFields, t } = this.props;
    return (
      <Space
        key={field.key}
        style={{
          display: 'flex',
          marginBottom: 8,
          width: '100%',
          justifyContent: 'space-between',
        }}
        align="baseline"
      >
        <Form.Item
          {...field}
          name={[field.name, 'serverurl']}
          fieldKey={[field.fieldKey, 'serverurl']}
          rules={[
            {
              required: true,
              message: t('missing_txt', { label: t('label_serverUrl') }),
            },
          ]}
        >
          <Input placeholder={t('label_serverUrl')} style={{ width: '140%' }} />
        </Form.Item>
        {this.state.isAuthRequired ? (
          <>
            <Form.Item
              {...field}
              name={[field.name, 'username']}
              fieldKey={[field.fieldKey, 'username']}
              rules={[
                {
                  required: true,
                  message: t('missing_txt', { label: t('label_username') }),
                },
              ]}
            >
              <Input
                placeholder={t('label_username')}
                style={{ marginLeft: '20%' }}
              />
            </Form.Item>
            <Form.Item
              {...field}
              name={[field.name, 'password']}
              fieldKey={[field.fieldKey, 'password']}
              rules={[
                {
                  required: true,
                  message: t('missing_txt', { label: t('label_password') }),
                },
              ]}
            >
              <Input.Password placeholder={t('label_password')} />
            </Form.Item>
          </>
        ) : (
          <Checkbox onChange={this.onChange}>
            {t('label_authorizationRequired')}
          </Checkbox>
        )}
        <MinusCircleOutlined
          onClick={() => {
            remove(field.name);
            passFields(fields);
          }}
        />
      </Space>
    );
  }
}

export default withTranslation()(Server);
