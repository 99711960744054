/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Dropdown, Menu, Popconfirm } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

class EditOperationStatus extends React.Component {
  selectedStatus = null;

  constructor(props) {
    super(props);
    this.state = {
      popConfirmVisible: false,
    };
  }

  getItem = (label, key, icon, children, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  };

  getMenu = () => {
    const { t } = this.props;
    const items = [
      this.getItem(
        <a>
          {t('label_uppercase_string', {
            label: t('label_error'),
          })}
        </a>,
        'error',
      ),
    ];
    return <Menu onClick={() => this.setPopConfirm('ERROR')} items={items} />;
  };

  onConfirm = () => {
    this.props.updateOperationState(
      this.props.operationId,
      this.selectedStatus,
    );
    this.unsetPopConfirm();
  };

  setPopConfirm = status => {
    this.selectedStatus = status;

    this.setState({
      popConfirmVisible: true,
    });
  };

  unsetPopConfirm = () => {
    this.selectedStatus = null;

    this.setState({
      popConfirmVisible: false,
    });
  };

  render() {
    const { status, t } = this.props;
    return (
      <Popconfirm
        title={
          <div>
            {t('statusChange_confirmTxt', {
              status: status,
              selectedStatus: this.selectedStatus,
            })}
          </div>
        }
        onConfirm={this.onConfirm}
        trigger="click"
        onCancel={this.unsetPopConfirm}
        okText={t('label_yes')}
        cancelText={t('label_no')}
        visible={this.state.popConfirmVisible}
      >
        <Dropdown overlay={this.getMenu} placement="bottomLeft">
          <a onClick={e => e.preventDefault()}>
            <span
              style={{
                display: 'inline-block',
                width: 120,
                textAlign: 'center',
                color: '#a44632',
                background: '#ffc799',
                padding: '3px 10px 3px 10px',
                borderRadius: 7,
              }}
            >
              {t('label_uppercase_string', {
                label: t(`label_${status.toLowerCase()}`),
              })}
              <DownOutlined style={{ marginLeft: 1 }} />
            </span>{' '}
          </a>
        </Dropdown>
      </Popconfirm>
    );
  }
}

export default withTranslation()(EditOperationStatus);
