/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

export const MONTHS = [
  { label: 'January', value: '1' },
  { label: 'February', value: '2' },
  { label: 'March', value: '3' },
  { label: 'April', value: '4' },
  { label: 'May', value: '5' },
  { label: 'June', value: '6' },
  { label: 'July', value: '7' },
  { label: 'August', value: '8' },
  { label: 'September', value: '9' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

export const OPERATION_CODES_DATA_VISUALIZAION = [
  'BILLING_REGISTERS_RETRIEVE',
  'REMOTE_RELAY_ON',
  'REMOTE_RELAY_OFF',
  'SELF_TEST',
  'STATUS_RETRIEVE',
];

export const PROFILE_DATA_OPTIONS = [
  'LP',
];

export const SUB_TYPE_ID = {
  "1": "Anteleco - DLMS SinglePhase",
  "2": "MicroStar - DLMS Bulk",
  "3": "Anteleco - DLMS 3Phase",
  "4": "Anteleco - IEC SinglePhase",
  "5": "MicroStar - IEC Bulk",
  "6": "Anteleco - IEC 3Phase",
  "7": "Anteleco - NMD",
  "8": "Iskra - IEC Bulk"
};

export const BRANCHES = {
  '00': {
    name: 'HEADOFFICE',
    depots: { '00_1': 'TEST LAB' },
  },
  '01': {
    name: 'KOTTE',
    depots: { '01_1': 'PITA-KOTTE', '01_3': 'KOLONNAWA', '01_6': 'KOTIKAWATTA' },
  },
  '02': {
    name: 'KELANIYA',
    depots: {
      '02_1': 'DALUGAMA',
      '02_2': 'MAHARA',
      '02_3': 'WATTALA',
      '02_4': 'KANDANA',
    },
  },
  '03': {
    name: 'MORATUWA',
    depots: {
      '03_1': 'MORATUWA NORTH C.S.C',
      '03_2': 'MORATUWA SOUTH C.S.C',
      '03_3': 'KESELWATTA C.S.C',
      '03_4': 'PANADURA C.S.C',
    },
  },
  '04': {
    name: 'GALLE',
    depots: {
      '04_1': 'GALLE',
      '04_2': 'HIKKADUWA',
      '04_3': 'AMBALANGODA',
    },
  },
  '05': {
    name: 'KALUTARA',
    depots: {
      '05_1': 'KALUTHARA',
      '05_2': 'PAYAGALA',
      '05_3': 'ALUTHGAMA',
    },
  },
  '06': {
    name: 'NEGOMBO',
    depots: {
      '06_1': 'NEGOMBO',
      '06_2': 'SEEDUWA',
      '06_3': 'JA-ELA',
    },
  },
  '07': {
    name: 'NUGEGODA',
    depots: {
      '07_1': 'NUGEGODA',
      '07_2': 'BORALASGAMUWA',
      '07_3': 'MAHARAGAMA',
    },
  },
};

export const SUBTYPE_MAPPING = {
  "Anteleco - DLMS SinglePhase": "1",
  "MicroStar - DLMS Bulk": "2",
  "Anteleco -DLMS 3Phase": "3",
  "Anteleco - IEC SinglePhase": "4",
  "MicroStar - IEC Bulk": "5",
  "Anteleco - IEC 3Phase": "6",
  "Anteleco - NMD": "7",
  "Iskra - IEC Bulk": "8"
};

export const ALERT_TYPES = {
  DOOR: 'Door',
  TEMPERATURE: 'Temperature',
  GRID_POWER: 'GridPower',
  SHOCK: 'Shock'
};
