/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 *  Licensed under the Entgra Commercial License,
 *  Version 1.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *
 *  Unless required by applicable law or agreed to in writing,
 *  software distributed under the License is distributed on an
 *  "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 *  KIND, either express or implied.  See the License for the
 *  specific language governing permissions and limitations
 *  under the License.
 *
 *  You may obtain a copy of the License at
 *  https://entgra.io/licenses/entgra-commercial/1.0
 */

export const isHtmlOnly = str => {
  const fragment = document.createRange().createContextualFragment(str);

  // remove all nodes that doesn't contain text from fragment
  fragment.querySelectorAll('*').forEach(el => {
    if (!(el.parentNode.textContent || '').trim()) {
      el.parentNode.removeChild(el);
    }
  });

  // if there is no text, then not HTML only
  return !(fragment.textContent || '').trim();
};

export const timeRanges = (t) => [
  { id: 1, label: t('label_last_5mints'), value: 'from=now-5m&to=now' },
  { id: 2, label: t('label_last_15mints'), value: 'from=now-15m&to=now' },
  { id: 3, label: t('label_last_30mints'), value: 'from=now-30m&to=now' },
  { id: 4, label: t('label_last_1hr'), value: 'from=now-1h&to=now' },
  { id: 5, label: t('label_last_2hrs'), value: 'from=now-3h&to=now' },
  { id: 6, label: t('label_last_6hrs'), value: 'from=now-6h&to=now' },
  { id: 7, label: t('label_last_12hrs'), value: 'from=now-12h&to=now' },
  { id: 8, label: t('label_last_24hrs'), value: 'from=now-24h&to=now' },
  { id: 9, label: t('label_last_2days'), value: 'from=now-2d&to=now' },
  { id: 10, label: t('label_last_7days'), value: 'from=now-7d&to=now' },
  { id: 11, label: t('label_last_30days'), value: 'from=now-30d&to=now' },
  { id: 12, label: t('label_last_90days'), value: 'from=now-90d&to=now' },
];

export const hoursObj = [
  { hour: "00", range: "00:00-00:59" },
  { hour: "01", range: "01:00-01:59" },
  { hour: "02", range: "02:00-02:59" },
  { hour: "03", range: "03:00-03:59" },
  { hour: "04", range: "04:00-04:59" },
  { hour: "05", range: "05:00-05:59" },
  { hour: "06", range: "06:00-06:59" },
  { hour: "07", range: "07:00-07:59" },
  { hour: "08", range: "08:00-08:59" },
  { hour: "09", range: "09:00-09:59" },
  { hour: "10", range: "10:00-10:59" },
  { hour: "11", range: "11:00-11:59" },
  { hour: "12", range: "12:00-12:59" },
  { hour: "13", range: "13:00-13:59" },
  { hour: "14", range: "14:00-14:59" },
  { hour: "15", range: "15:00-15:59" },
  { hour: "16", range: "16:00-16:59" },
  { hour: "17", range: "17:00-17:59" },
  { hour: "18", range: "18:00-18:59" },
  { hour: "19", range: "19:00-19:59" },
  { hour: "20", range: "20:00-20:59" },
  { hour: "21", range: "21:00-21:59" },
  { hour: "22", range: "22:00-22:59" },
  { hour: "23", range: "23:00-23:59" },
];
