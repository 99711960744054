/*
 * Copyright (C) 2020. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import CustomInput from '../CustomInput';
import CustomCheckbox from '../CustomCheckbox';
import CustomSelect from '../CustomSelect';
import CustomSearchInput from '../CustomSearchInput';
import CustomTextArea from '../CustomTextArea';
import CustomRadioGroup from '../CustomRadioGroup';
import CustomTimeSelector from '../CustomTimeSelector';
import CustomTable from '../CustomTable';
import { checkCondition } from '../../services/payloadHandler';
import CustomUpload from '../CustomUpload';
import CustomAPITable from '../CustomAPITable';
import { Row, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import CustomInputList from '../CustomInputList';
import { withTranslation } from 'react-i18next';

class Content extends React.Component {
  constructor(props) {
    super(props);
  }

  setUpdatedItem = (index, item, file) => {
    let items = [...this.props.content.items];
    items[index] = item;

    if (item.hasOwnProperty('upload') && item.upload.setFileName === true) {
      if (items[items.length - 1].key === 'fileName') {
        items[items.length - 1] = {
          key: 'fileName',
          value: file,
        };
      } else {
        items.push({
          key: 'fileName',
          value: file,
        });
      }
    }

    const content = { ...this.props.content };
    content.items = items;

    this.props.callback(content);
  };

  setUpdatedSubContent = (index, subContent) => {
    let subContents = [...this.props.content.subContents];
    subContents[index] = subContent;

    let content = { ...this.props.content };
    content.subContents = subContents;

    this.props.callback(content);
  };

  getContentItem = content => {
    const { t } = this.props;
    return content.items.map((item, index) => {
      if (checkCondition(item, content) === false) {
        return;
      }
      if (item.hasOwnProperty('input')) {
        return (
          <div key={index}>
            <CustomInput
              itemData={item}
              disabled={this.props.disabled}
              callback={value => {
                this.setUpdatedItem(index, value);
              }}
            />
          </div>
        );
      } else if (item.hasOwnProperty('checkbox')) {
        return (
          <div key={index}>
            <CustomCheckbox
              itemData={item}
              disabled={this.props.disabled}
              callback={value => {
                this.setUpdatedItem(index, value);
              }}
            />
          </div>
        );
      } else if (item.hasOwnProperty('select')) {
        return (
          <div key={index}>
            <CustomSelect
              itemData={item}
              disabled={this.props.disabled}
              callback={value => {
                this.setUpdatedItem(index, value);
              }}
            />
          </div>
        );
      } else if (item.hasOwnProperty('TextArea')) {
        return (
          <div key={index}>
            <CustomTextArea
              itemData={item}
              disabled={this.props.disabled}
              callback={value => {
                this.setUpdatedItem(index, value);
              }}
            />
          </div>
        );
      } else if (item.hasOwnProperty('radioGroup')) {
        return (
          <div key={index}>
            <CustomRadioGroup
              itemData={item}
              disabled={this.props.disabled}
              callback={value => {
                this.setUpdatedItem(index, value);
              }}
            />
          </div>
        );
      } else if (item.hasOwnProperty('timeSelector')) {
        return (
          <div key={index}>
            <CustomTimeSelector
              itemData={item}
              disabled={this.props.disabled}
              callback={value => {
                this.setUpdatedItem(index, value);
              }}
            />
          </div>
        );
      } else if (item.hasOwnProperty('table')) {
        return (
          <div key={index}>
            <CustomTable
              itemData={item}
              disabled={this.props.disabled}
              callback={value => {
                this.setUpdatedItem(index, value);
              }}
            />
          </div>
        );
      } else if (item.hasOwnProperty('upload')) {
        return (
          <div key={index}>
            <CustomUpload
              itemData={item}
              disabled={this.props.disabled}
              callback={(value, file) => {
                this.setUpdatedItem(index, value, file);
              }}
            />
          </div>
        );
      } else if (item.hasOwnProperty('apiTable')) {
        return (
          <div key={index}>
            <CustomAPITable
              itemData={item}
              disabled={this.props.disabled}
              callback={value => {
                this.setUpdatedItem(index, value);
              }}
              deviceType={this.props.deviceType}
            />
          </div>
        );
      } else if (item.hasOwnProperty('text')) {
        return (
          <div key={index}>
            <div style={{ margin: 5 }}>
              <Row>
                <span>
                  {t(item.label)}&nbsp;
                  <Tooltip title={t(item.tooltip)} placement="right">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              </Row>
              <Row>
                <b>{item.value}</b>
              </Row>
            </div>
          </div>
        );
      } else if (item.hasOwnProperty('inputList')) {
        return (
          <div key={index}>
            <CustomInputList
              itemData={item}
              disabled={this.props.disabled}
              callback={value => {
                this.setUpdatedItem(index, value);
              }}
            />
          </div>
        );
      } else if (item.hasOwnProperty('searchInput')) {
        return (
          <div key={index}>
            <CustomSearchInput
              itemData={item}
              disabled={this.props.disabled}
              callback={value => {
                this.setUpdatedItem(index, value);
              }}
            />
          </div>
        );
      }
      return <div key={index} />;
    });
  };

  render() {
    return (
      <div>
        {this.getContentItem(this.props.content)}
        {this.props.content.hasOwnProperty('subContents') && (
          <div>
            {this.props.content.subContents.map((content, index) => {
              if (checkCondition(content, this.props.content)) {
                return (
                  <Content
                    key={index}
                    callback={subContent =>
                      this.setUpdatedSubContent(index, subContent)
                    }
                    content={content}
                    disabled={this.props.disabled}
                  />
                );
              }
            })}
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(Content);
