/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {
  Card,
  Col,
  Divider,
  Row,
  Typography
} from 'antd';
import { withConfigContext } from '../../../../components/ConfigContext';

const { Meta } = Card;
const { Text } = Typography;

const SelectDeviceType = ({ context, onDeviceTypeClick, description, config }) => {
  //remove sim from device types
  const deviceTypesDetails = config.deviceTypesDetails.filter(deviceType => deviceType.name !== "sim");

  const handleClickCard = (e, deviceType) => {
    const properties = deviceType?.deviceTypeMetaDefinition?.properties ?? [];
    const callbackPayload = {
      deviceType: { type: deviceType.name, properties },
    };
    onDeviceTypeClick(callbackPayload);
  };

  const itemCard = deviceTypesDetails.map((deviceType, index) => {
    let icon = context.defaultPlatformIcons.default.icon;
    if (deviceType.name.toLowerCase() in context.defaultPlatformIcons) {
      icon = context.defaultPlatformIcons[deviceType.name.toLowerCase()].icon;
    }
    return (
      <Col span={25} key={index}>
        <a>
          <Card
            size="default"
            style={{ width: 150, marginTop: 5 }}
            bordered={true}
            onClick={(e) => handleClickCard(e, deviceType)}
            cover={
              <LegacyIcon
                type={icon}
                key="device-types"
                style={{
                  color: "#ffffff",
                  backgroundColor: "#4b92db",
                  fontSize: "100px",
                  padding: "20px"
                }}
              />
            }
          >
            <Meta
              style={{ textAlign: "center" }}
              title={deviceType.name.toUpperCase()}
            />
          </Card>
        </a>
      </Col>
    );
  });

  return (
    <div>
      {description && (
        <div>
          <Text type="secondary">{description}</Text>
          <Divider />
        </div>
      )}
      <Row gutter={16}>{itemCard}</Row>
    </div>
  );
};

export default withConfigContext(SelectDeviceType);
