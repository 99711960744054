import React from 'react';
import styles from './styles.module.css';
import {getBranchName, getDepotName, getSubTypeName} from "../../../../services/utils/functions";

const MeterDataInfo = (props) => {
    const category = props.meterInfo.category;
    return (
        <div className={styles.cardBody}>
            <div className={styles.row}>
                <div className={styles.column}>
                    <span><b>Serial</b></span>
                    <h6>{props.meterInfo.serial}</h6>
                </div>
                <div className={styles.column}>
                    <span><b>IP Address</b></span>
                    <h6>{props.meterInfo.ipAddress}</h6>
                </div>
                <div className={styles.column}>
                    <span><b>Meter Type</b></span>
                    <h6>{getSubTypeName(props.meterInfo.meterType)}</h6>
                </div>
                <div className={styles.column}>
                    <span><b>Category</b></span>
                    <h6>{category !== undefined ? category.replace('_', ' ') : category}</h6>
                </div>
                <div className={styles.column}>
                    <span><b>Status</b></span>
                    <h6>{props.meterInfo.status}</h6>
                </div>
                <div className={styles.column}>
                    <span><b>Facility</b></span>
                    <h6>{getBranchName(props.meterInfo.branch)}</h6>
                </div>
                <div className={styles.column}>
                    <span><b>Depot</b></span>
                    <h6>{getDepotName(props.meterInfo.branch,props.meterInfo.depot)}</h6>
                </div>
                <div className={styles.column}>
                    <span><b>Substation</b></span>
                    <h6>{props.meterInfo.substation}</h6>
                </div>
                <div className={styles.column}>
                    <span><b>Account No/Ref</b></span>
                    <h6>{props.meterInfo.customerRef}</h6>
                </div>
            </div>
        </div>
    );
};

export default MeterDataInfo;
