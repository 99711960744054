import {RoutesContext} from '../../index';
import React, {useState, useEffect, useContext} from 'react';
import {Col, Result, Row} from 'antd';
import {withConfigContext} from '../../../../components/ConfigContext';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import {withTranslation} from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import { subDays } from 'date-fns';
import IframeWrapper from '../../components/IframeWrapper';
import { getUnixTime } from "../../../../services/utils/functions";
import configs from "../../../../../public/conf/config.json";

const OtherDevicesHistory = (props) => {
    const config = props.context;
    const {t} = props;
    const routesContext = useContext(RoutesContext);
    const rightNow = new Date();
    const [range, setRange] = useState({
        from: getUnixTime(subDays(rightNow, 2)),
        to: getUnixTime(rightNow),
    });
    const [deviceId, setdeviceIdValue] = useState('');
    const [outsidedeviceId, setOutsidedeviceId] = useState(false);
    const [dashboardUrl1, setDashboardUrl1] = useState('');
    const [dashboardUrl2, setDashboardUrl2] = useState('');

    useEffect(() => {
        routesContext.setCurrentRoute('allOtherDevicesHistory');
        const pathSegments = window.location.pathname.split('/');
        const deviceType = pathSegments[3];
        const deviceId = pathSegments[4];

        if (deviceId) {
            setdeviceIdValue(deviceId);
            setOutsidedeviceId(true);
        }

        // Update dashboard URLs based on deviceType
        if (deviceType === 'jht') {
            setDashboardUrl1(`${configs.grafanaConfig.temperatureSensorsLastReading}&var-device=${deviceId}`);
            setDashboardUrl2(`${configs.grafanaConfig.temperatureSensorsHistoricalReading}&var-device=${deviceId}`);

        } else if (deviceType === 'jups') {
            setDashboardUrl1(`${configs.grafanaConfig.UPSSensorsLastReading}&var-device=${deviceId}`);
            setDashboardUrl2(`${configs.grafanaConfig.UPSSensorsHistoricalReading}&var-device=${deviceId}`);
        }
    }, [t]);

    const routes = (appName) => [
        {
            path: `/${appName}`,
            breadcrumbName: t('home_menu_home'),
        },
        {
            breadcrumbName: t('home_menu_other_devices'),
        },
        {
            breadcrumbName: t('home_menu_other_devices_view_per_device'),
        },
    ];

    return (
        <div>
            <Authorized
                scope={['perm:rmr:admin:meter-inventory']}
                yes={
                    <div>
                        <PageHeader className={styles.pageHeader} ghost={false}>
                            <div className={styles.positioning}>
                                <Row justify="space-between" align="middle" gutter={8}>
                                    <Col>
                                        <BreadcrumbComponent breadcrumbList={routes(config.appName)}/>
                                    </Col>
                                </Row>
                            </div>
                        </PageHeader>
                        <Row gutter={12}>
                            {/*<Col span={24}>*/}
                            {/*    <div className={styles.iframeContainer}>*/}
                            {/*        <IframeWrapper*/}
                            {/*            iframeSrc={dashboardUrl1}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</Col>*/}
                            <Col xl={12} xs={24}>
                                <div className={styles.iframeContainer}>
                                    <IframeWrapper
                                        iframeSrc={dashboardUrl1}
                                        height={"700px"}
                                    />
                                </div>
                            </Col>
                            <Col xl={12} xs={24}>
                                <div className={styles.iframeContainer}>
                                    <IframeWrapper
                                        iframeSrc={dashboardUrl2}
                                        height={"700px"}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                }
                no={
                    <Result
                        status="403"
                        title={t('noPerm_accessPageTitle')}
                        subTitle={t('noPerm_contactSysAdmin')}
                    />
                }
            />
        </div>
    );
};

export default withConfigContext(withTranslation()(OtherDevicesHistory));
